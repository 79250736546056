{
  "it": {
    "accounts": {
      "passwords": {
        "form": {
          "login": "login",
          "submit": "Richiedere"
        }
      },
      "sessions": {
        "form": {
          "forgot_your_password": "Hai dimenticato la password?",
          "submit": "login"
        },
        "new": {
          "title": "login"
        }
      },
      "switch_users": {
        "success": "Accesso a %{organization} riuscito"
      }
    },
    "action_menu": {
      "admin": {
        "mail_templates": {
          "single": {
            "delete_confirm": "Sei sicuro di voler eliminare questo modello di email?"
          }
        }
      },
      "assessments": {
        "clients_contact_persons": {
          "single": {
            "delete_confirm": "Sei sicuro di voler eliminare questo contatto?"
          }
        },
        "labels": {
          "single": {
            "delete_confirm": "Sei sicuro di voler rimuovere questa etichetta?"
          }
        },
        "program_templates": {
          "single": {
            "delete_confirm": "Sei sicuro di voler eliminare questo processo?"
          }
        },
        "programs": {
          "single": {
            "delete_confirm": "Sei sicuro di voler eliminare questo processo?"
          }
        }
      },
      "deactivation_confirm": "Disattivando %{name}, %{name} non avrà più accesso al sito e verrà automaticamente eliminato su %{retention_period}. Tieni presente che gli %{type} inattivi vengono nascosti dalle impostazioni di filtro predefinite. Modifica i filtri se desideri includerli nella panoramica.",
      "delete_confirm": "L'eliminazione di %{name} elimina automaticamente anche tutte le possibili traiettorie sottostanti.",
      "ixly": {
        "fixed_prices": {
          "single": {
            "delete_confirm": "Sei sicuro di voler eliminare questo prezzo fisso?"
          }
        },
        "job_profiles": {
          "single": {
            "delete_confirm": "Sei sicuro di voler eliminare questo modello?"
          }
        },
        "migration_organizations": {
          "default": {
            "add": "Aggiungi una nuova organizzazione di migrazione",
            "start": "Avvia le migrazioni"
          }
        },
        "subscriptions": {
          "single": {
            "delete_confirm": "Sei sicuro di voler eliminare questo abbonamento?"
          }
        }
      },
      "selection": {
        "job_profiles": {
          "single": {
            "delete_confirm": "Sei sicuro di voler eliminare questo profilo lavorativo?"
          }
        }
      }
    },
    "activemodel": {
      "attributes": {
        "queries/usage": {
          "end_date": "Data di fine",
          "organization": "Organizzazione",
          "start_date": "Data d'inizio"
        }
      }
    },
    "admin": {
      "authentication_actions": {
        "filter_form": {
          "authenticate": "login",
          "impersonate": "Accedi come..."
        },
        "index": {
          "action": "Azione",
          "browser": "Navigatore",
          "caption": "Di seguito sono riportati tutti gli eventi relativi all'autenticazione. Questi eventi vengono conservati per un massimo di 6 mesi.",
          "created_at": "Quando",
          "ip": "indirizzo IP",
          "title": "Registri di autenticazione"
        }
      },
      "candidate_csv_imports": {
        "import_file": {
          "log": "Tronco d'albero",
          "original": "Originale"
        },
        "index": {
          "list": "Contiene i file di importazione dei candidati all'interno dell'organizzazione",
          "title": "Importazione CSV del candidato"
        },
        "new": {
          "title": "Importazione CSV del candidato"
        }
      },
      "color_settings": {
        "destroy": {
          "destroyed": "Le impostazioni del colore sono state ripristinate ai valori predefiniti.",
          "reset_button": "Ripristina la pianificazione predefinita"
        },
        "edit": {
          "color_settings_explanation": "Puoi modificare la combinazione di colori della pagina dei risultati interattivi per tutti gli utenti utilizzando i campi sottostanti",
          "title": "Combinazione di colori"
        },
        "update": {
          "updated": "Le impostazioni del colore dell'organizzazione sono state aggiornate con successo."
        }
      },
      "default_mail_templates": {
        "could_not_be_saved": "Il modello di posta predefinito non può essere salvato",
        "explain": "Qui puoi scegliere tra i modelli di email organizzativi pubblicati e impostarli come predefiniti. Non è possibile scegliere i modelli di posta dell'applicazione. Se lo desideri, dovrai prima copiarli nella tua organizzazione.",
        "header": "Impostazioni dell 'email",
        "saved_successfully": "Modello di posta predefinito salvato!",
        "updated_successfully": "Modello di posta predefinito aggiornato!"
      },
      "default_reminder_mail_templates": {
        "could_not_be_saved": "Impossibile salvare il modello di email di promemoria predefinito",
        "explain": "Qui puoi scegliere tra i modelli di email organizzativi pubblicati e impostarli come predefiniti. Non è possibile scegliere i modelli di email dell'applicazione. Se lo desideri, devi prima copiarlo nella tua organizzazione.",
        "header": "Impostazioni e-mail di promemoria",
        "saved_successfully": "Modello email di promemoria predefinito salvato!",
        "updated_successfully": "Modello email di promemoria predefinito aggiornato con successo!"
      },
      "entries": {
        "index": {
          "candidate_assessment_entry": "Valutazione",
          "candidate_assessment_program_entry": "Programma di valutazione",
          "candidate_report_entry": "Rapporto",
          "generated_report_entry": "Rapporto",
          "leniency_entry": "Gentilezza",
          "list": {
            "candidate": "Candidato",
            "cost_center": "Centro di costo",
            "credits": "Numero",
            "date": "Data",
            "description": "Descrizione",
            "entry_type": "Tipo di transazione"
          },
          "order": {
            "frequent_credits": "Addebito mensile per i crediti utilizzati",
            "incidental_credits": "Acquisto di pacchetti di crediti",
            "intensive_credits": "Addebito mensile per i crediti utilizzati"
          },
          "order_entry": "Ordine",
          "title": "Panoramica della contabilità generale"
        }
      },
      "mail_templates": {
        "add": "Aggiungi modello di posta elettronica",
        "create": {
          "success": "Il nuovo modello di email è stato creato"
        },
        "destroy": {
          "deleted": "Il modello di email è stato eliminato"
        },
        "duplicate": {
          "duplicated": "Il modello di email \"%{title}\" è stato copiato"
        },
        "duplicated_title": "Copia di %{title}",
        "edit": {
          "title": "Modifica modello di posta elettronica"
        },
        "form": {
          "assessment_platform_link_explanation": "Un collegamento che indirizza il candidato al posto giusto nella piattaforma di valutazione. Se non hanno ancora impostato la password verranno indirizzati alla pagina di impostazione della password, altrimenti alla pagina di login. Questo campo è obbligatorio*",
          "assignment_title_explanation": "I titoli degli incarichi assegnati",
          "candidate_name_explanation": "Il nome dell'utente",
          "end_date_explanation": "L'ultima data entro la quale il percorso può essere completato",
          "explanation_text": "Le variabili possono essere utilizzate all'interno del testo del modello di posta per inserire dinamicamente il contenuto. Queste variabili sono circondate da %{VARIABLE}, dove VARIABILE è il nome e dove è completamente sostituito. Attualmente supportiamo quanto segue",
          "organization_name_explanation": "Il nome dell'organizzazione attuale",
          "passwordless_login_link_explanation": "Questo collegamento indirizza il candidato alla pagina di accesso della piattaforma di valutazione. Non è richiesta alcuna password per accedere. Tuttavia, il candidato può utilizzare questo collegamento solo una volta per accedere al sito web. Questo campo o la variabile assess_platform_link deve essere presente nel corpo dell'email*."
        },
        "index": {
          "application_template": "Modelli di applicazione",
          "help_text": "I modelli di posta sono le email predefinite che i tuoi consulenti possono inviare ai candidati. Solo i modelli pubblicati possono essere selezionati da un consulente. I modelli di applicazione vengono forniti come esempio.",
          "language": "Lingua",
          "mail_title": "Titolo",
          "state": "Stato",
          "subject": "Soggetto",
          "title": "Modelli di posta elettronica",
          "updated_at": "Aggiornato il"
        },
        "new": {
          "title": "Nuovo modello di posta elettronica"
        },
        "publish": {
          "cannot_publish": "Impossibile pubblicare il modello di email %{title}:<br> %{error_messages}",
          "published": "Il modello di email %{title} è stato pubblicato"
        },
        "show": {
          "body": "Contenuto dell'e-mail",
          "example_title": "Esempio dell'e-mail che verrà inviata",
          "subject": "Soggetto",
          "title": "Esempio del modello di email %{title}"
        },
        "update": {
          "cannot_publish": "Impossibile aggiornare il modello di email:<br> %{error_messages}",
          "success": "Modello di posta elettronica aggiornato",
          "title": "Modifica modello di posta elettronica"
        },
        "withdraw": {
          "withdrawn": "Il modello di email %{title} è stato ritirato"
        }
      },
      "organization_permissions": {
        "edit": {
          "help_text": "Qui puoi limitare quali ruoli utente hanno accesso a quali parti dell'applicazione",
          "title": "Diritti dell'utente"
        },
        "update": {
          "updated": "Le autorizzazioni dell'organizzazione sono state aggiornate."
        }
      },
      "organization_settings": {
        "default_report_objective": {
          "advice": "Consiglio",
          "selection": "Selezione"
        },
        "default_report_to": {
          "advisor": "L'allenatore",
          "both": "Entrambi",
          "candidate": "Il candidato"
        },
        "edit": {
          "available_languages_explanation": "Se disabiliti le lingue utilizzate dai tuoi utenti, le loro impostazioni della lingua passeranno automaticamente alla lingua predefinita della tua organizzazione.",
          "default_language_explanation": "La lingua selezionata funge da impostazione predefinita per i nuovi utenti all'interno dell'organizzazione.",
          "default_report_objective_explanation": "L'obiettivo di reporting predefinito viene selezionato come predefinito durante la creazione di un nuovo candidato. Se l'obiettivo è consigliare i candidati per svilupparsi ulteriormente, scegli \"Consulenza\"; Se l'obiettivo è selezionare i candidati più adatti, scegli \"Selezione\". Sulla base di questo obiettivo verrà selezionato automaticamente il report più adatto al candidato.",
          "default_report_to_explanation": "Questa impostazione determina chi avrà accesso al report dopo che una valutazione è stata completata: il candidato, il consulente o entrambi. Se viene selezionato \"consulente\", il consulente avrà la possibilità di impostarlo per valutazione. Quando si modifica un processo, è sempre possibile impostarlo per valutazione.",
          "title": "Impostazioni dell'organizzazione"
        },
        "update": {
          "required_languages": "Le lingue disponibili non possono essere vuote e la lingua predefinita deve essere disponibile nell'organizzazione",
          "updated": "Le impostazioni dell'organizzazione sono state aggiornate con successo."
        }
      },
      "privacy_settings": {
        "edit": {
          "advisor_deactivation_period_explanation": "Il periodo di inattività dopo il quale gli allenatori vengono disattivati.",
          "candidate_deactivation_period_explanation": "Il periodo di inattività dopo il quale i candidati vengono disattivati.",
          "impersonation_explanation": "Se abiliti questa opzione, i consulenti con diritti \"accedi come\" possono accedere agli account che gestiscono.",
          "ixly_support_explanation": "Se abiliti questa opzione, dai ai dipendenti dell'helpdesk Ixly la possibilità di monitorare i tuoi utenti per risolvere i problemi segnalati tramite l'helpdesk.",
          "retention_period_explanation": "Il Regolamento generale sulla protezione dei dati (GDPR) prevede che i dati personali non possano essere conservati più a lungo del necessario. Per ottemperare a ciò, i candidati, i consulenti e i contatti inattivi vengono automaticamente cancellati in base al periodo di conservazione scelto. Questo periodo di conservazione si applica anche alle valutazioni utilizzate e ai report generati.",
          "retention_period_explanation_addition": "Qualora il periodo di disattivazione sia pari al periodo di conservazione, il consulente e/o candidato verrà contestualmente disattivato e cancellato.",
          "title": "Privacy e conservazione dei dati"
        },
        "update": {
          "updated": "Le impostazioni sulla privacy dell'organizzazione sono state aggiornate con successo."
        }
      },
      "whitelabel": {
        "edit": {
          "title": "Impostazioni dell'etichetta bianca"
        },
        "update": {
          "whitelabel_updated": "La Whitelabel è stata aggiornata"
        }
      }
    },
    "advisors": {
      "index": {
        "title": "Consiglieri"
      }
    },
    "alt_texts": {
      "approval_request_send_reason": "Invia questo motivo",
      "component_add": "Aggiungi questo elemento",
      "component_delete": "Elimina questo elemento",
      "component_move": "Sposta questo elemento",
      "component_settings": "Apri le impostazioni dell'elemento",
      "details_collapse": "Comprimi i dettagli",
      "details_expand": "Espandi i dettagli",
      "document_delete": "Elimina questo rapporto salvato",
      "document_download": "Scarica questo rapporto salvato",
      "document_save": "Salva questo rapporto",
      "document_share": "Condividi questo report salvato con il candidato",
      "document_unshare": "Condividi le impostazioni di cui sopra con il candidato",
      "enrollment_page_print": "Stampa questo passaggio",
      "enrollment_page_requiring_action": "Contiene elementi con impostazioni aggiuntive",
      "file_download": "Scarica questo file",
      "filter_bar_collapse": "Comprimi i filtri",
      "filter_bar_expand": "Espandi i filtri",
      "interactive_results_agile_average": "Grafico a linee tratteggiate al %{percentage} percento da destra. Più alta è questa percentuale, più agile sei.",
      "interactive_results_agile_pillars": "Grafico che mostra i tuoi punteggi secondari come settori cliccabili di un grafico a torta. Forza %{power}, Cambiamento %{change}, Crescita personale %{growth}.",
      "interactive_results_info_birthdate": "Data di nascita",
      "interactive_results_info_date_of_birth": "Data di nascita",
      "interactive_results_info_education": "Livello di istruzione",
      "interactive_results_info_email": "Indirizzo e-mail",
      "interactive_results_info_study_subject": "Corso",
      "interactive_results_info_work_experience": "Esperienza lavorativa più recente",
      "interactive_results_info_years_experience": "Numero di anni di esperienza",
      "interactive_results_information": "Apri la spiegazione dei punteggi",
      "interactive_results_intelligence_chart": "Grafico che mostra il tuo punteggio QI di %{iq} su una distribuzione normale. Le linee tratteggiate mostrano i margini di errore.",
      "interactive_results_next": "Prossimo",
      "interactive_results_paging": "Pagina %{page} di %{pages}",
      "interactive_results_previous": "Precedente",
      "interactive_results_rating": "%{score} stelle su %{max}",
      "interactive_results_tip": "Apri la punta",
      "ixly_test_download": "Scarica questo rapporto",
      "ixly_test_settings": "Apri le impostazioni del rapporto",
      "loading": "Carichiamo...",
      "navbar_expand": "Espandi il menu di navigazione",
      "notification_close": "Chiudi questa attività",
      "notification_reopen": "Riapri questa attività",
      "organization_logo": "Logo di %{name}",
      "organization_logo_change": "Logo di %{name}: fare clic per modificare",
      "organization_logo_home": "Logo di %{name}: fai clic per iniziare",
      "post_rule_delete": "Elimina questa riga di condivisione",
      "price_in_euros": "in euro",
      "program_page_delete": "Rimuovi questo passaggio",
      "program_page_move": "Sposta questo passaggio",
      "program_page_settings": "Apri le impostazioni del passaggio",
      "program_settings": "Apri le impostazioni del processo",
      "quiz_correct": "Questa risposta è corretta",
      "quiz_incorrect": "Questa risposta non è corretta",
      "quiz_selected": "Questa risposta è stata selezionata",
      "saved": "Le modifiche sono state salvate",
      "saving": "Le modifiche vengono salvate",
      "template_column_delete": "Elimina questa colonna",
      "template_column_move": "Sposta questa colonna",
      "template_column_settings": "Apri le impostazioni della colonna",
      "template_element_delete": "Elimina questa sezione",
      "template_element_move": "Sposta questa sezione",
      "template_element_settings": "Apri le impostazioni della sezione",
      "template_element_variables": "Apri le impostazioni delle variabili",
      "template_item_delete": "Elimina questo elemento",
      "template_row_delete": "Elimina questa riga",
      "template_row_move": "Sposta questa riga",
      "template_zoom_in": "Ingrandire",
      "template_zoom_out": "Rimpicciolisci",
      "top_step_delete": "Elimina questo gruppo di passaggi",
      "top_step_insert": "Inserisci un gruppo di passaggi",
      "user_avatar": "Foto del profilo di %{name}",
      "user_avatar_change": "Foto del profilo di %{name}: fai clic per modificare",
      "view_switcher_cards": "Visualizza come tessere",
      "view_switcher_table": "Visualizza come tabella"
    },
    "approval_requests": {
      "index": {
        "help_text": "Qui troverai tutti i documenti che ti è stato richiesto di approvare",
        "table_header": [
          "File",
          "Candidate",
          "Processi",
          "Stato",
          "Cavalcò",
          "Allenatore"
        ],
        "title": "Accordi"
      }
    },
    "assessments": {
      "advisors": {
        "account_email": "E-mail",
        "create": {
          "created": "Il consulente è stato creato",
          "created_and_invited": "L'advisor è stato creato e accolto via email",
          "title": "Aggiungi consigliere"
        },
        "created_at": "Fatto su",
        "deactivate": {
          "advisor_deactivated": "L'advisor è stato disattivato"
        },
        "edit": {
          "labels": "Etichette",
          "title": "Modifica il consigliere"
        },
        "form": {
          "add_label": "Aggiungi etichetta",
          "impersonation_explanation": "Se selezioni questa opzione, concedi a questo utente il diritto di accedere come un altro utente",
          "role_explanation": "Fare clic per visualizzare la documentazione dettagliata del ruolo"
        },
        "index": {
          "candidates": "Candidati",
          "labels": "Etichette",
          "list": "Tutti i consulenti della tua organizzazione vengono visualizzati qui",
          "title": "Consiglieri"
        },
        "label": {
          "remove": "Cancellare"
        },
        "last_activity": "Ultima attività",
        "name": "Nome",
        "new": {
          "title": "Aggiungi consigliere"
        },
        "organization": "Organizzazione",
        "privacy_accepted_at": "Privacy accettata",
        "reactivate": {
          "advisor_reactivated": "L'advisor è stato nuovamente attivato"
        },
        "roles": "Rotolo",
        "send_welcome": {
          "welcome_mail_sent": "L'e-mail di benvenuto è stata inviata"
        },
        "show": {
          "list": {
            "track_with": "Traccia con"
          }
        },
        "status": "Stato",
        "two_factor": "Verifica in due passaggi",
        "type": "Tipo",
        "unconfirmed_email": "E-mail non confermata",
        "update": {
          "advisor_updated": "Il consigliere è stato aggiornato",
          "title": "Modifica il consigliere"
        }
      },
      "assessment_report_settings": {
        "edit": {
          "title": "Impostazioni del rapporto"
        },
        "update": {
          "success": "Le impostazioni del rapporto sono state aggiornate correttamente"
        }
      },
      "assessment_verifications": {
        "link": "Verifica",
        "show": {
          "about_verification_tests": "Un test di verifica è una versione abbreviata facoltativa dell'intero %{assessment}. I test di capacità vengono sempre più completati a distanza, senza supervisione. In questa situazione è difficile valutare se il candidato abbia sostenuto il test da solo e senza alcun ausilio. Puoi migliorare questa valutazione utilizzando un test di verifica, che viene completato sotto supervisione.",
          "about_verification_tests_title": "Informazioni sui test di verifica",
          "run_test": "Ora esegui il test di verifica",
          "title": "Autenticazione necessaria per %{assessment} di %{candidate}",
          "verification_tests_report": "Una volta creato il test di verifica, riceverai una pagina aggiuntiva nel report che mostra se i punteggi dell'%{assessment} precedentemente creato corrispondono in qualche modo ai punteggi del test di verifica. Se è così, vedrai un segno di spunta verde nel rapporto. Se vedi una croce rossa nel rapporto, la differenza tra i risultati del telecomando %{assessment} e il test di verifica è maggiore di quanto ci aspetteremmo in base al caso.",
          "verification_tests_report_title": "Differenze nel rapporto",
          "why_skip_verification_tests": "Se un candidato ha completato un È anche meno rilevante se i risultati del candidato sono inferiori al livello atteso. Il test di verifica non indica se il candidato ha ottenuto un punteggio più alto dopo aver sostenuto questo test di verifica. Puoi solo vedere se i punteggi del secondo test corrispondono al primo test.",
          "why_skip_verification_tests_title": "Scommettere o non scommettere?"
        },
        "skip": {
          "success": "La verifica di %{title} è stata saltata con successo"
        },
        "update": {
          "success": "La verifica di %{title} è iniziata con successo"
        }
      },
      "assignments": {
        "edit": {
          "end_date": "Modifica la data di fine",
          "end_date_explanation": "Ultimo giorno in cui l'attività può essere completata",
          "invitation_date": "Modifica la data dell'invito",
          "invitation_mail_title": "Personalizza l'e-mail di invito",
          "planning_management": "Gestione della pianificazione",
          "reminder_date": "Modifica l'e-mail di promemoria",
          "reminder_date_explanation": "La data in cui al candidato viene ricordata l'attività via email. Cancella il campo per eliminare l'e-mail di promemoria.",
          "reminder_mail_title": "Modifica l'e-mail di promemoria",
          "title": "Modifica la gestione della pianificazione per %{assessment} di %{candidate}"
        },
        "invite": {
          "candidate_double_invited": "Il candidato è stato invitato via email e avvisato alle %{title}",
          "candidate_invited": "Il candidato è stato avvisato tramite email all'indirizzo %{title}",
          "cannot_invite": "Non è stato possibile invitare il candidato a %{title}, il candidato era già invitato."
        },
        "prepare_invite": {
          "title": "Invita %{candidate} a %{assessment}"
        },
        "updated": "Compito aggiornato con successo"
      },
      "bulk_assignments": {
        "already_assigned": "%{assignment} è già selezionato",
        "assessment_not_available_in_subscription": "Questo processo include una valutazione che non è più disponibile nel tuo abbonamento. Modificare il processo in modo che possa essere assegnato nuovamente.",
        "blank_description": "Non è disponibile alcuna descrizione per questo processo.",
        "create": {
          "action_required": "La causa è stata accolta. È ancora necessaria un'azione prima che il candidato possa essere invitato.",
          "candidate_invited": "Il candidato è stato informato via e-mail della nuova procedura assegnata",
          "cannot_enroll": "Al momento non puoi assegnare questo processo",
          "enrollment_created": "La causa è stata accolta. Il candidato non è stato ancora informato."
        },
        "duration": "%{n} minuti",
        "example_report": "Apri il report di esempio in una nuova scheda",
        "more_information": "Maggiori informazioni",
        "new": {
          "selected_assessment_programs": "Programmi di valutazione selezionati",
          "selected_assessments": "Valutazioni assegnate",
          "selected_programs": "Processi assegnati",
          "title": "Assegna valutazione - %{candidate}"
        },
        "preview": "Esempio"
      },
      "candidate_assessments": {
        "confirm_destroy": "Sei sicuro di voler rimuovere %{assessment_title} da %{candidate_name}?",
        "confirm_reset": "Sei sicuro di voler reimpostare la valutazione %{assessment_title}? Il ripristino eliminerà tutte le risposte in modo che il candidato possa iniziare nuovamente la valutazione.",
        "destroy": {
          "success": "%{title} è stato ritirato con successo"
        },
        "report_tos": {
          "advisor": "L'allenatore",
          "both": "Entrambi",
          "candidate": "Il candidato"
        },
        "reset": {
          "success": "%{title} è stato reimpostato con successo"
        }
      },
      "candidate_email_messages": {
        "index": {
          "sent_at": "Inviato",
          "status": "Stato",
          "subject": "Soggetto",
          "title": "E-mail inviate a %{candidate}"
        }
      },
      "candidate_reports": {
        "add": "Genera rapporto",
        "create": {
          "success": "Il rapporto è in fase di generazione. Riceverai una notifica quando il documento sarà pronto nella libreria documenti e supporti."
        },
        "download": "Scarica il rapporto",
        "form": {
          "external_report": "Rapporto per %{candidate_assessment}",
          "generated_reports": "Report generati per %{candidate_assessment}",
          "no_external_reports_generated_yet": "Non è stato generato alcun report per %{candidate_assessment}",
          "no_reports_generated_yet": "Non è stato generato alcun report per %{candidate_assessment}",
          "report_objective": "Obiettivo del reporting: %{norm_group}"
        },
        "form_list": {
          "document_options": "Opzioni di rapporto",
          "language": "Lingua",
          "media_item": "Documento",
          "no_media_item_available": "Documento non ancora disponibile",
          "norm_group_id": "Obiettivo del reporting",
          "shared_with": "Condiviso con",
          "title": "Rapporto generato"
        },
        "index": {
          "assessment": "Valutazione",
          "created_at": "Fatto su",
          "document_options": "Opzioni di rapporto",
          "language": "Lingua",
          "link_title": "Rapporti",
          "media_item": "Documento",
          "no_media_item_available": "Documento non ancora disponibile",
          "norm_group_id": "Obiettivo del reporting",
          "shared_with": "Condiviso con",
          "title": "Rapporti da %{candidate}"
        },
        "new": {
          "link_title": "Genera rapporto",
          "title": "Genera report per %{candidate}"
        },
        "only_shared_with": {
          "one": "%{count} responsabile dell'organizzazione",
          "other": "%{count} gestori dell'organizzazione"
        },
        "shared_with": {
          "one": "e %{count} responsabile dell'organizzazione",
          "other": "e %{count} gestori dell'organizzazione"
        },
        "stitch_reports": "Unisci rapporti"
      },
      "candidate_results": {
        "index": {
          "title": "Panoramica dei risultati personali"
        }
      },
      "candidate_selection": {
        "active_filter_info": "Sono selezionati <span class='font-bold'> / %{count}</span> del filtro attivo.",
        "deselect_button": "deseleziona tutto",
        "select_button": "Seleziona tutto",
        "total_info": "Sono stati selezionati <span class='font-bold'>/%{count} candidati</span>."
      },
      "candidate_steps": {
        "create": {
          "action_required": "Il candidato è stato creato. È ancora necessaria un'azione per invitare il candidato al processo",
          "candidate_created": "Il candidato è stato creato",
          "candidate_invited": "Il candidato è stato informato via e-mail della nuova procedura assegnata",
          "cannot_enroll": "Al momento non puoi assegnare questo processo",
          "enrollment_created": "Hai creato <a class='underline font-bold' href='%{candidate_link}'>%{name}</a> e assegnato <a class='underline font-bold' href='%{enrollment_link}'>%{title}</a>."
        },
        "filter": {
          "all": "Tutto",
          "assessment_definitions": "Valutazioni",
          "assessment_programs": "Programmi di valutazione",
          "programs": "Processi"
        },
        "info_block": "Informazioni dettagliate",
        "new": {
          "candidate_title": "Aggiungi candidato",
          "coadvisor_title": "Assegnare la persona da contattare",
          "enrollment_title": "Assegnare la valutazione"
        }
      },
      "candidates": {
        "add": "Aggiungi candidato",
        "add_label": "Aggiungi etichetta",
        "added": "Il candidato <a class='underline' href='%{link}'>%{name}</a> è stato creato.",
        "assign_bulk_button": "Concessione",
        "contact_person": {
          "remove": "Cancellare"
        },
        "create": {
          "title": "Aggiungi candidato"
        },
        "deactivate": "Il candidato è stato disattivato",
        "delete": "Rimuovi candidato",
        "deleted": "Il candidato è stato eliminato",
        "download_report": {
          "not_shared_alt_text": "Il risultato non è stato ancora condiviso con te"
        },
        "edit": {
          "contact_persons": "Contatti",
          "labels": "Etichette",
          "title": "Modifica candidato"
        },
        "export": {
          "started": "Viene avviata l'esportazione dei candidati. Riceverai una notifica una volta completata l'esportazione."
        },
        "index": {
          "advisor": "Consulente",
          "api_identifier": "Identificatore dell'API",
          "coadvisor": "Co-consigliere",
          "contact_person": "Contatti",
          "cost_center": "Centro di costo",
          "created_at": "Fatto su",
          "email": "E-mail",
          "first_name": "Nome di battesimo",
          "labels": "Etichette",
          "language": "Lingua",
          "last_activity": "Ultimo attivo",
          "last_name": "Cognome",
          "name": "Nome",
          "organization": "Organizzazione",
          "passwordless_login_link": "Collegamento di accesso senza password",
          "privacy_accepted_at": "Privacy accettata",
          "report_objective": "Obiettivo del reporting",
          "status": "Stato",
          "title": "Candidati",
          "track": "L'ultima attività",
          "unconfirmed_email": "E-mail non confermata"
        },
        "label": {
          "remove": "Cancellare"
        },
        "premium_candidate_tooltip": "Questo è un candidato premium",
        "reactivated": "Il candidato è stato riattivato",
        "review_required": "Necessaria revisione",
        "update": {
          "title": "Modifica candidato"
        },
        "updated": "Candidato aggiornato",
        "upload_candidates": "Carica i candidati"
      },
      "candidates_contact_persons": {
        "show": {
          "Datum": "Creato a",
          "account_email": "Indirizzo e-mail",
          "contact_person": "Contatto",
          "created_at": "Fatto da",
          "name": "Nome",
          "organization": "Organizzazione",
          "privacy_accepted_at": "Privacy accettata",
          "role": "Ruolo",
          "status": "Stato"
        }
      },
      "clients": {
        "add": "Aggiungi cliente",
        "contact_person": {
          "edit": "Regolare",
          "remove": "Cancellare"
        },
        "create": {
          "client_created": "Il cliente è stato creato",
          "help_text": "Crea un nuovo cliente per la tua organizzazione qui",
          "title": "Nuovo cliente"
        },
        "edit": {
          "labels": "Etichette",
          "title": "Modifica cliente"
        },
        "form": {
          "add_contact_person": "Aggiungi persona da contattare",
          "add_label": "Aggiungi etichetta",
          "labels": "Etichette"
        },
        "index": {
          "candidates": "Candidati",
          "contact_persons": "Contatti",
          "help_text": "Qui troverai tutti i clienti (aziende) della tua organizzazione",
          "name": "Nome",
          "new_button": "Nuovo cliente",
          "title": "Clienti",
          "updated_at": "Aggiornato il"
        },
        "label": {
          "remove": "Cancellare"
        },
        "new": {
          "help_text": "Crea un nuovo cliente per la tua organizzazione qui",
          "title": "Nuovo cliente"
        },
        "row": {
          "number_of_candidates": "%{number} attivo",
          "number_of_contact_persons": "%{number} attivo"
        },
        "show": {
          "help_text": "Qui troverai tutti i tuoi contatti e candidati presso questo cliente",
          "new_button": "Nuova persona di contatto",
          "table_header": [
            "Fatti",
            "Indirizzo e-mail",
            "Stato"
          ]
        },
        "update": {
          "client_updated": "Il client è stato aggiornato",
          "title": "Modifica cliente"
        }
      },
      "clients_contact_persons": {
        "add": "Aggiungi persona da contattare",
        "create": {
          "created": "La persona di contatto %{name} è stata creata"
        },
        "deactivate": {
          "deactivated": "Il contatto è stato disattivato"
        },
        "destroy": {
          "deleted": "Il contatto è stato eliminato"
        },
        "edit": {
          "labels": "Etichette",
          "title": "Modifica il contatto"
        },
        "form": {
          "add_label": "Aggiungi etichetta",
          "new": "Nuova persona di contatto"
        },
        "index": {
          "account_email": "E-mail",
          "candidates": "Candidati",
          "client": "Cliente",
          "created_at": "Fatto da",
          "labels": "Etichette",
          "list_text": "Elenca tutte le persone di contatto all'interno della tua organizzazione",
          "name": "Nome",
          "organization": "Organizzazione",
          "privacy_accepted_at": "Privacy accettata",
          "status": "Stato",
          "title": "Contatti",
          "two_factor": "Autenticazione a 2 fattori",
          "unconfirmed_email": "E-mail non confermata",
          "updated_at": "Aggiornato il"
        },
        "label": {
          "remove": "Cancellare"
        },
        "new": {
          "title": "Nuovo referente",
          "title_with_client": "Nuovo referente per %{client}"
        },
        "reactivate": {
          "reactivated": "Il contatto è stato riattivato"
        },
        "send_welcome": {
          "welcome_mail_sent": "L'e-mail di benvenuto è stata inviata"
        },
        "show": {
          "client": "Cliente",
          "email": "Indirizzo e-mail",
          "email_address": "Indirizzo e-mail",
          "status": "Stato"
        },
        "update": {
          "updated": "La persona da contattare è stata aggiornata"
        }
      },
      "contact_persons_labels": {
        "destroy": {
          "removed": "Etichetta rimossa con successo dal contatto"
        },
        "form": {
          "new": "Nuova etichetta"
        }
      },
      "dashboards": {
        "show": {
          "title": "Pannello di controllo"
        }
      },
      "enrollers": {
        "add": "Aggiungi registrante",
        "create": {
          "created": "Il collegamento di registrazione è stato creato",
          "created_with_premium_components": {
            "one": "Il collegamento di registrazione è stato creato (%{costs} per registrazione a causa di 1 elemento premium)",
            "other": "Il collegamento di registrazione è stato creato (%{costs} per registrazione a causa di %{count} elementi premium)"
          },
          "title": "Nuovo link di registrazione"
        },
        "created": "Il collegamento di registrazione è stato creato",
        "created_with_premium_components": {
          "one": "Il collegamento di registrazione è stato creato (%{costs} per registrazione a causa di 1 elemento premium)",
          "other": "Il collegamento di registrazione è stato creato (%{costs} per registrazione a causa di %{count} elementi premium)"
        },
        "deactivate": {
          "deactivated": "Il link di registrazione è stato disattivato"
        },
        "destroy": {
          "deleted": "Il link di registrazione è stato rimosso",
          "destroy_confirm": "Sei sicuro di voler eliminare questo collegamento di registrazione?"
        },
        "edit": {
          "explanation": "I partecipanti possono utilizzare il collegamento sopra per registrarsi alla prova selezionata. Puoi condividere questo collegamento via e-mail o, ad esempio, tramite il tuo sito web. Tieni presente che chiunque può registrarsi se rendi pubblico il collegamento.",
          "link": "Frizione",
          "title": "Modifica collegamento di registrazione"
        },
        "form": {
          "help_texts": {
            "advisor": "Il coach guiderà il candidato attraverso il processo dopo che il candidato si sarà registrato.",
            "show_default": "Per impostazione predefinita viene mostrato un breve testo che spiega come registrarsi. In alternativa, puoi fornire il tuo testo nella descrizione del processo.",
            "url": "Collegamento di registrazione",
            "url_explanation": "I candidati possono registrarsi per il processo specificato utilizzando il collegamento sopra. Puoi distribuirlo via e-mail o sul tuo sito web, ad esempio. Tieni presente che chiunque può registrarsi se rendi pubblico il collegamento."
          }
        },
        "index": {
          "actions": "Azioni",
          "add": "Nuovo link di registrazione",
          "coach": "Allenatore",
          "enrolments": "Registrazioni",
          "list_of_enrollers": "Qui troverai tutti i link per la registrazione della tua organizzazione",
          "process": "Processi",
          "title": "Collegamenti di registrazione",
          "url": "Collegamento di registrazione"
        },
        "new": {
          "title": "Nuovo link di registrazione"
        },
        "reactivate": {
          "reactivated": "Il link di registrazione è stato riattivato"
        },
        "show": {
          "coach": "Allenatore",
          "created_at": "Data",
          "enrolments": "Registrazioni",
          "process": "Processi",
          "status": "Stato",
          "url": "Collegamento di registrazione"
        },
        "update": {
          "title": "Modifica collegamento di registrazione",
          "updated": "Il link per la registrazione è stato aggiornato"
        }
      },
      "enrollments": {
        "create": {
          "cannot_enroll": "Al momento non puoi assegnare questo processo"
        },
        "destroy": {
          "cannot_destroy": "Non puoi eliminarlo perché è già avviato.",
          "enrollment_destroyed": "Processo eliminato"
        },
        "form": {
          "add": "Aggiungi persona da contattare"
        },
        "invite": {
          "cannot_invite": "Non puoi invitare perché lo stato dell'iscrizione non è valido."
        },
        "new": {
          "contact_persons": "Contatti"
        }
      },
      "external_reports": {
        "create": {
          "error": "Impossibile generare il rapporto",
          "success": "Il rapporto viene generato."
        }
      },
      "integrations": {
        "show": {
          "api_status": {
            "incorrect": "Attualmente sussistono problemi tecnici che impediscono l'assegnazione delle valutazioni ai candidati. Se il problema persiste, contatta l'assistenza.",
            "new": "Al momento non è possibile assegnare le valutazioni ai candidati all'interno dell'organizzazione. Contatta l'assistenza per rendere disponibile questa funzionalità."
          }
        }
      },
      "label_multiples": {
        "create": {
          "send": "Le etichette sono state attaccate a %{number} candidati"
        },
        "index": {
          "title": "Aggiungi etichette"
        }
      },
      "labels": {
        "create": {
          "label_created": "L'etichetta è stata creata"
        },
        "destroy": {
          "removed": "Etichetta rimossa con successo"
        },
        "edit": {
          "title": "Regola l'etichetta"
        },
        "form": {
          "new": "Nuova etichetta"
        },
        "index": {
          "advisors": "Consiglieri",
          "candidates": "Candidati",
          "contact_persons": "Contatta persone",
          "help_text": "Qui troverai tutte le etichette che puoi utilizzare all'interno dell'applicazione",
          "name": "Nome",
          "programs": "Processi",
          "title": "Etichette",
          "updated_at": "Ultima modifica"
        },
        "new": {
          "title": "Nuova etichetta"
        },
        "update": {
          "label_updated": "L'etichetta è stata aggiornata"
        }
      },
      "mail_multiples": {
        "create": {
          "send": {
            "other": "Email inviata a %{count} candidati"
          }
        },
        "index": {
          "title": "Invia email"
        }
      },
      "multiple_bulk_assignments": {
        "create": {
          "candidates_invited": "I candidati sono stati informati delle nuove procedure assegnate via e-mail",
          "cannot_enroll": "Al momento non puoi assegnare questo processo",
          "processes_created": "I processi sono stati assegnati. I candidati non sono stati ancora informati del processo appena assegnato."
        },
        "new": {
          "title": "Assegna processi ai candidati: %{names}"
        }
      },
      "multiple_talent_solution_assignments": {
        "create": {
          "cannot_enroll": "Il processo assegnato è già stato assegnato a tutti i candidati."
        }
      },
      "news": {
        "index": {
          "title": "Notizia"
        },
        "show": {
          "title": "Notizia"
        }
      },
      "norm_groups": {
        "advice": "Consigli",
        "selection": "Selezione"
      },
      "organization_tasks": {
        "index": {
          "credits": "Crediti",
          "default_report": "Rapporto standard",
          "help_text": "Puoi impostare il report standard per ogni valutazione disponibile qui",
          "key": "Chiave",
          "language": "Lingua",
          "module": "Modulo",
          "name": "Nome",
          "price": "Prezzo",
          "title": "Rapporti standard"
        },
        "task": {
          "de": "Tedesco",
          "en": "Inglese",
          "nl": "Olandese"
        }
      },
      "program_templates": {
        "destroy": {
          "destroyed": "Il processo '%{program_title}' è stato eliminato"
        },
        "duplicate": {
          "duplicate_prefix": "Copia:",
          "duplicate_suffix": "[copia]",
          "program_duplicated": "Il processo '%{program_title}' è stato copiato",
          "template_duplicated": "Il processo \"%{program_title}\" è stato copiato nei processi della tua organizzazione"
        },
        "index": {
          "actions": "Azioni",
          "assigned": "Collegato a",
          "date": "Adeguato a",
          "labels": "Etichetta",
          "length": "Passi",
          "list": "Elenco dei processi del modello di organizzazione progettati",
          "program_title": "Titolo",
          "status": "Stato",
          "title": "Modelli di processo",
          "type": "Tipo"
        },
        "new": "Aggiungi processo",
        "program": {
          "candidates": "Candidati",
          "delete_confirm": "Sei sicuro di voler eliminare questo processo?",
          "steps": "Passi",
          "type": {
            "program": "Proprio processo",
            "program_template": "Modello"
          }
        },
        "publish": {
          "all_pages_invisible": "Non puoi pubblicare questo processo perché nessuno dei passaggi è impostato come visibile al candidato",
          "published": "Il processo '%{program_title}' è stato pubblicato"
        },
        "publish_as_template": {
          "published_as_template": "Il processo '%{program_title}' è stato pubblicato come modello"
        },
        "update": {
          "update_failed": "Impossibile aggiornare il processo",
          "updated": "Il processo è stato aggiornato"
        },
        "withdraw": {
          "withdrawn": "Il processo '%{program_title}' è stato ritirato"
        }
      },
      "programs": {
        "destroy": {
          "destroyed": "Il processo '%{program_title}' è stato eliminato"
        },
        "duplicate": {
          "duplicate_prefix": "Copia:",
          "duplicate_suffix": "[copia]",
          "program_duplicated": "Il processo '%{program_title}' è stato copiato",
          "template_duplicated": "Il processo \"%{program_title}\" è stato copiato nei processi della tua organizzazione"
        },
        "index": {
          "actions": "Azioni",
          "assigned": "Collegato a",
          "date": "Adeguato a",
          "labels": "Etichetta",
          "length": "Passi",
          "list": "Progetta, modifica o visualizza i processi della tua organizzazione. I processi sono disponibili per i consulenti con il ruolo di coach e per i candidati premium.",
          "program_title": "Titolo",
          "status": "Stato",
          "title": "Sviluppare processi",
          "type": "Tipo"
        },
        "new": "Aggiungi processo",
        "program": {
          "candidates": "Candidati",
          "delete_confirm": "Sei sicuro di voler eliminare questo processo?",
          "steps": "Passi",
          "type": {
            "program": "Proprio processo",
            "program_template": "Modello"
          }
        },
        "publish": {
          "all_pages_invisible": "Non puoi pubblicare questo processo perché nessuno dei passaggi è impostato come visibile al candidato",
          "published": "Il processo '%{program_title}' è stato pubblicato"
        },
        "publish_as_template": {
          "published_as_template": "Il processo '%{program_title}' è stato pubblicato come modello"
        },
        "update": {
          "update_failed": "Impossibile aggiornare il processo",
          "updated": "Il processo è stato aggiornato"
        },
        "withdraw": {
          "withdrawn": "Il processo '%{program_title}' è stato ritirato"
        }
      },
      "rooms": {
        "add": "Aggiungi videochiamata",
        "create": {
          "created": "La videochiamata è stata creata",
          "title": "Nuova videochiamata"
        },
        "destroy": {
          "deleted": "La videochiamata è stata eliminata"
        },
        "edit": {
          "title": "Modifica videochiamata"
        },
        "form": {
          "help_texts": {
            "end_to_end_encryption": "Questo ulteriore livello di sicurezza funziona solo sui browser più recenti con supporto per Insertable Streams. Ciò impedisce la partecipazione dalla maggior parte dei browser."
          }
        },
        "index": {
          "actions": "Azioni",
          "help_text": "Qui troverai tutte le videochiamate della tua organizzazione",
          "participants": "Partecipanti",
          "subject": "Soggetto",
          "title": "Videochiamate",
          "updated_at": "Ultima attività"
        },
        "new": {
          "title": "Nuova videochiamata"
        },
        "room": {
          "participants_present": "%{number} presenti"
        },
        "update": {
          "title": "Modifica videochiamata",
          "updated": "La videochiamata è stata aggiornata"
        }
      },
      "shared": {
        "assessment_program_card": {
          "assessment_program_subtasks": "Questo programma di valutazione combina le valutazioni %{subtasks}."
        },
        "candidate_form": {
          "add_contact_person": "Aggiungi persona da contattare",
          "add_label": "Aggiungi etichetta",
          "help_texts": {
            "advisor": "Il consulente può assegnare programmi al candidato e guidarlo in questi processi.",
            "coadvisor": "Un co-consulente è un consulente aggiuntivo che può visualizzare i processi del candidato.",
            "contact_person": "Una persona di contatto è una parte interessata nel processo di un candidato. Possono visualizzare i progressi di un candidato, condividere documenti con il candidato e i suoi consulenti e ricevere la richiesta di approvare i documenti",
            "intro": {
              "text": "Scegli 'Salva' e il candidato non sarà ancora invitato a lavorare sulla piattaforma, non verrà inviata alcuna email. Scegli \"Avanti\" e puoi invitare il candidato a creare un account e sostenere una valutazione.",
              "title": "Aggiungi candidato"
            },
            "outro": {
              "text": "Scegli \"Salva\" per invitare un candidato in un secondo momento, il candidato non riceverà ancora un'e-mail. Scegli 'Avanti' per invitare direttamente un candidato e assegnare immediatamente una valutazione.",
              "title": "Assegnare la valutazione"
            },
            "report_objective": "Se l'obiettivo è consigliare i candidati affinché possano svilupparsi ulteriormente, scegli \"consiglio\"; Se l'obiettivo è selezionare i candidati più idonei, scegli \"selezione\". Sulla base di questo obiettivo verrà selezionato automaticamente il report più adatto al candidato."
          },
          "primary_title": "Dati primari"
        },
        "mail_and_settings_form": {
          "candidate_without_account": "Non esiste un indirizzo email noto nel sistema per questo candidato. Ciò significa che il candidato può accedere alle valutazioni assegnate solo tramite un collegamento di accesso una tantum senza password. Aggiungi un indirizzo email se il candidato necessita di più di un accesso una tantum.",
          "delivery_date": "Data di spedizione",
          "invitation_mail_title": "Configura l'e-mail di invito",
          "reminder_mail": "E-mail di promemoria",
          "reminder_mail_title": "Imposta l'e-mail di promemoria",
          "report_settings_explanation": "Quando un candidato ha completato una valutazione, viene generato automaticamente un report. Abbiamo già selezionato un report adatto in base alle impostazioni della tua organizzazione e agli obiettivi di reporting del tuo candidato.",
          "report_settings_title": "Impostazioni del rapporto"
        },
        "selected_candidates": "Candidati selezionati"
      },
      "stitched_reports": {
        "create": {
          "success": "Viene generato il report unito. Riceverai una notifica quando il report sarà pronto nei tuoi Documenti e Media."
        },
        "new": {
          "stitch_button": "Combinare",
          "title": "Unisci i report per %{candidate}"
        }
      },
      "whitelabel": {
        "edit": {
          "title": "Impostazioni dell'etichetta bianca"
        },
        "update": {
          "whitelabel_updated": "La Whitelabel è stata aggiornata"
        }
      }
    },
    "authentication_actions": {
      "authenticate": "%{actor} ha effettuato l'accesso con successo.",
      "impersonate": "%{actor} ha effettuato l'accesso come %{target}",
      "unknown": "Azione sconosciuta: %{action}"
    },
    "boolean": {
      "yes": "SÌ"
    },
    "builder": {
      "component": {
        "component_delete_confirm": "Sei sicuro di voler rimuovere questo elemento?",
        "settings": "Impostazioni degli elementi"
      },
      "elements": {
        "advisor_introduction": "Presentazione dell'allenatore",
        "advisor_introduction_tooltip": "Ciò consente all'allenatore di presentarsi al candidato",
        "amc": "AMC",
        "amc_tooltip": "Con l'AMC, il consulente può identificare i limiti del candidato e ottenere informazioni dettagliate sulle opzioni di carriera",
        "appointment": "Programmazione degli appuntamenti",
        "appointment_tooltip": "Ciò consente al coach e al candidato di organizzare un incontro (fisico).",
        "blog": "Blog",
        "blog_tooltip": "Blog con tutti gli articoli condivisi con il candidato",
        "bov": "BOV",
        "bov_tooltip": "Il BOV consente al candidato di effettuare ricerche in un database di professioni",
        "checkboxes": "Caselle di controllo",
        "checkboxes_tooltip": "Numero di scelte tra cui selezionare più",
        "consent": "Autorizzazione",
        "consent_tooltip": "Domanda sì-no a cui il candidato deve rispondere per procedere",
        "custom_html": "HTML personalizzato",
        "custom_html_tooltip": "Inserisci il tuo codice HTML",
        "date": "Inserimento della data",
        "date_tooltip": "Un campo di input per una data",
        "document_approval": "Approvazione del documento",
        "document_approval_tooltip": "Ciò consente al coach di caricare un file affinché il candidato o la persona di contatto possa approvarlo",
        "document_download": "Scaricamento del documento",
        "document_download_tooltip": "Ciò consente al coach di caricare file affinché il candidato possa scaricarli",
        "document_upload": "Caricamento del documento",
        "document_upload_tooltip": "Ciò consente al candidato di caricare file che il coach potrà scaricare",
        "docx_template": "Generatore di CV e lettere (Word)",
        "docx_template_tooltip": "Ciò consente di caricare un modello di documento DOCX, che il candidato può compilare con le informazioni personali",
        "fit": "ADATTO",
        "fit_tooltip": "Con il FIT il candidato può identificare i propri limiti e orientarsi sulle opzioni di carriera",
        "image": "Immagine",
        "image_tooltip": "Immagine da visualizzare",
        "input_text": "Inserimento di testo (breve)",
        "input_text_tooltip": "Un campo di input di una riga che deve essere completato dal candidato",
        "ixly_test": "Valutazione Ixly",
        "ixly_test_tooltip": "Ciò consente al candidato di completare un test o un questionario e quindi scaricare un rapporto",
        "job_market_scan": "Opportunità del mercato del lavoro",
        "job_market_scan_en": "Opportunità nel mercato del lavoro in inglese",
        "job_market_scan_en_tooltip": "Con Labour Market Opportunities il candidato può calcolare le sue possibilità nel mercato del lavoro",
        "job_market_scan_tooltip": "Con Labour Market Opportunities il candidato può calcolare le sue possibilità nel mercato del lavoro",
        "job_suggestions": "Suggerimenti di lavoro",
        "job_suggestions_plus": "Suggerimenti di lavoro più",
        "job_suggestions_plus_tooltip": "Con Job Suggestions Plus, il candidato può scoprire lavori adatti, oltre alla relativa formazione e posti vacanti",
        "job_suggestions_tooltip": "Con i suggerimenti di lavoro il candidato può scoprire lavori adatti",
        "jobfeed": "Jobfeed",
        "jobfeed_tooltip": "Con Jobfeed il candidato può cercare tra tutte le offerte di lavoro nei Paesi Bassi",
        "logbook": "Tronco d'albero",
        "logbook_tooltip": "Ciò consente al candidato di tenere appunti",
        "no_premium_components_available_yet": "(nessun elemento premium disponibile in questo momento)",
        "pdf_template": "Generatore di CV e lettere",
        "pdf_template_tooltip": "Ciò consente di creare modelli per documenti PDF, che il candidato può compilare con informazioni personali",
        "premium": "Premio",
        "premium_costs_are_per_assignment": "(i prezzi sopra indicati si intendono per allocazione del processo ed esclusa %{vat}% IVA)",
        "quiz": "Quiz",
        "quiz_tooltip": "Ciò consente al candidato di rispondere a domande (a scelta aperta o multipla) e quindi di verificare le proprie risposte",
        "radio_buttons": "Scelta multipla",
        "radio_buttons_tooltip": "Numero di scelte tra cui selezionarne una (pulsanti di opzione)",
        "search_job": "Infatti localizzatore di lavoro",
        "search_job_tooltip": "Localizzatore di lavoro integrato per il database Indeed",
        "selectbox": "Selezione",
        "selectbox_tooltip": "Numero di scelte tra cui selezionarne una (a discesa)",
        "standard": "Standard",
        "text": "Lettura del testo",
        "text_tooltip": "Testo da leggere",
        "textarea": "Inserimento di testo (lungo)",
        "textarea_tooltip": "Un campo di input di più righe, che deve essere completato dal candidato",
        "title": "Elementi",
        "vacancy": "Responsabile dei posti vacanti",
        "vacancy_tooltip": "Ciò consente al candidato di tenere traccia dei posti vacanti interessanti e dei loro progressi",
        "youtube_video": "Video Youtube",
        "youtube_video_tooltip": "Video di YouTube da guardare"
      },
      "menu_item_page": {
        "page_delete_confirm": "Sei sicuro di voler eliminare questo passaggio e tutti i suoi elementi?",
        "settings": "Impostazioni del passo"
      },
      "sidebar": {
        "new_page": "Nuovo passo",
        "settings": "Impostazioni del processo"
      },
      "top_steps": {
        "placeholder": "Nome del gruppo di passaggi..."
      }
    },
    "button": {
      "add": "Aggiungere",
      "add_migration_organization": "Migrare l'organizzazione TTK",
      "back": "Indietro",
      "cancel": "Annulla",
      "click_to_close_modal": "(clicca qui per chiudere questa finestra)",
      "close": "Vicino",
      "complete_setup": "Spedire un invito",
      "copy": "copia",
      "copy_token_authentication_link": "Copia il collegamento per un accesso senza password una tantum",
      "deactivate": "Disattivare",
      "delete": "Rimuovere",
      "download_csv": "Scarica come CSV",
      "duplicate": "copia",
      "edit": "Modificare",
      "export": "Esportare",
      "filter": "Applicare",
      "inspect": "Ispezionare",
      "invite": "Invitare",
      "join": "Partecipare",
      "mark_all_as_read": "Segna come letto",
      "mark_as_template": "Contrassegna come modello",
      "next": "Prossimo",
      "publish": "Pubblicare",
      "publish_as_template": "Pubblica come modello",
      "reactivate": "Riattivare",
      "refresh": "Ricaricare",
      "reset": "Ripristina",
      "reset_password": "Password dimenticata invia email",
      "select": "Selezione",
      "send": "Sterzare",
      "skip": "Saltare",
      "start": "Iniziare",
      "test_results": "Risultati",
      "unmark_as_template": "Deseleziona come modello",
      "verify": "Verificare",
      "view": "Guarda a",
      "withdraw": "Ritirarsi"
    },
    "candidate": {
      "assessments": {
        "advisor_action": "Il consulente deve agire sulla base di questa valutazione",
        "finished_at": "Arrotondato a",
        "started_at": "Iniziato il"
      },
      "assignments": {
        "active_count": "%{number} ha iniziato l'assegnazione(i)",
        "finished_count": "%{number} premio/i completato/i",
        "not_started_count": "%{number} allocazione/i non avviata/e"
      },
      "created": {
        "empty_list": "Non ci sono nuove attività pronte"
      },
      "enrollment": {
        "deadline": "Questo processo deve essere completato entro e non oltre %{date}.",
        "download_alt_text": "Scarica il rapporto",
        "enrolment_link_alt_text": "Apri %{title}",
        "enrolment_link_disabled_alt_text": "%{title} non disponibile: scadenza superata",
        "finished_at": "Arrotondato a",
        "invite_alt_text": "Invita candidato per %{title}",
        "invited_at": "Il candidato sarà invitato a questo processo il %{date}.",
        "not_invited": "Il candidato non è stato ancora invitato per questo processo.",
        "not_started": "Non ancora iniziato",
        "reminder_date": "E-mail di promemoria all'indirizzo %{date}",
        "started_at": "Iniziato il"
      },
      "enrollments": {
        "finished": "arrotondato",
        "not_started": "non ancora iniziato",
        "started": "iniziato"
      },
      "finished": {
        "empty_list": "Non ci sono ancora attività completate"
      },
      "started": {
        "empty_list": "Non ci sono attività avviate"
      },
      "title": "I miei viaggi",
      "unknown_initials": "n / a."
    },
    "candidates": {
      "advisors": {
        "show": {
          "coach_role": "Allenatore",
          "email": "E-mail",
          "role": "Ruolo"
        }
      },
      "confirmations": {
        "advisors": {
          "show": {
            "coach_role": "Allenatore",
            "email": "E-mail",
            "role": "Ruolo"
          }
        },
        "contact_people": {
          "show": {
            "client": "Cliente",
            "contact_person_role": "Contatto",
            "email": "E-mail",
            "role": "Ruolo"
          }
        },
        "show": {
          "title": "fare un conto"
        }
      },
      "contact_people": {
        "show": {
          "client": "Cliente",
          "contact_person_role": "Contatto",
          "email": "E-mail",
          "role": "Ruolo"
        }
      },
      "external_reports": {
        "create": {
          "error": "Impossibile generare il rapporto",
          "success": "Il rapporto viene generato."
        }
      },
      "results": {
        "index": {
          "title": "Panoramica dei risultati personali"
        }
      },
      "send_mail": {
        "blank_email": "Impossibile inviare l'e-mail, il candidato non ha un indirizzo e-mail",
        "inactive_candidate": "Impossibile inviare l'e-mail, il candidato è inattivo",
        "message": "Invia una email",
        "resend_welcome": "Invia nuovamente l'e-mail di benvenuto",
        "send_welcome": "Invia e-mail di benvenuto"
      },
      "token_authentications": {
        "new": {
          "invalid": "Il collegamento di accesso senza password non è più valido"
        },
        "update": {
          "copied": "Il collegamento per l'accesso singolo senza password è stato copiato!"
        }
      }
    },
    "categories": {
      "adaptive": "Prova adattiva",
      "all": "Tutte le categorie",
      "e_assessment": "Valutazione elettronica",
      "game": "Gioco psicometrico",
      "intelligence": "Intelligenza",
      "personality": "Personalità",
      "sample_tests": "Test campione",
      "simulation": "Simulazione"
    },
    "clients": {
      "create": {
        "client_created": "Il cliente è stato creato",
        "help_text": "Crea un nuovo cliente per la tua organizzazione qui",
        "title": "Nuovo cliente"
      },
      "edit": {
        "title": "Modifica cliente"
      },
      "form": {
        "labels": "Etichette"
      },
      "index": {
        "help_text": "Qui troverai tutti i clienti (aziende) della tua organizzazione",
        "new_button": "Nuovo cliente",
        "table_header": [
          "Nome",
          "Contatti",
          "Candidati"
        ],
        "title": "Clienti"
      },
      "new": {
        "help_text": "Crea un nuovo cliente per la tua organizzazione qui",
        "title": "Nuovo cliente"
      },
      "row": {
        "number_of_candidates": "%{number} attivo",
        "number_of_contact_persons": "%{number} attivo"
      },
      "show": {
        "help_text": "Qui troverai tutti i tuoi contatti e candidati presso questo cliente",
        "new_button": "Nuova persona di contatto",
        "table_header": [
          "Fatti",
          "Indirizzo e-mail",
          "Stato"
        ]
      },
      "update": {
        "client_updated": "Il client è stato aggiornato",
        "title": "Modifica cliente"
      }
    },
    "comments": {
      "index": {
        "title": "Cronologia dei messaggi"
      }
    },
    "common": {
      "by": "Attraverso",
      "copy_to_clipboard": "Copia negli appunti"
    },
    "compare_and_select": {
      "competencies_rnd_service": {
        "aansturenvangroepen": "leadership_gruppo",
        "aansturenvanindividuen": "leadership_individuale",
        "ambitie": "ambizione",
        "assertiviteit": "assertività",
        "besluitvaardigheid": "risolutezza",
        "coachenvanmedewerkers": "dipendente_coaching",
        "delegeren": "delegazione",
        "doorzettingsvermogen": "perseveranza",
        "flexibiliteit": "flessibilità",
        "initiatief": "iniziativa",
        "inzet": "impegno",
        "klantorientatie": "orientamento_cliente",
        "kwaliteitsgerichtheid": "qualità_focus",
        "leerbereidheid": "volontà_di_imparare",
        "nauwkeurigheid": "precisione",
        "onderhandelen": "negoziazione",
        "ondernemerschap": "imprenditorialità",
        "optreden": "prestazione",
        "organisatiesensitiviteit": "sensibilità_organizzativa",
        "overtuigingskracht": "persuasività",
        "plannenenorganiseren": "pianificazione_organizzazione",
        "relatiebeheer": "gestione_della_relazione",
        "resultaatgerichtheid": "risultati_orientamento",
        "samenwerking": "collaborazione",
        "sensitiviteit": "sensibilità",
        "sociabiliteit": "socievolezza",
        "stressbestendigheid": "resistenza_stress",
        "voortgangscontrole": "progresso_monitoraggio",
        "zelfstandigheid": "indipendenza"
      }
    },
    "competencies": {
      "accuracy": "Precisione",
      "ambition": "Ambizione",
      "assertiveness": "Assertività",
      "collaboration": "Cooperazione",
      "commitment": "Palo",
      "customer_orientation": "Orientamento alla clientela",
      "decisiveness": "Decisione",
      "delegation": "Delegare",
      "employee_coaching": "Formare i dipendenti",
      "entrepreneurship": "Imprenditoria",
      "flexibility": "Flessibilità",
      "group_leadership": "Gestione dei gruppi",
      "independence": "Indipendenza",
      "individual_leadership": "Gestire gli individui",
      "initiative": "Iniziativa",
      "negotiation": "Negoziare",
      "organizational_sensitivity": "Sensibilità organizzativa",
      "performance": "Prestazione",
      "perseverance": "Perseveranza",
      "persuasiveness": "Persuasività",
      "planning_organizing": "Pianificazione e organizzazione",
      "progress_monitoring": "Controllo avanzamento",
      "quality_focus": "Orientamento alla qualità",
      "relationship_management": "Gestione delle relazioni",
      "results_orientation": "Orientamento al risultato",
      "sensitivity": "Sensibilità",
      "sociability": "Socievolezza",
      "stress_resistance": "Resistenza allo stress",
      "willingness_to_learn": "Voglia di imparare"
    },
    "components": {
      "iframe": {
        "back": "Torniamo al processo"
      },
      "report_visible_for_explanation": "Il rapporto può comunque essere reso visibile all'altra persona in seguito.",
      "update": {
        "error": "Impossibile salvare questo elemento perché:"
      }
    },
    "consents": {
      "cannot_share": "Impossibile condividere il rapporto.",
      "confirm_retract": "Sei sicuro di voler revocare l'autorizzazione a condividere i risultati?",
      "consent_retracted": "Consenso ritirato",
      "retract_consent": "Revoca il consenso",
      "share_consent": "Condividi i risultati",
      "shared_successfully": "Il rapporto è stato condiviso con successo"
    },
    "contact_people": {
      "enrollments": {
        "list": {
          "advisor": "Allenatore",
          "candidate": "Candidato",
          "coadvisor": "Cocoa",
          "contact_person": "Contatto",
          "pages": "Pagine",
          "status": "Stato"
        }
      }
    },
    "current": "Attuale",
    "datatables": {
      "filtered_from_after_count": "Risultati",
      "filtered_from_before_count": "filtrato",
      "loading": "Carichiamo...",
      "next": "Prossimo",
      "previous": "Precedente",
      "showing_page_after_count": "di",
      "showing_page_before_count": "Pagina",
      "zero_records": "Nessun risultato disponibile"
    },
    "date": {
      "abbr_day_names": [
        "dom",
        "lun",
        "mar",
        "mer",
        "gio",
        "ven",
        "sab"
      ],
      "abbr_month_names": [
        null,
        "gen",
        "feb",
        "mar",
        "apr",
        "mag",
        "giu",
        "lug",
        "ago",
        "set",
        "ott",
        "nov",
        "dic"
      ],
      "day_names": [
        "domenica",
        "lunedì",
        "martedì",
        "mercoledì",
        "giovedì",
        "venerdì",
        "sabato"
      ],
      "formats": {
        "date_with_day": "%A, %e %B %Y",
        "date_without_day": "%e %B %Y",
        "date_without_day_and_year": "%d%b",
        "default": "%d/%m/%Y",
        "long": "%d %B %Y",
        "short": "%d %b"
      },
      "month_names": [
        null,
        "gennaio",
        "febbraio",
        "marzo",
        "aprile",
        "maggio",
        "giugno",
        "luglio",
        "agosto",
        "settembre",
        "ottobre",
        "novembre",
        "dicembre"
      ],
      "order": [
        "day",
        "month",
        "year"
      ]
    },
    "datetime": {
      "distance_in_words": {
        "about_x_hours": {
          "one": "circa un'ora",
          "other": "circa %{count} ore"
        },
        "about_x_months": {
          "one": "circa un mese",
          "other": "circa %{count} mesi"
        },
        "about_x_years": {
          "one": "circa un anno",
          "other": "circa %{count} anni"
        },
        "almost_x_years": {
          "one": "quasi un anno",
          "other": "quasi %{count} anni"
        },
        "half_a_minute": "mezzo minuto",
        "less_than_x_minutes": {
          "one": "meno di un minuto",
          "other": "meno di %{count} minuti"
        },
        "less_than_x_seconds": {
          "one": "meno di un secondo",
          "other": "meno di %{count} secondi"
        },
        "over_x_years": {
          "one": "oltre un anno",
          "other": "oltre %{count} anni"
        },
        "x_days": {
          "one": "%{count} giorno",
          "other": "%{count} giorni"
        },
        "x_minutes": {
          "one": "%{count} minuto",
          "other": "%{count} minuti"
        },
        "x_months": {
          "one": "%{count} mese",
          "other": "%{count} mesi"
        },
        "x_seconds": {
          "one": "%{count} secondo",
          "other": "%{count} secondi"
        },
        "x_years": {
          "one": "%{count} anno",
          "other": "%{count} anni"
        }
      },
      "prompts": {
        "day": "Giorno",
        "days": "all'alba",
        "hour": "Ora",
        "minute": "Minuto",
        "month": "mese",
        "months": "mesi",
        "second": "Secondo",
        "year": "anno",
        "years": "anni"
      }
    },
    "delimit_options_by_commas": "Separare le diverse scelte con virgole (ad esempio: mela, pera, banana).",
    "delimit_sections_by_commas": "Separare le diverse sezioni con virgole.",
    "devise": {
      "confirmations": {
        "confirmed": "Il tuo account è stato correttamente confermato.",
        "new": {
          "resend_confirmation_instructions": "Invia di nuovo le istruzioni per la conferma"
        },
        "send_instructions": "Riceverai un messaggio email con le istruzioni per confermare il tuo account entro qualche minuto.",
        "send_paranoid_instructions": "Se la tua email esiste nel nostro database, riceverai un messaggio email con le istruzioni per confermare il tuo account entro qualche minuto."
      },
      "failure": {
        "already_authenticated": "Hai già effettuato l'accesso.",
        "inactive": "Il tuo account non è stato ancora attivato.",
        "invalid": "Nessun account trovato con la combinazione email/password specificata",
        "last_attempt": "Hai ancora un tentativo prima che l'account venga bloccato.",
        "locked": "Il tuo account è bloccato.",
        "not_found_in_database": "Nessun account trovato con la combinazione email/password specificata",
        "organization_inactive": "Non puoi accedere, l'organizzazione di cui sei membro non è attiva",
        "timeout": "Sessione scaduta, accedere nuovamente per continuare.",
        "unauthenticated": "È necessario eseguire il login per continuare",
        "unconfirmed": "Devi confermare il tuo account per continuare.",
        "user_status_created": "Il tuo account non è ancora attivo",
        "user_status_inactive": "Il tuo account non è attivo",
        "user_status_invited": "Non puoi ancora accedere, utilizza l'e-mail di invito per creare una password"
      },
      "mailer": {
        "confirmation_instructions": {
          "action": "Conferma il mio account",
          "greeting": "Benvenuto %{recipient}!",
          "instruction": "Puoi confermare il tuo account cliccando sul link qui sotto:",
          "subject": "Istruzioni per la conferma"
        },
        "email_changed": {
          "greeting": "Ciao %{recipient}!",
          "message": "Ti stiamo contattando per notificarti che la tua email è stata cambiata in %{email}.",
          "message_unconfirmed": null,
          "subject": "Email modificata"
        },
        "password_change": {
          "greeting": "Ciao %{recipient}!",
          "message": "Ti stiamo contattando per notificarti che la tua password è stata modificata.",
          "subject": "Password modificata"
        },
        "reset_password_instructions": {
          "action": "Cambia la mia password",
          "greeting": "Ciao %{recipient}!",
          "instruction": "Qualcuno ha richiesto di resettare la tua password, se lo vuoi davvero puoi farlo cliccando sul link qui sotto.",
          "instruction_2": "Se non sei stato tu ad effettuare questa richiesta puoi ignorare questa mail.",
          "instruction_3": "La tua password non cambierà finché non accederai al link sopra.",
          "subject": "Istruzioni per reimpostare la password"
        },
        "unlock_instructions": {
          "action": "Sblocca il mio account",
          "greeting": "Ciao %{recipient}!",
          "instruction": "Clicca sul link qui sotto per sbloccare il tuo account:",
          "message": "Il tuo account è stato bloccato a seguito di un numero eccessivo di tentativi di accesso falliti.",
          "subject": "Istruzioni per sbloccare l'account"
        }
      },
      "omniauth_callbacks": {
        "failure": "Non è stato possibile autorizzarti da %{kind} perché \"%{reason}\".",
        "success": "Autorizzato con successo dall'account %{kind}."
      },
      "passwords": {
        "edit": {
          "change_my_password": "Cambia la mia password",
          "change_your_password": "Cambia la tua password",
          "confirm_new_password": "Conferma la nuova password",
          "new_password": "Nuova password"
        },
        "new": {
          "forgot_your_password": "Password dimenticata?",
          "send_me_reset_password_instructions": "Inviami le istruzioni per resettare la password"
        },
        "no_token": "Puoi accedere a questa pagina solamente dalla email di reset della password. Se vieni dalla email controlla di aver inserito l'url completo riportato nella email.",
        "send_instructions": "Riceverai un messaggio email con le istruzioni per reimpostare la tua password entro qualche minuto.",
        "send_paranoid_instructions": "Se la tua email esiste nel nostro database, riceverai un messaggio email contentente un link per il ripristino della password",
        "updated": "La tua password è stata cambiata. Ora sei collegato.",
        "updated_not_active": "La tua password è stata cambiata."
      },
      "registrations": {
        "destroyed": "Arrivederci! L'account è stato cancellato. Speriamo di rivederti presto.",
        "edit": {
          "are_you_sure": "Sei sicuro?",
          "cancel_my_account": "Rimuovi il mio account",
          "currently_waiting_confirmation_for_email": "In attesa di conferma per: %{email}",
          "leave_blank_if_you_don_t_want_to_change_it": "lascia in bianco se non vuoi cambiarla",
          "title": "Modifica %{resource}",
          "unhappy": "Scontento",
          "update": "Aggiorna",
          "we_need_your_current_password_to_confirm_your_changes": "abbiamo bisogno della tua password attuale per confermare i cambiamenti"
        },
        "new": {
          "sign_up": "Registrati"
        },
        "signed_up": "Iscrizione eseguita correttamente. Se abilitata, una conferma è stata inviata al tuo indirizzo email.",
        "signed_up_but_inactive": "Iscrizione eseguita correttamente. Però non puoi accedere in quanto il tuo account non è ancora attivo.",
        "signed_up_but_locked": "Iscrizione eseguita correttamente. Però non puoi accedere in quanto il tuo account è bloccato.",
        "signed_up_but_unconfirmed": "Ti è stato inviato un messaggio con un link di conferma. Ti invitiamo a visitare il link per attivare il tuo account.",
        "update_needs_confirmation": "Il tuo account è stato aggiornato, ma dobbiamo verificare la tua email. Ti invitiamo a consultare la tua email e cliccare sul link di conferma.",
        "updated": "Il tuo account è stato aggiornato.",
        "updated_but_not_signed_in": "Il tuo account è stato aggiornato con successo, ma dato che la tua password è cambiata, devi accedere di nuovo."
      },
      "sessions": {
        "already_signed_out": "Sei uscito correttamente.",
        "new": {
          "sign_in": "Accedi"
        },
        "signed_in": "Accesso effettuato con successo.",
        "signed_out": "Sei uscito correttamente."
      },
      "shared": {
        "links": {
          "back": "Indietro",
          "didn_t_receive_confirmation_instructions": "Non hai ricevuto le istruzioni per la conferma?",
          "didn_t_receive_unlock_instructions": "Non hai ricevuto le istruzioni per lo sblocco?",
          "forgot_your_password": "Password dimenticata?",
          "sign_in": "Accedi",
          "sign_in_with_provider": "Accedi con %{provider}",
          "sign_up": "Registrati"
        },
        "minimum_password_length": {
          "one": "(minimo %{count} carattere)",
          "other": "(minimo %{count} caratteri)"
        }
      },
      "sign_out": "Disconnettersi",
      "two_factor_authentication": {
        "attempt_failed": "Codice non corretto.",
        "code_has_been_sent": "Il tuo codice di verifica in due passaggi è stato inviato.",
        "contact_administrator": "Contatta un amministratore dell'organizzazione.",
        "max_login_attempts_reached": "Accesso completamente negato perché hai raggiunto il numero massimo di tentativi",
        "success": "Autenticazione a due fattori superata."
      },
      "unlocks": {
        "new": {
          "resend_unlock_instructions": "Invia di nuovo le istruzioni per lo sblocco"
        },
        "send_instructions": "Riceverai un messaggio email con le istruzioni per sbloccare il tuo account entro qualche minuto.",
        "send_paranoid_instructions": "Se la tua email esiste nel nostro database, riceverai un messaggio email con le istruzioni per sbloccare il tuo account entro qualche minuto.",
        "unlocked": "Il tuo account è stato correttamente sbloccato. Ora sei collegato."
      }
    },
    "discounts": {
      "create": {
        "created": "Gli sconti sono stati creati"
      },
      "destroy": {
        "already_started": "Questo sconto è già iniziato."
      },
      "index": {
        "new_button": "Nuovi sconti",
        "table_header": [
          "SU",
          "Percentuale",
          "Data d'inizio",
          "Data di fine",
          "Azioni"
        ],
        "title": "Sconti"
      },
      "new": {
        "premium_components": "Elementi premium",
        "subscriptions": "Abbonamenti",
        "title": "Nuovi sconti per %{organization}"
      },
      "row": {
        "discount_delete": "Sei sicuro di voler rimuovere questo sconto?"
      }
    },
    "education_levels": {
      "academic": "Mercoledì",
      "higher_vocational": "Istruzione professionale superiore",
      "pre_vocational": "Istruzione secondaria preprofessionale",
      "secondary_vocational": "Formazione professionale media"
    },
    "education_levels_rnd": {
      "academic": "wo",
      "higher_vocational": "hbo",
      "pre_vocational": "vmbo",
      "secondary_vocational": "mbo"
    },
    "education_levels_rnd_keys": {
      "hbo": "higher_vocational",
      "mbo": "secondary_vocational",
      "vmbo": "pre_vocational",
      "wo": "academic"
    },
    "enrollers": {
      "enroll": {
        "cannot_enroll": "Al momento non puoi iscriverti a questo processo",
        "enrolled": "Ora stai partecipando a questo processo"
      },
      "enroll_existing": {
        "cannot_enroll": "Al momento non puoi iscriverti a questo processo",
        "enrolled": "Ora stai partecipando a questo processo",
        "enrolling_user_is_a_contact_person": "In qualità di persona di contatto non puoi partecipare personalmente ai processi",
        "enrolling_user_is_enrollers_advisor": "In qualità di coach di questo collegamento di registrazione, non puoi partecipare tu stesso a questo processo"
      },
      "enroll_test": {
        "cannot_enroll": "Al momento non puoi iscriverti a questo processo"
      },
      "form": {
        "accept": "Accetto",
        "and": "E",
        "participate": "Partecipare",
        "privacy_label": "l'<a class='btn-link' href='%{privacy_link}' target='_blank'>informativa sulla privacy</a>",
        "terms_label": "i <a class='btn-link' href='%{terms_link}' target='_blank'>termini e condizioni generali</a>"
      },
      "show": {
        "deactivated": "Questo collegamento di registrazione non è più disponibile",
        "sign_in_or_sign_up": "Se hai già un account, puoi <a class='btn-link' href='%{login_link}'>accedere</a> per partecipare a questo processo. In caso contrario, è necessario prima registrarsi a Process Toolkit utilizzando il modulo sottostante."
      },
      "signed_in_form": {
        "already_enrolled": "Stai già partecipando a questo processo, continua da <a class='alert-link' href='%{link}'>%{text}</a>",
        "participate": "Partecipare",
        "participate_as": "Puoi partecipare direttamente a questo processo tramite il pulsante qui sotto."
      },
      "test_only_form": {
        "accept": "Accetto",
        "and": "E",
        "participate": "Partecipare",
        "privacy_label": "l'<a class='btn-link' href='%{privacy_link}' target='_blank'>informativa sulla privacy</a>",
        "terms_label": "i <a class='btn-link' href='%{terms_link}' target='_blank'>termini e condizioni generali</a>"
      },
      "tests_assigned": {
        "email_sent": "Grazie per la vostra partecipazione. Riceverai un'e-mail da no-reply@test-toolkit.nl con le istruzioni su come iniziare."
      }
    },
    "enrollment_pages": {
      "control_panel": {
        "accept_page": "Approva il passaggio",
        "back_to_tracks_as_advisor": "Torna alla panoramica dei candidati",
        "back_to_tracks_as_candidate": "Torna all'elenco dei miei percorsi",
        "comments": "Informare",
        "finish_enrollment": "Processo completo",
        "finish_enrollment_confirm": "Sei sicuro di voler completare il processo? Un processo completato non può più essere modificato dal candidato o dal coach.",
        "finish_page": "Passo completo",
        "finish_page_and_next": "Concludere e poi",
        "next_page": "Passo successivo",
        "next_page_unavailable": "Passaggio successivo non ancora disponibile...",
        "reject_page": "Ignora passaggio",
        "reopen_enrollment": "Riaprire il processo",
        "reopen_enrollment_confirm": "Sei sicuro di voler riaprire il processo? Il candidato potrà apportare nuovamente modifiche.",
        "submit_page": "Invia passaggio",
        "submit_page_and_next": "Invia e successivo"
      },
      "insert_optional": {
        "cannot_enroll": "Impossibile inserire la pagina perché contiene una valutazione attualmente non disponibile nell'abbonamento.",
        "page_inserted": "È stato inserito il passo '%{page_title}'"
      },
      "show": {
        "insert": "Inserire",
        "is_optional": "Questo passaggio attualmente non fa parte di questo processo, ma può essere aggiunto facoltativamente",
        "original_page_deleted": "Questo passaggio facoltativo non può più essere inserito perché l'originale è stato eliminato",
        "page_insert_confirm_part_1": "L'inserimento è irreversibile",
        "page_insert_confirm_part_2": "Sei sicuro di voler inserire questo passaggio nel punto indicato del processo?",
        "with_premium_components": {
          "one": "e costa %{costs} a causa di 1 elemento premium",
          "other": "e costa %{costs} a causa di %{count} elementi premium"
        }
      }
    },
    "enrollments": {
      "confirm_withdraw": "Sei sicuro di voler annullare questo processo?",
      "create": {
        "candidate_double_invited": "Il candidato è stato invitato tramite e-mail e informato sul processo appena assegnato",
        "candidate_invited": "Il candidato è stato informato via e-mail della nuova procedura assegnata",
        "cannot_enroll": "Al momento non puoi assegnare questo processo",
        "enrollment_created": "La causa è stata accolta. Il candidato non è stato ancora informato."
      },
      "destroy": {
        "candidate_enrollment_already_started": "Questo processo non può essere ritirato perché il processo è già iniziato",
        "candidate_enrollment_destroyed": "Il processo è stato ritirato"
      },
      "finish": {
        "enrollment_finished": "Il processo è stato completato",
        "finish_enrollment_failed": "Impossibile completare il processo perché non è stato avviato o è già stato completato"
      },
      "navbar_page": {
        "not_available_yet": "Disponibile dopo aver completato i passaggi precedenti"
      },
      "new": {
        "help_text": "Seleziona uno dei processi pubblicati da assegnare al candidato",
        "table_header": [
          "Processi",
          "Azioni"
        ],
        "title": "Assegnare il processo"
      },
      "progress": {
        "done": "arrotondato",
        "rejected": "Ancora",
        "submitted": "consegnato"
      },
      "reopen": {
        "enrollment_reopened": "Il processo è stato riaperto",
        "reopen_enrollment_failed": "Il processo non può essere riaperto perché il processo non è stato completato"
      },
      "row": {
        "add": "Concessione",
        "enrollment_create_confirm": {
          "one": "Il processo selezionato contiene 1 elemento premium, che costa %{costs}. Sei sicuro di volerlo concedere?",
          "other": "Il processo selezionato contiene %{count} elementi premium, che insieme costano %{costs}. Sei sicuro di volerlo concedere?"
        },
        "invite_later": "Invita più tardi",
        "view": "Guarda a"
      }
    },
    "errors": {
      "format": "%{attribute} %{message}",
      "messages": {
        "accepted": "deve essere accettata",
        "advisor_cannot_be_coadvisor": "Non è possibile nominare un consulente selezionato come co-consulente",
        "already_confirmed": "è stato già confermato",
        "already_generated": "è già generato",
        "already_passed": "è nel passato",
        "aspect_ratio_is_not": "deve avere un rapporto altezza / larghezza di %{aspect_ratio}",
        "aspect_ratio_not_landscape": "l’orientamento dell’immagine deve essere orizzontale",
        "aspect_ratio_not_portrait": "l’orientamento dell’immagine deve essere verticale",
        "aspect_ratio_not_square": "deve essere un’immagine quadrata",
        "aspect_ratio_unknown": "ha un rapporto altezza / larghezza sconosciuto",
        "assessment_platform_link_or_passwordless_login_link_missing": "non è valido perché deve contenere un Assessment_platform_link o un passwordless_login_link",
        "attachment_size": "Deve essere inferiore a 50 MB",
        "before_start_date": "deve essere successivo alla data di inizio",
        "blank": "non può essere lasciato in bianco",
        "candidate_limit_reached": "Impossibile attivare il tuo account perché è stato raggiunto il numero massimo di candidati attivi",
        "cannot_be_sub_organization_of_itself": "Un'organizzazione non può essere una sotto-organizzazione di se stessa",
        "cannot_downgrade_before_year": "deve essere successivo di almeno un anno alla data di inizio dell'abbonamento precedente",
        "confirmation": "non corrisponde a %{attribute}",
        "confirmation_period_expired": "deve essere confermato entro %{period}, richiedi una nuova conferma",
        "contains_unpermitted_variables": "non è valido perché contiene variabili non autorizzate",
        "content_type_blacklist_error": "Le immagini SVG non sono supportate",
        "content_type_invalid": "ha un tipo di contenuto non valido",
        "content_type_is_invalid": "Sono supportati solo i file CSV",
        "content_type_whitelist_error": "Il file caricato non è un'immagine",
        "create_otp_organization_failed": "Si è verificato un problema durante la creazione dell'organizzazione nel Test Toolkit",
        "current_included": "potrebbe non contenere se stesso",
        "destroy_otp_organization_failed": "Si è verificato un problema durante l'eliminazione dell'organizzazione nel Test Toolkit",
        "dimension_height_equal_to": "l’altezza deve essere pari a %{length} pixel",
        "dimension_height_greater_than_or_equal_to": "l’altezza deve essere maggiore o uguale a %{length} pixel",
        "dimension_height_inclusion": "l’altezza deve essere compresa tra %{min} e %{max} pixel",
        "dimension_height_less_than_or_equal_to": "l’altezza deve essere minore o uguale a %{length} pixel",
        "dimension_max_inclusion": "deve essere minore o uguale a %{width} x %{height} pixel",
        "dimension_min_inclusion": "deve essere maggiore o uguale a %{width} x %{height} pixel",
        "dimension_width_equal_to": "la larghezza deve essere pari a %{length} pixel",
        "dimension_width_greater_than_or_equal_to": "la larghezza deve essere maggiore o uguale a %{length} pixel",
        "dimension_width_inclusion": "la larghezza deve essere compresa tra %{min} e %{max} pixel",
        "dimension_width_less_than_or_equal_to": "la larghezza deve essere minore o uguale a %{length} pixel",
        "email_invalid": "deve avere la seguente struttura: nome@dominio.nl",
        "empty": "non può essere vuoto",
        "equal_to": "deve essere uguale a %{count}",
        "even": "deve essere pari",
        "exclusion": "è riservato",
        "expired": "è scaduto, si prega di richiederne uno nuovo",
        "file_size_not_between": "la dimensione del file deve essere compresa tra %{min_size} e %{max_size} (la dimensione attuale è %{file_size})",
        "file_size_not_greater_than": "la dimensione del file deve essere maggiore di %{min_size} (la dimensione attuale è %{file_size})",
        "file_size_not_greater_than_or_equal_to": "la dimensione del file deve essere maggiore o uguale a %{min_size} (la dimensione attuale è %{file_size})",
        "file_size_not_less_than": "la dimensione del file deve essere inferiore a %{max_size} (la dimensione attuale è %{file_size})",
        "file_size_not_less_than_or_equal_to": "la dimensione del file deve essere minore o uguale a %{max_size} (la dimensione attuale è %{file_size})",
        "free_included": "non può contenere abbonamenti gratuiti",
        "greater_than": "deve essere maggiore di %{count}",
        "greater_than_or_equal_to": "deve essere maggiore o uguale a %{count}",
        "image_metadata_missing": "non è un'immagine valida",
        "image_not_processable": "non è un'immagine valida",
        "inclusion": "non è compreso tra le opzioni disponibili",
        "incomplete_post_rule": "La riga sottostante è incompleta",
        "insufficient_credits": "Impossibile generare il report perché non ci sono abbastanza crediti prepagati.",
        "internet_explorer_error": "Il browser che stai utilizzando (Internet Explorer) non è più supportato. Utilizza un browser diverso.",
        "invalid": "non è valido",
        "invalid_annual_end_date": "di %{subscription_type} deve essere un multiplo di %{period_in_months} mesi dopo la data di inizio (- 1 giorno)",
        "invalid_annual_start_date": "di %{subscription_type} deve essere un multiplo di %{period_in_months} mesi prima della data di fine (+ 1 giorno)",
        "invalid_category": "La categoria %{value} non è valida",
        "invalid_date_range": "non è valido, deve essere successivo alla data dell'invito",
        "invalid_otp": "Il codice inserito non è corretto",
        "invalid_subscription_type": "il passaggio a %{subscription_type} non è consentito",
        "invalid_vat": "IVA non valido per %{country_adjective}",
        "language_not_available": "non è disponibile nell'organizzazione",
        "less_than": "deve essere minore di %{count}",
        "less_than_or_equal_to": "deve essere minore o uguale a %{count}",
        "limit_out_of_range": "il valore è al di fuori dell’intervallo consentito",
        "login_not_allowed": "La tua organizzazione o il tuo account non sono attivi",
        "maximum_number_of_advisors_reached": "Non è possibile aggiungere un nuovo pullman perché è stato raggiunto il numero massimo di pullman",
        "maximum_number_of_candidates_reached": "Non è possibile aggiungere un nuovo candidato perché è stato raggiunto il numero massimo di candidati",
        "mini_magick_processing_error": "La manipolazione con MiniMagick non è riuscita",
        "model_invalid": "Validazione fallita: %{errors}",
        "mollie_error": "Si è verificato un errore con il nostro fornitore di servizi di pagamento Mollie; Riprovare più tardi",
        "more_than_two_decimals": "non deve avere più di 2 cifre decimali",
        "not_a_number": "non è un numero",
        "not_an_integer": "non è un numero intero",
        "not_found": "non trovato",
        "not_locked": "non era bloccato",
        "not_saved": {
          "one": "Non posso salvare questo %{resource}: 1 errore",
          "other": "Non posso salvare questo %{resource}: %{count} errori."
        },
        "odd": "deve essere dispari",
        "one_pending_subscription": "non è consentito perché è già pianificato un abbonamento",
        "organization_address_missing": "l'indirizzo deve essere impostato per assegnare un abbonamento a pagamento",
        "organization_must_be_ixly": "Non è possibile creare un dipendente Ixly al di fuori dell'organizzazione Ixly",
        "organization_vat_number_missing": "Per assegnare un abbonamento a pagamento è necessario impostare una partita IVA",
        "other_than": "devono essere di numero diverso da %{count}",
        "overlaps_existing_dates": "non devono sovrapporsi alle date esistenti",
        "present": "deve essere lasciato in bianco",
        "required": "deve esistere",
        "restrict_dependent_destroy": {
          "has_many": "impossibile eliminare l'elemento perché esiste il dipendente %{record}",
          "has_one": "impossibile eliminare l'elemento perché esiste un %{record} dipendente"
        },
        "sub_organization_not_allowed": "Le sottoorganizzazioni non possono avere le proprie sottoorganizzazioni",
        "subscriptions_attached": "non può essere modificato perché sono presenti iscrizioni con questo modulo",
        "taken": "è già presente",
        "too_long": {
          "one": "è troppo lungo (il massimo è %{count} carattere)",
          "other": "è troppo lungo (il massimo è %{count} caratteri)"
        },
        "too_short": {
          "one": "è troppo corto (il minimo è %{count} carattere)",
          "other": "è troppo corto (il minimo è %{count} caratteri)"
        },
        "url_invalid": "deve avere la seguente struttura: https://www.example.com/path/to/page",
        "user_limit_reached": "Il tuo account non può essere attivato perché è stato raggiunto il numero massimo di coach",
        "wrong_length": {
          "one": "è della lunghezza sbagliata (deve essere di %{count} carattere)",
          "other": "è della lunghezza sbagliata (deve essere di %{count} caratteri)"
        }
      },
      "template": {
        "body": "Si sono verificati problemi con i seguenti campi:",
        "header": {
          "one": "%{model} non salvato: 1 errore trovato",
          "other": "%{model} non salvato: %{count} errori trovati"
        }
      }
    },
    "filterable": {
      "no_results": "nessun risultato",
      "reset": "reimpostare i filtri",
      "title": "Filtro"
    },
    "flash": {
      "try_again": "riprova"
    },
    "helpers": {
      "select": {
        "prompt": "Fai una scelta",
        "sr_only": "Selezionare"
      },
      "submit": {
        "create": "Creare",
        "submit": "Salva %{model}",
        "update": "Salva"
      }
    },
    "i18n": {
      "plural": {
        "keys": [
          "one",
          "other"
        ]
      }
    },
    "impersonated_as": "Hai effettuato l'accesso come %{name}.",
    "impersonation_ended": "Ora hai effettuato di nuovo l'accesso come te stesso.",
    "import": {
      "current_template": "Modello",
      "download_template": "Scarica il modello",
      "errors": {
        "duplicate": "Duplicare"
      },
      "import/candidate_file": {
        "candidate": {
          "account": {
            "email": "E-mail %{message}"
          },
          "advisor": "Consigliere %{message}",
          "language": "Lingua %{message}"
        },
        "file": {
          "headers": "Intestazioni di colonna errate %{message}"
        }
      },
      "import/organization_file": {
        "file": {
          "headers": "Intestazioni di colonna errate %{message}",
          "unique": "L'organizzazione \"%{organization_name}\" esiste già. (api_organization_id: %{api_organization_id}"
        }
      },
      "labels": {
        "description": "Etichette",
        "placeholder": "Inserisci le etichette"
      },
      "loading": "Carichiamo...",
      "processing": "Il file è in fase di elaborazione. Ora puoi chiudere questa pagina.",
      "status": {
        "errors": "Problemi",
        "existed": "Invariato",
        "invalid": "Non valido",
        "new": "Nuovo",
        "previous_data": "Lavati per questo",
        "updated": "Cambiato"
      },
      "store": "Salva",
      "title": "Importazione CSV",
      "upload": "Carica CSV"
    },
    "info": {
      "error_occured": "Qualcosa è andato storto. Inoltra questo messaggio di errore al tuo coach o al responsabile organizzativo.",
      "flagged_elements": "Le pagine evidenziate contengono elementi evidenziati che richiedono un'ulteriore configurazione prima che il candidato possa iniziare il processo.",
      "no_results_found": "nessun risultato trovato",
      "review_required": "È necessaria una revisione. Il coach deve rivedere la valutazione prima che le opzioni di report diventino disponibili.",
      "review_started": "Completa la revisione nell'altra scheda e ricarica questa pagina poco dopo.",
      "verification_planned": "Il candidato deve iniziare la prova di verifica.",
      "verification_required": "È necessaria la verifica. Il coach deve rivedere la valutazione prima che le opzioni di report diventino disponibili.",
      "verification_started": "Il candidato deve completare il test di verifica prima di poter scaricare nuovi report."
    },
    "invoice_kind": {
      "administrative_costs": "Costi amministrativi",
      "change_internal_limit": "Regolazione del limite interno",
      "change_mandate": "Pagamento di autorizzazione",
      "extra_external_credit": "Aumento del credito estero",
      "fixed_price": "Valutazioni completate",
      "frequent_credits": "Utilizzo del credito",
      "incidental_credits": "Pacchetto di crediti",
      "initial_authorization": "Autorizzazione iniziale",
      "intensive_credits": "Utilizzo del credito",
      "monthly_bill": "Ammortamento mensile",
      "premium_components": "Elementi premium"
    },
    "invoice_status": {
      "canceled": "annullato",
      "charged_back": "invertito",
      "closed": "annullato",
      "expired": "scaduto",
      "failed": "fallito",
      "open": "Aprire",
      "paid": "pagato",
      "processed": "implementato",
      "refunded": "rimborsato",
      "revoked": "annullato",
      "sent": "inviato",
      "settled": "sistemato",
      "unconfirmed": "non confermato"
    },
    "invoices": {
      "no_longer_unconfirmed": "Questo ordine di pagamento è già stato confermato",
      "show": {
        "administrative_costs": "Costi amministrativi",
        "annual_fee": "Costi di abbonamento periodici",
        "back_to_account": "(clicca qui per tornare alla fatturazione)",
        "change_internal_limit_explanation": "Gli adeguamenti influiscono sui costi del mese corrente solo se il limite viene aumentato fino a un livello non ancora raggiunto questo mese. La differenza con il limite più alto (%{previous_limit}) verrà poi saldata nella successiva fattura mensile, proporzionale al numero di giorni rimanenti del mese.",
        "confirm_direct_debit": "Ammortamento di x-%{mandate_string}",
        "confirm_direct_invoice": "Ho ricevuto questa fattura",
        "confirm_direct_payment": "Alla schermata di pagamento",
        "costs_next_months": "Da pagare dal mese prossimo",
        "costs_now": "Pagabile ora",
        "credit_charge": "Crediti",
        "external_user_spots": "Candidati attivi (prezzo mensile)",
        "external_user_spots_partial": {
          "general": "Candidati attivi",
          "one": "Candidati attivi (1 giorno)",
          "other": "Candidati attivi (%{count} giorni)"
        },
        "fill_out_address": "Mancano i dettagli dell'indirizzo necessari per inviare una fattura corretta per il tuo acquisto. Si prega di verificare e correggere le informazioni seguenti per effettuare un acquisto.",
        "internal_user_spots": "Allenatori attivi (prezzo mensile)",
        "internal_user_spots_partial": {
          "general": "Allenatori attivi",
          "one": "Allenatori attivi (1 giorno)",
          "other": "Allenatori attivi (%{count} giorni)"
        },
        "internal_users": "Posti utente interno (prezzo mensile)",
        "monthly_bill": "Fattura mensile per %{month} %{year}",
        "premium_components": "Assegnazione di elementi premium: %{kind}",
        "premium_components_invoice": "Assegnazione di elementi premium",
        "status_flash_part_1": "Questo ordine di pagamento è stato emesso da",
        "status_flash_part_2": "ed è %{status} su %{updated_at}",
        "system_initiated": "il toolkit di processo",
        "table_header": [
          "Data",
          "Prodotto",
          "Centro di costo",
          "Prezzo unitario",
          "Numero",
          "Quantità"
        ],
        "terms_and_services": "Pagando accetti automaticamente",
        "terms_and_services_link": "i termini e le condizioni generali di Ixly",
        "title": "Conferma il tuo ordine",
        "total_with_vat": "Totale IVA inclusa",
        "total_without_vat": "Totale IVA esclusa",
        "vat": "%{vat}% di IVA"
      }
    },
    "ixly": {
      "accounts": {
        "add": "creare un account",
        "index": {
          "title": "Tutti i conti"
        }
      },
      "advisors": {
        "create": {
          "success": "Consulente creato!",
          "title": "Aggiungi nuovo consigliere"
        },
        "edit": {
          "title": "Modifica consigliere"
        },
        "new": {
          "title": "Aggiungi nuovo consigliere"
        },
        "update": {
          "success": "Il consigliere è cambiato!",
          "title": "Modifica consigliere"
        }
      },
      "candidates": {
        "edit": {
          "title": "Modifica candidato"
        },
        "index": {
          "title": "Tutti i candidati"
        },
        "new": {
          "title": "Tutti i candidati"
        }
      },
      "discounts": {
        "create": {
          "created": "Gli sconti sono stati creati",
          "title": "Nuovo sconto"
        },
        "destroy": {
          "already_started": "Questo sconto è già iniziato.",
          "destroyed": "Sconto rimosso"
        },
        "edit": {
          "title": "Modifica sconto"
        },
        "index": {
          "actions": "Azioni",
          "add": "Aggiungi sconto",
          "end_date": "Data di fine",
          "help_text": "Una panoramica di tutti gli sconti. Utilizza i filtri per visualizzare gli sconti per un'organizzazione o un tipo di prezzo.",
          "on": "SU",
          "organization": "Organizzazione",
          "percentage": "Percentuale",
          "start_date": "Data d'inizio",
          "title": "Sconti",
          "true": "SU"
        },
        "new": {
          "title": "Nuovo sconto"
        },
        "update": {
          "title": "Modifica sconto",
          "updated": "Lo sconto è stato aggiornato"
        }
      },
      "entries": {
        "create": "Gentilezza"
      },
      "fixed_prices": {
        "create": {
          "already_exists": "Il prezzo fisso per questa organizzazione e attività esiste già",
          "created": "Nuovo prezzo fisso creato"
        },
        "destroy": {
          "destroyed": "Il prezzo fisso è stato distrutto"
        },
        "edit": {
          "title": "Modifica prezzo fisso"
        },
        "index": {
          "add": "Aggiungi prezzo fisso",
          "description": "Descrizione sulla fattura",
          "help_text": "Una panoramica di tutti i prezzi fissi. Utilizza i filtri per visualizzare i prezzi fissi per un'organizzazione o un'attività specifica.",
          "organization": "Organizzazione",
          "price": "Prezzo fisso",
          "task": "Compito",
          "task_price": "Prezzo del lavoro standard",
          "title": "Prezzi fissi"
        },
        "new": {
          "title": "Nuovo prezzo fisso"
        },
        "update": {
          "updated": "Il prezzo fisso è stato aggiornato"
        }
      },
      "import_files": {
        "import_file": {
          "delete": "Cancellare",
          "delete_confirm": "Sei sicuro di voler eliminare questa importazione?",
          "failed": "Fallito",
          "original": "Originale",
          "processed": "Incorporato"
        },
        "index": {
          "title": "File CSV importati"
        },
        "new": {
          "title": "Importa file CSV"
        }
      },
      "integrations": {
        "edit": {
          "title": "Testare l'integrazione del toolkit"
        },
        "form": {
          "api_anonymous_explanation": "Se selezioni questa opzione, i nomi utente non verranno condivisi con Test Toolkit",
          "checking_integration": "È in corso il controllo dello stato dell'API"
        },
        "link_testtoolkit": "Visualizza l'organizzazione collegata nel Test Toolkit",
        "show": {
          "api_status": {
            "correct": "L'organizzazione ha una connessione operativa con il Test Toolkit",
            "incorrect": "La connessione con l'API Test-Toolkit non è riuscita, contatta il team SD se il problema persiste",
            "new": "L'API non è ancora configurata. Aggiungi un utente API, una chiave API e un uuid dell'organizzazione dall'organizzazione Test-Toolkit per generare un token di accesso."
          }
        }
      },
      "invoices": {
        "index": {
          "title": "Fatture"
        },
        "stats": {
          "last_month_amount": "Il mese scorso",
          "last_month_count": "Fatture (ultimo mese)",
          "search_results": "Risultati della ricerca",
          "upcoming_candidate_assessment_entry_count": "Valutazione (imminente)",
          "upcoming_candidate_assessment_program_entry_count": "Programmi (prossimi)",
          "upcoming_candidate_report_entry_count": "Rapporti dei candidati (imminente)",
          "upcoming_leniency_entry_count": "Indulgenza (imminente)"
        }
      },
      "ixly_employees": {
        "create": {
          "created": "Un dipendente Ixly ha creato con successo <a class='underline' href='%{link}'>%{name}</a>."
        },
        "edit": {
          "title": "Modifica Dipendente Ixly"
        },
        "new": {
          "title": "Aggiungi dipendente Ixly"
        },
        "update": {
          "success": "Modifiche al dipendente Ixly salvate"
        }
      },
      "job_profile_templates": {
        "create": {
          "success": "Il modello del profilo professionale è stato creato correttamente",
          "title": "Nuovo modello di profilo professionale"
        },
        "destroy": {
          "success": "Modello del profilo professionale rimosso"
        },
        "edit": {
          "title": "Modifica il modello del profilo professionale"
        },
        "index": {
          "title": "Modelli di profili professionali"
        },
        "new": {
          "title": "Nuovo modello di profilo professionale"
        },
        "update": {
          "success": "Il modello del profilo lavorativo è stato modificato con successo",
          "title": "Modifica il modello del profilo professionale"
        }
      },
      "leniency_entries": {
        "create": {
          "created": "La transazione di clemenza è stata creata con successo.",
          "title": "Nuova operazione di clemenza"
        },
        "new": {
          "form": {
            "in_euro": "Importo in Euro"
          },
          "title": "Nuova operazione di clemenza"
        }
      },
      "mail_templates": {
        "mark_as_template": {
          "marked": "Il modello di email %{title} è contrassegnato come modello"
        },
        "unmark_as_template": {
          "unmarked": "Il modello di email %{title} non è più contrassegnato come modello"
        }
      },
      "migrated_organizations": {
        "index": {
          "title": "Organizzazioni migranti"
        }
      },
      "migration_organizations": {
        "create": {
          "success": "Organizzazione per la migrazione creata con successo"
        },
        "index": {
          "title": "Organizzazioni da migrare"
        },
        "new": {
          "title": "Migrazione della nuova organizzazione TTK"
        },
        "reset": {
          "reset": "Organizzazione di migrazione ripristinata su \"creata\""
        },
        "run_migration": {
          "run_migration": "La migrazione è iniziata!"
        },
        "show": {
          "title": "Dettagli dell'organizzazione della migrazione"
        }
      },
      "organization_csv_imports": {
        "import_file": {
          "log": "Tronco d'albero",
          "original": "Originale"
        },
        "index": {
          "list": "Elenco dei file di importazione dell'organizzazione",
          "title": "Importazioni CSV dell'organizzazione"
        },
        "new": {
          "title": "Importazione CSV dell'organizzazione"
        }
      },
      "organization_settings": {
        "edit": {
          "title": "Impostazioni Ixly"
        }
      },
      "organization_statistics": {
        "customer_credits": {
          "index": {
            "balance_at": "Saldo del credito a %{date}:",
            "candidate": "Candidato",
            "candidate_assessment_entry": "Valutazione",
            "candidate_assessment_program_entry": "Programma di valutazione",
            "candidate_report_entry": "Rapporto (solo test)",
            "cost_center": "Centro di costo",
            "credits": "Numero",
            "date": "Data",
            "description": "Descrizione",
            "entry_type": "Tipo di transazione",
            "generated_report_entry": "Rapporto (processi)",
            "leniency_entry": "Gentilezza",
            "no_entries": "Durante questo periodo non sono stati utilizzati crediti",
            "order": {
              "frequent_credits": "Addebito mensile per i crediti utilizzati",
              "incidental_credits": "Acquisto di pacchetti di crediti",
              "intensive_credits": "Addebito mensile per i crediti utilizzati"
            },
            "order_entry": "Ordine",
            "title": "Panoramica del credito"
          }
        },
        "finished_assessments": {
          "index": {
            "number_finished": "Numero arrotondato",
            "task_key": "Chiave del compito",
            "task_name": "Nome dell'attività",
            "title": "Valutazioni completate"
          }
        },
        "premium_components": {
          "index": {
            "list": {
              "number_assigned": "Numero assegnato",
              "premium_component": "Componente Premium"
            },
            "title": "Componenti Premium premiati"
          }
        },
        "show": {
          "title": "Panoramica della gestione"
        }
      },
      "organizations": {
        "create": {
          "organization_created": "L'organizzazione è stata creata! <a class='alert-link' href='%{link}'>Attiva un abbonamento</a> in modo che i suoi utenti possano accedere",
          "title": "Nuova organizzazione"
        },
        "destroy": {
          "organization_deleted": "L'organizzazione è stata eliminata",
          "organization_deletion_failed": "Impossibile eliminare l'organizzazione"
        },
        "index": {
          "add": "Aggiungi organizzazione",
          "list": {
            "tooltip": {
              "sub_organization": "Organizzazione principale: %{top_organization}",
              "top_organization": "Organizzazioni secondarie: %{sub_organizations}"
            }
          },
          "statistics_button": "Utilizzo",
          "subscription_button": "Abbonamenti",
          "title": "Organizzazioni"
        },
        "new": {
          "title": "Nuova organizzazione"
        }
      },
      "prices": {
        "create": {
          "title": "Nuovo prezzo"
        },
        "edit": {
          "title": "Modifica prezzo"
        },
        "index": {
          "help_text": "Ecco l'elenco dei prezzi",
          "title": "Prezzi"
        },
        "new": {
          "title": "Nuovo prezzo"
        },
        "show": {
          "amc_price": "Prezzo dell'AMC",
          "bov_price": "Prezzo BOV",
          "fit_price": "Prezzo FIT",
          "help_text": "Dettagli sul prezzo",
          "job_market_opportunities_price": "Prezzo delle opportunità nel mercato del lavoro",
          "job_suggestions_price": "Prezzo proposte di lavoro",
          "jobfeed_price": "Premio Jobfeed",
          "monthly_fee_per_coach": "Canone mensile per pullman",
          "monthly_fee_per_participant": "Contributo mensile per candidato",
          "title": "Prezzo"
        },
        "update": {
          "title": "Modifica prezzo"
        }
      },
      "products": {
        "create": {
          "created": "Il modulo è stato creato"
        },
        "deactivate": {
          "deactivated": "Il modulo è disattivato"
        },
        "destroy": {
          "delete_confirm": "Sei sicuro di voler rimuovere questo modulo?",
          "deleted": "Il modulo è stato rimosso"
        },
        "edit": {
          "title": "Modifica modulo"
        },
        "index": {
          "help_text": "Ecco l'elenco di tutti i moduli",
          "name": "Nome",
          "status": "Stato",
          "status_changed_at": "Lo stato è cambiato",
          "title": "Moduli",
          "updated_at": "Aggiornato il"
        },
        "new": {
          "title": "Nuovo modulo"
        },
        "reactivate": {
          "reactivated": "Il modulo è stato riattivato"
        },
        "update": {
          "updated": "Il modulo è stato aggiornato"
        }
      },
      "subscription_type_products": {
        "create": {
          "created": "I moduli sono stati aggiunti al modulo di iscrizione",
          "products_blank": "Nessun modulo è stato selezionato"
        },
        "form": {
          "add_module": "Aggiungi modulo"
        },
        "new": {
          "title": "Aggiungi moduli a %{subscription_type}"
        }
      },
      "subscriptions": {
        "create": {
          "incidental_subscription_credits_left": "Se all'inizio del nuovo abbonamento rimangono dei crediti, verranno utilizzati per primi",
          "success": "L'abbonamento è stato aggiunto."
        },
        "destroy": {
          "success": "L'abbonamento è stato eliminato"
        },
        "edit": {
          "title": "Modifica abbonamento %{subscription}"
        },
        "index": {
          "add": "Aggiungi un nuovo abbonamento",
          "end_date": "Data di fine",
          "help_text": "Qui troverai la panoramica degli abbonamenti assegnati all'organizzazione",
          "invoice_settings": "Impostazioni di fatturazione",
          "name": "Nome",
          "start_date": "Data d'inizio",
          "title": "Abbonamenti da %{organization}"
        },
        "new": {
          "title": "Nuovo abbonamento per %{organization}"
        },
        "update": {
          "success": "L'abbonamento è stato aggiornato con successo"
        }
      },
      "users": {
        "index": {
          "title": "Utenti"
        }
      }
    },
    "javascript": {
      "before_unload": "La tua ultima modifica non è stata ancora salvata.",
      "components": {
        "add_to_calendar": {
          "add_appointment": "Metti un appuntamento nella tua agenda"
        },
        "advisor_introduction": {
          "avatar_placeholder": "Una volta premiato, la foto del profilo dell'allenatore verrà mostrata qui",
          "biography_placeholder": "Qui è mostrata la descrizione personale dell'allenatore, così come l'ha impostata nel suo profilo.",
          "default_biography": "%{advisor_first_name} ti guida durante tutto il tuo viaggio. Puoi contattare il tuo allenatore per qualsiasi domanda.",
          "default_biography_explanation": "(il tuo candidato attualmente vede il testo standard sopra perché non hai ancora impostato una descrizione personale nel tuo profilo)",
          "title_placeholder": "[nome dell'allenatore]",
          "your_advisor": "Il tuo allenatore"
        },
        "appointment": {
          "accept": "Accettare",
          "appointment": "Programmazione degli appuntamenti",
          "appointment_accepted": "Appuntamento accettato",
          "appointment_cancelled": "Appuntamento annullato",
          "appointment_created": "Appuntamento creato",
          "appointment_declined": "Appuntamento rifiutato",
          "appointment_submitted": "Appuntamento suggerito",
          "at_time": "A",
          "back": "Indietro",
          "cancel": "Annulla",
          "date": "Data",
          "decline": "Rifiutare",
          "delete": "Rimuovere",
          "description": "Descrizione",
          "description_placeholder": "spiegazione facoltativa",
          "end_time": "Fino a",
          "help_text": "Non è stato ancora proposto alcun appuntamento.",
          "join": "Partecipa alla conversazione",
          "location": "Posizione",
          "location_placeholder": "luogo d'incontro",
          "on_date": "SU",
          "plan_appointment": "Suggerisci un appuntamento",
          "propose": "Suggerire",
          "start_time": "di",
          "title": "Titolo",
          "title_placeholder": "titolo per questo appuntamento"
        },
        "blog": {
          "help_text": "Visualizza tutti gli articoli del blog condivisi con il candidato.",
          "manage": "Gestisci articoli",
          "title": "Blog"
        },
        "comments": {
          "placeholder": "Messaggio",
          "send": "Invia messaggio",
          "step_unavailable": "Questo passaggio non è attualmente disponibile per il candidato. Il candidato riceverà una notifica su questo messaggio una volta che il passaggio sarà disponibile."
        },
        "consent": [
          "NO",
          "SÌ",
          "Inserisci la domanda nelle impostazioni di questo elemento"
        ],
        "custom_html": {
          "html_placeholder": "Inserisci il codice HTML nelle impostazioni di questo elemento per creare un elemento personalizzato"
        },
        "document_approval": {
          "approve": "Accordo",
          "approved": "Approvato",
          "candidate": "il candidato",
          "contact_person": "il contatto",
          "no_file_uploaded": "Nessun file caricato dall'allenatore",
          "no_file_uploaded_again": "Nessun nuovo file caricato dall'allenatore",
          "reason_placeholder": "motivo (facoltativo)",
          "reason_placeholder_table": "Rifiutato? Inserisci un motivo",
          "reject": "Rifiutare",
          "rejected": "Rifiutato",
          "reset": "Ancora",
          "status_message_responded": "%{action} a %{date} a %{time}",
          "status_message_submitted": "%{can_approve} non ha ancora risposto alla richiesta di approvazione",
          "status_message_submitted_again": "%{can_approve} non ha ancora risposto alla nuova richiesta di approvazione",
          "submitted": "Inviato",
          "upload_file": "Caricare un file",
          "upload_file_again": "Carica nuovo file",
          "with_reason": "a causa di \"%{reason}\""
        },
        "document_upload": {
          "no_file_uploaded": {
            "advisor": "Nessun file caricato dall'allenatore",
            "candidate": "Nessun file caricato dal candidato"
          },
          "upload_file": "Caricare un file"
        },
        "filter_bar": {
          "labels": {
            "filter": "Filtro",
            "records": "Record",
            "search": "Cercare",
            "sort": "Ordinare"
          },
          "no_filters": "Tutto",
          "sort": {
            "ascending": "Ascendente",
            "default": "Standard",
            "descending": "discendente"
          }
        },
        "filter_notifications": {
          "all": "Tutto",
          "done": "Fatto",
          "label": "Stato",
          "open": "Fare"
        },
        "image": {
          "no_image_uploaded": "Nessuna immagine caricata",
          "upload_image": "Carica immagine"
        },
        "interactive_results": {
          "agile": {
            "descriptions": {
              "groups": {
                "early_adopter": [
                  "Appartieni alla categoria degli \"early adopter\". Siete aperti ai cambiamenti e disposti ad accettarli, anche se forse non è ancora del tutto chiaro quali problemi sorgeranno o quali saranno esattamente le conseguenze. Puoi affrontare così bene le innovazioni e il mondo in rapida evoluzione, che ti rende agile.\n\nUn rischio potrebbe essere quello di non valutare adeguatamente le conseguenze dei cambiamenti. Prova a fermarti di tanto in tanto e ad ascoltare le idee degli altri e quali problemi vedono ancora in un particolare cambiamento.\n\nIl lavoro agile ti andrà bene, perché si adatta bene alla tua agilità. È importante non assecondare sempre ogni cambiamento, ma anche finire le cose prima di iniziare qualcosa di nuovo.",
                  "Appartieni alla categoria degli \"early adopter\". Siete aperti ai cambiamenti e disposti ad accettarli, anche se forse non è ancora del tutto chiaro quali problemi sorgeranno o quali saranno esattamente le conseguenze. Puoi affrontare così bene le innovazioni e il mondo in rapida evoluzione, che ti rende agile.\n\nUn rischio potrebbe essere quello di non valutare adeguatamente le conseguenze dei cambiamenti. Prova a fermarti di tanto in tanto e ad ascoltare le idee degli altri e quali problemi vedono ancora in un particolare cambiamento.\n\nIl lavoro agile ti andrà bene, perché si adatta bene alla tua agilità. È importante non assecondare sempre ogni cambiamento, ma anche finire le cose prima di iniziare qualcosa di nuovo."
                ],
                "early_majority": [
                  "Appartieni alla 'prima maggioranza'. Sei leggermente più propenso della media ad accettare l'innovazione, ma ti piace sempre aspettare fino a quando i problemi iniziali non saranno risolti. Hai un atteggiamento positivo nei confronti del cambiamento, ma vuoi sapere cosa significa per te. Piani, cambiamenti e nuovi metodi di lavoro devono prima essere testati a fondo da te.\n\nPuoi andare abbastanza d'accordo con il lavoro Agile e apprezzerai che esistono metodi di lavoro strutturati per questo. Tuttavia, non fare del metodo un obiettivo in sé, si tratta in definitiva dello stile di lavoro e della realizzazione di prodotti e metodi di lavoro nuovi e utili.",
                  "Appartieni alla 'prima maggioranza'. Sei più propenso rispetto alla media ad accettare l'innovazione, ma ti piace aspettare fino a quando i problemi iniziali non saranno risolti. Hai un atteggiamento positivo nei confronti del cambiamento, ma vuoi sapere cosa significa per te. Piani, cambiamenti e nuovi metodi di lavoro potrebbero essere stati ben testati per te.\n\nPuoi andare d'accordo con il lavoro Agile e apprezzerai che esistono metodi di lavoro strutturati per questo. Tuttavia, non fare del metodo un obiettivo in sé, si tratta in definitiva dello stile di lavoro e della realizzazione di prodotti e metodi di lavoro nuovi e utili."
                ],
                "innovator": [
                  "Sei un 'innovatore' e un precursore. Spesso avvii il cambiamento, trovi nuove idee e sei aperto a nuove avventure. Incoraggi gli altri a partecipare ai processi di cambiamento. Ti piace soprattutto il lavoro agile perché è un modo di lavorare agile e che offre spazio all'innovazione. Ti piace il fatto che questo crei movimento più velocemente. Poiché lavori principalmente a grandi linee, è possibile che trascuri i dettagli. Usa i talenti degli altri per questo.\n\nPotrebbe esserci il rischio che a volte tu voglia andare troppo veloce. Assicurati di non andare troppo avanti rispetto alle truppe e che i tuoi piani rimangano realistici. La resistenza e l'incomprensione possono irritarti. Anche il reporting non fa per te. Ciò può portare a negligenza e renderti meno imitabile per gli altri.",
                  "Sei un \"innovatore\" e un chiaro pioniere. Inizi il cambiamento, trovi nuove idee e sei aperto a nuove avventure. Incoraggi gli altri a partecipare ai processi di cambiamento. Ti piace soprattutto il lavoro agile perché è un modo di lavorare agile e che offre spazio all'innovazione. Ti piace il fatto che questo crei movimento più velocemente. Poiché lavori principalmente a grandi linee, è possibile che trascuri i dettagli. Usa i talenti degli altri per questo.\n\nPotrebbe esserci il rischio che tu voglia andare troppo veloce. Assicurati di non andare troppo avanti rispetto alle truppe e che i tuoi piani rimangano realistici. La resistenza e l'incomprensione ti irritano. Anche il reporting non fa per te. Ciò può portare a negligenza e renderti meno imitabile per gli altri."
                ],
                "laggards": [
                  "Appartieni ai cosiddetti 'laggards', i ritardatari. Di fronte ai cambiamenti, tendi a ignorare tutto e preferisci mantenere tutto uguale. Hai davvero bisogno di essere convinto a cambiare, e preferisci farlo a piccoli passi piuttosto che a grandi passi contemporaneamente. Sei scettico riguardo al cambiamento e talvolta è giusto. Dopotutto, non tutti i cambiamenti sono miglioramenti. Ma attenzione a non rimanere troppo indietro e a voler mantenere a tutti i costi il vecchio familiare. Dopotutto, il mondo intorno a noi sta cambiando rapidamente. A volte dovrai superare te stesso per apprendere e applicare nuovi metodi.\n\nDove puoi essere utile durante i cambiamenti è garantire che venga prestata attenzione ai dettagli, garantendo così la qualità di un cambiamento. Puoi anche contribuire a rendere pratica l'innovazione, assicurandoti che tutto sia elaborato e registrato fino all'ultimo dettaglio.\n\nLavorare in team Agile sarà meno adatto a te. Preferisci far parte di un team in cui il lavoro è strutturato e di natura più di routine.",
                  "Appartieni ai cosiddetti 'laggards', i ritardatari. Di fronte ai cambiamenti, tendi a ignorare la situazione. Tendi a voler mantenere tutto uguale. Hai davvero bisogno di essere convinto a cambiare, e preferisci farlo a piccoli passi piuttosto che a grandi passi contemporaneamente. Sei scettico riguardo al cambiamento e talvolta è giusto. Dopotutto, non tutti i cambiamenti sono miglioramenti. Ma attenzione a non rimanere troppo indietro e a voler mantenere a tutti i costi il vecchio familiare. Dopotutto, il mondo intorno a noi sta cambiando rapidamente. A volte dovrai superare te stesso per apprendere e applicare nuovi metodi.\n\nDove puoi essere utile durante i cambiamenti è garantire che venga prestata attenzione ai dettagli, garantendo così la qualità di un cambiamento. Puoi anche contribuire a rendere pratica l'innovazione, assicurandoti che tutto sia elaborato e registrato fino all'ultimo dettaglio.\n\nLavorare in team Agile potrebbe essere meno adatto a te. Preferisci far parte di un team in cui il lavoro è strutturato e possibilmente di natura più di routine.",
                  "Appartieni ai cosiddetti 'laggards', i ritardatari. Di fronte ai cambiamenti, tendi a ignorare la situazione. Tendi a voler mantenere tutto uguale. Hai davvero bisogno di essere convinto a cambiare, e preferisci farlo a piccoli passi piuttosto che a grandi passi contemporaneamente. Sei scettico riguardo al cambiamento e talvolta è giusto. Dopotutto, non tutti i cambiamenti sono miglioramenti. Ma attenzione a non rimanere troppo indietro e a voler mantenere a tutti i costi il vecchio familiare. Dopotutto, il mondo intorno a noi sta cambiando rapidamente. A volte dovrai superare te stesso per apprendere e applicare nuovi metodi.\n\nDove puoi essere utile durante i cambiamenti è garantire che venga prestata attenzione ai dettagli, garantendo così la qualità di un cambiamento. Puoi anche contribuire a rendere pratica l'innovazione, assicurandoti che tutto sia elaborato e registrato fino all'ultimo dettaglio.\n\nLavorare in team Agile potrebbe essere meno adatto a te. Preferisci far parte di un team in cui il lavoro è strutturato e possibilmente di natura più di routine."
                ],
                "late_majority": [
                  "Appartieni alla \"tarda maggioranza\". Alla fine accetti i cambiamenti, ma di solito non con tutto il cuore. Ci vuole uno sforzo per adattarsi. Quindi vuoi sempre sapere prima quali sono i vantaggi del cambiamento, qual è la sua utilità e quali vantaggi ti porteranno personalmente. Fai attenzione a non tendere ad una “mentalità fissa”, a non voler aggrapparti troppo al vecchio familiare. Naturalmente non vorrai restare troppo indietro, dopotutto il mondo non si ferma.\n\nIl tuo valore nei processi di cambiamento è che puoi identificare chiaramente le obiezioni, i punti che possono ancora essere migliorati, prima che siano praticamente utili. Il tuo atteggiamento critico aumenta le possibilità di successo del cambiamento.\n\nLa domanda è se lavorare in team Agile è adatto a te. Il tuo ruolo nel team potrebbe forse essere quello di controller o tester. Qualcuno che identifichi i rischi e renda visibili le obiezioni. Ce n'è bisogno anche nei team!",
                  "Appartieni alla \"tarda maggioranza\". Alla fine accetti i cambiamenti, ma non sempre con tutto il cuore. Ci vuole un certo sforzo per adattarsi. Vuoi quindi sapere quali sono i vantaggi del cambiamento, qual è la sua utilità e quali vantaggi ti porterà personalmente. Fai attenzione a non tendere ad una “mentalità fissa”, a non voler aggrapparti troppo al vecchio familiare. Naturalmente non vorrai restare troppo indietro, dopotutto il mondo non si ferma.\n\nIl tuo valore nei processi di cambiamento è che puoi identificare chiaramente le obiezioni, i punti che possono ancora essere migliorati, prima che siano praticamente utili. Il tuo atteggiamento critico aumenta le possibilità di successo del cambiamento.\n\nLa domanda è se lavorare in team Agile è adatto a te. Il tuo ruolo nel team potrebbe forse essere quello di controller o tester. Qualcuno che identifichi i rischi e renda visibili le obiezioni. Ce n'è bisogno anche nei team!"
                ]
              },
              "pillars": {
                "change": [
                  "Ti piace sapere a che punto sei. Ti piace quando le cose rimangono le stesse e hai una certa routine nel tuo lavoro. Preferisci lavorare su una serie fissa di compiti, secondo procedure prescritte. Preferisci andare sul sicuro piuttosto che correre rischi. Non è necessario necessariamente cambiare o innovare le cose. Implementare cambiamenti nel tuo lavoro è qualcosa che potresti trovare fastidioso. Anche altri potrebbero percepirlo come una difficoltà, come una resistenza. Cerca di vedere il positivo in ciò che ti capita. Fai piccoli passi, ma continua a muoverti. Fai attenzione a non diventare uno di quelli rimasti indietro.",
                  "Ti piace che le cose rimangano le stesse, ma non ti preoccupi dei piccoli cambiamenti. Di solito preferisci lavorare su una serie fissa di compiti secondo procedure prescritte. Sebbene ti piaccia sapere a che punto ti trovi, a volte sei anche curioso di conoscere i cambiamenti volti al miglioramento. Non sarai in prima linea in questi cambiamenti, poiché preferisci limitare la tua assunzione di rischi al minimo indispensabile, ma ti conformerai ai cambiamenti. Nemmeno tu lo riprenderai molto velocemente.",
                  "Sei neutrale nei confronti dei cambiamenti. Sebbene ti piaccia svolgere determinate attività fisse, ti piace anche sperimentare una certa varietà nel tuo ambiente o nelle tue attività. Quando avviene un cambiamento nella tua organizzazione, non sarai immediatamente tu ad avviare quel cambiamento, ma sarai in grado di adattarti alla nuova situazione abbastanza facilmente. Non trovi necessario lavorare secondo schemi fissi, anche se non lo trovi inquietante. Inoltre, accogli con favore nuovi compiti che devono ancora essere pensati.",
                  "Ti piace quando i cambiamenti avvengono regolarmente nel tuo lavoro. Non hai bisogno di un insieme fisso di compiti, preferisci un lavoro vario. Quando svolgi compiti regolari, penserai a nuovi modi in cui puoi affrontare questo lavoro in modo diverso. Trovi demotivante quando ti viene detto come fare certe cose. Quando qualcuno avvia un cambiamento, sarai aperto ad esso, ma i rischi associati non dovrebbero essere troppo alti per quanto ti riguarda.",
                  "Abbracci i cambiamenti. Spesso promuovi anche l'innovazione; nuove intuizioni ed esperimenti ti vengono in modo naturale. Idealmente, ogni giorno lavorativo sarebbe diverso per te. Ti piace avere compiti stimolanti e vari che puoi organizzare come ritieni opportuno. Sperimenti le procedure, la routine e le normative come demotivanti. In genere sarai in prima linea nei cambiamenti, qualcosa che avvierai regolarmente. Assumere rischi non ti scoraggia, perché consideri l’innovazione più importante della certezza."
                ],
                "growth": [
                  "Hai bisogno di certezze e routine. Non vivi l'ambizione o il misurarti con gli altri come stimolante, anzi ti spegne. Hai padroneggiato una serie di compiti in cui sei bravo e non ritieni necessario apprendere altri compiti. Di solito sei soddisfatto del livello al quale svolgi attualmente i compiti e non senti alcuna pressione per svolgere i compiti meglio dei tuoi colleghi. Eviti i compiti che ritieni siano al di là delle tue capacità, perché vuoi fare pochi errori. Poiché sei così concentrato sulla qualità, non puoi sempre ignorarla quando commetti un errore.",
                  "Hai bisogno di sicurezza più che di sviluppo. Hai padroneggiato una serie di compiti in cui sei bravo e generalmente sei soddisfatto del livello al quale ora svolgi i compiti. Vuoi imparare come eseguire queste attività ancora meglio, ma non senti la necessità di apprendere altre attività. Eviti i compiti che ritieni siano al di là delle tue capacità, perché vuoi fare pochi errori. Potresti ricevere feedback sul tuo lavoro a livello personale, perché ti piace apparire buono agli occhi degli altri.",
                  "Hai un bisogno medio di auto-sviluppo. Ti piace avere una serie di compiti fissi, ma sei anche aperto all'apprendimento di nuovi compiti. Ti piace anche migliorare nei tuoi compiti attuali. Vedi le tue prestazioni in modo isolato e non sei propenso a confrontare le tue prestazioni con quelle dei tuoi colleghi. Anche se non ti piace commettere errori, capisci che questo fa parte del processo di apprendimento.",
                  "Hai un forte bisogno di auto-sviluppo. Il tuo obiettivo è imparare molto e migliorarti nei compiti di cui sei responsabile. Preferisci svolgere meglio i tuoi compiti e affrontare compiti più difficili rispetto ai tuoi colleghi. Non ti dispiace fare errori, perché fa parte del processo di apprendimento. Hai un approccio resiliente e non ti fermi facilmente. Infine, non ti dispiace avere alcuni compiti che offrono meno opportunità di sviluppo.",
                  "Trovi che il tuo sviluppo personale sia estremamente importante. Hai una grande sete di conoscenza e vorresti fare dei passi. Trovi fastidiose le attività di routine perché preferisci pensare il più possibile. Il tuo obiettivo è imparare molto e non hai problemi a commettere errori. Consideri questi errori come fonti di informazione, perché questi sono i punti in cui puoi svilupparti ulteriormente. Affronti compiti difficili con entrambe le mani. Per quanto possibile, cerchi di ottenere il meglio da te stesso e vuoi essere migliore degli altri."
                ],
                "power": [
                  "Ti concentri sulla preservazione di ciò che è buono, in base al tuo approccio orientato alla qualità. Preferisci lavorare su attività familiari o progetti per i quali hai già acquisito competenze, perché vuoi fornire un lavoro impeccabile. Iniziare nuovi progetti o apprendere altri compiti ti attrae meno perché hai paura di commettere errori. I progetti che hai padroneggiato attraverso l'esperienza e la routine sono quindi la tua preferenza. Sei critico nei confronti dei cambiamenti, che possono favorire la qualità di queste innovazioni. Tuttavia, poiché vedi regolarmente ostacoli sulla strada, hai anche la tendenza a resistere ai cambiamenti. I cambiamenti possono anche costarti troppa energia. È consigliabile indagare su come rafforzare il proprio potere, per avere energia sufficiente per mantenere il proprio posto nel mondo che cambia e per contribuire positivamente ad esso.",
                  "Preferisci immergerti in un argomento. Preferisci lavorare più a lungo su un progetto in cui stai acquisendo esperienza, piuttosto che concentrarti sull'ampliamento delle tue competenze. Ti piace anche lavorare su progetti in cui tutti i processi sono conosciuti e semplificati. Preferisci compiti e progetti che hai padroneggiato attraverso l'esperienza e la routine. I problemi iniziali all’interno di nuovi progetti ti infastidiscono. Adotterai quindi un atteggiamento critico nei confronti delle innovazioni, che può giovare alla qualità di questi cambiamenti. Fai attenzione a non lasciarti frenare troppo dai possibili ostacoli che vedi sulla strada, perché i cambiamenti possono avere anche tante conseguenze positive. È consigliabile indagare su come rafforzare il proprio potere, per avere energia sufficiente per mantenere il proprio posto nel mondo che cambia e per contribuire positivamente ad esso.",
                  "Hai abbastanza energia per affrontare i cambiamenti senza svolgere un ruolo di iniziativa. Da un lato vedi i benefici dei cambiamenti e allo stesso tempo trovi piacevole restare fedele a ciò che ti è familiare. Sei aperto a nuovi compiti e attività, ma ti piace quando non si verificano troppi cambiamenti contemporaneamente. Il vantaggio di questa varietà è che ti dà lo spazio per affrontare i possibili problemi iniziali di questi cambiamenti.",
                  "Possiedi un'alta dose di energia innovativa. Mostri molta forza e impegno e quindi riesci a gestire i cambiamenti abbastanza bene. In genere si vedono gli aspetti positivi dell'innovazione, anche se a volte può risultare fastidioso il fatto che non tutti i processi siano stati ancora ottimizzati. Tuttavia, non ti lasci fermare e contribuisci attivamente a migliorare i processi di cambiamento sul posto di lavoro.",
                  "Possiedi un'enorme dose di temperamento innovativo. Ciò significa che hai la forza, l’impegno e l’attitudine per affrontare bene i cambiamenti. Sebbene le innovazioni possano avere molte conseguenze positive, spesso ci sono problemi iniziali o processi che non sono stati ancora ottimizzati. Capisci che questo fa parte del processo di cambiamento e puoi gestirlo bene. In caso di tale battuta d'arresto, non sarai scoraggiato e continuerai a lavorare con un atteggiamento positivo. Credi che con il duro lavoro e la perseveranza puoi superare qualsiasi sfida."
                ]
              },
              "tips": {
                "change": [
                  "Sebbene razionalmente ogni cambiamento abbia i suoi punti positivi e le sue opportunità, potresti prestare maggiore attenzione ai pericoli e alle minacce che comporta. Durante i processi di cambiamento, chiediti regolarmente cosa si può davvero concludere con certezza e cosa ne hai ricavato tu stesso. Interroga gli orsi che vedi sulla strada. Rendendoti consapevole del confine tra i fatti e le tue interpretazioni, impari ad affrontare i cambiamenti in modo più neutrale. Un altro modo in cui puoi affrontare le cose che consideri minacce è scrivere tutti i rischi e gli svantaggi che hai in mente. Quindi sforzati di offrire in cambio altrettanti vantaggi e nuove opportunità.",
                  "Sebbene razionalmente ogni cambiamento abbia i suoi punti positivi e le sue opportunità, potresti prestare maggiore attenzione ai pericoli e alle minacce che comporta. Durante i processi di cambiamento, chiediti regolarmente cosa si può davvero concludere con certezza e cosa ne hai ricavato tu stesso. Interroga gli orsi che vedi sulla strada. Rendendoti consapevole del confine tra i fatti e le tue interpretazioni, impari ad affrontare i cambiamenti in modo più neutrale. Un altro modo in cui puoi affrontare le cose che consideri minacce è scrivere tutti i rischi e gli svantaggi che hai in mente. Quindi sforzati di offrire in cambio altrettanti vantaggi e nuove opportunità.",
                  "Ti adatti ai cambiamenti abbastanza facilmente. Tuttavia, non sarai sempre propenso a rispondere con entusiasmo ai cambiamenti. Razionalmente parlando, ogni cambiamento ha i suoi punti positivi e le sue opportunità. Per aumentare il tuo entusiasmo per il cambiamento, fai un elenco di tutti i vantaggi e le nuove opportunità che questo cambiamento comporta. Rendendoti consapevole delle conseguenze positive del cambiamento, ti entusiasmerai più facilmente.",
                  "Con il tuo atteggiamento aperto ai cambiamenti sarai una buona bussola per gli innovatori, che a volte vogliono andare troppo avanti rispetto al gruppo. Stimare i rischi dei cambiamenti e quanto realistici li consideri, e discuterne con gli Innovatori.",
                  "Assicurati di rimanere in contatto con gli altri, non camminare troppo davanti alle truppe. Prestare attenzione a garantire la qualità delle modifiche non nuoce."
                ],
                "growth": [
                  "Investiga su quali competenze puoi e vuoi sviluppare ulteriormente e quali sono utili per la tua occupabilità. Poiché ti concentri sulla certezza e sulla routine, in futuro potresti concentrarti meno sulle competenze che sono rilevanti e interessanti per il tuo datore di lavoro. Cerca di prevenirlo e cogli le opportunità quando vedi opportunità per sviluppare ulteriormente le competenze che ritieni utili.",
                  "Investiga su quali competenze puoi e vuoi sviluppare ulteriormente e quali sono utili per la tua occupabilità. Poiché hai più bisogno di sicurezza che di sviluppo, in futuro potresti concentrarti meno sulle competenze che sono rilevanti e interessanti per il tuo datore di lavoro. Cerca di prevenirlo scoprendo quali competenze rilevanti puoi padroneggiare e prendendo iniziative per sviluppare queste competenze.",
                  "Hai una buona combinazione di qualità: non hai problemi a svolgere compiti familiari, ma ti piace anche continuare ad imparare. Verifica se il tuo saldo attuale è quello desiderato. Ci sono delle competenze che vorresti padroneggiare? Cosa puoi fare per sviluppare queste competenze? Cerca di prendere iniziative a tal fine e crea opportunità nel tuo lavoro.",
                  "Hai una buona combinazione di qualità: ti piace svilupparti in vari ambiti, ma non hai problemi anche a svolgere compiti familiari. Verifica se il tuo saldo attuale è quello desiderato. Ci sono delle competenze che vorresti padroneggiare? Cosa puoi fare per sviluppare queste competenze? Cerca di prendere iniziative a tal fine e crea opportunità nel tuo lavoro.",
                  "Hai una grande sete di conoscenza e di sviluppo personale, una buona qualità quando si tratta di cambiamenti nelle organizzazioni. Tuttavia, ricorda anche che non tutto cambierà nello stesso momento e che alcune attività di routine fanno semplicemente parte del tuo lavoro. Non lasciare che il tuo umore e la tua motivazione ti influenzino troppo quando devi svolgere compiti più familiari, perché con la tua apertura allo sviluppo, un nuovo compito impegnativo ti verrà automaticamente incontro."
                ],
                "power": [
                  "Per rafforzare il tuo potere, potresti scoprire quali conseguenze positive hanno sperimentato altre persone a seguito dei cambiamenti. Prova anche a chiederti quali cambiamenti sono avvenuti nella tua vita o sul posto di lavoro, e come li hai affrontati. Concentrati principalmente sugli aspetti che hai affrontato bene. Ci sono stati momenti in cui hai avviato tu stesso i cambiamenti? Quali sono state le conseguenze positive di ciò? Concentrandoti su questi elementi, puoi aumentare la fiducia in te stesso nell’affrontare i cambiamenti.",
                  "Per rafforzare il tuo potere, potresti scoprire quali conseguenze positive hanno sperimentato altre persone a seguito dei cambiamenti. Prova anche a indovinare quali cambiamenti sono avvenuti nella tua vita o sul posto di lavoro, e come li hai affrontati. Concentrati principalmente sugli aspetti che hai affrontato bene. Ci sono stati momenti in cui hai avviato tu stesso i cambiamenti? Quali sono state le conseguenze positive di ciò? Concentrandoti su questi elementi, puoi aumentare la fiducia in te stesso nell’affrontare i cambiamenti.",
                  "Cogli la tua opportunità: hai un ruolo importante nel moderare piani troppo ambiziosi, in cui le persone vogliono affrontare troppe cose allo stesso tempo. Sebbene tu sia aperto ai cambiamenti, mantieni i piedi per terra. Questo atteggiamento può avere un effetto che fa riflettere sugli innovatori.",
                  "Cogli la tua opportunità: hai un ruolo importante nel moderare piani troppo ambiziosi, in cui le persone vogliono affrontare troppe cose allo stesso tempo. Sebbene tu sia molto aperto ai cambiamenti, sei anche consapevole dei possibili ostacoli. Prova a farlo notare agli Innovatori e pensa a come puoi prevenire o risolvere gli ostacoli.",
                  "È una buona qualità avere molta energia per affrontare i cambiamenti. Tuttavia, assicurati di non lasciarti accecare dal tuo entusiasmo e ascolta le preoccupazioni e i desideri che i tuoi colleghi esprimono riguardo ai cambiamenti. Prendi sul serio i possibili ostacoli che vedono e cerca di trovare una soluzione. Ciò migliorerà la qualità delle modifiche."
                ]
              }
            },
            "header": "Agilità",
            "tip": "MANCIA!",
            "titles": {
              "groups": {
                "early_adopter": "I primi ad adottare",
                "early_majority": "Maggioranza anticipata",
                "innovator": "Innovatore",
                "laggards": "Ritardatari",
                "late_majority": "Maggioranza tardiva"
              },
              "pillars": {
                "change": "Modifica",
                "growth": "Crescita personale (crescita)",
                "power": "Energia"
              }
            }
          },
          "behavior": {
            "header": "Tratti comportamentali",
            "keys": {
              "aanspreken": "Parlare e parlare",
              "durft": "Osare",
              "veranderbereid": "Cambiare la volontà"
            }
          },
          "candidate": {
            "date_of_birth": "Data di nascita",
            "education": "Livello di istruzione",
            "email": "Indirizzo e-mail",
            "header": "Dati personali",
            "most_recent_work_experience": "Esperienza lavorativa più recente",
            "study_subject": "Corso",
            "years": {
              "one": "%{years} anni",
              "other": "%{years} anni"
            },
            "years_of_experience": "Anni di esperienza"
          },
          "competences": {
            "descriptions": {
              "aansturenvangroepen": "Essere in grado di guidare un gruppo.",
              "aansturenvanindividuen": "Essere in grado di guidare un individuo.",
              "ambitie": "Essere motivati, voler esibirsi al di sopra della media.",
              "assertiviteit": "Difende le proprie opinioni, anche quando viene messo sotto pressione dall'ambiente.",
              "besluitvaardigheid": "Essere in grado di prendere decisioni in modo rapido e adeguato.",
              "coachenvanmedewerkers": "Essere in grado di stimolare il ruolo dei dirigenti e guidarli nel loro sviluppo.",
              "delegeren": "Essere in grado di trasferire il lavoro ad altri in modo mirato.",
              "doorzettingsvermogen": "Concentrarsi sul portare a termine le cose una volta iniziate, nonostante gli insuccessi.",
              "flexibiliteit": "Può agire in modo mirato ed efficace in circostanze diverse e mutevoli modificando gli stili comportamentali.",
              "initiatief": "Essere in grado di avviare le cose e intraprendere azioni efficaci per conto proprio.",
              "inzet": "Essere in grado di fare un grande sforzo per un lungo periodo di tempo.",
              "klantorientatie": "Essere in grado di entrare in empatia e rispondere alle esigenze del cliente.",
              "kwaliteitsgerichtheid": "Concentrarsi sulla fornitura di alta qualità e sul perfezionamento delle cose.",
              "leerbereidheid": "Essere disposti a sviluppare ed espandere conoscenze e abilità attraverso l'apprendimento.",
              "nauwkeurigheid": "Essere in grado di lavorare con precisione e avere occhio per i dettagli e le connessioni.",
              "onderhandelen": "Essere in grado di esplorare interessi e posizioni reciproci al fine di raggiungere un accordo accettabile per tutte le parti.",
              "ondernemerschap": "Concentrarsi sul raggiungimento del profitto identificando e cogliendo opportunità di business e osando assumere rischi calcolati.",
              "optreden": "Fare un'impressione forte e professionale sugli altri.",
              "organisatiesensitiviteit": "Essere in grado di riconoscere l'influenza e le conseguenze delle proprie decisioni o attività su altre unità organizzative.",
              "overtuigingskracht": "Essere in grado di convincere gli altri ad essere d'accordo con un certo punto di vista, proposta o idea.",
              "plannenenorganiseren": "Essere in grado di pianificare e organizzare attività e lavoro.",
              "relatiebeheer": "Stabilire e mantenere relazioni in modo costruttivo.",
              "resultaatgerichtheid": "Essere spinti a raggiungere obiettivi e risultati concreti.",
              "samenwerking": "Raggiungere obiettivi comuni insieme agli altri in modo costruttivo.",
              "sensitiviteit": "Essere in grado di percepire i segnali degli altri e rispondere in modo appropriato.",
              "sociabiliteit": "Si muove volentieri e facilmente in gruppi di persone.",
              "stressbestendigheid": "Essere in grado di continuare a funzionare efficacemente in circostanze stressanti.",
              "voortgangscontrole": "Essere in grado di dare seguito alle questioni una volta avviate e verificarne i progressi.",
              "zelfstandigheid": "Essere in grado di svolgere il lavoro in autonomia, fissando obiettivi e dando loro forma e contenuto."
            },
            "header": "Le tue 5 competenze principali",
            "keys": {
              "aansturenvangroepen": "Gestione dei gruppi",
              "aansturenvanindividuen": "Gestire gli individui",
              "ambitie": "Ambizione",
              "assertiviteit": "Assertività",
              "besluitvaardigheid": "Decisione",
              "coachenvanmedewerkers": "Formare i dipendenti",
              "delegeren": "Delegare",
              "doorzettingsvermogen": "Perseveranza",
              "flexibiliteit": "Flessibilità",
              "initiatief": "Iniziativa",
              "inzet": "Palo",
              "klantorientatie": "Orientamento alla clientela",
              "kwaliteitsgerichtheid": "Orientamento alla qualità",
              "leerbereidheid": "Voglia di imparare",
              "nauwkeurigheid": "Precisione",
              "nauwkeurigheid_comp": "Precisione",
              "onderhandelen": "Negoziare",
              "ondernemerschap": "Imprenditoria",
              "optreden": "Prestazione",
              "organisatiesensitiviteit": "Sensibilità organizzativa",
              "overtuigingskracht": "Persuasività",
              "plannenenorganiseren": "Pianificazione e organizzazione",
              "relatiebeheer": "Gestione delle relazioni",
              "resultaatgerichtheid": "Orientamento al risultato",
              "samenwerking": "Cooperazione",
              "samenwerking_comp": "Cooperazione",
              "sensitiviteit": "Sensibilità",
              "sociabiliteit": "Socievolezza",
              "stressbestendigheid": "Resistenza allo stress",
              "voortgangscontrole": "Controllo avanzamento",
              "zelfstandigheid": "Indipendenza"
            }
          },
          "development": {
            "header": "Potenziale di sviluppo",
            "keys": {
              "ambitie": "Ambizione",
              "growth": "Crescita personale",
              "onafhankelijkheid": "Indipendenza",
              "samenwerking": "Lavorare in gruppo",
              "sociabiliteit": "Socievolezza"
            }
          },
          "documents": {
            "date": "Data",
            "header": "Documenti (%{documents})",
            "less": "Mostra meno",
            "more": "Mostra di più",
            "refresh": "La tua sessione è scaduta. Clicca sul pulsante qui sotto per ricaricare la pagina."
          },
          "explanations": {
            "behavior": {
              "description": "Le caratteristiche comportamentali sono i principi che costituiscono la base del modo in cui vogliamo collaborare tra loro all'interno dei NS"
            },
            "intelligence": {
              "description": "In questo widget vedi due tipi di punteggi. Il tuo punteggio di intelligenza viene mostrato nella prima pagina. Un punteggio pari a 100 è la media della popolazione attiva olandese.\n\nNelle pagine seguenti, il tuo punteggio viene confrontato con gruppi di riferimento di diversi livelli di istruzione. I numeri su queste pagine sono punteggi sten, che mostrano il tuo punteggio rispetto al gruppo di riferimento. I punteggi, ad esempio, di 5 o 6 sono medi e comuni. I punteggi, ad esempio, di 1 o 10 sono eccezionali e rari."
            },
            "interests": {
              "description": "In questo widget vedi due tipi di punteggi, il tuo interesse per i settori e per le attività. Il tuo interesse per i settori mostra in quale settore del mercato del lavoro ti piacerebbe lavorare. Il tuo interesse per le attività indica quali attività vorresti svolgere. Queste informazioni possono aiutarti a scoprire quale lavoro è interessante per te. Eseguirai questo lavoro con più motivazione e piacere rispetto a un lavoro meno rilevante per i tuoi interessi.\n\nLa prima pagina mostra i tuoi 5 principali interessi nei settori. I punteggi vanno da 1 a 10, dove 1 rappresenta un interesse molto al di sotto della media e 10 rappresenta un interesse molto al di sopra della media. La seconda pagina mostra i tuoi 5 principali interessi nelle attività. I punteggi vanno da 1 a 5, dove 1 rappresenta un interesse molto al di sotto della media e 5 rappresenta un interesse molto al di sopra della media."
            },
            "star_scores": {
              "description": "Le stelle in questo widget mostrano quanto sono sviluppabili le competenze per te. Più stelle saranno colorate di giallo, maggiore sarà il tuo talento. Ciò non significa che la competenza sia già stata sviluppata e a quale livello."
            },
            "sten_scores": {
              "description": "I numeri in questo widget sono punteggi sten, che mostrano il tuo punteggio rispetto alla popolazione attiva olandese. I punteggi, ad esempio, di 5 o 6 sono medi e comuni. I punteggi, ad esempio, di 1 o 10 sono eccezionali e rari. I punteggi al di sotto e al di sopra della media portano con sé qualità e insidie.",
              "scores": [
                "Ben al di sotto della media",
                "Ben al di sotto della media",
                "Sotto la media",
                "Appena sotto la media",
                "Media",
                "Media",
                "Appena sopra la media",
                "Sopra la media",
                "Ben al di sopra della media",
                "Molto al di sopra della media"
              ]
            }
          },
          "filter": {
            "clear": "Cancella selezione",
            "empty": "Seleziona fino a 10 competenze per vedere i tuoi risultati",
            "header": "Filtra le competenze"
          },
          "hybrid_work": {
            "average": {
              "gemiddeld": "media",
              "hoog": "alto",
              "laag": "Basso"
            },
            "header": "Competenze del 21° secolo",
            "skills": {
              "autonomie": {
                "descriptions": {
                  "gemiddeld": "Quando lavori da casa, hai una certa libertà di decidere in modo indipendente come svolgere il tuo lavoro. Tuttavia, a volte è necessaria l'autorizzazione per eseguire determinate attività e si sperimenta un certo grado di controllo. Inoltre, a volte potresti essere distratto dall’ambiente domestico. Probabilmente ti senti in qualche modo in grado di utilizzare le tue capacità per contribuire al team e/o all'organizzazione in cui lavori. Ciò può portare ad una certa motivazione e soddisfazione.",
                  "hoog": "Quando lavori da casa, sperimenti la libertà di decidere in modo indipendente come svolgere il tuo lavoro. Non sei monitorato molto. Hai un elevato grado di responsabilità e sei in grado di pianificare e organizzare il tuo lavoro in modo indipendente. Raramente sei disturbato dalle distrazioni del tuo ambiente domestico e difficilmente avverti conflitti tra vita privata e lavoro. Probabilmente ti senti in grado di utilizzare le tue capacità per contribuire al team e/o all'organizzazione in cui lavori. Ciò può portare ad un’elevata motivazione e soddisfazione.",
                  "laag": "Quando lavori da casa, hai poca libertà di decidere autonomamente come svolgere il tuo lavoro. Sei spesso monitorato e hai bisogno dell'autorizzazione per eseguire determinate attività. Ciò ti rende dipendente dagli altri per prendere decisioni sul tuo lavoro. Probabilmente a volte durante il lavoro sei distratto dall'ambiente domestico e vivi conflitti tra la vita privata e quella lavorativa. Potresti sentirti inibito e avere poca motivazione e soddisfazione."
                },
                "label": "Autonomia"
              },
              "competentie": {
                "descriptions": {
                  "gemiddeld": "Quando lavori da casa provi una sensazione media di competenza. Ciò significa che di solito hai le capacità e le conoscenze per svolgere il tuo lavoro in modo efficace, ma a volte potresti avere difficoltà a svolgere bene determinati compiti. A volte puoi essere limitato dalle tue capacità tecnologiche. In generale, hai una certa fiducia nelle tue capacità, ma potresti sentirti insicuro riguardo alla tua capacità di svolgere bene il tuo lavoro. Ciò può portare a una riduzione della motivazione e della soddisfazione.",
                  "hoog": "Quando lavori da casa sperimenti un alto livello di competenza. Hai le capacità e le conoscenze per svolgere il tuo lavoro in modo efficace. Riscontri pochi o nessun problema con le tue capacità tecniche quando lavori da casa. Hai molta fiducia nelle tue capacità e probabilmente sarai motivato e molto soddisfatto delle tue prestazioni lavorative.",
                  "laag": "Quando lavori da casa provi uno scarso senso di competenza. Ciò significa che probabilmente hai competenze e conoscenze limitate per svolgere il tuo lavoro in modo efficace e potresti avere difficoltà a completare determinate attività. Potresti riscontrare problemi con le tue competenze tecnologiche quando lavori da casa. Probabilmente è per questo che hai poca fiducia nelle tue capacità. Potresti sentirti insicuro riguardo alla tua capacità di svolgere bene il tuo lavoro. Ciò può portare a una riduzione della motivazione e della soddisfazione."
                },
                "label": "Competenza"
              },
              "verbondenheid": {
                "descriptions": {
                  "gemiddeld": "Quando lavori da casa, sperimenti un ragionevole grado di connessione con i tuoi colleghi. Occasionalmente potresti avere difficoltà a entrare in contatto con i colleghi. Non sempre provi il coinvolgimento dei tuoi colleghi. Ciò può portare a una riduzione della motivazione e della soddisfazione.",
                  "hoog": "Quando lavori da casa, provi un forte senso di connessione con i tuoi colleghi. Entri facilmente in contatto con i tuoi colleghi e sperimenti il loro supporto e coinvolgimento quando ne hai bisogno. Ciò contribuisce al tuo interesse per il tuo lavoro. Probabilmente sei motivato a svolgere e soddisfatto del tuo lavoro.",
                  "laag": "Quando lavori da casa, provi un debole senso di connessione con i tuoi colleghi. Hai difficoltà a entrare in contatto con i tuoi colleghi e provi poco supporto e coinvolgimento da parte loro. Ciò può portare a una riduzione della motivazione e della soddisfazione."
                },
                "label": "Connettività"
              }
            },
            "title": "Lavoro ibrido"
          },
          "information": {
            "skills": {
              "informatievaardigheden": {
                "label": "Abilità informative: gestire le informazioni",
                "tips": [
                  "Il modo in cui cerchi ed elabori le informazioni può essere migliorato in diverse aree. Puoi leggere ulteriori informazioni sul modello Big 6 di competenza informativa su <a href=\"https://o21.nu/informatieinformatie\" target=\"_blank\">questo</a> sito web. Verifica con te stesso quali passaggi puoi ancora migliorare!",
                  "Il modo in cui cerchi ed elabori le informazioni può essere migliorato in diverse aree. Puoi leggere ulteriori informazioni sul modello Big 6 di competenza informativa su <a href=\"https://o21.nu/informatieinformatie\" target=\"_blank\">questo</a> sito web. Verifica con te stesso quali passaggi puoi ancora migliorare!",
                  "Il modo in cui cerchi ed elabori le informazioni è già buono sotto molti aspetti! Ma c’è ancora spazio per miglioramenti. Puoi leggere ulteriori informazioni sul modello Big 6 di competenza informativa su <a href=\"https://o21.nu/informatieinformatie\" target=\"_blank\">questo</a> sito web. Verifica con te stesso quali passaggi stai già facendo bene e quali puoi migliorare.",
                  "Il modo in cui cerchi ed elabori le informazioni è già di alto livello. Ma chi lo sa, c’è ancora margine di miglioramento. Su <a href=\"https://o21.nu/informatieinformatie\" target=\"_blank\">questo</a> sito web puoi leggere di più sul modello Big 6 di competenza informativa. Verifica con te stesso quali passaggi stai già facendo bene e quali puoi migliorare ulteriormente!",
                  "Il modo in cui cerchi ed elabori le informazioni è già di alto livello. Ma chi lo sa, c’è ancora margine di miglioramento. Puoi leggere ulteriori informazioni sul modello Big 6 di competenza informativa su <a href=\"https://o21.nu/informatieinformatie\" target=\"_blank\">questo</a> sito web. Verifica con te stesso quali passaggi stai già facendo bene e quali puoi migliorare ulteriormente!"
                ]
              },
              "kritisch": {
                "description": "Esaminare criticamente le informazioni",
                "label": "Critico",
                "tips": [
                  "Non guardi in modo critico la fonte delle tue informazioni e tendi a copiare le informazioni letteralmente. Prova a combinare più spesso informazioni provenienti da fonti diverse e a esprimerle con parole tue. In questo modo sei automaticamente più critico riguardo alle informazioni che adotti.",
                  "Non sei sempre molto critico nei confronti della fonte delle tue informazioni. Tendi a prendere le informazioni alla lettera. Prova a combinare più spesso informazioni provenienti da fonti diverse e a esprimerle con parole tue. In questo modo sei automaticamente più critico riguardo alle informazioni che adotti.",
                  "Cerchi di non accettare semplicemente le informazioni e di copiarle letteralmente. Ma potresti farlo ancora di più. Prova a combinare più spesso informazioni provenienti da fonti diverse e a esprimerle con parole tue. In questo modo sei automaticamente più critico riguardo alle informazioni che adotti.",
                  "Sei accurato durante la ricerca e nell'elaborazione delle informazioni. Esamini criticamente varie fonti. Cerchi anche di inserire le informazioni con parole tue invece di limitarti a copiarle. Non allentare questa visione critica.",
                  "Sei accurato durante la ricerca e durante l'elaborazione delle informazioni. Esamini criticamente varie fonti. Cerchi anche di inserire le informazioni con parole tue invece di semplicemente copiarle. Mantieni questo occhio critico."
                ]
              },
              "organisatie": {
                "description": "Organizzazione delle informazioni",
                "label": "Organizzazione",
                "tips": [
                  "Non sempre ti prendi il tempo per preparare o valutare il tuo processo di ricerca. Di conseguenza, non troverai sempre le informazioni corrette e il processo di ricerca potrebbe richiedere più tempo del necessario.",
                  "A volte puoi dedicare più tempo alla preparazione o alla valutazione del processo di ricerca. Ciò ti consente di effettuare ricerche in modo ancora più specifico e di accelerare il processo di ricerca.",
                  "Stai cercando di preparare e valutare il tuo processo di ricerca, ma c'è ancora spazio per miglioramenti. Con una buona preparazione e un occhio critico durante il processo di ricerca, puoi cercare in modo ancora più specifico.",
                  "Ti prendi il tempo per preparare e valutare il tuo processo di ricerca. Prova a farlo più spesso, perché con una buona preparazione e un occhio critico durante il processo di ricerca puoi cercare in modo ancora più specifico.",
                  "Ti prendi tutto il tempo per preparare e valutare il tuo processo di ricerca. In questo modo eviti errori e duplicazioni di lavoro."
                ]
              },
              "presenteren": {
                "description": "Presentare informazioni",
                "label": "Presente",
                "tips": [
                  "Il modo in cui presenti le informazioni non è sempre chiaro e ordinato. Forse hai difficoltà a creare una struttura o non prendi in considerazione il tuo pubblico.",
                  "Il modo in cui presenti le informazioni può essere più chiaro e organizzato. Forse hai difficoltà a creare una struttura o non prendi in considerazione il tuo pubblico.",
                  "Fai del tuo meglio per presentare le informazioni in modo chiaro e conciso. Potresti migliorarlo ulteriormente aggiungendo più struttura. Puoi anche tenere ancora di più in considerazione il livello e la conoscenza pregressa del tuo pubblico.",
                  "Il modo in cui presenti le informazioni è generalmente chiaro e ordinato. Per migliorare ulteriormente questo aspetto, è bene pensare a chi è il tuo pubblico. In questo modo puoi tenere meglio conto del livello e delle conoscenze pregresse del tuo pubblico.",
                  "Il modo in cui presenti le informazioni è chiaro e ordinato. Assicurati che la tua storia abbia un filo conduttore. Prendi in considerazione anche il livello e la conoscenza preliminare del tuo pubblico."
                ]
              },
              "strategie": {
                "description": "La ricerca strategica delle informazioni",
                "label": "Strategia",
                "tips": [
                  "Sei limitato a un metodo di ricerca. Di conseguenza, non sempre troverai le informazioni corrette. La tua ricerca richiederà anche più tempo del necessario. Cerca suggerimenti per affinare la ricerca. Cambia la tua strategia se noti che non riesci a trovare le informazioni giuste.",
                  "Tendi a limitarti a un metodo di ricerca. Ciò può rendere difficile trovare le informazioni giuste. La tua ricerca richiederà anche più tempo del necessario. Cerca suggerimenti per migliorare la tua ricerca. Cambia la tua strategia se noti che non riesci a trovare le informazioni giuste.",
                  "Cerchi di adattare il tuo modo di ricerca alla situazione. Questo di solito significa che puoi trovare rapidamente le informazioni giuste. Considera se alcuni tipi di informazioni sono più difficili da trovare. Potresti migliorarlo, ad esempio, utilizzando filtri o termini di ricerca più specifici?",
                  "Di solito adatti il tuo metodo di ricerca alle informazioni che stai cercando. Questo di solito ti consente di trovare rapidamente le informazioni giuste. Usa questa conoscenza per semplificare ancora di più il tuo processo di ricerca.",
                  "Durante la ricerca, modifica il metodo di ricerca per ottenere i migliori risultati. Questo spesso ti fa trovare le informazioni che stai cercando in modo facile e veloce."
                ]
              }
            }
          },
          "intelligence": {
            "closest_group": "Il tuo punteggio corrisponde al meglio al gruppo di riferimento:",
            "description": "In questo grafico puoi vedere il tuo punteggio rispetto alla popolazione attiva olandese. Possiamo dire con una certezza dell'80% che il tuo punteggio è compreso tra le linee tratteggiate.",
            "groups": {
              "hbo_ba": "HBO/Laurea",
              "mbo": "MBO",
              "mbo1": "MBO1",
              "mbo2": "MBO2",
              "mbo3": "MBO3",
              "mbo4": "MBO4",
              "vmbo": "VMBO",
              "wo_ma": "WO/Maestro"
            },
            "header": "Lavora e pensa a livello",
            "keys": {
              "abstract": "Astratto",
              "numeriek": "Numerico",
              "totaalscore": "Punteggio totale",
              "verbaal": "Verbale"
            },
            "labels": {
              "average": "Media",
              "high": "Sopra la media",
              "low": "Sotto la media"
            },
            "subtitles": [
              "Rispetto a un gruppo di riferimento %{group}, il tuo punteggio totale è ben al di sotto della media",
              "Rispetto a un gruppo di riferimento %{group}, il tuo punteggio totale è inferiore alla media",
              "Rispetto a un gruppo di riferimento %{group}, il tuo punteggio totale è nella media",
              "Rispetto a un gruppo di riferimento %{group}, il tuo punteggio totale è superiore alla media",
              "Rispetto a un gruppo di riferimento %{group}, il tuo punteggio totale è ben al di sopra della media"
            ],
            "title": "Gruppo di riferimento %{group}"
          },
          "interests": {
            "sectoren": {
              "header": "Primi 5 interessi nei settori",
              "keys": {
                "administratie": "Amministrazione",
                "advieseninformatie": "Consigli e informazioni",
                "agrarischeondersteuning": "Sostegno agricolo",
                "beheer": "Gestione",
                "bouw": "Costruire",
                "commercieledienstverlening": "Servizio commerciale",
                "communicatie": "Comunicazione",
                "cultuur": "Cultura",
                "delfstofwinning": "Estrazione",
                "dieren": "Animali",
                "elektrotechniek": "ingegnere elettrico",
                "gezondheidszorg": "assistenza sanitaria",
                "glasaardewerkkeramiek": "Vetro, ceramica e ceramica",
                "grafischetechniek": "Tecnologia grafica",
                "handel": "Commercio",
                "horeca": "Industria di catering",
                "houtmeubileringstechniek": "Tecnologia dell'arredo in legno",
                "huishouding": "Faccende domestiche",
                "industrielereiniging": "Pulizie industriali",
                "installatietechniek": "Tecnologia di installazione",
                "magazijnopslagbezorging": "Magazzino, stoccaggio e consegna",
                "media": "Media",
                "metaal": "Metallo",
                "milieu": "Ambiente",
                "onderwijs": "Formazione scolastica",
                "ontwerpenontwikkeling": "Design e sviluppo",
                "openbaarbestuur": "Pubblica amministrazione",
                "organisatieenadvies": "Organizzazione e consulenza",
                "personeelarbeidloopbaan": "Personale, lavoro e carriera",
                "persoonlijkeverzorging": "Cura personale",
                "planningproductiebegeleiding": "Pianificazione e supervisione della produzione",
                "planten": "Impianti",
                "procestechniek": "Ingegneria dei processi",
                "rechtspraak": "Giurisdizione",
                "recreatie": "Ricreazione",
                "schoonmaak": "Pulizia",
                "sportontspanning": "Sport e tempo libero",
                "textielmode": "Tessile e moda",
                "toerisme": "Turismo",
                "veiligheid": "Sicurezza",
                "vervoer": "Trasporto",
                "visserij": "Pesca",
                "welzijn": "Benessere",
                "wetenschap": "Scienza"
              }
            },
            "taken": {
              "header": "I 5 principali interessi per le attività",
              "keys": {
                "administratievetaken": "Compiti amministrativi",
                "analyseren": "Analizzare",
                "assisteren": "Assistere",
                "conflictenoplossen": "Risoluzione dei conflitti",
                "contactonderhouden": "Mantenere il contatto",
                "creatiefzijn": "Essere creativo",
                "cursussentrainingengeven": "Erogazione di corsi e formazione",
                "elektronischeapparatierepareren": "Riparare dispositivi elettronici",
                "fysiekinspannen": "Sforzo fisico",
                "grotemachinesbedienen": "Azionare macchine di grandi dimensioni",
                "informatieverzamelen": "Ottenere informazioni",
                "inroosteren": "Pianificazione",
                "klantenhelpen": "Aiuta i clienti",
                "leidinggeven": "Condurre",
                "lesgeven": "Insegnamento",
                "lezen": "Leggere",
                "machinesrepareren": "Riparazione di macchine",
                "mensenadviseren": "Consigliare le persone",
                "mensenbegeleiden": "Per guidare le persone",
                "mensenbeinvloeden": "Influenzare le persone",
                "mensenovertuigen": "Convinci le persone",
                "metcollegasoverleggen": "Consultarsi con i colleghi",
                "metcomputerswerken": "Lavorare con i computer",
                "metelektronischeapparatenwerken": "Lavorare con dispositivi elettronici",
                "methandenwerken": "Lavorare con le mani",
                "metmachineswerken": "Lavorare con le macchine",
                "middelenvoorwerpenbeheren": "Gestire risorse e oggetti",
                "motiveren": "Motivare",
                "onderhandelen": "Negoziare",
                "organiseren": "Organizzare",
                "projectenopzetten": "Impostazione progetti",
                "rekenen": "Calcolare",
                "schrijven": "Scrivere",
                "strategischdenken": "Pensiero strategico",
                "teplannen": "Pianificare",
                "voertuigenbesturen": "Veicoli alla guida",
                "werkzaamhedenplannen": "Pianificare il lavoro"
              }
            }
          },
          "it": {
            "descriptions": {
              "gemiddeld": "Le tue competenze ICT di base sono sufficienti, ma hai ancora spazio per imparare.",
              "hoog": "Le tue competenze TIC di base sono eccellenti.",
              "laag": "Le tue competenze TIC di base potrebbero aver bisogno di un aggiornamento."
            },
            "header": "Competenze del 21° secolo",
            "title": "Competenze ICT di base: Trattare con la tecnologia"
          },
          "media": {
            "categories": [
              "Tu sei il migliore in questo",
              "Sei molto bravo in questo",
              "Sei bravo come la maggior parte degli altri",
              "Puoi ancora migliorare",
              "Hai ancora molto da imparare a riguardo",
              "Ecco come gestisci i social media"
            ],
            "header": "Competenze del 21° secolo",
            "skills": {
              "consumptie": {
                "description": "Essere critici riguardo alle informazioni che condividi e consumi",
                "label": "Consumo",
                "tips": [
                  "Non sei critico riguardo alle informazioni che condividi. Cerca di essere più attento a ciò che si può trovare su di te online e ai messaggi che diffondi.",
                  "Non sei sempre critico nei confronti delle informazioni che condividi. Cerca di essere più attento a ciò che si può trovare su di te online e ai messaggi che diffondi.",
                  "Di solito pensi prima di condividere informazioni e ti assicuri che non si possa trovare nulla di strano su di te online.",
                  "Di solito sei critico nei confronti delle informazioni che condividi e di ciò che si può trovare su di te online.",
                  "Sei critico riguardo alle informazioni che condividi e a cosa si può trovare su di te online."
                ]
              },
              "maatschappelijk": {
                "description": "L’influenza sociale dei media",
                "label": "Sociale",
                "tips": [
                  "Trovi difficile stimare l’influenza sociale dei media. Non sempre ci si rende conto di quanto i media possano influenzare o fuorviare le persone.",
                  "Hai una visione moderata dell'influenza sociale dei media. Non sempre si comprende appieno la misura in cui i media possono essere utilizzati per influenzare o addirittura fuorviare le persone.",
                  "Hai una comprensione media dell'influenza sociale dei media. Di solito vedi come le persone usano i media e possono esserne fuorviati. Non è ancora possibile riconoscere questa influenza dei media in tutte le situazioni.",
                  "Hai una visione eccellente dell'influenza sociale dei media. Di solito si vede come le persone usano i media e come le persone possono esserne fuorviate.",
                  "Hai una buona conoscenza dell'influenza sociale dei media. Vedi come le persone usano i media e come possono esserne fuorviati."
                ]
              },
              "mediawijsheid": {
                "label": "Alfabetizzazione mediatica: gestire i media",
                "tips": [
                  "I social media sono diventati indispensabili. I social media non svolgono solo un ruolo nella vita privata, ma anche sul posto di lavoro. Come gestisci i social media da dipendente? Ottieni informazioni dettagliate e strumenti per l'utilizzo dei social media come dipendente pubblico con il workshop <a href=\"https://www.ubriijk.nl/service/ suggestn-en-adviseurs-laborsrecht/masterclasses-en-trainingen/social-media -e-funzionari pubblici\" target=\"_blank\">Social media e dipendenti pubblici</a>.",
                  "I social media sono diventati indispensabili. I social media non svolgono solo un ruolo nella vita privata, ma anche sul posto di lavoro. Come gestisci i social media da dipendente? Ottieni informazioni dettagliate e strumenti per l'utilizzo dei social media come dipendente pubblico con il workshop <a href=\"https://www.ubriijk.nl/service/ suggestn-en-adviseurs-laborsrecht/masterclasses-en-trainingen/social-media -e-funzionari pubblici\" target=\"_blank\">Social media e dipendenti pubblici</a>.",
                  "Conosci i punti di forza e di debolezza dei media moderni, ma c'è ancora spazio per miglioramenti. L’alfabetizzazione mediatica non riguarda l’uso frequente dei media. L’alfabetizzazione mediatica è spesso una questione di valutare consapevolmente i pro e i contro. Concedetevi lo spazio per (ri)considerare le vostre scelte. Siete forse troppo critici e prudenti e quindi non sfruttate adeguatamente il potere dei media moderni? Oppure non sei sufficientemente consapevole dei rischi e ti rendi quindi vulnerabile?",
                  "Sfrutta il tuo atteggiamento attivo e acuto nei confronti dell’alfabetizzazione mediatica. Tieniti informato sugli ultimi sviluppi tecnologici e iscriviti alla <a href=\"https://ibestuur.nl/nieuwsbrief/\" target=\"_blank\">rivista iBestuur</a>.",
                  "Sfrutta il tuo atteggiamento attivo e acuto nei confronti dell’alfabetizzazione mediatica. Tieniti informato sugli ultimi sviluppi tecnologici e iscriviti alla <a href=\"https://ibestuur.nl/nieuwsbrief/\" target=\"_blank\">rivista iBestuur</a>."
                ]
              },
              "schuwheid": {
                "descriptions": [
                  "Utilizzi poco i social rispetto agli altri.",
                  "Utilizzi i social media meno della media.",
                  "Usi i social media tanto quanto gli altri.",
                  "Rispetto ad altri usi di più i social.",
                  "Usi molto i social media rispetto ad altri."
                ],
                "label": "Timidezza",
                "tips": [
                  "Fai poco o nessun uso dei social/digitali. Molte persone utilizzano i social media per tenersi informati o mantenere i contatti. Potrebbe essere che ti mancano informazioni o opportunità perché non sei attivo online? Oppure consideri un vantaggio il fatto che sei tu a decidere quali informazioni ricevere?",
                  "Fai relativamente poco uso dei social/media digitali. Molte persone utilizzano i social media per tenersi informati o mantenere i contatti. Potrebbe essere che ti mancano informazioni o opportunità perché non sei attivo online? Oppure consideri un vantaggio il fatto che sei tu a decidere quali informazioni ricevere?",
                  "Utilizzi regolarmente i social/digitali. Questo può aiutarti a rimanere informato e a mantenere i contatti. Ma questo tipo di media possono anche dare un’immagine distorta della realtà.",
                  "Fai un uso superiore alla media dei social/digitali. Questo probabilmente ti tiene informato su cosa sta succedendo (online) e ti aiuta a mantenere i contatti. Ma bisogna essere consapevoli che questo tipo di media possono dare un’immagine distorta della realtà.",
                  "Usi i social/digitali relativamente spesso. Ciò probabilmente significa che sei ben informato su ciò che sta succedendo (online). Aiuta anche a mantenere i tuoi contatti. Ma bisogna essere consapevoli che questo tipo di media possono dare un’immagine distorta della realtà."
                ]
              },
              "trend": {
                "description": "Avere un occhio per le tendenze",
                "label": "Tendenza",
                "tips": [
                  "Non hai un occhio attento alle tendenze. Non ti interessa ciò che è e ciò che non è popolare.",
                  "Non hai davvero occhio per le tendenze. Non ti preoccupi molto di ciò che è e non è popolare.",
                  "Cerchi di rimanere al passo con le tendenze. Noti cose che sono molto popolari o che stanno diventando obsolete. Ma non sei nemmeno una persona che segue immediatamente tutte le tendenze.",
                  "Rimani informato sulle tendenze. Di solito ti rendi conto di ciò che è popolare e lo vedi come qualcosa che sta diventando obsoleto.",
                  "Rimani ben informato sulle tendenze. Hai una visione approfondita di ciò che è popolare e noti quando le cose diventano obsolete."
                ]
              }
            }
          },
          "no_data": "(nessun risultato ancora disponibile)",
          "no_relevant_data": "(non sono ancora disponibili risultati per i quali esistono widget)",
          "personality": {
            "descriptions": {
              "competitie": "Voler essere migliore degli altri.",
              "conformisme": "Essere in grado di adattarsi alle norme e ai valori applicabili. Rispettare l'autorità.",
              "contactbehoefte": "Ho bisogno di compagnia.",
              "dominantie": "Prendi il controllo. Giocare un ruolo determinante nella collaborazione.",
              "energie": "Avere l'energia per fare molto.",
              "frustratietolerantie": "Sii tollerante verso gli altri.",
              "hartelijkheid": "Essere gentili, allegri e allegri con gli altri.",
              "incasseringsvermogen": "Essere in grado di affrontare adeguatamente critiche e battute d'arresto.",
              "nauwkeurigheid": "Lavorare con attenzione, con un occhio per i dettagli.",
              "onafhankelijkheid": "Voler fare le cose a modo tuo.",
              "ordelijkheid": "Crea tu stesso la struttura.",
              "originaliteit": "Trovare nuove soluzioni. Essere creativo.",
              "positivisme": "Avere una visione positiva della vita.",
              "regelmaat": "Hanno bisogno di ordine e di regole.",
              "sociaal_ontspannen": "Sentirsi rilassati nel contatto con gli altri.",
              "status": "Volere ottenere il meglio possibile. Volere arrivare più in alto.",
              "vernieuwing": "Ama il cambiamento e acquisire nuove esperienze.",
              "vertrouwen": "Credere nelle buone intenzioni degli altri.",
              "volharding": "Mostra entusiasmo. Prendere sul serio i compiti e gli accordi e mantenerli.",
              "weloverwogen": "Pensa attentamente prima di agire.",
              "zelfonthulling": "Volere condividere i propri sentimenti con gli altri.",
              "zelfontwikkeling": "Spinto a sfruttare appieno le proprie qualità.",
              "zelfvertoon": "Mi piace essere al centro dell'attenzione.",
              "zelfvertrouwen": "Sii fiducioso.",
              "zorgzaamheid": "Sentirsi coinvolti con gli altri. Vorrei aiutare gli altri."
            },
            "header": "Personalità",
            "keys": {
              "competitie": "concorrenza",
              "conformisme": "Conformismo",
              "contactbehoefte": "Necessità di contatto",
              "dominantie": "Dominanza",
              "energie": "Energia",
              "frustratietolerantie": "Tolleranza alla frustrazione",
              "hartelijkheid": "Cordialità",
              "incasseringsvermogen": "Capacità di raccogliere",
              "nauwkeurigheid": "Precisione",
              "onafhankelijkheid": "Indipendenza",
              "ordelijkheid": "Ordine",
              "originaliteit": "Originalità",
              "positivisme": "Positivismo",
              "regelmaat": "Regolarità",
              "sociaal_ontspannen": "Socialmente rilassante",
              "status": "Stato",
              "vernieuwing": "Bisogno di varietà",
              "vertrouwen": "Fidarsi",
              "volharding": "Perseveranza",
              "weloverwogen": "Considerato",
              "zelfonthulling": "Autodivulgazione",
              "zelfontwikkeling": "Autosviluppo",
              "zelfvertoon": "Auto-visualizzazione",
              "zelfvertrouwen": "Fiducia in se stessi",
              "zorgzaamheid": "Attenzione"
            },
            "titles": [
              "Le tue qualità più caratteristiche",
              "I tuoi tratti caratteristici",
              "Le tue qualità meno caratteristiche"
            ]
          },
          "thinking": {
            "average": {
              "benedengemiddeld": "Sotto la media",
              "bovengemiddeld": "Sopra la media",
              "gemiddeld": "Media",
              "hoog": "Alto",
              "laag": "Basso"
            },
            "header": "Competenze del 21° secolo",
            "skills": {
              "communiceren": {
                "descriptions": {
                  "benedengemiddeld": "Nel 21° secolo la comunicazione avviene sempre più per iscritto, ad esempio tramite e-mail o app di messaggistica. Per una buona comunicazione, è importante che il tuo messaggio abbia una buona struttura e possa essere seguito da altri. Devi anche tenere conto del destinatario del tuo messaggio. Rispetto ad altri dipendenti pubblici, per te comunicare è naturalmente meno facile.",
                  "bovengemiddeld": "Nel 21° secolo la comunicazione avviene sempre più per iscritto, ad esempio tramite e-mail o app di messaggistica. Per una buona comunicazione, è importante che il tuo messaggio abbia una buona struttura e possa essere seguito da altri. Devi anche tenere conto del destinatario del tuo messaggio. Rispetto ad altri dipendenti pubblici, comunicare in modo naturale ti risulta relativamente facile.",
                  "gemiddeld": "Nel 21° secolo la comunicazione avviene sempre più per iscritto, ad esempio tramite e-mail o app di messaggistica. Per una buona comunicazione, è importante che il tuo messaggio abbia una buona struttura e possa essere seguito da altri. Devi anche tenere conto del destinatario del tuo messaggio. Possiedi un'attitudine comunicativa nella media rispetto al gruppo di riferimento dei dipendenti pubblici.",
                  "hoog": "Nel 21° secolo la comunicazione avviene sempre più per iscritto, ad esempio tramite e-mail o app di messaggistica. Per una buona comunicazione, è importante che il tuo messaggio abbia una buona struttura e possa essere seguito da altri. Devi anche tenere conto del destinatario del tuo messaggio. Hai naturalmente una forte attitudine alla comunicazione.",
                  "laag": "Nel 21° secolo la comunicazione avviene sempre più per iscritto, ad esempio tramite e-mail o app di messaggistica. Per una buona comunicazione, è importante che il tuo messaggio abbia una buona struttura e possa essere seguito da altri. Devi anche tenere conto del destinatario del tuo messaggio. Rispetto ad altri dipendenti pubblici hai meno attitudine alla comunicazione."
                },
                "label": "Comunicare"
              },
              "creatief_denken": {
                "descriptions": {
                  "benedengemiddeld": "Rispetto ad altri dipendenti pubblici, è naturalmente meno probabile che tu abbia nuove idee e applicazioni. Le persone creative hanno idee originali. Spesso hanno meno bisogno di struttura e sicurezza. Il pensiero creativo ti aiuta a vedere nuove possibilità e a svilupparti ulteriormente.",
                  "bovengemiddeld": "Rispetto ad altri dipendenti pubblici, naturalmente trovi più facile trovare nuove idee e applicazioni. Le persone creative hanno idee originali. Spesso hanno meno bisogno di struttura e sicurezza. Il pensiero creativo ti aiuta a vedere nuove possibilità e a svilupparti ulteriormente. Cerca consapevolmente situazioni in cui puoi applicare e mantenere questa abilità.",
                  "gemiddeld": "Rispetto ad altri dipendenti pubblici, hai un'attitudine media a proporre nuove idee e applicazioni. Le persone creative hanno idee originali. Spesso hanno meno bisogno di struttura e sicurezza. Il pensiero creativo ti aiuta a vedere nuove possibilità e a svilupparti ulteriormente. C'è spazio per svilupparsi in questo.",
                  "hoog": "Rispetto ad altri dipendenti pubblici, per te è naturalmente facile trovare nuove idee e applicazioni. Le persone creative hanno idee originali. Spesso hanno meno bisogno di struttura e sicurezza. Il pensiero creativo ti aiuta a vedere nuove possibilità e a svilupparti ulteriormente. Cerca consapevolmente situazioni in cui puoi applicare e mantenere questa abilità.",
                  "laag": "Rispetto ad altri dipendenti pubblici, naturalmente hai più difficoltà a trovare nuove idee e applicazioni. Le persone creative hanno idee originali. Spesso hanno meno bisogno di struttura e sicurezza. Il pensiero creativo ti aiuta a vedere nuove possibilità e a svilupparti ulteriormente."
                },
                "label": "Pensiero creativo"
              },
              "kritisch_denken": {
                "descriptions": {
                  "benedengemiddeld": "I pensatori critici sono indipendenti e amano andare a fondo delle cose. Il pensiero critico ti mantiene acuto e ti assicura di non fare affidamento semplicemente su ciò che la tecnologia ci dice. La tua naturale capacità di affrontare un problema in modo critico e approfondito è inferiore alla media rispetto ad altri dipendenti pubblici.",
                  "bovengemiddeld": "I pensatori critici sono indipendenti e amano andare a fondo delle cose. La tua naturale capacità di affrontare un problema in modo critico e approfondito è superiore alla media. Il pensiero critico ti mantiene acuto e ti assicura di non fare affidamento semplicemente su ciò che la tecnologia ci dice.",
                  "gemiddeld": "I pensatori critici sono indipendenti e amano andare a fondo delle cose. Il pensiero critico ti mantiene acuto e ti assicura di non fare affidamento semplicemente su ciò che la tecnologia ci dice. La tua naturale capacità di affrontare un problema in modo critico e approfondito è paragonabile a quella del dipendente pubblico medio.",
                  "hoog": "I pensatori critici sono indipendenti e amano andare a fondo delle cose. La tua naturale capacità di affrontare un problema in modo critico e profondo è forte. Il pensiero critico ti mantiene acuto e ti assicura di non fare affidamento semplicemente su ciò che la tecnologia ci dice.",
                  "laag": "I pensatori critici sono indipendenti e amano andare a fondo delle cose. Il pensiero critico ti mantiene acuto e ti assicura di non fare affidamento semplicemente su ciò che la tecnologia ci dice. La tua naturale capacità di affrontare un problema in modo critico e approfondito è meno forte di quella di altri dipendenti pubblici."
                },
                "label": "Pensiero critico"
              },
              "probleem_oplossen": {
                "descriptions": {
                  "benedengemiddeld": "Le persone con forti capacità di problem solving possono sopportare colpi e non si arrendono facilmente. Perseverano e coinvolgono facilmente gli altri con le loro idee. Di conseguenza, sanno anche come risolvere problemi complessi o conflitti a lungo termine. Rispetto ad altri dipendenti pubblici, la tua attitudine alla risoluzione dei problemi è inferiore alla media.",
                  "bovengemiddeld": "Le persone con forti capacità di problem solving possono sopportare colpi e non si arrendono facilmente. Perseverano e coinvolgono facilmente gli altri con le loro idee. Di conseguenza, sanno anche come risolvere problemi complessi o conflitti a lungo termine. La tua attitudine alla risoluzione dei problemi è superiore alla media.",
                  "gemiddeld": "Le persone con forti capacità di problem solving possono sopportare colpi e non si arrendono facilmente. Perseverano e coinvolgono facilmente gli altri con le loro idee. Di conseguenza, sanno anche come risolvere problemi complessi o conflitti a lungo termine. La tua attitudine alla risoluzione dei problemi è paragonabile a quella del dipendente pubblico medio.",
                  "hoog": "Le persone con forti capacità di problem solving possono sopportare colpi e non si arrendono facilmente. Perseverano e coinvolgono facilmente gli altri con le loro idee. Di conseguenza, sanno anche come risolvere con successo problemi complessi o conflitti a lungo termine. Rispetto al dipendente pubblico medio, hai una forte attitudine alla risoluzione dei problemi.",
                  "laag": "Le persone con forti capacità di problem solving possono sopportare colpi e non si arrendono facilmente. Perseverano e coinvolgono facilmente gli altri con le loro idee. Di conseguenza, sanno anche come risolvere problemi complessi o conflitti a lungo termine. Rispetto al dipendente pubblico medio, hai una minore attitudine alla risoluzione dei problemi."
                },
                "label": "Risoluzione dei problemi"
              },
              "samenwerken": {
                "descriptions": {
                  "benedengemiddeld": "Naturalmente hai una minore attitudine alla collaborazione rispetto al dipendente pubblico medio. Per lavorare bene insieme, devi avere il coraggio di fidarti degli altri e comunicare bene tra loro. Devi anche assumerti la responsabilità.",
                  "bovengemiddeld": "Hai naturalmente un talento per la collaborazione superiore alla media. L’abilità di collaborare consiste nell’assumersi la responsabilità, osare fidarsi degli altri e comunicare tra loro.",
                  "gemiddeld": "Hai naturalmente un'attitudine media alla collaborazione rispetto ad altri dipendenti pubblici. Per lavorare bene insieme, devi avere il coraggio di fidarti degli altri e comunicare bene tra loro. Devi anche assumerti la responsabilità.",
                  "hoog": "Hai naturalmente una forte attitudine alla collaborazione. L’abilità di collaborare consiste nell’assumersi la responsabilità, osare fidarsi degli altri e comunicare tra loro.",
                  "laag": "Naturalmente hai meno attitudine alla collaborazione rispetto al dipendente pubblico medio. Per lavorare bene insieme, devi avere il coraggio di fidarti degli altri e comunicare bene tra loro. Devi anche assumerti la responsabilità."
                },
                "label": "Collaborare"
              },
              "sociale_culturele_vaardigheden": {
                "descriptions": {
                  "benedengemiddeld": "Rispetto al gruppo di riferimento, naturalmente, hai più difficoltà a tenere conto del background di qualcuno. Per interagire bene con persone provenienti da contesti diversi, è importante essere aperti a punti di vista e idee diverse. Hai anche bisogno di buone antenne sociali e devi essere aperto al miglioramento personale.",
                  "bovengemiddeld": "Rispetto al gruppo di riferimento si tiene naturalmente facilmente conto del background di qualcuno. Per interagire bene con persone provenienti da contesti diversi, è importante essere aperti a punti di vista e idee diverse. Hai anche bisogno di buone antenne sociali e sei aperto al miglioramento personale.",
                  "gemiddeld": "Rispetto al gruppo di riferimento, nel prendere in considerazione il background di qualcuno, ti comporti più o meno come la media. Per interagire bene con persone provenienti da contesti diversi, è importante essere aperti a punti di vista e idee diverse. Hai anche bisogno di buone antenne sociali e devi essere aperto al miglioramento personale.",
                  "hoog": "Rispetto al gruppo di riferimento si tiene naturalmente facilmente conto del background di qualcuno. Per interagire bene con persone provenienti da contesti diversi, è importante essere aperti a punti di vista e idee diverse. Hai anche bisogno di buone antenne sociali e sei aperto al miglioramento personale.",
                  "laag": "Rispetto al gruppo di riferimento, naturalmente, hai difficoltà a tenere conto del background di qualcuno. Per interagire bene con persone provenienti da contesti diversi, è importante essere aperti a punti di vista e idee diverse. Hai anche bisogno di buone antenne sociali e devi essere aperto al miglioramento personale."
                },
                "label": "Abilità sociali e culturali"
              },
              "zelfregulering": {
                "descriptions": {
                  "benedengemiddeld": "Nel lavoro, oggi e sicuramente in futuro, sta diventando sempre più importante che tu, come dipendente, fai di più che limitarti a eseguire gli ordini. Ecco perché è importante che tu ti assuma la responsabilità di pianificare il tuo lavoro e di completarlo come previsto. Rispetto agli altri dipendenti pubblici avete naturalmente qualche difficoltà in più a regolare il vostro lavoro.",
                  "bovengemiddeld": "Nel lavoro, oggi e sicuramente in futuro, sta diventando sempre più importante che tu, come dipendente, fai di più che limitarti a eseguire gli ordini. Ecco perché è importante che tu ti assuma la responsabilità di pianificare il tuo lavoro e di completarlo come previsto. Rispetto agli altri dipendenti pubblici hai un'attitudine superiore alla media nel regolamentare il tuo lavoro.",
                  "gemiddeld": "Nel lavoro, oggi e sicuramente in futuro, sta diventando sempre più importante che tu, come dipendente, fai di più che limitarti a eseguire gli ordini. Ecco perché è importante che tu ti assuma la responsabilità di pianificare il tuo lavoro e di completarlo come previsto. Hai un'attitudine media per questo rispetto ad altri dipendenti pubblici.",
                  "hoog": "Nel lavoro, oggi e sicuramente in futuro, sta diventando sempre più importante che tu, come dipendente, fai di più che limitarti a eseguire gli ordini. Ecco perché è importante che tu ti assuma la responsabilità di pianificare il tuo lavoro e di completarlo come previsto. Hai un talento naturale nel regolare il tuo lavoro.",
                  "laag": "Nel lavoro, oggi e sicuramente in futuro, sta diventando sempre più importante che tu, come dipendente, fai di più che limitarti a eseguire gli ordini. Ecco perché è importante che tu ti assuma la responsabilità di pianificare il tuo lavoro e di completarlo come previsto. Regolamentare il tuo lavoro naturalmente ti conviene meno."
                },
                "label": "Autoregolamentazione"
              }
            },
            "titles": {
              "thinking_styles": "Stili di pensiero: come posso collaborare con la tecnologia?",
              "work_competences": "Competenze lavorative: come svolgo il mio lavoro?"
            }
          },
          "title": "Panoramica dei risultati personali",
          "values": {
            "descriptions": {
              "demotivators": {
                "aandacht": "Preferisci restare in disparte. Se dovessi profilarti, rimarresti deluso.",
                "analyseren": "Consideri l'analisi dei problemi demotivante, qualcosa che preferiresti non fare.",
                "autonomie": "Preferisci non cercare autonomia e indipendenza. Preferisci coordinarti con gli altri e lasciare che siano gli altri a prendere decisioni per te.",
                "beinvloeden": "Non ti piace dover influenzare gli altri.",
                "carriere": "Potresti provare avversione per lo status e il bisogno di raggiungere risultati. Se ti viene chiesto di concentrarti su questo, probabilmente avrà un effetto demotivante.",
                "concrete_resultaten": "Dover raggiungere risultati concreti ti demotivarà anziché stimolarti. Potresti essere orientato al processo piuttosto che ai risultati.",
                "creatief_denken": "Quando la tua creatività sarà chiamata in causa, probabilmente ti demotivarà invece di stimolarti.",
                "financiele_beloning": "Gli obiettivi finanziari possono demotivarti. Non sei interessato al lato finanziario delle cose, quindi se sei diretto verso quello, può demotivarti.",
                "fysiek_actief_zijn": "Preferisci non essere fisicamente attivo al lavoro. Se richiede uno sforzo fisico eccessivo, potresti sperimentarlo negativamente.",
                "hectiek": "Preferisci un ambiente tranquillo, dove sai cosa ti sta succedendo e dove puoi svolgere il tuo lavoro in silenzio.",
                "hulp_verlenen": "Aiutare direttamente gli altri ti demotiva.",
                "kwaliteit": "Probabilmente sei più interessato al quadro generale che ai dettagli. I requisiti di alta qualità potrebbero essere un fastidio per te.",
                "ondernemen": "Sei felice di lasciare iniziative ad altri. Questo non dovrebbe essere previsto da te; questo ti demotivarebbe davvero.",
                "ontwikkelen": "Non devi sviluppare te stesso. Anche a te piace farlo di meno. Forse pensi di poter farcela con le tue qualità attuali.",
                "samenwerking": "Preferisci lavorare da solo piuttosto che con altri. Dover lavorare insieme e soprattutto coordinarsi con gli altri ti frustra piuttosto che divertirti.",
                "taakuitdaging": "Le sfide nuove o difficili in realtà ti demotivano. Preferisci sentirti sicuro di poter gestire i tuoi compiti.",
                "waardering_en_erkenning": "Non sei sensibile all'apprezzamento e al riconoscimento, anzi ti delude.",
                "zekerheid_en_stabiliteit": "La sicurezza e la stabilità in realtà ti demotivano. Lo preferisci quando non sai esattamente dove ti trovi e quando sei sorpreso.",
                "zinvolle_bijdrage": "Non sei un idealista. In effetti, probabilmente ti demotiva quando devi lavorare per un'organizzazione orientata al sociale."
              },
              "neutral_motivators": {
                "aandacht": "Non sempre cerchi opportunità per emergere. Al contrario, ti sta bene non avere tutti gli occhi puntati su di te.",
                "analyseren": "Non vivi l'analisi delle informazioni o dei problemi come motivante, ma non lo odi nemmeno.",
                "autonomie": "L'indipendenza e l'autonomia nel tuo lavoro non ti motivano fortemente. Non hai problemi a essere responsabile nei confronti degli altri.",
                "beinvloeden": "Trovi meno importante avere influenza. Puoi anche farti guidare da altri.",
                "carriere": "Lo status e il perseguimento di una carriera ti stimolano solo in misura limitata. Non sei necessariamente concentrato sul raggiungimento di una posizione onorevole.",
                "concrete_resultaten": "Non devi necessariamente lavorare per risultati chiari o concreti. Puoi anche essere più orientato al processo.",
                "creatief_denken": "Non devi essere creativo o pensare in modo creativo nel tuo lavoro.",
                "financiele_beloning": "Potrebbe piacerti la ricompensa finanziaria, ma non la trovi molto importante.",
                "fysiek_actief_zijn": "Sei neutrale nei confronti del movimento e delle attività fisiche nel tuo lavoro.",
                "hectiek": "Il dinamismo non ti motiva particolarmente. Vuoi avere il tempo per completare le cose e concentrarti sul tuo lavoro.",
                "hulp_verlenen": "Fornire aiuto agli altri non ti motiva necessariamente, non lo cerchi attivamente.",
                "kwaliteit": "Sei meno motivato da standard di qualità elevati. Non sei sfidato da questo.",
                "ondernemen": "Non è sempre necessario avere lo spazio per prendere iniziative da soli.",
                "ontwikkelen": "Al momento non ti motiva molto a svilupparti ulteriormente. Probabilmente vorrai sfruttare le conoscenze e le competenze che possiedi attualmente.",
                "samenwerking": "Sei neutrale nei confronti della cooperazione con gli altri. Potresti lavorare in squadra, ma potresti anche funzionare bene da solo. Non devi necessariamente appartenere a qualche posto o lavorare in una squadra.",
                "taakuitdaging": "La tua sfida non sta tanto nella difficoltà del tuo lavoro. Inoltre, non devi confrontarti ogni volta con nuove sfide.",
                "waardering_en_erkenning": "L'apprezzamento e il riconoscimento sono piacevoli, ma ti motivano in misura limitata. Probabilmente sei più concentrato sulla tua opinione riguardo alle tue azioni che sul feedback degli altri.",
                "zekerheid_en_stabiliteit": "La sicurezza e la stabilità non sono così importanti per te. Non devi sapere esattamente dove ti trovi.",
                "zinvolle_bijdrage": "Dare un contributo significativo alla società non contribuisce specificamente alla tua motivazione. Non pensi che sia importante significare qualcosa per gli altri con il tuo lavoro."
              },
              "primary_motivators": {
                "aandacht": "Ti piace essere in primo piano e sei motivato quando gli occhi sono puntati su di te. Puoi divertirti quando gli altri ti ammirano. Ti piacerà una posizione che offra un certo grado di status. Un rischio può essere quello di chiedere erroneamente attenzione o di non offrire abbastanza spazio agli altri.",
                "analyseren": "Trovi motivante fare analisi, pensare a questioni e problemi. Ti piacciono i rompicapo e il pensiero. Un rischio di ciò potrebbe essere quello di continuare ad analizzare troppo a lungo e rimanere bloccati in un problema per troppo tempo.",
                "autonomie": "Trovi motivante poter lavorare in modo autonomo e decidere da solo come svolgere il tuo lavoro. La libertà e l’indipendenza sono per te molto stimolanti. Quando gli altri ti trattengono o ti indirizzano, ciò può causare irritazione. Il rischio potrebbe essere che gli altri ti trovino troppo indipendente e forse testardo. Per te è importante un buon inserimento in un’organizzazione. Lavorare in modo completamente indipendente potrebbe essere un'opzione per te.",
                "beinvloeden": "Trovi motivante influenzare gli altri. Ti piacerebbe vedere la tua opinione riflessa nelle decisioni e nel modo in cui funzionano le cose. Convincere e motivare gli altri ti attrae. Un rischio potrebbe essere quello di essere meno sensibili alle opinioni degli altri o di spingersi troppo oltre.",
                "carriere": "Trovi importante ottenere qualcosa nel tuo lavoro, fare carriera. Lo status e il prestigio giocano un ruolo in questo. Puoi anche essere competitivo e misurarti con gli altri. Un rischio può essere che tu sia troppo concentrato sull’esterno, assicurati di non superarti e di essere anche intrinsecamente motivato.",
                "concrete_resultaten": "Ti piace lavorare per un risultato chiaro e concreto. Troverai un risultato finale visibile in cui il tuo contributo diretto può essere riconosciuto stimolante. Il rischio può essere quello di allentarsi quando i risultati concreti non vengono raggiunti regolarmente.",
                "creatief_denken": "Vuoi usare la tua creatività, generare idee e pensare fuori dagli schemi. Cerchi spesso nuove soluzioni. Il rischio potrebbe essere quello di avere troppe idee per realizzarle tutte o che non tutte le idee siano ugualmente pratiche.",
                "financiele_beloning": "Sei motivato ottenendo una buona ricompensa finanziaria. Gli incentivi finanziari, come obiettivi e remunerazione variabile, ti danno ulteriore incoraggiamento. Ciò vale anche per il raggiungimento di risultati finanziari per i vostri clienti o datori di lavoro. Un rischio potrebbe essere che, a causa degli incentivi finanziari, intraprendi qualcosa che non avresti dovuto fare, ad esempio perché ti dà meno soddisfazione sotto altri aspetti.",
                "fysiek_actief_zijn": "Ti piace essere fisicamente attivo. Ritieni che l'esercizio fisico, lo sport o le attività fisiche siano stimolanti. Ciò potrebbe significare che ti piace fare lavoro fisico. Ma poiché ciò non è sempre possibile, avrete bisogno almeno di uno sbocco nel privato, ad esempio nello sport. Il rischio potrebbe essere che tu sia troppo irrequieto per un vero lavoro d’ufficio.",
                "hectiek": "Ti piacciono le dinamiche e il trambusto intorno a te: un ambiente frenetico ti stimola. Anche a te piace essere occupato. Quando al lavoro c'è troppo silenzio, puoi rilassarti e possibilmente concentrarti meno.",
                "hulp_verlenen": "Quando puoi essere al servizio degli altri o aiutare in attività concrete, ti dà molta soddisfazione. Sarai anche fortemente concentrato sul benessere degli altri. Un rischio di questo valore è che vai troppo oltre e proteggi troppo poco i tuoi confini.",
                "kwaliteit": "Lo senti come stimolante per fornire alta qualità. Il bene non è sempre abbastanza buono per te, vuoi soddisfare standard elevati. Il rischio potrebbe essere che non sempre ci sia tempo per farlo o che altri non soddisfino i vostri requisiti di qualità nella stessa misura.",
                "ondernemen": "Ti piacerebbe essere intraprendente. Ti piace prendere iniziative e cogliere le opportunità. Ciò è possibile come imprenditore indipendente, ma anche all'interno di un'organizzazione che offre spazio all'imprenditorialità. Uno svantaggio di questa motivazione può essere che sei troppo ottimista e corri troppi rischi.",
                "ontwikkelen": "Attribuisci grande importanza allo sviluppo di te stesso e all'acquisizione di nuove conoscenze e abilità. Vedi stare fermo come andare indietro. Spesso ti divertirai anche ad aiutare gli altri a svilupparsi ulteriormente. Il rischio potrebbe essere quello di annoiarsi (troppo) velocemente e voler imparare qualcosa di nuovo.",
                "samenwerking": "Per te è molto importante la collaborazione con gli altri. Ti piace essere in contatto diretto con gli altri e lavorare insieme su un compito o obiettivo comune. Un rischio potrebbe essere quello di rallentare quando lavori in modo indipendente o da solo.",
                "taakuitdaging": "Sei stimolato da compiti impegnativi che richiedono molto da te. Vuoi ottenere il massimo da te stesso. Il rischio potrebbe essere che tu sia pronto per qualcosa di nuovo abbastanza rapidamente. La sfida può anche essere troppo grande, il che significa che corri il rischio che le cose non vadano bene.",
                "waardering_en_erkenning": "L’apprezzamento e il riconoscimento sono molto motivanti per te. Quando gli altri ti fanno i complimenti e ti riconoscono, ti stimola. Anche tu ti impegni in questo senso. Il rischio potrebbe essere quello di diventare troppo dipendenti dall’apprezzamento. Inoltre continuano a trarre soddisfazione dal lavoro stesso.",
                "zekerheid_en_stabiliteit": "Vuoi sicurezza e stabilità. Ti senti bene quando sai dove ti trovi. Nel tuo lavoro ti piace avere una gamma di compiti chiaramente definita, in modo da sapere cosa ci si aspetta da te. Un rischio potrebbe essere quello di essere meno capaci di affrontare i cambiamenti e le incertezze.",
                "zinvolle_bijdrage": "Ti piace dare un contributo significativo alla società o agli enti di beneficenza. Pensi che sia importante significare qualcosa per gli altri con il tuo lavoro. Ciò può riflettersi nella tua posizione o nell’organizzazione per cui lavori. Un rischio potrebbe essere quello di essere troppo idealisti e quindi meno realistici o di mettere troppo da parte i propri interessi."
              },
              "secondary_motivators": {
                "aandacht": "Ti piace essere in primo piano e sei motivato quando gli occhi sono puntati su di te. Puoi divertirti quando gli altri ti ammirano.",
                "analyseren": "Trovi motivante fare analisi, pensare a questioni e problemi. Ti piacciono i rompicapo e il pensiero.",
                "autonomie": "Trovi motivante poter lavorare in modo autonomo. Trovi motivante poter decidere da solo come svolgere il tuo lavoro. La libertà e l’indipendenza sono per te molto stimolanti.",
                "beinvloeden": "Trovi motivante influenzare gli altri. Ti piacerebbe vedere la tua opinione riflessa nelle decisioni e nel modo in cui funzionano le cose. Convincere e motivare gli altri ti attrae.",
                "carriere": "Trovi importante ottenere qualcosa nel tuo lavoro, fare carriera. Lo status e il prestigio giocano un ruolo in questo. Puoi essere in qualche modo competitivo e misurarti con gli altri.",
                "concrete_resultaten": "Ti piace lavorare per un risultato chiaro e concreto. Troverai un risultato finale visibile in cui il tuo contributo diretto può essere riconosciuto stimolante.",
                "creatief_denken": "Vuoi usare la tua creatività, generare idee e pensare fuori dagli schemi. Cerchi spesso nuove soluzioni.",
                "financiele_beloning": "Sei motivato ottenendo una buona ricompensa finanziaria. Gli incentivi finanziari, come obiettivi e remunerazione variabile, ti danno ulteriore incoraggiamento. Ciò vale anche per il raggiungimento di risultati finanziari per i vostri clienti o datori di lavoro.",
                "fysiek_actief_zijn": "Ti piace essere fisicamente attivo. Ritieni che l'esercizio fisico, lo sport o le attività fisiche siano stimolanti. Ciò potrebbe significare che ti piace fare lavoro fisico. Ma poiché ciò non è sempre possibile, avrete bisogno almeno di uno sbocco nel privato, ad esempio nello sport.",
                "hectiek": "Ti piacciono le dinamiche e il trambusto intorno a te: un ambiente frenetico ti stimola. Anche a te piace essere occupato.",
                "hulp_verlenen": "Quando puoi essere al servizio degli altri o aiutare in attività concrete, ti dà molta soddisfazione. Sarai anche fortemente concentrato sul benessere degli altri.",
                "kwaliteit": "Lo senti come stimolante per fornire alta qualità. Il bene non è sempre abbastanza buono per te, vuoi soddisfare standard elevati.",
                "ondernemen": "Ti piacerebbe essere intraprendente. Ti piace prendere iniziative e cogliere le opportunità. Ciò è possibile anche all’interno di un’organizzazione che offre spazio all’imprenditorialità.",
                "ontwikkelen": "Attribuisci grande importanza allo sviluppo di te stesso e all'acquisizione di nuove conoscenze e abilità. Vedi stare fermo come andare indietro. Spesso ti divertirai anche ad aiutare gli altri a svilupparsi ulteriormente.",
                "samenwerking": "Per te è molto importante la collaborazione con gli altri. Ti piace essere in contatto diretto con gli altri e lavorare insieme su un compito o obiettivo comune.",
                "taakuitdaging": "Sei stimolato da compiti impegnativi che richiedono molto da te. Vuoi ottenere il massimo da te stesso.",
                "waardering_en_erkenning": "L’apprezzamento e il riconoscimento sono molto motivanti per te. Quando gli altri ti fanno i complimenti e ti riconoscono, ti stimola.",
                "zekerheid_en_stabiliteit": "Vuoi sicurezza e stabilità. Ti senti bene quando sai dove ti trovi. Nel tuo lavoro ti piace avere una gamma di compiti chiaramente definita, in modo da sapere cosa ci si aspetta da te.",
                "zinvolle_bijdrage": "Ti piace dare un contributo significativo alla società o agli enti di beneficenza. Pensi che sia importante significare qualcosa per gli altri con il tuo lavoro. Ciò può riflettersi nella tua posizione o nell’organizzazione per cui lavori."
              }
            },
            "header": "Valori di carriera",
            "keys": {
              "aandacht": "Profilazione",
              "analyseren": "Analizzare",
              "autonomie": "Autonomia",
              "beinvloeden": "Influenzare",
              "carriere": "Carriera",
              "concrete_resultaten": "Risultati concreti",
              "creatief_denken": "Pensiero creativo",
              "financiele_beloning": "Ricompensa finanziaria",
              "fysiek_actief_zijn": "Sii fisicamente attivo",
              "hectiek": "Dinamica",
              "hulp_verlenen": "Fornire assistenza",
              "kwaliteit": "Qualità",
              "ondernemen": "Intraprendere",
              "ontwikkelen": "Sviluppare",
              "samenwerking": "Cooperazione",
              "taakuitdaging": "Sfida del compito",
              "waardering_en_erkenning": "Apprezzamento e riconoscimento",
              "zekerheid_en_stabiliteit": "Sicurezza e stabilità",
              "zinvolle_bijdrage": "Contributo significativo"
            },
            "titles": {
              "demotivators": "Quali sono i tuoi demotivatori?",
              "neutral_motivators": "Quali sono i tuoi motivatori neutrali?",
              "primary_motivators": "Quali sono le tue motivazioni principali?",
              "secondary_motivators": "Quali sono le tue motivazioni secondarie?"
            }
          }
        },
        "ixly_test": {
          "calculating_result": "Il risultato viene calcolato...",
          "component": "Elemento",
          "document_option_for": "Opzioni documento per",
          "download_modal_header": "Scarica il rapporto",
          "download_report": "Scarica il rapporto",
          "generating_report": "Il rapporto è in fase di generazione...",
          "make_visible_confirm": "Questa azione è irreversibile. Sei sicuro di voler continuare?",
          "no_test_selected_desc": "Seleziona una valutazione tramite le impostazioni di questo elemento",
          "no_test_selected_title": "Nessuna valutazione selezionata",
          "norm_group_for": "Gruppi di norme disponibili",
          "payment_required": "La tua organizzazione non dispone di crediti sufficienti per scaricare questo report.",
          "program_subtasks": "%{program} è costituito da %{subtest_names}",
          "report_name": "Nome del rapporto",
          "report_settings": "Impostazioni del rapporto",
          "report_visible_for_advisor": "Risultato visibile all'allenatore",
          "report_visible_for_both": "Risultato visibile per entrambi",
          "report_visible_for_candidate": "Risultato visibile al candidato",
          "resume_subtest": "Riprendi %{test}",
          "resume_test": "Riprendere la valutazione",
          "save_date": "Data salvata",
          "select_document_option_placeholder": "Seleziona l'opzione del documento desiderato per questo rapporto:",
          "select_norm_group_placeholder": "Seleziona il gruppo standard desiderato per questo rapporto:",
          "settings_modal_header": "Impostazioni del rapporto",
          "skip_verification": "Saltare",
          "start_review": "Inizia la revisione",
          "start_subtest": "Avvia %{test}",
          "start_test": "Inizia la valutazione",
          "start_verification": "Inizio",
          "start_verification_subtest": "Inizia a verificare %{test}",
          "start_verification_test": "Avvia la verifica",
          "task": "Compito",
          "test_completed": "arrotondato",
          "test_inprogress": "Iniziato",
          "test_pending": "Non ancora iniziato",
          "test_reviewrequired": "Revisione richiesta",
          "test_verificationrequired": "Verifica richiesta",
          "verification_completed": "Il test di verifica è stato completato.",
          "verification_restart": "Riavvio",
          "verification_skipped": "La verifica è stata saltata. Se necessario, puoi riavviarlo utilizzando il pulsante.",
          "verification_skipped_processing": "La verifica è stata saltata. Se necessario, è possibile riavviarlo utilizzando il pulsante dopo aver generato il report.",
          "view_results": "Vedi i risultati",
          "waiting_verification_start": "Il candidato non ha ancora iniziato la prova di verifica; la verifica può ancora essere saltata."
        },
        "job_market_scan": {
          "compare": "Confronta le professioni",
          "description_created": "Opportunità del mercato del lavoro calcola le opportunità attuali e future sul mercato del lavoro olandese. Dopo aver inserito alcune informazioni sull'istruzione e sul lavoro, viene assegnato un punteggio una tantum compreso tra 1 (molto scarso) e 99 (molto buono). Al termine è possibile scaricare un report contenente i risultati dettagliati.",
          "description_finished": "Le opportunità nel mercato del lavoro sono state completate! Tutti i risultati sono stati calcolati. Fare clic sul pulsante \"Scarica\" per (ri)scaricare il rapporto in formato PDF.",
          "description_in_progress": "Le opportunità nel mercato del lavoro non sono state ancora completamente completate. Ciò è necessario per generare il report. Fare clic sul pulsante qui sotto per continuare.",
          "download": "Scaricamento",
          "download_alert": "Le opportunità nel mercato del lavoro sono state completate più di 6 mesi fa; non è più possibile generare nuovi report.",
          "download_description_1": "Scarica tutti i report salvati di seguito",
          "download_description_2": "generare un nuovo report con offerte di lavoro, formazione e corsi aggiornati (disponibile per 6 mesi)",
          "download_link_text": "Oppure clicca qui",
          "download_title": "Scarica i rapporti",
          "resume": "Riprendere",
          "start": "Inizio",
          "terms_and_conditions": "Accetto Termini e Condizioni",
          "title": "Opportunità del mercato del lavoro",
          "title_finished": "Risultati",
          "working": "Occupato..."
        },
        "job_market_scan_en": {
          "title": "Opportunità nel mercato del lavoro in inglese",
          "title_finished": "Risultati"
        },
        "logbook": {
          "back": "Indietro",
          "content_placeholder": "ecco il contenuto",
          "delete": "Rimuovere",
          "help_text": "Puoi conservare pagine di note nel tuo diario di bordo.",
          "logbook": "Tronco d'albero",
          "new_page": "Nuova pagina",
          "title_placeholder": "titolo della pagina di registro"
        },
        "quiz": {
          "add_answer": "Aggiungi risposta",
          "answer_correct": "Corretto",
          "answer_incorrect": "Errato",
          "answer_placeholder": "Inserisci una risposta qui",
          "answer_placeholder_viewer": "Il candidato inserisce qui una risposta",
          "answer_text_placeholder": "Inserisci qui la risposta %{index}",
          "back_current": "Precedente",
          "back_started": "Indietro",
          "correct_placeholder": "Inserisci qui la risposta corretta",
          "delete": "Rimuovere",
          "next_add": "Aggiungi domanda",
          "next_check": "Controllo",
          "next_current": "Prossimo",
          "question_of": "DOMANDA %{index} DA %{length}",
          "question_text_placeholder": "Inserisci la domanda qui",
          "question_type_choice": "Scelta multipla",
          "question_type_open": "Aprire",
          "selections_number": "Numero di selezioni",
          "selections_to": "Fino a",
          "start_text_checking": "Visualizza le risposte",
          "start_text_resume": "Riprendere",
          "start_text_set_up": "Impostato",
          "start_text_start": "Inizio",
          "start_text_viewer": "Visualizza le domande"
        },
        "report_visibility": {
          "advisor": {
            "both": "Il rapporto è ora a disposizione del candidato. Il diritto del candidato di scaricare/visualizzare il report può essere revocato disattivando lo slider a destra.",
            "self": "Il rapporto non è ancora a disposizione del candidato. Attivando lo slider a destra si dà al candidato il diritto di scaricare e visualizzare il report."
          },
          "candidate": {
            "both": "Il report è ora a disposizione dell'allenatore. Il diritto dell'allenatore di scaricare/visualizzare il report può essere revocato disattivando lo slider a destra.",
            "self": "Il resoconto non è ancora a disposizione dell'allenatore. Attivando lo slider a destra si dà al coach il diritto di scaricare e visualizzare il report."
          }
        },
        "search_job": {
          "delete": "Cancellare",
          "favorite": "Preferito",
          "favorites": "Preferiti",
          "location": "Dove?",
          "no_favorites_chosen": "(nessun preferito selezionato)",
          "no_jobs_found": "(nessun posto vacante trovato)",
          "query": "Che cosa?",
          "search": "Cercare",
          "view": "Guarda a"
        },
        "templates": {
          "add": "aggiungere",
          "add_template": "Aggiungi modello",
          "added": "Selezionabile",
          "builder": {
            "add_column": "Aggiungi colonna",
            "add_element": "Aggiungi sezione",
            "add_row": "Aggiungi riga",
            "block": "Bloccare",
            "circle": "Cerchio",
            "date_formats": {
              "D": "numero del giorno (1 o 2 cifre)",
              "DD": "numero del giorno (2 cifre)",
              "M": "numero del mese (1 o 2 cifre)",
              "MM": "numero del mese (2 cifre)",
              "MMM": "nome del mese (abbreviato)",
              "MMMM": "nome del mese",
              "YY": "anno (2 cifre)",
              "YYYY": "anno (4 cifre)",
              "ddd": "nome del giorno (abbreviato)",
              "dddd": "nomegiorno"
            },
            "description": "Descrizione",
            "destroy_confirm_column": "Sei sicuro di voler eliminare questa colonna?",
            "destroy_confirm_element": "Sei sicuro di voler eliminare questa sezione?",
            "destroy_confirm_row": "Sei sicuro di voler eliminare questa riga?",
            "global": "Per tutte le organizzazioni?",
            "header": "Tazzina",
            "header_font_size": "Dimensione del carattere dell'intestazione",
            "height": "Altezza",
            "hide_borders": "Nascondi contorni",
            "hide_preview": "Nascondi anteprima",
            "margin_from_top": "Spazio sopra la sezione",
            "optional": "Opzionale",
            "padding": "Spazio attorno al blocco",
            "shape": "Modulo",
            "show_borders": "Mostra contorni",
            "show_header": "Mostra titolo",
            "show_icon": "Mostra icona",
            "show_preview": "Mostra esempio",
            "square": "Piazza",
            "title": "Titolo",
            "type": "Tipo di sezione",
            "variable_add_option": "Aggiungi scelta",
            "variable_date": "Data",
            "variable_date_format": "Formato",
            "variable_dropdown": "Selezione",
            "variable_help_text": "Testo guida",
            "variable_options": "Scelte",
            "variable_text": "Testo",
            "variable_type": "Tipo variabile",
            "width": "Larghezza"
          },
          "change_template": "Cambia modello",
          "default": "Selezionato",
          "delete": "Rimuovere",
          "deselect": "Deseleziona",
          "destroy_confirm": "Sei sicuro di voler eliminare questo modello?",
          "documentation": "(clicca qui per la documentazione del generatore di CV e lettere)",
          "edit": "Modificare",
          "edit_replacements": "Modifica i testi di aiuto",
          "filler": {
            "add_item": "Aggiungi articolo",
            "add_optional": "Aggiungi questa sezione al documento",
            "change": "Modifica",
            "crop_and_save": "Ritaglia e salva",
            "download_docx": "Scarica DOCX",
            "download_pdf": "Scarica il pdf",
            "remove": "Cancellare",
            "remove_optional": "Rimuovere questa sezione dal documento",
            "upload_image": "Scegli un'immagine"
          },
          "image": "Immagine",
          "list": "Elenco",
          "no_template_selected": "Nessun modello selezionato",
          "not_ready_yet_text": "Non ancora fatto",
          "open": "Aprire",
          "remove": "Porta via",
          "remove_template": "Elimina modello",
          "select": "Selezionare",
          "select_template": "Seleziona un modello",
          "switch_template": "Selezione del modello",
          "text": "Testo"
        },
        "text": {
          "placeholder": "Fai clic qui per inserire il testo (quindi selezionalo per ulteriori opzioni di stile)"
        },
        "vacancy": {
          "action": "Azione",
          "action_deadline": "Azione di scadenza",
          "action_placeholder": "Candidarsi per un lavoro, prepararsi per il primo colloquio...",
          "back": "Indietro",
          "before": "per",
          "delete": "Rimuovere",
          "help_text": "Annota i posti vacanti interessanti e determina le azioni di follow-up con l'aiuto del responsabile dei posti vacanti.",
          "new_vacancy": "Nuovo posto vacante",
          "next_action": "L'azione successiva può essere concordata di seguito con una scadenza associata:",
          "notes": "Appunti",
          "notes_placeholder": "altri commenti",
          "status": "Stato",
          "statuses": {
            "created": "Creato",
            "first_interview": "Prima intervista",
            "hired": "Accettato",
            "interested": "Interessato",
            "letter_sent": "Lettera scritta",
            "other": "Altrimenti",
            "rejected": "Rifiutato",
            "second_interview": "Seconda intervista"
          },
          "title": "Titolo",
          "title_placeholder": "corriere della posta presso PostNL",
          "vacancy_link": "Collegamento al lavoro",
          "vacancy_link_placeholder": "aggiungi un link al posto vacante",
          "vacancy_manager": "Responsabile dei posti vacanti",
          "view_vacancy": "visualizzare il posto vacante"
        },
        "visibility": {
          "advisor": {
            "both": "Il documento è ora a disposizione del candidato. Il diritto del candidato a scaricare/visualizzare il documento può essere revocato disattivando lo slider a destra.",
            "self": "Il documento non è ancora a disposizione del candidato. Attivando lo slider di destra si dà al candidato il diritto di scaricare e visionare il documento."
          },
          "candidate": {
            "both": "Il documento è ora a disposizione dell'allenatore. Il diritto del coach di scaricare/visualizzare il documento può essere revocato disattivando lo slider a destra.",
            "self": "Il documento non è ancora a disposizione dell'allenatore. Attivando lo slider di destra si dà facoltà al coach di scaricare e visionare il documento."
          }
        },
        "youtube_video": {
          "url_placeholder": "Inserisci l'URL completo di un video di YouTube nelle impostazioni di questo elemento"
        }
      },
      "cost_centers": {
        "number_of_users": "%{number} attivo"
      },
      "editable_empty": "(NO)",
      "program_templates": {
        "number_of_pages": {
          "one": "1 passo",
          "other": "%{count} passi"
        },
        "status": {
          "deleted": "Eliminato",
          "editable": "Modificabile",
          "hidden": "Eliminato",
          "published": "Pubblicato",
          "template": "Modello"
        }
      },
      "programs": {
        "number_of_pages": {
          "one": "1 passo",
          "other": "%{count} passi"
        },
        "status": {
          "deleted": "Eliminato",
          "editable": "Modificabile",
          "hidden": "Eliminato",
          "published": "Pubblicato",
          "template": "Modello"
        }
      },
      "rooms": {
        "browser_unsupported": "Questo browser non è supportato. Utilizza un browser moderno come Chrome o Firefox.",
        "default_local_display_name": "IO",
        "default_remote_display_name": "In modo anonimo"
      }
    },
    "label": {
      "search": "Cercare"
    },
    "labels": {
      "index": {
        "title": "Etichette"
      }
    },
    "language": {
      "name": "Italiano"
    },
    "languages": {
      "all": "Tutte le lingue",
      "de": "Tedesco",
      "en": "Inglese",
      "fr": "Francese",
      "it": "Italiano",
      "nl": "Olandese"
    },
    "layouts": {
      "assessment": {
        "header": {
          "logout": "Disconnettersi",
          "notifications": "Notifiche"
        },
        "language_menu": {
          "select_language": "Seleziona la lingua"
        },
        "resource_header": {
          "notifications": "Notifiche",
          "profile": "Profilo",
          "switch_account": "Passa a un altro account"
        },
        "users": {
          "dropdown": {
            "notifications": "Notifiche"
          }
        }
      },
      "mailer": {
        "personal_info": "Modificare le informazioni personali",
        "privacy_policy": "politica sulla riservatezza",
        "rights": "Ixly 2023 Vleutensevaart 100 3532 d.C. Utrecht",
        "terms": "Termini e Condizioni",
        "unsubscribe": "disconnessione"
      }
    },
    "legacy_invoices": {
      "show": {
        "title": "Fattura del kit di strumenti di prova"
      }
    },
    "login_mailer": {
      "confirmation_button": {
        "text": "creare un account"
      },
      "welcome_mail": {
        "subject": "Benvenuto"
      }
    },
    "media_item_share": {
      "empty_shared_with_list": "Non c'è nessuno con cui condividere un elemento multimediale",
      "only_shared_with": {
        "one": "%{count} responsabile dell'organizzazione",
        "other": "%{count} gestori dell'organizzazione"
      },
      "send": "Condividere",
      "share_with": "Condividi con",
      "shared_with": {
        "one": "e %{count} responsabile dell'organizzazione",
        "other": "e %{count} gestori dell'organizzazione"
      }
    },
    "media_item_shares": {
      "deleted": "La condivisione degli elementi multimediali è stata eliminata correttamente.",
      "drawer_content": {
        "download": "Scaricamento"
      },
      "failed_create": "Impossibile condividere l'elemento multimediale. Seleziona un utente con cui condividere l'elemento multimediale.",
      "index": {
        "created_at": "Data",
        "filename": "Nome",
        "relates_to": "Relativo a"
      },
      "success_create": "L'elemento multimediale è stato condiviso correttamente."
    },
    "media_items": {
      "add": "Aggiungi un file",
      "allowed_formats": "Formati di file consentiti:",
      "cannot_delete": "Rimozione non riuscita. Non è possibile eliminare un elemento multimediale in uso.",
      "created": "Fatto",
      "deleted": "Elemento multimediale eliminato correttamente.",
      "destroy": "Cancellare",
      "download": "Scaricamento",
      "failed_upload": "La dimensione del file è troppo grande (il massimo è 50 MB)",
      "files": "I miei file",
      "index": {
        "created_at": "Data",
        "filename": "Nome",
        "relates_to": "Relativo a",
        "shared_with": "Condiviso con"
      },
      "relates_to": "Relativo a",
      "shared_by": "Diviso per",
      "shared_with": "Condiviso con",
      "shared_with_me": "Condiviso con me",
      "success_upload": "L'elemento multimediale è stato creato correttamente.",
      "title": "Documenti e supporti",
      "upload_title": "Scegli il file"
    },
    "notice": {
      "bulk": {
        "candidates_enrolled": "I candidati sono stati informati del processo appena assegnato tramite e-mail",
        "send_welcome": "Sono state inviate e-mail di benvenuto"
      },
      "password_has_been_updated": "La password è stata modificata"
    },
    "notification": {
      "details": {
        "assessment_finished_notification": "%{candidate} completato %{assessment}.",
        "candidate_assessment": {
          "finished": "%{candidate} completato %{assessment}.",
          "invited": "Ti è stata assegnata la valutazione \"%{assessment}\"."
        },
        "comment_notification": "%{sender} ti ha inviato un messaggio: \"%{message}\"",
        "credit_limit_reached_notification": "Hai ricevuto questo messaggio perché i crediti prepagati disponibili per la tua organizzazione sono scesi al di sotto di %{credit_limit}. Ti consigliamo di acquistare più crediti in modo da poter continuare a generare report.",
        "document": {
          "approved_notification": "%{sender} ha approvato il documento %{filename}.",
          "rejected_notification": "%{sender} ha rifiutato il documento %{filename}. Puoi caricare un nuovo documento e farlo approvare nuovamente.",
          "submitted_notification": "%{sender} ti chiede di approvare il documento %{filename}.",
          "uploaded_notification": "%{sender} ha caricato il documento %{filename}."
        },
        "enroller_notification": "%{candidate} si è registrato in '%{title}'.",
        "enrollment": {
          "coach_action_notification": "Il processo \"%{title}\" di %{candidate} prevede valutazioni per le quali è necessario selezionare un report. Dopo aver selezionato i report desiderati, il candidato può essere invitato ad avviare il processo.",
          "created_notification": "Il processo '%{title}' ti è stato assegnato."
        },
        "enrollment_page": {
          "component_action_notification": "Il passaggio %{title} nel processo %{process} contiene un elemento che richiede un'azione.",
          "done_notification": "Il passaggio %{title} nel processo %{process} è stato completato.",
          "rejected_notification": "Il passaggio %{title} nel processo %{process} è stato rifiutato. È possibile aggiornare le parti del passaggio e inviarle nuovamente.",
          "submitted_notification": "Il passaggio %{title} nel processo %{process} è stato inviato. Puoi controllare il passaggio e approvarlo o rifiutarlo."
        },
        "media_item_notification": "Il file %{filename} è stato aggiunto alla Libreria Documenti e Media.",
        "media_item_share_notification": "%{name} ha condiviso %{filename} con te nella Libreria documenti e contenuti multimediali.",
        "post_notification": "L'articolo del blog %{title} è stato condiviso con te.",
        "postmark": {
          "inactive_recipient": "Qualcosa è andato storto durante l'invio di un'e-mail a %{candidate}. Si prega di controllare l'indirizzo e-mail",
          "invalid_address": "L'indirizzo email %{email} di %{candidate} non è valido. Correggi l'indirizzo email in modo che %{candidate} possa ricevere email."
        },
        "review_required": "La valutazione %{assessment_title} di %{candidate_name} necessita di una revisione.",
        "suppressed_email": "Abbiamo provato a inviare un'e-mail a %{name}, ma non ha funzionato a causa del motivo %{reason}. Assicurati che l'indirizzo email utilizzato sia corretto (%{email}).\n%{name} non ha ricevuto un'e-mail.\n",
        "unsuppressed_email": "Possiamo inviare nuovamente messaggi tramite %{email} a %{name}.",
        "verification_required": "La valutazione %{assessment_title} di %{candidate_name} necessita di verifica.\nScegli se è possibile avviare la verifica o se saltarla per completare la valutazione.\n",
        "verification_started": "La valutazione %{assessment_title} prevede un test di verifica che può essere avviato"
      },
      "type": {
        "AppointmentAcceptedNotification": "Appuntamento accettato",
        "AppointmentCancelledNotification": "Appuntamento annullato",
        "AppointmentDeclinedNotification": "Appuntamento rifiutato",
        "AppointmentSubmittedNotification": "Appuntamento suggerito",
        "assessment_finished_notification": "Valutazione completata",
        "candidate_assessment": {
          "finished": "Valutazione completata",
          "invited": "Valutazione appena assegnata"
        },
        "comment_notification": "Nuovo messaggio",
        "credit_limit_reached_notification": "Pochi crediti rimasti",
        "document": {
          "approved_notification": "Documento approvato",
          "rejected_notification": "Documento rifiutato",
          "submitted_notification": "Documento da approvare",
          "uploaded_notification": "File caricato"
        },
        "enroller_notification": "Nuova applicazione",
        "enrollment": {
          "coach_action_notification": "Selezione del rapporto obbligatoria",
          "created_notification": "Processo appena concesso"
        },
        "enrollment_page": {
          "component_action_notification": "Azione richiesta",
          "done_notification": "Passaggio completato",
          "rejected_notification": "Passaggio rifiutato",
          "submitted_notification": "Passaggio inviato"
        },
        "media_item_notification": "Un nuovo file è stato aggiunto alla Libreria documenti e media",
        "media_item_share_notification": "Un file è stato condiviso con te",
        "post_notification": "Nuovo articolo sul blog",
        "postmark_notification": "Impossibile consegnare l'e-mail",
        "review_required": "Revisione richiesta per %{assessment_title}",
        "suppressed_email": "Non siamo riusciti a contattare %{member_type} via email",
        "unsuppressed_email": "L'%{member_type} è nuovamente raggiungibile via e-mail",
        "verification_required": "Autenticazione richiesta per %{assessment_title}",
        "verification_started": "La valutazione %{assessment_title} prevede un test di verifica che può essere avviato"
      }
    },
    "notifications_overview_mailer": {
      "notification_mail": {
        "subject": "Hai ricevuto nuove notifiche"
      }
    },
    "number": {
      "currency": {
        "format": {
          "delimiter": ".",
          "format": "%n %u",
          "precision": 2,
          "separator": ",",
          "significant": false,
          "strip_insignificant_zeros": false,
          "unit": "€"
        }
      },
      "format": {
        "delimiter": ".",
        "precision": 2,
        "separator": ",",
        "significant": false,
        "strip_insignificant_zeros": false
      },
      "human": {
        "decimal_units": {
          "format": "%n %u",
          "units": {
            "billion": "Miliardi",
            "million": "Milioni",
            "quadrillion": "Biliardi",
            "thousand": "Mila",
            "trillion": "Bilioni",
            "unit": ""
          }
        },
        "format": {
          "delimiter": "",
          "precision": 3,
          "significant": true,
          "strip_insignificant_zeros": true
        },
        "storage_units": {
          "format": "%n %u",
          "units": {
            "byte": {
              "one": "Byte",
              "other": "Byte"
            },
            "eb": "EB",
            "gb": "GB",
            "kb": "KB",
            "mb": "MB",
            "pb": "PB",
            "tb": "TB"
          }
        }
      },
      "percentage": {
        "format": {
          "delimiter": "",
          "format": "%n%"
        }
      },
      "precision": {
        "format": {
          "delimiter": ""
        }
      }
    },
    "organization": "Organizzazione",
    "organization_products": {
      "create": {
        "created": "I moduli sono stati aggiunti all'organizzazione"
      },
      "destroy": {
        "already_started": "Questo modulo è già iniziato."
      },
      "index": {
        "from_subscription": "Fuori abbonamento",
        "new_button": "Aggiungi moduli",
        "table_header": [
          "Nome",
          "Data d'inizio",
          "Data di fine",
          "Azioni"
        ],
        "title": "Moduli"
      },
      "new": {
        "title": "Aggiungi moduli a %{organization}"
      },
      "row": {
        "product_delete": "Sei sicuro di voler rimuovere questo modulo dall'organizzazione?"
      }
    },
    "organization_status": {
      "active": "Attivo",
      "created": "Creato",
      "deleted": "Eliminato",
      "inactive": "Inattivo"
    },
    "organizations": {
      "billing": {
        "details": {
          "cost_centers": {
            "in_use": "Questo centro di costo ha ancora utenti attivi.",
            "new_button": "Nuovo centro di costo",
            "table_header": [
              "Nome",
              "Descrizione",
              "Utenti",
              "Candidati",
              "Azioni"
            ],
            "title": "Centri di costo"
          },
          "organization": "Organizzazione",
          "title": "Fatti"
        },
        "help_text": "Qui troverai una panoramica del tuo abbonamento, utilizzo, fatture e dati",
        "info": "Scegli un metodo di pagamento e il pacchetto di crediti che desideri acquistare.",
        "invoices": {
          "legacy_invoices": "Fatture del kit di test",
          "no_orders": "(nessun ordine è stato ancora effettuato)",
          "previous_orders": "Ordini precedenti",
          "title": "Fatture"
        },
        "payment": "Metodo di pagamento",
        "payment_title": "Acquista pacchetto di crediti",
        "subscription": {
          "credits": {
            "invoice": "L'aumento del credito è stato attuato! Verrà inviata una fattura. Ora hai %{count} crediti",
            "open": "L'aumento del credito è stato attuato! L'importo verrà addebitato automaticamente. Ora hai %{count} crediti",
            "paid": "L'aumento del credito è stato attuato! Ora hai %{count} crediti"
          },
          "mandate": {
            "activate_credit_purchase": "Attiva la tua organizzazione con l'acquisto di un pacchetto di crediti in modo che i tuoi candidati possano iniziare!",
            "change_html": "<a href='%{link}' data-method='post'>Clicca qui per autorizzare un nuovo addebito diretto</a> .",
            "credit_card": "la carta di credito con il numero XXXX XXXX XXXX %{number}.",
            "direct_debit": "il conto IBAN con il numero %{number}.",
            "not_valid_html": "I tuoi utenti potranno accedere solo previo pagamento di autorizzazione. Ciò garantisce che Ixly BV sia autorizzata ad addebitare il conto utilizzato a tale scopo. Si applicano <a class='alert-link' href='%{link}' target='_blank'>i nostri termini e condizioni generali</a> .",
            "open": "Il pagamento dell'autorizzazione è attualmente ancora in fase di elaborazione; ricaricare la pagina per un aggiornamento",
            "paid": "Il pagamento dell'autorizzazione è andato a buon fine! D'ora in poi i tuoi costi verranno addebitati su %{number}",
            "valid": "I costi verranno automaticamente addebitati"
          },
          "not_active": "La tua organizzazione non ha un abbonamento attivo",
          "order": {
            "canceled": "Il pagamento è stato annullato"
          },
          "payment": {
            "automatic_method": {
              "explain": "Autorizza acquistando un pacchetto di crediti iniziale per transazioni di credito fluide e senza sforzo, ora e in futuro",
              "title": "Pagamento automatico"
            },
            "direct_method": {
              "info_text1": "Sperimenta i pagamenti diretti senza costi aggiuntivi con iDEAL",
              "info_text2": "Sicuro, veloce e facile.",
              "title": "Pagamento immediato"
            },
            "info_text1": "Niente più preoccupazioni per i pagamenti!",
            "info_text2": "Con l'addebito diretto puoi organizzarlo in modo semplice e veloce.",
            "manual_method": {
              "confirmation": "Sei sicuro? Ciò significa che da giugno pagherai 9,99€ a fattura.",
              "explain": "Dal 1° luglio addebiteremo 9,99 € di spese amministrative per ogni fattura",
              "info_text1": "Preferiresti pagare dopo?",
              "info_text2": "Questo è possibile con il pagamento manuale.",
              "please_note": "notare che",
              "title": "Pagamento manuale"
            },
            "mollie": {
              "conditional1": "I pagamenti manuali appartengono al passato",
              "conditional2": "Hai il pieno controllo sulle tue finanze",
              "conditional3": "Risparmia sui costi aggiuntivi e sui problemi con le fatture"
            },
            "payment_type": {
              "automatic": "Pagamento automatico",
              "direct": "Pagamento diretto con ideale",
              "invoice": "Pagamento manuale"
            }
          },
          "subscriptions": {
            "active_advisors_title": "Consiglieri attivi",
            "active_candidates": "(da %{max_candidates})",
            "active_candidates_title": "Candidati attivi",
            "active_premium_candidates_title": "Candidati premium attivi",
            "active_users": "(da %{max_users})",
            "active_users_title": "Allenatori attivi",
            "already_started": "Questo abbonamento è già iniziato.",
            "credits": "Crediti:",
            "downgrade": "Downgrade",
            "downgrades": "Declassamenti",
            "incidental_subscription_credits_left": "Se all'inizio del nuovo abbonamento rimangono dei crediti, verranno utilizzati per primi",
            "new_button": "Nuovo abbonamento",
            "prices": "Prezzi",
            "purchase": "Comprare",
            "settings": "Specifiche",
            "table_header": [
              "Nome",
              "Data d'inizio",
              "Data di fine",
              "Azioni"
            ],
            "title": "Abbonamenti",
            "total_advisors": "(da %{max_users})",
            "total_advisors_title": "Tutti i consiglieri",
            "total_candidates": "(da %{max_candidates})",
            "total_candidates_title": "Tutti i candidati",
            "total_coaches_title": "Tutti gli allenatori",
            "total_premium_candidates_title": "Tutti i candidati premium",
            "upgrade": "Aggiornamento",
            "upgrades": "Aggiornamenti",
            "user_counts": "Numeri utente"
          },
          "title": "Sottoscrizione"
        },
        "title": "Fatturazione",
        "usage": {
          "active_candidates": {
            "prepaid": "prepagato",
            "table_header": [
              "Candidate",
              "Attivato il",
              "Prezzo"
            ],
            "title": "Candidati attivi"
          },
          "active_coaches": {
            "table_header": [
              "Allenatore",
              "Attivato il",
              "Prezzo"
            ],
            "title": "Allenatori attivi"
          },
          "aycr_periods": {
            "table_header": [
              "Candidate",
              "Valido per",
              "Prezzo"
            ],
            "title": "Candidati con valutazioni avviate"
          },
          "generated_reports": {
            "table_header": [
              "Valutazione",
              "Rapporto",
              "Candidate",
              "Generato il",
              "Crediti",
              "Prezzo"
            ],
            "title": "Rapporti generati"
          },
          "period": "Periodo",
          "premium_components": {
            "table_header": [
              "Elemento",
              "Candidate",
              "Iniziato il",
              "Prezzo"
            ],
            "title": "Elementi premium iniziati"
          },
          "title": "Utilizzo",
          "total_with_vat": "Costi totali inclusi %{vat}% IVA: %{price}"
        }
      },
      "edit": {
        "available_languages_confirm": "Stai per disattivare le lingue utilizzate dai tuoi utenti. La loro lingua viene modificata nella lingua predefinita della tua organizzazione. Sei sicuro?",
        "explanation": "La lingua selezionata funge da impostazione predefinita per i nuovi utenti all'interno dell'organizzazione.",
        "logo": "Logo",
        "title": "Impostazioni dell'organizzazione",
        "upload_logo": "Clicca qui per caricare un logo"
      },
      "index": {
        "table_header": [
          "Nome",
          "Sottoscrizione",
          "Utenti",
          "Processi",
          "Stato",
          "Azioni"
        ]
      },
      "payment_method_mollie_direct": {
        "success": "Il pagamento diretto è stato creato con successo"
      },
      "row": {
        "impersonate": "Accedi come",
        "organization_delete": "Sei sicuro di voler eliminare questa organizzazione?"
      },
      "texts": {
        "welcome_mail": {
          "example_mail_body": "Ciao %{user_name}. Benvenuti nella piattaforma di valutazione.\n\nÈ stato creato un nuovo account per te su Assessment Platform.\n\nImposta immediatamente la tua password per accedere al tuo ambiente personale.\n\n<a href='%{password_set_link}'>Crea un account</a>.\n\nÈ importante scegliere una password complessa e unica per proteggere il tuo account. Pertanto, assicurati che la tua nuova password contenga almeno dieci caratteri e sia una combinazione di lettere, numeri e simboli.\n\nCordiali saluti,\n%{organization_name}"
        }
      },
      "update": {
        "organization_updated": "L'organizzazione è stata aggiornata"
      }
    },
    "otp_secret": {
      "cancel_otp": "Annulla il processo di autenticazione",
      "input_the_code": "Inserisci il codice fornito dall'app di autenticazione",
      "scan_the_code": "Scansiona il codice con un'app di autenticazione come %{app_1} o %{app_2}."
    },
    "page_status": {
      "created": {
        "seen": "Non letto",
        "set": "Aprire",
        "submit": "Aprire"
      },
      "done": {
        "seen": "Visualizzato",
        "set": "arrotondato",
        "submit": "Approvato"
      },
      "hidden": "Nascosto",
      "optional": "Opzionale",
      "rejected": {
        "submit": "Ancora"
      },
      "submitted": {
        "submit": "Consegnato"
      }
    },
    "pagy": {
      "combo_nav_js": "<label>Pagina %{page_input} di %{pages}</label>",
      "info": {
        "multiple_pages": "Visualizzazione %{item_name} <b>%{from}-%{to}</b> di <b>%{count}</b> in totale",
        "no_items": "Nessun %{item_name} trovato",
        "single_page": "Visualizzazione di <b>%{count}</b> %{item_name}"
      },
      "item_name": {
        "one": "elemento",
        "other": "elementi"
      },
      "items_selector_js": "<label>Mostra %{items_input} %{item_name} per pagina</label>",
      "nav": {
        "gap": "&hellip;",
        "next": "Seguente&nbsp;&rsaquo;",
        "prev": "&lsaquo;&nbsp;Precedente"
      }
    },
    "pallet": {
      "image": {
        "label": "Scegli un'immagine"
      },
      "ixly_test": {
        "description": "Spiegazione della valutazione",
        "label": "Nome della valutazione"
      },
      "label": "Etichetta",
      "quiz": {
        "title": "Quiz"
      },
      "templates": {
        "title": "Curriculum e creazione di lettere"
      }
    },
    "password": {
      "forgot_password": {
        "help_text": "Inserisci il tuo indirizzo email per richiedere una nuova password",
        "request": "Richiedere",
        "title": "Hai dimenticato la password"
      },
      "reset_password_form": {
        "button": "Modificare",
        "help_text": "Inserisci e conferma la tua nuova password",
        "invalid_link": "Il tuo link di recupero è utilizzato, è scaduto o non è valido: richiedilo nuovamente",
        "title": "cambiare la password"
      },
      "send_reset_password_link": {
        "help_text": "Entro pochi minuti riceverai la mail con la quale potrai impostare una nuova password",
        "title": "E-mail di recupero password inviata",
        "unknown_email": "L'indirizzo email che hai fornito non ci è noto"
      },
      "update_password_with_reset_token": {
        "invalid_link": "Il tuo link di recupero è utilizzato, è scaduto o non è valido: richiedilo nuovamente",
        "password_has_been_reset": "La tua password è stata modificata e hai effettuato l'accesso con successo",
        "password_is_of_insufficient_length": "La nuova password deve contenere almeno 10 caratteri",
        "passwords_dont_match": "Password e conferma non corrispondono"
      }
    },
    "placeholder": {
      "search": "Cercare"
    },
    "posts": {
      "available_locales": "Lingue disponibili",
      "destroy": {
        "destroyed": "L'articolo è stato rimosso"
      },
      "destroy_confirm": "Sei sicuro di voler eliminare questo articolo?",
      "edit": "Modifica articolo",
      "edit_rules": "Modifica le regole di condivisione",
      "empty": "(nessun articolo è stato ancora pubblicato)",
      "help_text": "Gestisci questi articoli e le relative regole di condivisione",
      "hide_more": "Nascondere",
      "index": {
        "title": "Articoli del blog"
      },
      "new": "Nuovo articolo",
      "open": "Aprire",
      "post_rules": {
        "add": "Aggiungi una linea di condivisione",
        "private": "(nessuno ancora)",
        "program_labels": {
          "multiple": ", a cui sono assegnati <strong>processi con etichette %{list}</strong>",
          "single": ", a cui sono assegnati <strong>processi con etichetta %{list}</strong>"
        },
        "programs": {
          "multiple": ", a cui <strong>processi",
          "single": ", a cui il <strong>processo"
        },
        "public": "(visibile a tutti)",
        "roles": {
          "account_managers": "gestori degli account",
          "accountmanagers": "Gestori del conto",
          "advisors": "consiglieri",
          "all_internal_users": "allenatori",
          "all_users": "utenti",
          "coachs": "allenatori di processo",
          "contact_persons": "contatta persone",
          "ixly_admins": "Amministratori di Ixly",
          "ixly_employees": "Dipendenti dell'Ixly",
          "organization_managers": "responsabili organizzativi",
          "selected": "Tutti <strong>%{list}</strong>",
          "selected_advisor": "con <strong>allenatore %{author_name}</strong>",
          "selected_global": "da <strong>tutte le organizzazioni</strong>",
          "users": "Partecipanti"
        },
        "user_labels": {
          "multiple": "con <strong>etichette %{list}</strong>",
          "single": "con <strong>etichetta %{list}</strong>"
        },
        "users": {
          "multiple": "Utenti <strong>%{names}</strong>",
          "single": "Utente <strong>%{names}</strong>"
        },
        "users_type": {
          "certain": "Condividi con utenti specifici",
          "filters": "Condividere con ruoli, etichette e processi",
          "public": "Pubblico"
        }
      },
      "private": "In privato",
      "published_by": "Pubblicato da",
      "shared": "Condiviso",
      "shared_with": "Questo articolo è stato condiviso con:",
      "show_more": "Mostra di più",
      "sidebar": {
        "advisor": "Per i tuoi candidati",
        "global": "Per tutte le organizzazioni",
        "organization": "Per %{organization_name}",
        "shared": "Condiviso con te"
      },
      "status": "Stato",
      "title": "Titolo",
      "update": {
        "updated": "Le regole di condivisione sono state aggiornate"
      }
    },
    "prices": {
      "active": "Attivo",
      "amc": "Prezzo dell'AMC",
      "bov": "Prezzo BOV",
      "cannot_modify_expired": "Non è possibile modificare i listini prezzi attivi e scaduti",
      "created_by": "fatto da",
      "does_not_exist": "Listino prezzi non trovato",
      "edit": "Modifica listino prezzi",
      "effective_from": "In vigore dal",
      "expired": "Scaduto",
      "external_user": "Costi mensili per candidato",
      "fit": "Prezzo ADATTA",
      "internal_user": "Costi mensili per pullman",
      "job_market_scan": "Prezzo delle opportunità nel mercato del lavoro",
      "job_market_scan_en": "Prezzo delle opportunità nel mercato del lavoro in inglese",
      "job_suggestions": "Suggerimenti per lavori sui prezzi",
      "jobfeed": "Prezzo Jobfeed",
      "new": "Nuovo listino prezzi",
      "pending": "In attesa di",
      "price_created": "Il listino prezzi è stato creato",
      "price_updated": "Il listino prezzi è stato aggiornato",
      "something_went_wrong": "Qualcosa è andato storto",
      "status": "Stato",
      "updated_at": "Aggiornato il"
    },
    "product_program_templates": {
      "create": {
        "created": "I modelli di processo sono stati aggiunti al modulo",
        "product_program_templates_blank": "Nessun modello di processo selezionato"
      },
      "destroy": {
        "product_program_template_destroyed": "Il modello di processo è stato rimosso dal modulo"
      },
      "new": {
        "title": "Aggiungi modelli di processo a %{product}"
      },
      "row": {
        "product_program_template_delete": "Sei sicuro di voler rimuovere questi modelli di processo dal modulo?"
      }
    },
    "product_status": {
      "active": "Attivo",
      "deactivated": "Disattivato"
    },
    "product_tasks": {
      "create": {
        "created": "Le valutazioni sono state aggiunte al modulo",
        "product_tasks_blank": "Non è stata selezionata alcuna valutazione"
      },
      "deactivate": {
        "deactivated": "La connessione alla valutazione è stata disattivata"
      },
      "destroy": {
        "task_destroyed": "La valutazione è stata rimossa dal modulo"
      },
      "new": {
        "title": "Aggiungi valutazioni a %{product}"
      },
      "reactivate": {
        "reactivated": "Il collegamento alla valutazione è stato riattivato"
      },
      "row": {
        "task_delete": "Sei sicuro di voler rimuovere questa valutazione dal modulo?"
      }
    },
    "products": {
      "create": {
        "product_created": "Il modulo è stato creato",
        "title": "Nuovo modulo"
      },
      "deactivate": {
        "deactivated": "Il modulo è disattivato"
      },
      "destroy": {
        "deleted": "Il modulo è stato rimosso"
      },
      "edit": {
        "title": "Modifica modulo"
      },
      "index": {
        "new_button": "Nuovo modulo",
        "table_header": [
          "Nome",
          "Stato",
          "Lo stato è cambiato",
          "Azioni"
        ],
        "title": "Moduli"
      },
      "new": {
        "title": "Nuovo modulo"
      },
      "reactivate": {
        "reactivated": "Il modulo è stato riattivato"
      },
      "row": {
        "product_delete": "Sei sicuro di voler rimuovere questo modulo?"
      },
      "show": {
        "new_button": "Aggiungi valutazioni",
        "new_program_template_button": "Aggiungi processi",
        "new_task_button": "Aggiungi valutazioni",
        "organizations": "Organizzazioni",
        "product_program_templates": "Modelli di processo",
        "product_program_templates_table_header": [
          "Nome",
          "Lingua",
          "Stato",
          "Azioni"
        ],
        "product_tasks": "Valutazioni",
        "product_tasks_table_header": [
          "Nome",
          "Chiave",
          "Lingua",
          "Crediti",
          "Stato",
          "Collegamento",
          "Azioni"
        ],
        "status": "Stato",
        "subscription_types": "Tipi di abbonamento"
      },
      "update": {
        "product_updated": "Il modulo è stato aggiornato",
        "title": "Modifica modulo"
      }
    },
    "profile": {
      "audits": {
        "show": {
          "action": "Azione",
          "browser": "Navigatore",
          "caption": "Di seguito troverai tutte le azioni di autenticazione per il tuo account.",
          "created_at": "Effettuato l'accesso a",
          "ip": "IP",
          "title": "Cronologia dell'audit"
        }
      },
      "base": {
        "tabs": {
          "audit_history": "Cronologia dell'audit",
          "personal_information": "Informazione personale",
          "settings": "Istituzioni"
        }
      },
      "candidates": {
        "form": {
          "create_account": "Creare un account"
        },
        "update": {
          "updated": "Le tue informazioni personali sono state aggiornate"
        }
      },
      "images": {
        "destroy": {
          "deleted": "Foto del profilo rimossa",
          "destroy_confirm": "Rimuovi la foto del profilo"
        },
        "form": {
          "allowed_formats": "Formati file consentiti: JPG, PNG",
          "upload": "Carica una foto"
        },
        "update": {
          "updated": "Foto del profilo modificata"
        }
      },
      "modal": {
        "profile_image": {
          "change": "Regolare",
          "save": "Salva",
          "title": "Modifica la foto del profilo",
          "upload": "Caricamento"
        }
      },
      "notification_settings": {
        "email_notifications": {
          "help_text": "Scegli le impostazioni di notifica desiderate, tieni presente che solo le notifiche non visualizzate vengono inviate via email.",
          "title": "Frequenza e-mail di notifica"
        },
        "update": {
          "updated": "Le tue impostazioni personali sono state aggiornate"
        }
      },
      "notifications": {
        "all": "Mostra tutto",
        "bulk_update": {
          "marked_as_read": "Le notifiche sono contrassegnate come lette"
        },
        "document_approvals": "Approvazioni dei documenti",
        "documents": "Documenti",
        "last_30_days": "Ultimi 30 giorni",
        "messages": "Informare",
        "news": "Notizia",
        "system": "Sistema",
        "title": "Notifiche",
        "tracks": "Traiettorie"
      },
      "passwords": {
        "update": {
          "sent": "Riceverai una email con le istruzioni per modificare la tua password"
        }
      },
      "profiles": {
        "show": {
          "password": {
            "instructions": "Se desideri modificare la password del tuo account, riceverai una nostra email con le istruzioni.",
            "request": "Chiedi a",
            "title": "Cambiare la password"
          },
          "profile_image": "Immagine del profilo",
          "title": "Le tue informazioni"
        }
      },
      "settings": {
        "activate_2fa": {
          "activate": "Attivare",
          "inactive": "La 2FA non è stata ancora attivata"
        },
        "deactivate_2fa": {
          "active": "2FA è attivato",
          "deactivate": "Disattivare",
          "disabled": "L'uso della verifica in due passaggi è richiesto dall'organizzazione"
        },
        "two_factor_authentication": {
          "title": "Autenticazione a 2 fattori"
        }
      },
      "title": "Il mio profilo",
      "two_factor_authentications": {
        "destroy": {
          "destroyed": "2FA è disattivato",
          "tfa_required": "2FA non può essere disattivato"
        },
        "update": {
          "2fa_settings_reset": "Due fattori possono essere riconfigurati."
        }
      },
      "users": {
        "update": {
          "updated": "Le tue informazioni personali sono state aggiornate"
        }
      }
    },
    "profile_picture_disclaimer": {
      "accept": "Accettare",
      "cancel": "Annulla",
      "title": "Dichiarazione di non responsabilità sulla foto del profilo"
    },
    "program_pages": {
      "edit": {
        "drop": "Trascina gli elementi qui"
      },
      "new": {
        "default_page_title": "Nuovo passo"
      },
      "show": {
        "not_editable": "Questo processo è stato pubblicato ed è quindi attribuibile, ma potrà essere nuovamente modificato solo dopo il ritiro",
        "not_editable_advisor": "In qualità di coach del processo non puoi modificare questo processo, ma puoi visualizzarlo (comprese tutte le impostazioni)",
        "not_editable_deleted": "Questo processo è stato rimosso e pertanto non è più modificabile o assegnabile",
        "not_editable_template": "Puoi visualizzare o copiare questo modello nei processi della tua organizzazione"
      }
    },
    "program_templates": {
      "bulk": {
        "publish": "Sono stati pubblicati diversi processi",
        "withdraw": "Diversi processi sono stati ritirati"
      },
      "destroy": {
        "destroyed": "Il processo '%{program_title}' è stato eliminato"
      },
      "duplicate": {
        "duplicate_prefix": "Copia:",
        "program_duplicated": "Il processo '%{program_title}' è stato copiato",
        "template_duplicated": "Il processo \"%{program_title}\" è stato copiato nei processi della tua organizzazione"
      },
      "form": {
        "invite_mail": "E-mail di invito",
        "invite_mail_documentation": "(clicca qui per la documentazione dell'email di invito)",
        "labels": "Etichette",
        "options": "Opzioni",
        "title_placeholder": "Scegli un titolo",
        "your_labels": "Le tue etichette"
      },
      "index": {
        "help_text": "Progetta, modifica o visualizza i processi della tua organizzazione. I processi sono disponibili per i consulenti con il ruolo di coach e per i candidati premium.",
        "new_button": "Nuovo processo",
        "own_programs": "Propri processi",
        "table_header": [
          "Stato",
          "Fatti",
          "Lunghezza",
          "Azioni"
        ],
        "templates": "Modelli",
        "title": "Sviluppare processi"
      },
      "new": {
        "default_program_title": "Processi"
      },
      "publish": {
        "all_pages_invisible": "Non puoi pubblicare questo processo perché nessuno dei passaggi è impostato come visibile al candidato",
        "published": "Il processo '%{program_title}' è stato pubblicato"
      },
      "publish_as_template": {
        "published_as_template": "Il processo '%{program_title}' è stato pubblicato come modello"
      },
      "update": {
        "update_failed": "Impossibile aggiornare il processo",
        "updated": "Il processo è stato aggiornato"
      },
      "withdraw": {
        "withdrawn": "Il processo '%{program_title}' è stato ritirato"
      }
    },
    "programs": {
      "bulk": {
        "publish": "Sono stati pubblicati diversi processi",
        "withdraw": "Diversi processi sono stati ritirati"
      },
      "destroy": {
        "destroyed": "Il processo '%{program_title}' è stato eliminato"
      },
      "duplicate": {
        "duplicate_prefix": "Copia:",
        "program_duplicated": "Il processo '%{program_title}' è stato copiato",
        "template_duplicated": "Il processo \"%{program_title}\" è stato copiato nei processi della tua organizzazione"
      },
      "form": {
        "labels": "Etichette",
        "options": "Opzioni",
        "title_placeholder": "Scegli un titolo",
        "your_labels": "Le tue etichette"
      },
      "index": {
        "help_text": "Progetta, modifica o visualizza i processi della tua organizzazione. I processi sono disponibili per i consulenti con il ruolo di coach e per i candidati premium.",
        "new_button": "Nuovo processo",
        "own_programs": "Propri processi",
        "table_header": [
          "Stato",
          "Fatti",
          "Lunghezza",
          "Azioni"
        ],
        "templates": "Modelli",
        "title": "Sviluppare processi"
      },
      "new": {
        "default_program_title": "Processi"
      },
      "publish": {
        "all_pages_invisible": "Non puoi pubblicare questo processo perché nessuno dei passaggi è impostato come visibile al candidato",
        "published": "Il processo '%{program_title}' è stato pubblicato"
      },
      "publish_as_template": {
        "published_as_template": "Il processo '%{program_title}' è stato pubblicato come modello"
      },
      "update": {
        "update_failed": "Impossibile aggiornare il processo",
        "updated": "Il processo è stato aggiornato"
      },
      "withdraw": {
        "withdrawn": "Il processo '%{program_title}' è stato ritirato"
      }
    },
    "prompt": {
      "cost_center_delete_confirm": "Sei sicuro di voler eliminare questo centro di costo?",
      "file_remove_confirm": "Sei sicuro di voler eliminare questo file?",
      "program_delete_confirm": "Sei sicuro di voler eliminare questo processo con tutti i suoi passaggi ed elementi?",
      "room_delete_confirm": "Sei sicuro di voler eliminare questa videochiamata?",
      "subscription_delete_confirm": "Sei sicuro di voler eliminare questo abbonamento?",
      "subscription_upgrade_confirm": "Sei sicuro di voler aggiornare il tuo abbonamento? Non è possibile eseguire il downgrade entro un anno dall'inizio del nuovo abbonamento."
    },
    "resource": {
      "candidate_email_messages": "E-mail inviate",
      "close": "Vicino",
      "edit": "Modificare",
      "filter": "Filtro",
      "impersonate": "Accedi come",
      "interactive_results": "Risultati",
      "new_enrollment": "Crea percorso",
      "reinvite": "Invita di nuovo"
    },
    "role": {
      "AccountManager": "Account Manager",
      "Coach": "Allenatore di processi",
      "IxlyAdmin": "Amministratore di Ixly",
      "OrganizationManager": "Responsabile dell'organizzazione",
      "SelectionManager": "Responsabile della selezione"
    },
    "rooms": {
      "show": {
        "cannot_join": "Questa videochiamata è attualmente chiusa"
      }
    },
    "score": {
      "no_score": "Senza punteggio",
      "with_score": "Con punteggio"
    },
    "selection": {
      "compare_candidates": {
        "new": {
          "current_position": "Posizione attuale:",
          "no_scores": "Puoi confrontare i candidati solo con i risultati",
          "position_unknown": "Sconosciuto",
          "title": "Confronta i candidati",
          "too_many_candidates": "Puoi confrontare fino a 5 candidati contemporaneamente."
        }
      },
      "job_profile_templates": {
        "duplicate": "Il profilo professionale \"%{job_profile_title}\" è stato copiato nella tua organizzazione",
        "index": {
          "list": "Elenco dei profili lavorativi standard progettati",
          "title": "Modelli di profili professionali"
        },
        "new": {
          "tooltip": "Aggiungi modello"
        },
        "show": {
          "competencies": "Competenze",
          "education_level": "Livello di istruzione",
          "job_profile_title": "Titolo",
          "title": "%{job_profile_title}"
        }
      },
      "job_profiles": {
        "create": {
          "success": "Il profilo professionale è stato creato",
          "title": "Nuovo profilo professionale"
        },
        "destroy": {
          "failure": "non può essere eliminato perché è associato a uno o più comandi corrispondenti",
          "success": "Il profilo lavorativo selezionato è stato eliminato"
        },
        "edit": {
          "title": "Modifica profilo lavorativo"
        },
        "form": {
          "job_profile_explanation": "Con Confronta & Seleziona utilizziamo valutazioni avanzate che misurano oggettivamente le competenze e l'intelligenza dei candidati. Le nostre valutazioni standardizzate forniscono punteggi concreti, che costituiscono la base per la nostra corrispondenza basata sui dati. Con questo approccio scientifico trascendiamo la soggettività e introduciamo la cura"
        },
        "index": {
          "compare_select_icon_alt": "Icona Confronta e seleziona",
          "created_at": "Fatto su",
          "created_by": "Creato da",
          "job_title": "Titolo",
          "list": "Passa alla <strong>panoramica dei candidati, seleziona i candidati</strong> e premi il pulsante <strong>\"Confronta e seleziona\"</strong> %{icon} per vedere i risultati corrispondenti.",
          "title": "Profili lavorativi",
          "updated_at": "Ultima modifica il"
        },
        "new": {
          "title": "Nuovo profilo professionale",
          "tooltip": "Funzionalità Aggiungi profilo"
        },
        "update": {
          "success": "La modifica del profilo lavorativo è riuscita",
          "title": "Modifica profilo lavorativo"
        }
      },
      "matching_assignments": {
        "create": {
          "candidates": "Candidati",
          "success": "L'assegnazione corrispondente è stata creata correttamente",
          "title": "Componi il compito corrispondente"
        },
        "edit": {
          "title": "Modifica il comando corrispondente"
        },
        "index": {
          "candidate": "candidati",
          "caption": "Qui troverai una panoramica dei tuoi incarichi corrispondenti",
          "incomplete": "Abbinamento non ancora completato",
          "list": {
            "candidates": "Candidati",
            "created_at": "Fatto su",
            "job_title": "Titolo di lavoro",
            "label": "Etichetta",
            "status": "Stand",
            "title": "Caratteristica",
            "updated_at": "Ultima modifica il"
          },
          "title": "Incarichi corrispondenti"
        },
        "list": {
          "assignment_note": "<span class='font-bold'>Nota:</span> non a tutti i candidati sono stati assegnati un ACT e un WPV. <a href='%{assignment_link}' class='font-bold underline hover:text-blue-light' data-turbo-frame='_top'>Fai clic qui</a> per sostenere immediatamente un test di intelligenza (ACT) e una competenza test (WPV) da assegnare.<br> Garantiamo che questo processo funzioni senza intoppi e impediamo doppie assegnazioni.",
          "name": "Nome"
        },
        "new": {
          "title": "Componi il compito corrispondente"
        },
        "show": {
          "education_level": "Livello di istruzione misurato",
          "title": "Risultati dell'Abbinamento"
        },
        "tooltip": {
          "start_comparison": "Confronta i candidati"
        },
        "update": {
          "success": "La modifica del compito corrispondente è riuscita",
          "title": "Modifica il compito corrispondente"
        }
      },
      "matching_results": {
        "index": {
          "education_level": "Livello di istruzione misurato",
          "list": {
            "competencies": "Competenze",
            "education_level": "Livello di istruzione misurato",
            "favorite": "Preferiti",
            "matching_score": "Punteggio corrispondente",
            "name": "Nome"
          },
          "title": "Compito corrispondente"
        },
        "list": {
          "assignment_note": "<span class='font-bold'>Nota:</span> non tutti i candidati sono stati assegnati alle valutazioni. <a href='%{assignment_link}' class='font-bold underline hover:text-blue-light' data-turbo-frame='_top'>Fai clic qui</a> per assegnare direttamente le recensioni.",
          "name": "Nome"
        }
      },
      "results_overview": {
        "index": {
          "title": "Panoramica dei risultati"
        }
      },
      "title": "Compare & Select"
    },
    "sessions": {
      "create": {
        "combination_does_not_exist": "Il tuo indirizzo email o la password non sono corretti"
      },
      "destroy": {
        "logout": "Ti sei disconnesso con successo"
      },
      "new": {
        "forgot_your_password": "Hai dimenticato la password?",
        "help_text": "Benvenuti nella pagina di accesso di Process Toolkit",
        "new_login": "Login",
        "title": "login"
      },
      "verify_otp": {
        "invalid": "Il codice fornito non era corretto"
      }
    },
    "shared": {
      "accounts": {
        "suppressed_email": "L'indirizzo email associato a questo account <span class=\"font-medium\">non può essere raggiunto</span>.",
        "suppressed_email_tooltip": "L'indirizzo email associato a questo account non è raggiungibile.",
        "unconfirmed_email": {
          "unconfirmed_email": "L'indirizzo email verrà cambiato in:",
          "unconfirmed_email_explanation": "Il titolare dell'indirizzo email dovrà confermare la modifica tramite un link. L'indirizzo email modificato verrà utilizzato solo dopo la conferma."
        }
      },
      "candidate_result_widgets": {
        "personal_information": {
          "date_of_birth": "Data di nascita",
          "education": "Livello di istruzione",
          "email": "Indirizzo e-mail",
          "most_recent_work_experience": "Esperienza lavorativa più recente",
          "study_subject": "Corso",
          "title": "Dati personali",
          "years": {
            "one": "%{years} anni",
            "other": "%{years} anni"
          },
          "years_of_experience": "Anni di esperienza"
        }
      },
      "enrollment_sidebar": {
        "advisor": "Allenatore",
        "candidate": "Candidato",
        "coadvisor": "Cocoa",
        "comments": "Informare",
        "contact_person": "Contatto",
        "room": "Video chiamata",
        "track": "Procedura"
      },
      "forms": {
        "password": {
          "hide_password": "nascondere la password",
          "show_password": "Mostra password"
        }
      },
      "header": {
        "admin": "Amministratore",
        "advisors": "Consiglieri",
        "all_organizations": "Tutte le organizzazioni",
        "all_users": "Tutti gli utenti",
        "approval_requests": "Accordi",
        "blog": "Blog",
        "candidates": "Candidati",
        "clients": "Clienti",
        "enrollers": "Collegamenti di registrazione",
        "login": "login",
        "organization": "Organizzazione",
        "prices": "Prezzi",
        "products": "Moduli",
        "programs": "Sviluppare processi",
        "rooms": "Videochiamate",
        "settings": "Istituzioni",
        "subscription_types": "Tipi di abbonamento",
        "tasks": "Compiti",
        "tracks": "Traiettorie",
        "translations": "Traduzioni",
        "users": "Utenti"
      },
      "header_profile": {
        "log_in_as": "Accedi come:",
        "log_in_at": "Accedere:",
        "logout": "Disconnettersi",
        "my_tracks": "I miei viaggi",
        "profile": "Profilo"
      },
      "notifications_sidebar": {
        "help_text": "Filtra la tua lista di cose da fare con le opzioni seguenti:",
        "title": "Filtri"
      },
      "organization_sidebar": {
        "clients_help_text": "Filtra i tuoi clienti con le opzioni seguenti:",
        "labels": "Etichette",
        "programs_help_text": "Filtra i tuoi processi con le opzioni seguenti:",
        "title": "Filtri",
        "users_help_text": "Filtra i tuoi utenti con le opzioni seguenti:",
        "your_own_organization": "La tua organizzazione"
      },
      "rebound_email_banner": {
        "action_label": "Cambia indirizzo email",
        "description": "Non è stato possibile consegnare un'e-mail inviata a [[email]]. Questo è il motivo: [[motivo]]",
        "fallback_description": "Non è stato possibile consegnare un'e-mail inviata di recente a [[email]].",
        "title": "Aggiorna il tuo indirizzo email"
      },
      "suppressed_email": "L'indirizzo email associato a questo account <span class=\"font-medium\">non può essere raggiunto</span>.",
      "suppressed_email_tooltip": "Impossibile raggiungere l'indirizzo email associato a questo account.",
      "user_sidebar": {
        "my_track": "Il mio percorso",
        "new_track": "Crea traiettoria",
        "show_deactivated": "Mostra disattivato",
        "track_of": "Traiettoria di %{name}",
        "track_with": "Traiettoria con %{name}"
      }
    },
    "sidebar": {
      "close": "Chiudi la barra laterale"
    },
    "simple_form": {
      "error_notification": {
        "default_message": "Si prega di esaminare i problemi riportati di seguito:"
      },
      "include_blank": {
        "default_mail_template": {
          "no_default": "NO"
        },
        "default_reminder_mail_template": {
          "no_default": "Nessuna norma"
        },
        "select_option": "fate la vostra scelta"
      },
      "labels": {
        "advisor": {
          "avatar": "Immagine del profilo",
          "biography": "Descrizione personale",
          "can_impersonate": "Può accedere come utente diverso",
          "consent_given": "Autorizzazione",
          "cost_center_id": "Centro di costo",
          "current_password": "Password attuale",
          "first_name": "Nome di battesimo",
          "id": "Contatto",
          "invite_immediately": "Invia immediatamente un'e-mail di benvenuto",
          "label": "Etichette",
          "label_ids": "Etichette",
          "labels": "Etichette",
          "language": "Lingua",
          "last_name": "Cognome",
          "mailing_frequency": "Frequenza delle e-mail",
          "new_password": "Nuova password",
          "organization": "Organizzazione",
          "organization_language": "Organizzazione linguistica",
          "organization_name": "organizzazione del nome",
          "otp_code": "Codice",
          "privacy_accepted": "Termini/Privacy",
          "remove_avatar": "Elimina questa foto",
          "role": "Rotolo",
          "roles": "Rotolo",
          "status": "Stato",
          "type": "Tipologia di utente",
          "unconfirmed_email": "E-mail"
        },
        "assignment": {
          "deadline": "Termine per la valutazione",
          "invited_at": "Data dell'invito",
          "mail_template_id": "E-mail di invito",
          "reminder_mail_delivery_date": "Data dell'e-mail di promemoria",
          "reminder_mail_template_id": "E-mail di promemoria"
        },
        "authentication_action": {
          "auth_action": "Tipo di autenticazione"
        },
        "candidate": {
          "account_email": "Indirizzo e-mail",
          "advisor": "Consulente",
          "advisor_id": "Consulente",
          "client": "Clienti",
          "client_ids": "Clienti",
          "coadvisor": "Co-consigliere",
          "coadvisor_ids": "Co-consiglieri",
          "coadvisors": "Co-consigliere",
          "contact_person": "Contatti",
          "contact_person_ids": "Contatti",
          "contact_persons": "Contatti",
          "cost_center_id": "Centro di costo",
          "created_at": "Data di registrazione",
          "deadline": "Termine per la valutazione",
          "end_date": "Data di fine",
          "first_name": "Nome di battesimo",
          "invitation_date": "Data dell'invito",
          "invite_immediately": "Invita direttamente",
          "label": "Etichette",
          "label_ids": "Etichette",
          "labels": "Etichette",
          "language": "Lingua",
          "last_name": "Cognome",
          "mail_template_id": "E-mail di invito",
          "name": "Nome",
          "organization": "Organizzazione",
          "program": "Processi",
          "reminder_mail_delivery_date": "Data dell'e-mail di promemoria",
          "reminder_mail_template_id": "E-mail di promemoria",
          "report_objective": "Obiettivo del reporting",
          "start_date": "Data di inizio",
          "status": "Stato"
        },
        "candidate_assessment": {
          "document_options": "Opzioni di rapporto",
          "end_date": "Arrotondato a",
          "norm_group_uuid": "Obiettivo del reporting",
          "report_to": "Primi risultati disponibili per",
          "selected_report_id": "Rapporto",
          "start_date": "Arrotondato da"
        },
        "candidate_assessment_program": {
          "document_options": "Opzioni di rapporto",
          "norm_group_uuid": "Obiettivo del reporting",
          "report_to": "Primi risultati disponibili per",
          "selected_report_id": "Rapporto"
        },
        "candidate_matching_assignment": {
          "education_level": "Livello di istruzione",
          "no_score": "Nessun punteggio",
          "score": "Punto"
        },
        "candidate_report": {
          "candidate_assessment_program_id": "Programma di valutazione",
          "document_options": "Opzioni di rapporto",
          "norm_group_id": "Obiettivo del reporting",
          "report_id": "Rapporto",
          "reportable": "Valutazione"
        },
        "client": {
          "label_ids": "Etichette",
          "name": "Nome"
        },
        "component": {
          "alt_text": "Descrizione dell'immagine per gli screen reader",
          "bov_editable_by": "BOV può essere avviato da",
          "can_approve": "Per essere approvato da",
          "cc_load_policy": "Visualizza immediatamente i sottotitoli (nella tua lingua) durante la riproduzione",
          "controls": "Mostra la barra di controllo in basso durante la riproduzione",
          "description_finished": "Descrizione aggiuntiva dopo il completamento",
          "editable_by": "Rispondi da",
          "html": "Codice HTML",
          "iframe_title": "Titolo del video per gli screen reader",
          "label": "Assegna un'etichetta al campo",
          "max_selections": "Numero massimo di selezioni",
          "options": "Scelte",
          "question": "Chiedere",
          "rel": "Mostra anche video correlati da altri canali dopo la riproduzione",
          "report_visible_for": "Risultato visibile prima",
          "required": "È necessario rispondere con \"Sì\" per procedere",
          "sections": "Sezioni",
          "task_key": "Valutazione selezionata",
          "template_can_be_switched_by": "Modello intercambiabile da",
          "url": "URL del video di YouTube",
          "visible_for": "Documento visibile a",
          "visible_to_candidate": "Rendi l'elemento visibile anche al candidato",
          "visible_to_contact_person": "Condividi automaticamente il documento con i contatti dopo l'approvazione"
        },
        "contact_person": {
          "avatar": "Immagine del profilo",
          "biography": "Descrizione personale",
          "can_impersonate": "Può accedere come utente diverso",
          "client": "Cliente",
          "consent_given": "Autorizzazione",
          "cost_center_id": "Centro di costo",
          "current_password": "Password attuale",
          "first_name": "Nome di battesimo",
          "id": "Contatto",
          "invite_immediately": "Invia immediatamente un'e-mail di benvenuto",
          "label": "Etichette",
          "label_ids": "Etichette",
          "labels": "Etichette",
          "language": "Lingua",
          "last_name": "Cognome",
          "mailing_frequency": "Frequenza delle e-mail",
          "new_password": "Nuova password",
          "organization": "Organizzazione",
          "organization_language": "Organizzazione linguistica",
          "organization_name": "organizzazione del nome",
          "otp_code": "Codice",
          "privacy_accepted": "Termini/Privacy",
          "remove_avatar": "Elimina questa foto",
          "role": "Rotolo",
          "status": "Stato",
          "type": "Tipologia di utente",
          "unconfirmed_email": "E-mail"
        },
        "cost_center": {
          "identifier": "Nome"
        },
        "defaults": {
          "account_email": "Indirizzo e-mail",
          "advisor_id": "Consulente",
          "candidate_id": "Candidato",
          "client_id": "Cliente",
          "coadvisor_id": "Co-consigliere",
          "contact_person_id": "Contatto",
          "description": "Descrizione",
          "email": "Indirizzo e-mail",
          "end_date": "Data di fine",
          "explanation": "Spiegazione",
          "first_name": "Nome di battesimo",
          "language": "Lingua dell'applicazione",
          "last_name": "Cognome",
          "name": "Nome",
          "organization_id": "Organizzazione",
          "password": "parola d'ordine",
          "product_id": "Moduli",
          "program_id": "Processi",
          "start_date": "Data d'inizio",
          "title": "Titolo",
          "two_factor": "Verifica in due passaggi"
        },
        "discount": {
          "end_date": "Data di fine",
          "kind": "SU",
          "organization": "Organizzazione",
          "organization_id": "Organizzazione",
          "percentage": "Percentuale",
          "start_date": "Data d'inizio"
        },
        "enroller": {
          "advisor": "Allenatore",
          "advisor_id": "Allenatore",
          "allow_repeated": "Consenti iscrizioni ripetute",
          "link": "Collegamento",
          "program": "Processi",
          "program_id": "Processi",
          "show_default": "Mostra testo predefinito"
        },
        "enrollment": {
          "coadvisor_id": "Cocoa",
          "invite_immediately": "Invita direttamente",
          "program_id": "Processi"
        },
        "entry": {
          "end_date": "Fino a compreso",
          "identifier": "identificatore",
          "start_date": "Da",
          "type": "Tipo"
        },
        "fixed_price": {
          "description": "Descrizione sulla fattura",
          "organization": "Organizzazione",
          "price": "Prezzo",
          "task": "Compito"
        },
        "import/file": {
          "created_at": "aggiunto",
          "imported_rows": "righe importate",
          "name": "Nome",
          "organization": "organizzazione",
          "organization_id": "Organizzazione",
          "state": "importazione dello stato",
          "total_rows": "righe totali",
          "type": "Tipo"
        },
        "import_file": {
          "created_at": "aggiunto",
          "download_example": "Scarica il file di esempio",
          "imported_rows": "righe importate",
          "name": "Nome",
          "organization": "organizzazione",
          "organization_id": "Organizzazione",
          "state": "importazione dello stato",
          "total_rows": "righe totali",
          "type": "Tipo"
        },
        "ixly_employee": {
          "avatar": "Immagine del profilo",
          "biography": "Descrizione personale",
          "can_impersonate": "Può accedere come utente diverso",
          "consent_given": "Autorizzazione",
          "cost_center_id": "Centro di costo",
          "current_password": "Password attuale",
          "first_name": "Nome di battesimo",
          "id": "Contatto",
          "invite_immediately": "Invia immediatamente un'e-mail di benvenuto",
          "label": "Etichette",
          "language": "Lingua",
          "last_name": "Cognome",
          "mailing_frequency": "Frequenza delle e-mail",
          "new_password": "Nuova password",
          "organization": "Organizzazione",
          "organization_language": "Organizzazione linguistica",
          "organization_name": "organizzazione del nome",
          "otp_code": "Codice",
          "privacy_accepted": "Termini/Privacy",
          "remove_avatar": "Elimina questa foto",
          "role": "Rotolo",
          "roles": "Rotolo",
          "status": "Stato",
          "type": "Tipologia di utente",
          "unconfirmed_email": "E-mail"
        },
        "job_profile": {
          "competencies": "Competenze",
          "education_level": "Livello di istruzione",
          "job_profile": "Profilo lavorativo",
          "label": "Etichette",
          "label_ids": "Etichette",
          "title": "Titolo di lavoro",
          "user": "Utente"
        },
        "join": {
          "display_name": "Nome da visualizzare",
          "start_hidden": "Inizia nascosto",
          "start_muted": "Inizia disattivato"
        },
        "leniency": {
          "credits": "Crediti",
          "reason": "Motivo"
        },
        "mail_template": {
          "body": "Testo",
          "language": "Lingua",
          "subject": "Soggetto",
          "title": "Titolo"
        },
        "matching_assignment": {
          "education_level": "Livello di istruzione",
          "job_profile": "Profilo lavorativo",
          "job_profile_competencies": "Competenze",
          "job_profile_education_level": "Livello di istruzione",
          "job_profile_title": "Profilo lavorativo",
          "label": "Etichette",
          "label_ids": "Etichette",
          "matching_score": "Punteggio corrispondente",
          "no_score": "Nessun punteggio",
          "title_description": "Caratteristica",
          "user": "Utente",
          "vacancy_number_placeholder": "Numero di posto vacante"
        },
        "media_item": {
          "advisor": "Consulente",
          "candidate": "Candidato",
          "contact_person": "Contatto"
        },
        "organization": {
          "account_manager_id": "Account Manager",
          "address": "Indirizzo",
          "advisor_can_create_candidates": "I consulenti possono creare candidati",
          "advisor_can_manage_existing_candidates": "I consulenti possono visualizzare e aggiornare tutti i candidati",
          "advisor_can_manage_users": "I consulenti possono creare coach e contatti di processo",
          "advisor_can_remove_candidates": "I consulenti possono rimuovere i candidati",
          "advisor_can_view_enrollers": "I coach del processo possono visualizzare i collegamenti di registrazione",
          "advisor_can_view_prices": "I consulenti possono vedere i prezzi",
          "advisor_deactivation_period": "Periodo di disattivazione per i consulenti",
          "advisor_rooms_permission": "I coach del processo possono visualizzare le videochiamate",
          "allow_compare_select": "Consenti Compare & Select",
          "allow_impersonation": "Consenti agli utenti di accedere come",
          "allow_ixly_support": "Consenti il supporto Ixly",
          "api_anonymous": "API anonima",
          "api_key": "Chiave API",
          "api_organization_id": "Uuid dell'organizzazione del toolkit di test",
          "api_user": "Posta dell'utente API",
          "available_languages": "Lingue disponibili",
          "brand_logo": "Logo",
          "can_create_suborganizations": "L'organizzazione può creare sotto-organizzazioni",
          "candidate_deactivation_period": "Periodo di disattivazione per i candidati",
          "city": "Città",
          "clients_enabled": "Clienti e persone di contatto sono impegnati per l'organizzazione",
          "country": "Paese",
          "credit_bundle": "Fascio",
          "default_language": "Lingua standard",
          "default_report_objective": "Impostazione predefinita per \"Obiettivo del reporting\"",
          "default_report_to": "Primi risultati disponibili per",
          "has_dashboard_link": "I consulenti hanno un collegamento all'app Dashboard",
          "interactive_button_color": "Icone a colori",
          "interactive_header_color": "Colore del testo del titolo della pagina",
          "interactive_header_text_color": "Colore del testo dell'intestazione",
          "interactive_primary_color": "Colore primario",
          "invoice_company_name": "Nome dell'azienda di fatturazione",
          "invoice_company_name_placeholder": "Lascia vuoto come il nome dell'organizzazione",
          "invoice_email": "Indirizzo e-mail per le fatture",
          "invoice_remarks": "Commenti sulla fattura",
          "invoice_remarks_placeholder": "Mostrato sotto la fattura",
          "name": "organizzazione del nome",
          "organization_manager_can_view_enrollments": "I manager dell'organizzazione possono visualizzare i processi di tutti i candidati",
          "payment_type": "Metodo di pagamento",
          "postal_code": "Codice Postale",
          "privacy_link": "Privacy",
          "profile_picture_disclaimer": "Dichiarazione di non responsabilità del testo",
          "remove_logo": "Elimina il logo",
          "remove_report_cover_logo": "Rimuovere il logo della copertina del report",
          "remove_report_header_logo": "Rimuovere il logo dall'intestazione del report",
          "report_cover_logo": "Logo di copertina per il rapporto",
          "report_header_logo": "Logo dell'intestazione del rapporto",
          "retention_period": "Periodo di conservazione dei dati personali",
          "show_interactive_results": "Mostra i risultati",
          "show_posts": "Mostra blog",
          "status": "Stato",
          "subscription_type": "Sottoscrizione",
          "terms_link": "Condizioni",
          "top_organization": "Massima organizzazione",
          "two_factor": "Richiedi la verifica in due passaggi per questi ruoli",
          "vat_number": "Numero di identificazione IVA",
          "welcome_mail_subject": "Oggetto dell'e-mail di benvenuto",
          "welcome_mail_text": "Testo e-mail di benvenuto",
          "whitelabeled": "Etichetta bianca"
        },
        "organization_default_mail_template": {
          "mail_template_id": "Modello di posta elettronica standard"
        },
        "organization_default_reminder_mail_template": {
          "mail_template_id": "Modello di email di promemoria standard"
        },
        "post_rule": {
          "label_ids": "Etichette",
          "program_ids": "Processi",
          "roles": "Ruoli utente"
        },
        "premium_candidate": {
          "coadvisor_ids": "Co-consulenti",
          "contact_person_ids": "Contatti",
          "label_ids": "Etichette"
        },
        "price": {
          "amc": "Prezzo AMC, €",
          "bov": "Prezzo BOV, €",
          "external_user": "Contributo mensile per candidato, €",
          "fit": "Prezzo FIT, €",
          "internal_user": "Canone mensile per pullman, €",
          "job_market_scan": "Opportunità di prezzo sul mercato del lavoro, €",
          "job_suggestions": "Prezzo proposte di lavoro, €",
          "jobfeed": "Prezzo Jobfeed, €",
          "start_date": "Efficace da"
        },
        "product": {
          "name": "Nome"
        },
        "product_program_templates": {
          "ids": "Modelli di processo"
        },
        "product_tasks": {
          "ids": "Valutazioni"
        },
        "program": {
          "invite_mail_subject": "E-mail di invito con oggetto",
          "invite_mail_text": "E-mail di invito via SMS",
          "label": "Etichette",
          "language": "Lingua",
          "status": "Stato",
          "step_order": "Ordine dei passaggi",
          "type": "Tipo"
        },
        "program_page": {
          "approve_by": "Approvato da",
          "has_comments": "Mostra i messaggi nella parte inferiore del passaggio",
          "hidden_for_candidate": "Nascondere il passaggio al candidato",
          "optional": "Rendi il passaggio facoltativo (inseribile dopo l'assegnazione)"
        },
        "program_template": {
          "invite_mail_subject": "E-mail di invito con oggetto",
          "invite_mail_text": "E-mail di invito via SMS",
          "label": "Etichette",
          "status": "Stato",
          "step_order": "Ordine dei passaggi",
          "type": "Tipo"
        },
        "report_objectives": {
          "advice": "Consiglio",
          "other": "Altrimenti",
          "selection": "Selezione"
        },
        "role": {
          "AccountManager": "Account Manager",
          "Coach": "Allenatore",
          "IxlyAdmin": "Amministratore di Ixly",
          "OrganizationManager": "Responsabile dell'organizzazione",
          "SelectionManager": "Responsabile della selezione"
        },
        "room": {
          "end_to_end_encryption": "Crittografia end-to-end",
          "everyone_starts_hidden": "Tutti iniziano nascosti",
          "everyone_starts_muted": "Tutti iniziano in silenzio",
          "link": "Collegamento",
          "only_managers_can_join": "Possono partecipare solo i responsabili dell'organizzazione",
          "only_managers_can_kick": "Solo i gestori dell'organizzazione possono eliminare gli altri",
          "only_managers_can_mute": "Solo i gestori dell'organizzazione possono disattivare l'audio degli altri",
          "subject": "Soggetto"
        },
        "subscription": {
          "annual_fee": "Canone di abbonamento annuale",
          "end_date": "Data di fine",
          "start_date": "Data d'inizio",
          "subscription_type": "Modulo di iscrizione",
          "subscription_type_id": "Modulo di iscrizione"
        },
        "subscription_type": {
          "can_downgrade_to": "Può effettuare il downgrade al modulo di iscrizione",
          "can_upgrade_to": "Può aggiornare al modulo di iscrizione",
          "candidate_price": "Costi annuali per candidato per valutazioni illimitate",
          "credit_price": "Prezzo per credito",
          "credits_notice": "Pochi crediti sulla segnalazione",
          "external_user": "Costi mensili per candidato",
          "internal_user": "Costi mensili per pullman",
          "invoice_line_type": "Categoria contabile",
          "max_candidates": "Numero massimo di candidati attivi",
          "max_coaches": "Numero massimo di allenatori attivi",
          "max_users": "Numero massimo di allenatori attivi",
          "month_price": "Canoni di abbonamento mensili",
          "payment_period": "Periodo di pagamento",
          "prepay": "Pagato in anticipo",
          "prepay_credits": "Pacchetti di crediti disponibili",
          "type": "Categoria"
        },
        "task": {
          "language": "Lingua"
        },
        "translation": {
          "identifier": "identificatore"
        },
        "user": {
          "avatar": "Immagine del profilo",
          "biography": "Descrizione personale",
          "can_impersonate": "Può accedere come utente diverso",
          "consent_given": "Autorizzazione",
          "cost_center_id": "Centro di costo",
          "current_password": "Password attuale",
          "first_name": "Nome di battesimo",
          "id": "Contatto",
          "invite_immediately": "Invia immediatamente un'e-mail di benvenuto",
          "label": "Etichette",
          "language": "Lingua",
          "last_name": "Cognome",
          "mailing_frequency": "Frequenza delle e-mail",
          "new_password": "Nuova password",
          "organization": "Organizzazione",
          "organization_language": "Organizzazione linguistica",
          "organization_name": "organizzazione del nome",
          "otp_code": "Codice",
          "privacy_accepted": "Termini/Privacy",
          "remove_avatar": "Elimina questa foto",
          "role": "Rotolo",
          "status": "Stato",
          "type": "Tipologia di utente",
          "unconfirmed_email": "E-mail"
        },
        "whitelabel": {
          "domain": "Dominio",
          "subdomain": "Sottodominio"
        }
      },
      "options": {
        "component": {
          "bov_editable_by": {
            "both": "Entrambi (l'allenatore può iniziare solo dopo che il candidato ha iniziato)",
            "candidate": "Il candidato"
          },
          "can_approve": {
            "candidate": "Il candidato",
            "contact_person": "Il contatto"
          },
          "editable_by": {
            "advisor": "L'allenatore",
            "both": "Entrambi",
            "candidate": "Il candidato"
          },
          "report_visible_for": {
            "advisor": "L'allenatore",
            "both": "Entrambi",
            "candidate": "Il candidato"
          },
          "template_can_be_switched_by": {
            "advisor": "L'allenatore",
            "both": "Entrambi",
            "candidate": "Il candidato",
            "creator": "Nessuno"
          },
          "visible_for": {
            "advisor": "L'allenatore",
            "both": "Entrambi",
            "candidate": "Il candidato"
          }
        },
        "discount": {
          "kind": {
            "amc": "Prezzo AMC",
            "bov": "Prezzo BOV",
            "candidate_price": "Costi annuali per candidato per valutazioni illimitate",
            "credit_price": "Prezzo per credito",
            "external_user": "Costi mensili per candidato",
            "fit": "Prezzo ADATTA",
            "internal_user": "Costi mensili per pullman",
            "job_market_scan": "Prezzo delle opportunità nel mercato del lavoro",
            "job_suggestions": "Suggerimenti per lavori sui prezzi",
            "jobfeed": "Prezzo Jobfeed",
            "month_price": "Canoni di abbonamento mensili"
          }
        },
        "organization": {
          "advisor_deactivation_period": {
            "never": "Mai"
          },
          "payment_type": {
            "automatic": "Automaticamente",
            "invoice": "Fattura"
          },
          "two_factor": {
            "Advisor": "Consulente",
            "ContactPerson": "Contatto",
            "OrganizationManager": "Responsabile dell'organizzazione",
            "User": "Partecipante"
          }
        },
        "program": {
          "step_order": {
            "approval": "Il candidato può procedere solo dopo l'approvazione del passaggio corrente",
            "free": "Il candidato può eseguire i passaggi in qualsiasi ordine",
            "submission": "Il candidato può continuare solo dopo aver inviato il passaggio corrente"
          }
        },
        "program_page": {
          "approve_by": {
            "none": "Non è necessario approvare il passaggio",
            "seen": "Il passaggio viene automaticamente approvato quando il candidato lo visualizza",
            "set": "Il coach e il candidato possono entrambi approvare il passaggio",
            "submit": "Il coach approva il passaggio dopo averlo presentato dal candidato"
          }
        },
        "subscription_type": {
          "invoice_line_type": {
            "frequent": "Spesso",
            "intensive": "Intensivo"
          },
          "payment_period": {
            "month": "Mese",
            "quarter": "Trimestre",
            "year": "Anno"
          },
          "type": {
            "AllYouCanSubscription": "Segnala tutto ciò che puoi",
            "AnnualSubscription": "Annuale",
            "DemoSubscription": "Dimostrazione",
            "IncidentalSubscription": "Incidentale",
            "ManualSubscription": "Manualmente",
            "ResearchSubscription": "Ricerca",
            "StandardSubscription": "Standard",
            "TestSubscription": "Test"
          }
        },
        "user": {
          "mailing_frequency": {
            "every_morning": "Raccogli le notifiche e inviale via email ogni mattina.",
            "immediately": "Ricevi notifiche immediatamente.",
            "never": "Non ricevere mai notifiche via e-mail.",
            "once_an_hour": "Raccogli le notifiche e inviale via email ogni ora."
          },
          "type": {
            "Advisor": "Consulente",
            "ContactPerson": "Contatto",
            "IxlyEmployee": "Impiegato Ixly",
            "User": "Partecipante"
          }
        }
      },
      "placeholders": {
        "search_by_name": "Ricerca per nome",
        "search_by_title": "Cerca per titolo",
        "search_for_coadvisor_ids": "Cerca co-consulente",
        "search_for_competencies": "Cerca competenze",
        "search_for_contact_person_ids": "Trova la persona di contatto",
        "search_for_job_profile_competencies": "Cerca competenze",
        "search_for_label_ids": "Cerca etichetta",
        "search_for_labels": "Cerca etichetta",
        "search_for_options": "opzioni di ricerca",
        "user": {
          "edit": {
            "biography": "Il testo inserito qui verrà mostrato a chiunque visualizzi il tuo profilo"
          }
        }
      },
      "required": {
        "mark": "*",
        "text": "Questo è un campo obbligatorio"
      }
    },
    "skiplink": "Passa al contenuto principale",
    "status": {
      "advisor": {
        "active": "Attivo",
        "created": "Creato",
        "inactive": "Inattivo",
        "invited": "Invitato"
      },
      "candidate": {
        "active": "Attivo",
        "created": "Creato",
        "inactive": "Inattivo",
        "invited": "Invitato"
      },
      "candidate_email_message": {
        "bounced": "Non può essere consegnato",
        "delivered": "Consegnato",
        "loading": "Caricamento",
        "sent": "Inviato",
        "transient": "Possibili problemi alla consegna",
        "unsubscribed": "Iscrizione annullata"
      },
      "contact_person": {
        "active": "Attivo",
        "created": "Creato",
        "inactive": "Inattivo",
        "invited": "Invitato"
      },
      "enroller": {
        "active": "Attivo",
        "created": "Creato",
        "deactivated": "Disattivato",
        "inactive": "Inattivo"
      },
      "enrollment": {
        "action_required": "Azione richiesta",
        "created": "Creato",
        "finished": "arrotondato",
        "invited": "Invitato",
        "started": "Iniziato"
      },
      "enrollment_page": {
        "created": "Creato",
        "done": "arrotondato",
        "rejected": "Rifiutato",
        "submitted": "Inviato"
      },
      "import_file": {
        "created": "Creato",
        "failed": "Fallito",
        "processed": "Incorporato",
        "validating": "Convalidare"
      },
      "invoice": {
        "canceled": "Annullato",
        "charged_back": "Riaddebitato",
        "closed": "Chiuso",
        "expired": "Scaduto",
        "failed": "Fallito",
        "open": "Aprire",
        "paid": "Pagato",
        "processed": "Incorporato",
        "refunded": "Rimborsato",
        "revoked": "Ritirato",
        "sent": "Inviato",
        "settled": "Pagato",
        "unconfirmed": "Non confermato"
      },
      "mail_template": {
        "draft": "Progetto",
        "published": "Pubblicato"
      },
      "organization": {
        "active": "Attivo",
        "created": "Creato",
        "deleted": "Eliminato",
        "inactive": "Inattivo"
      },
      "price": {
        "active": "Attivo",
        "expired": "Scaduto",
        "pending": "Non ancora attivo"
      },
      "product": {
        "active": "Attivo",
        "deactivated": "Disattivato"
      },
      "program": {
        "deleted": "Eliminato",
        "editable": "Modificabile",
        "published": "Pubblicato",
        "template": "Modello"
      },
      "program_template": {
        "deleted": "Eliminato",
        "editable": "Modificabile",
        "published": "Pubblicato",
        "template": "Modello"
      },
      "ttk_migration": {
        "completed": "Migrazione completata",
        "created": "Creato",
        "failed": "La migrazione fallisce",
        "ready_to_migrate": "Pronto per la migrazione",
        "syncing_default_reports": "Migrare i report standard",
        "syncing_settings": "Migrare le impostazioni",
        "syncing_users_candidates": "Migrare gli account",
        "updating_managed_via_ap": "Migrazione completa",
        "updating_manager": "Configura il gestore dell'organizzazione"
      },
      "user": {
        "active": "Attivo",
        "created": "Creato",
        "inactive": "Inattivo",
        "invited": "Invitato"
      }
    },
    "suborganizations": "Sottoorganizzazioni",
    "subscription_type_product_status": {
      "active": "Attivo",
      "deactivated": "Disattivato"
    },
    "subscription_type_products": {
      "create": {
        "created": "I moduli sono stati aggiunti al modulo di iscrizione",
        "products_blank": "Nessun modulo è stato selezionato"
      },
      "deactivate": {
        "deactivated": "La connessione al modulo è disattivata"
      },
      "destroy": {
        "deleted": "Il modulo è stato rimosso dal modulo di iscrizione"
      },
      "list": {
        "table_header": [
          "Nome",
          "Collegamento",
          "Azioni"
        ]
      },
      "new": {
        "title": "Aggiungi moduli a %{subscription_type}"
      },
      "reactivate": {
        "reactivated": "La connessione al modulo è stata riattivata"
      },
      "row": {
        "product_delete": "Sei sicuro di voler rimuovere questo modulo dal modulo di iscrizione?"
      }
    },
    "subscription_type_status": {
      "active": "Attivo",
      "deactivated": "Disattivato"
    },
    "subscription_types": {
      "create": {
        "subscription_type_created": "Il modulo di iscrizione è stato creato",
        "title": "Nuovo modulo di iscrizione"
      },
      "deactivate": {
        "deactivated": "Il modulo di iscrizione è stato disattivato"
      },
      "destroy": {
        "deleted": "Il modulo di iscrizione è stato rimosso"
      },
      "edit": {
        "title": "Modifica modulo di iscrizione"
      },
      "index": {
        "new_button": "Nuovo modulo di iscrizione",
        "table_header": [
          "Nome",
          "Stato",
          "Lo stato è cambiato",
          "Abbonamenti attivi",
          "Categoria",
          "Azioni"
        ],
        "title": "Tipi di abbonamento"
      },
      "new": {
        "title": "Nuovo modulo di iscrizione"
      },
      "reactivate": {
        "reactivated": "Il modulo di iscrizione è stato riattivato"
      },
      "row": {
        "subscription_type_delete": "Sei sicuro di voler eliminare questo modulo di iscrizione?"
      },
      "show": {
        "disabled": "NO",
        "enabled": "SÌ",
        "product_new_button": "Aggiungi moduli",
        "status": "Stato"
      },
      "update": {
        "subscription_type_updated": "Il modulo di iscrizione è stato aggiornato",
        "title": "Modifica modulo di iscrizione"
      }
    },
    "support": {
      "array": {
        "last_word_connector": " E",
        "two_words_connector": " E",
        "words_connector": ", "
      }
    },
    "templates": {
      "document": "documento"
    },
    "time": {
      "am": "am",
      "formats": {
        "date_with_day": "%A, %e %B %Y",
        "date_with_time": "%e %B %Y, %H:%M",
        "date_with_time_short": "%e %b %Y, %H:%M",
        "date_without_day": "%e %B %Y",
        "date_without_day_and_year": "%d%b",
        "date_without_time": "%d-%m-%Y",
        "default": "%a %d %b %Y, %H:%M:%S %z",
        "long": "%d %B %Y %H:%M",
        "short": "%d %b %H:%M",
        "time_only": "%H:%M",
        "time_with_date": "%H:%M (%e %B %Y)"
      },
      "pm": "pm"
    },
    "translations": {
      "actions": "Azioni",
      "edit": "Modifica traduzione",
      "new": "Nuova traduzione",
      "show": "Visualizza la traduzione",
      "source": "Fonte",
      "translation_created": "La traduzione è stata creata",
      "translation_destroyed": "La traduzione è stata rimossa",
      "translation_updated": "La traduzione è stata aggiornata"
    },
    "two_factor": {
      "activate_help_text": "Scansiona questo codice QR con la tua app di autenticazione preferita (ad esempio Google Authenticator o Authy) e inserisci il codice per attivare la verifica in due passaggi.",
      "title": "Verifica in due passaggi",
      "verify_help_text": "Inserisci il codice fornito dall'app di autenticazione"
    },
    "type": {
      "Advisor": "Consulente",
      "Candidate": "Candidato",
      "CandidateAssessmentEntry": "Valutazione",
      "CandidateAssessmentProgramEntry": "Programma di valutazione",
      "CandidateReportEntry": "Rapporto (solo test)",
      "Cocoach": "Co-consigliere",
      "ContactPerson": "Contatto",
      "GeneratedReportEntry": "Rapporto (processi)",
      "IxlyEmployee": "Impiegato Ixly",
      "LeniencyEntry": "Gentilezza",
      "OrderEntry": "Ordine",
      "PremiumCandidate": "Candidato",
      "TestOnlyCandidate": "Candidato",
      "User": "Candidato",
      "plural": {
        "Advisor": "Consiglieri",
        "Candidate": "Candidati",
        "ContactPerson": "Contatta persone",
        "PremiumCandidate": "Candidati",
        "TestOnlyCandidate": "Candidati"
      }
    },
    "unauthorized": {
      "manage": {
        "all": "Non disponi delle autorizzazioni appropriate per eseguire questa operazione"
      }
    },
    "user_types": {
      "Advisor": "Consulente",
      "Candidate": "Candidato",
      "Co_coach": "Co-consigliere",
      "Coach": "Consulente",
      "ContactPerson": "Contatto"
    },
    "users": {
      "all": {
        "new_button": "Nuovo utente",
        "table_header": [
          "Nome",
          "Organizzazione",
          "Tipo",
          "Rotolo",
          "Stato",
          "Centro di costo"
        ],
        "title": "Tutti gli utenti"
      },
      "confirmations": {
        "form": {
          "privacy_accepted_label": "Accetto i <a href='%{terms_url}' target='_blank' class='underline hover:text-blue-light'>termini e condizioni</a> e i <a href='%{privacy_statement_url}' target='_blank' class='underline hover:text-blue-light'>informativa sulla privacy</a>",
          "submit": "Ulteriore"
        },
        "show": {
          "already_confirmed_account": "<span class='font-medium'>Nota!</span> Esiste già un account con questo indirizzo email. Utilizza la stessa password per accedere al tuo nuovo account.",
          "already_confirmed_resource": "Hai già creato un account. Utilizza il tuo indirizzo email e la password per accedere.",
          "confirmed": "L'indirizzo email del tuo account è stato confermato, accedi con il tuo account qui",
          "title": "fare un conto"
        },
        "update": {
          "confirmed": "Il tuo account è stato creato, accedi con il tuo account qui"
        }
      },
      "create": {
        "created": "L'utente è stato creato",
        "created_and_invited": "L'utente è stato creato e accolto via email",
        "help_text": "Crea un nuovo utente per la tua organizzazione qui",
        "more_information": "Maggiori informazioni",
        "title": "Nuovo utente"
      },
      "deactivate": {
        "user_deactivated": "L'utente è stato disattivato"
      },
      "edit": {
        "title": "Modifica utente"
      },
      "form": {
        "impersonation_explanation": "Se selezioni questa opzione, concedi a questo utente il diritto di accedere come un altro utente.",
        "labels": "Etichette",
        "unconfirmed_email": "L'indirizzo email verrà cambiato in:",
        "unconfirmed_email_explanation": "Il titolare dell'indirizzo email dovrà confermare la modifica tramite un link. L'indirizzo email modificato verrà utilizzato solo dopo la conferma.",
        "upload_avatar": "Clicca sull'immagine per caricare la tua foto",
        "user_type_documentation": "(clicca qui per la documentazione sui ruoli utente)",
        "your_labels": "Le tue etichette"
      },
      "index": {
        "help_text": "Qui troverai tutti gli utenti della tua organizzazione",
        "import": "Importazione CSV",
        "new_button": "Nuovo utente",
        "title": "Utenti"
      },
      "new": {
        "help_text": "Crea un nuovo utente per la tua organizzazione qui",
        "title": "Nuovo utente"
      },
      "password": {
        "title": "cambiare la password"
      },
      "reactivate": {
        "user_reactivated": "L'utente è stato riattivato"
      },
      "removed_member": "eliminato %{type}",
      "reset_password": {
        "sent": "L'e-mail di reimpostazione della password è stata inviata"
      },
      "row": {
        "number_of_assigned_programs": {
          "one": "1 processo",
          "other": "%{count} processi"
        }
      },
      "send_welcome": {
        "bulk_button": "Invitare",
        "welcome_mail_sent": "L'e-mail di benvenuto è stata inviata"
      },
      "show": {
        "account_manager": "Responsabile del conto di",
        "advisor": "Consulente",
        "biography": "Descrizione personale",
        "candidate_overview": "Panoramica dei candidati",
        "client": "Cliente",
        "cost_center": "Centro di costo",
        "created_at": "Fatto su",
        "disabled": "Spento",
        "email": "Indirizzo e-mail",
        "enabled": "Abilitato",
        "impersonate": "Accedi come",
        "organization": "Organizzazione",
        "resend_welcome_email": "Invia nuovamente l'e-mail di benvenuto",
        "reset_password": "Recupera la password",
        "send_email": "Invia una email",
        "send_welcome_email": "Invia e-mail di benvenuto",
        "status": "Stato",
        "two_factor": "Verifica in due passaggi",
        "type": "Ruolo",
        "user_overview": "Panoramica dell'utente"
      },
      "update": {
        "title": "Modifica utente",
        "updated": "L'utente è stato aggiornato"
      }
    },
    "validations": {
      "minlength_12": "minimo dodici caratteri."
    },
    "valvat": {
      "country_adjectives": {
        "at": "Austria",
        "be": "Belgio",
        "bg": "Bulgaria",
        "cy": "Cipro",
        "cz": "Repubblica Ceca",
        "de": "Germania",
        "dk": "Danimarca",
        "ee": "Estonia",
        "es": "Spagna",
        "eu": "Europa",
        "fi": "Finlandia",
        "fr": "Francia",
        "gb": "Regno Unito",
        "gr": "Grecia",
        "hu": "Ungheria",
        "ie": "Irlanda",
        "it": "Italia",
        "lt": "Lituania",
        "lu": "Lussemburgo",
        "lv": "Lettonia",
        "mt": "Malta",
        "nl": "Paesi Bassi",
        "pl": "Polonia",
        "pt": "Portogallo",
        "ro": "Romania",
        "se": "Svezia",
        "si": "Slovenia",
        "sk": "Slovacchia"
      }
    },
    "will_paginate": {
      "next_label": "Avanti →",
      "page_gap": "&hellip;",
      "previous_label": "← Precedente"
    }
  }
}