{
  "en": {
    "accounts": {
      "passwords": {
        "form": {
          "login": "Login",
          "submit": "Request"
        }
      },
      "sessions": {
        "form": {
          "forgot_your_password": "Forgot your password?",
          "submit": "Login"
        },
        "new": {
          "title": "Login"
        }
      },
      "switch_users": {
        "success": "Successfully logged into %{organization}"
      }
    },
    "action_menu": {
      "admin": {
        "mail_templates": {
          "single": {
            "delete_confirm": "Are you sure you want to delete this mail template?"
          }
        }
      },
      "assessments": {
        "clients_contact_persons": {
          "single": {
            "delete_confirm": "Are you sure that you want to delete this Contact Person?"
          }
        },
        "labels": {
          "single": {
            "delete_confirm": "Are you sure that you want to delete this label?"
          }
        },
        "program_templates": {
          "single": {
            "delete_confirm": "Are you sure you want to delete this process?"
          }
        },
        "programs": {
          "single": {
            "delete_confirm": "Are you sure you want to delete this process?"
          }
        }
      },
      "deactivation_confirm": "By deactivating the %{name}, %{name} will no longer have access to the site and will be automatically removed in %{retention_period}. Please note that inactive %{type} are hidden by the default filter settings, adjust your filters if you want them included in the overview.",
      "delete_confirm": "Deleting %{name} also automatically deletes all possible underlying trajectories.",
      "ixly": {
        "fixed_prices": {
          "single": {
            "delete_confirm": "Are you sure you want to delete this fixed price?"
          }
        },
        "job_profiles": {
          "single": {
            "delete_confirm": "Are you sure you want to delete this template?"
          }
        },
        "migration_organizations": {
          "default": {
            "add": "Add new migration organisation",
            "start": "Start migrations"
          }
        },
        "subscriptions": {
          "single": {
            "delete_confirm": "Are you sure you want to delete this subscription?"
          }
        }
      },
      "selection": {
        "job_profiles": {
          "single": {
            "delete_confirm": "Are you sure you want to delete this job profile?"
          }
        }
      }
    },
    "activemodel": {
      "attributes": {
        "queries/usage": {
          "end_date": "End date",
          "organization": "Organisation",
          "start_date": "Start date"
        }
      }
    },
    "admin": {
      "authentication_actions": {
        "filter_form": {
          "authenticate": "Login",
          "impersonate": "Login as ..."
        },
        "index": {
          "action": "Action",
          "browser": "Browser",
          "caption": "Below are all authentication related events. These events are stored for a maximum of 6 months.",
          "created_at": "When",
          "ip": "IP Address",
          "title": "Authentication logs"
        }
      },
      "candidate_csv_imports": {
        "import_file": {
          "log": "Log",
          "original": "Original"
        },
        "index": {
          "list": "This lists candidate csv import files within the organisation",
          "title": "Candidate CSV imports"
        },
        "new": {
          "title": "Candidate CSV import"
        }
      },
      "color_settings": {
        "destroy": {
          "destroyed": "Colour settings have been reset to default.",
          "reset_button": "Reset default scheme"
        },
        "edit": {
          "color_settings_explanation": "You can change the interactive results page's colour scheme for all users by using the fields below",
          "title": "Colour scheme"
        },
        "update": {
          "updated": "Organisation`s colour settings were updated successfully."
        }
      },
      "default_mail_templates": {
        "could_not_be_saved": "Default mail template could not be saved",
        "explain": "Here you can choose from published organisational email templates and set them as default. It is not possible to choose application mail templates. If you want this, you will first have to copy them to your own organisation.",
        "header": "Email settings",
        "saved_successfully": "Default mail template saved!",
        "updated_successfully": "Default mail template updated successfully!"
      },
      "default_reminder_mail_templates": {
        "could_not_be_saved": "Default reminder mail template could not be saved",
        "explain": "Here you can choose from published organisational email templates and set them as default. It is not possible to choose application mail templates. If you want this, you will first have to copy them to your own organisation.",
        "header": "Reminder email settings",
        "saved_successfully": "Default reminder mail template saved!",
        "updated_successfully": "Default reminder mail template updated successfully!"
      },
      "entries": {
        "index": {
          "candidate_assessment_entry": "Candidate Assessment",
          "candidate_assessment_program_entry": "Candidate Assessment Program",
          "candidate_report_entry": "Report",
          "generated_report_entry": "Report",
          "leniency_entry": "Leniency",
          "list": {
            "candidate": "Candidate",
            "cost_center": "Cost Center",
            "credits": "Amount",
            "date": "Date",
            "description": "Description",
            "entry_type": "Transaction type"
          },
          "order": {
            "frequent_credits": "Monthly charge for used credits",
            "incidental_credits": "Credit bundle purchase",
            "intensive_credits": "Monthly charge for used credits"
          },
          "order_entry": "Order",
          "title": "Credit overview"
        }
      },
      "mail_templates": {
        "add": "Add new mail template",
        "create": {
          "success": "The mail template has been created"
        },
        "destroy": {
          "deleted": "The mail template has been deleted"
        },
        "duplicate": {
          "duplicated": "The '%{title}' mail template has been copied"
        },
        "duplicated_title": "Copy of %{title}",
        "edit": {
          "title": "Change mail template"
        },
        "form": {
          "assessment_platform_link_explanation": "A link that directs the candidate to the right place in Assessment Platform. If they have not set a password yet, they will be directed to the set password page, otherwise to the login page. This field or passwordless_login_link variable should be present in the mail body*.",
          "assignment_title_explanation": "The titles of assigned assignments",
          "candidate_name_explanation": "The name of this user",
          "end_date_explanation": "The latest date by which the track can be completed",
          "explanation_text": "Within the text of the mail template, variables can be used to insert dynamic content. These variables are surrounded by %{VARIABLE}, where VARIABLE is the name, and where the entire thing is replaced. Currently, we support the following",
          "organization_name_explanation": "The name of the current organisation",
          "passwordless_login_link_explanation": "This link directs the candidate to the login page of the Assessment Platform. No password is required for login. However, the candidate can use this link only once to access the application. This field or assessment_platform_link variable should be present in the mail body*."
        },
        "index": {
          "application_template": "Application Template",
          "help_text": "Mail templates are the predefined emails your advisors can send to candidates. Only published templates can be selected by an advisor. Application templates are provided as an example.",
          "language": "Language",
          "mail_title": "Title",
          "state": "State",
          "subject": "Subject",
          "title": "Mail Templates",
          "updated_at": "Updated at"
        },
        "new": {
          "title": "New Mail Template"
        },
        "publish": {
          "cannot_publish": "The e-mail template %{title} could not be published:<br> %{error_messages}",
          "published": "The e-mail template %{title} has been published"
        },
        "show": {
          "body": "Mail body",
          "example_title": "Preview of the email that will be sent",
          "subject": "Subject",
          "title": "Preview of the %{title} mail template"
        },
        "update": {
          "cannot_publish": "The e-mail template could not be updated:<br> %{error_messages}",
          "success": "Mail template changed",
          "title": "Change mail template"
        },
        "withdraw": {
          "withdrawn": "The e-mail template %{title} has been withdrawn"
        }
      },
      "organization_permissions": {
        "edit": {
          "help_text": "You can constrain which user roles have access to which parts of the application below",
          "title": "User permissions"
        },
        "update": {
          "updated": "Organisation permissions were updated successfully."
        }
      },
      "organization_settings": {
        "default_report_objective": {
          "advice": "Advice",
          "selection": "Selection"
        },
        "default_report_to": {
          "advisor": "The coach",
          "both": "Both",
          "candidate": "The candidate"
        },
        "edit": {
          "available_languages_explanation": "Disabling languages utilized by your users will result in their language settings being automatically switched to your organisation's default language.",
          "default_language_explanation": "The selected language will be the default setting for all new organisation users.",
          "default_report_objective_explanation": "The default reporting objective will be selected by default when creating a new candidate. When you are assessing candidates to advice them in their development, choose 'Advice'; when you are assessing candidates to select among them, choose 'Selection'. Based on this objective we'll automatically select the most relevant report for this candidate.",
          "default_report_to_explanation": "This setting determines who can initially view a report when an assessment has  been finished: the candidate, the advisor, or both. When 'advisor' is selected, the advisor will have the option to decide this for each assessment. When editing a process this can always be configured per assessment.",
          "title": "Organisation settings"
        },
        "update": {
          "required_languages": "Available languages can't be blank and default language must be available in the organisation",
          "updated": "Organisation settings were updated successfully."
        }
      },
      "privacy_settings": {
        "edit": {
          "advisor_deactivation_period_explanation": "The period of inactivity after which advisors will be deactivated.",
          "candidate_deactivation_period_explanation": "The period of inactivity after which candidates will be deactivated.",
          "impersonation_explanation": "By selecting this option you enable any users within your organisation with impersonation rights to impersonate all of your organisation's users and candidates. Additionally, with this option selected users can now generate and copy single-use login links for candidates, allowing them to log in without a password.",
          "ixly_support_explanation": "By selecting this option you give the Ixly helpdesk and IT team the ability to impersonate your users when solving issues that you have reported via the helpdesk.",
          "retention_period_explanation": "The General Data Protection Regulation (GDPR) stipulates that personal data may not be kept longer than necessary. To comply with this, inactive candidates, advisors and contact people are automatically deleted based on the chosen retention period. This retention period is also applied to the assessments used and reports generated.",
          "retention_period_explanation_addition": "If the deactivation period is equal to the retention period, the advisor and/or candidate will be deactivated and removed at the same time.",
          "title": "Privacy and data settings"
        },
        "update": {
          "updated": "Organisation privacy settings were updated successfully."
        }
      },
      "whitelabel": {
        "edit": {
          "title": "Whitelabel settings"
        },
        "update": {
          "whitelabel_updated": "The Whitelabel is updated"
        }
      }
    },
    "advisors": {
      "index": {
        "title": "Advisors"
      }
    },
    "alt_texts": {
      "approval_request_send_reason": "Send this reason",
      "component_add": "Add this element",
      "component_delete": "Delete this element",
      "component_move": "Move this element",
      "component_settings": "Open the element settings",
      "details_collapse": "Collapse the details",
      "details_expand": "Expand the details",
      "document_delete": "Delete this saved report",
      "document_download": "Download this saved report",
      "document_save": "Save this report",
      "document_share": "Share this saved report with the candidate",
      "document_unshare": "Share the settings above with the candidate",
      "enrollment_page_print": "Print this step",
      "enrollment_page_requiring_action": "Contains elements with additional setup",
      "file_download": "Download this file",
      "filter_bar_collapse": "Collapse the filters",
      "filter_bar_expand": "Expand the filters",
      "interactive_results_agile_average": "Chart with dashed line at %{percentage} percent from the right. The higher this percentage, the more agile you are.",
      "interactive_results_agile_pillars": "Chart displaying your subscores as clickable sectors of a pie chart. Power %{power}, Change %{change}, Growth %{growth}.",
      "interactive_results_info_birthdate": "Birth date",
      "interactive_results_info_date_of_birth": "Date of birth",
      "interactive_results_info_education": "Education level",
      "interactive_results_info_email": "Email address",
      "interactive_results_info_study_subject": "Education",
      "interactive_results_info_work_experience": "Most recent work experience",
      "interactive_results_info_years_experience": "Number of years of experience",
      "interactive_results_information": "Open the interpretation of the scores",
      "interactive_results_intelligence_chart": "Chart indicating your IQ score of %{iq} on a normal distribution. Dashed lines show the margins of error.",
      "interactive_results_next": "Next",
      "interactive_results_paging": "Page %{page} of %{pages}",
      "interactive_results_previous": "Previous",
      "interactive_results_rating": "%{score} out of %{max} stars",
      "interactive_results_tip": "Open the tip",
      "ixly_test_download": "Download this rapport",
      "ixly_test_settings": "Open the report settings",
      "loading": "Loading...",
      "navbar_expand": "Expand the navigation menu",
      "notification_close": "Close this task",
      "notification_reopen": "Reopen this task",
      "organization_logo": "Logo of %{name}",
      "organization_logo_change": "Logo of %{name} – click to change",
      "organization_logo_home": "Logo of %{name} – click for home",
      "post_rule_delete": "Delete this sharing rule",
      "price_in_euros": "in euros",
      "program_page_delete": "Delete this step",
      "program_page_move": "Move this step",
      "program_page_settings": "Open the step settings",
      "program_settings": "Open the process settings",
      "quiz_correct": "This answer is correct",
      "quiz_incorrect": "This answer is incorrect",
      "quiz_selected": "This answer is selected",
      "saved": "Your changes have been saved",
      "saving": "Your changes are being saved",
      "template_column_delete": "Delete this column",
      "template_column_move": "Move this column",
      "template_column_settings": "Open the column settings",
      "template_element_delete": "Delete this section",
      "template_element_move": "Move this section",
      "template_element_settings": "Open the section settings",
      "template_element_variables": "Open the variables settings",
      "template_item_delete": "Delete this item",
      "template_row_delete": "Delete this row",
      "template_row_move": "Move this row",
      "template_zoom_in": "Zoom in",
      "template_zoom_out": "Zoom out",
      "top_step_delete": "Delete this step group",
      "top_step_insert": "Insert a step group",
      "user_avatar": "Profile picture of %{name}",
      "user_avatar_change": "Profile picture of %{name} – click to change",
      "view_switcher_cards": "View as tiles",
      "view_switcher_table": "View as table"
    },
    "approval_requests": {
      "index": {
        "help_text": "Here are all documents you have been requested to approve",
        "table_header": [
          "File",
          "Candidate",
          "Process",
          "Status",
          "Reason",
          "Coach"
        ],
        "title": "Approval requests"
      }
    },
    "assessments": {
      "advisors": {
        "account_email": "Email",
        "create": {
          "created": "The advisor has been created",
          "created_and_invited": "The advisor has been created and was sent a welcome email",
          "title": "Add advisor"
        },
        "created_at": "Created at",
        "deactivate": {
          "advisor_deactivated": "The advisor has been deactivated"
        },
        "edit": {
          "labels": "Labels",
          "title": "Edit the advisor"
        },
        "form": {
          "add_label": "Add label",
          "impersonation_explanation": "If you select this option, you enable this user with impersonation rights",
          "role_explanation": "Click to view detailed Role documentation"
        },
        "index": {
          "candidates": "Candidates",
          "labels": "Labels",
          "list": "This lists all advisors within your organisation",
          "title": "Advisors"
        },
        "label": {
          "remove": "Remove"
        },
        "last_activity": "Last activity",
        "name": "Name",
        "new": {
          "title": "Add advisor"
        },
        "organization": "Organisation",
        "privacy_accepted_at": "Privacy accepted",
        "reactivate": {
          "advisor_reactivated": "The advisor has been reactivated"
        },
        "roles": "Roles",
        "send_welcome": {
          "welcome_mail_sent": "The welcome email has been sent"
        },
        "show": {
          "list": {
            "track_with": "Track with"
          }
        },
        "status": "Status",
        "two_factor": "2-Factor Authentication",
        "type": "Type",
        "unconfirmed_email": "Unconfirmed email",
        "update": {
          "advisor_updated": "The advisor has been updated",
          "title": "Edit the advisor"
        }
      },
      "assessment_report_settings": {
        "edit": {
          "title": "Report Settings"
        },
        "update": {
          "success": "The report settings has been updated successfully"
        }
      },
      "assessment_verifications": {
        "link": "Verification",
        "show": {
          "about_verification_tests": "A verification test is an optional, shortened version of the full %{assessment}. Cognitive tests are increasingly being completed remotely, without supervision. In this situation it is difficult to estimate whether the candidate took the test independently and without any assistance. You can make this assessment better by using a verification test, which is completed under supervision.",
          "about_verification_tests_title": "About verification tests",
          "run_test": "Run verification test now",
          "title": "Verification required for %{assessment} of %{candidate}",
          "verification_tests_report": "Once the verification test has been created, you will receive an extra page in the report that shows whether the scores of the previously created %{assessment} somewhat correspond to the scores of the verification test. If so, you will see a green check mark in the report. If you see a red cross in the report, the difference between the results of the remote %{assessment} and the verification test is greater than we would expect based on chance.",
          "verification_tests_report_title": "Differences in the report",
          "why_skip_verification_tests": "If a candidate has completed a %{assessment} under supervision, or if there is no reason to suspect that the candidate received assistance in completing the test, then using a verification test is unnecessary. It is also less relevant if the candidate's results are below the expected level. The verification test does not indicate whether the candidate has achieved a higher score after taking this verification test. You only get to see whether the scores on the second test match the first test.",
          "why_skip_verification_tests_title": "To use or not?"
        },
        "skip": {
          "success": "The verification of %{title} has been skipped successfully"
        },
        "update": {
          "success": "The verification of %{title} has been started successfully"
        }
      },
      "assignments": {
        "edit": {
          "end_date": "Edit deadline",
          "end_date_explanation": "The latest date by which the track can be completed. Clear the date field to remove the deadline",
          "invitation_date": "Edit invitation date",
          "invitation_mail_title": "Set invitation mail",
          "planning_management": "Planning Management",
          "reminder_date": "Edit reminder date",
          "reminder_date_explanation": "The date when the candidate will be reminded about the track. Clear the date field to remove the reminder",
          "reminder_mail_title": "Set reminder mail",
          "title": "Edit planning management for %{assessment} from %{candidate}"
        },
        "invite": {
          "candidate_double_invited": "The candidate has been invited and notified of %{title} by email",
          "candidate_invited": "The candidate has been notified of %{title} by email",
          "cannot_invite": "Inviting the candidate for %{title} failed; the candidate was already invited."
        },
        "prepare_invite": {
          "title": "Invite %{candidate} for %{assessment}"
        },
        "updated": "Assignment successfully updated"
      },
      "bulk_assignments": {
        "already_assigned": "This task is already selected",
        "assessment_not_available_in_subscription": "This process contains an assessment which is no longer available in your subscription. Please modify the program to ensure it can be assigned again.",
        "blank_description": "No description is available for this process.",
        "create": {
          "action_required": "The process has been assigned. Action is required before the candidate can be invited.",
          "candidate_invited": "The candidate has been notified of the newly assigned process by email",
          "cannot_enroll": "You cannot assign this process at the moment",
          "enrollment_created": "The process has been assigned. The candidate hasn't been notified of the newly assigned process yet."
        },
        "duration": "%{n} minutes",
        "example_report": "Open example report in new tab",
        "more_information": "More information",
        "new": {
          "selected_assessment_programs": "Selected assessment programs",
          "selected_assessments": "Selected assessments",
          "selected_programs": "Selected processes",
          "title": "Assign to candidate - %{candidate}"
        },
        "preview": "Preview"
      },
      "candidate_assessments": {
        "confirm_destroy": "Are you sure that you want to remove %{assessment_title} from %{candidate_name}?",
        "confirm_reset": "Are you sure that you want to reset %{assessment_title}? By resetting all answers are removed to ensure that the candidate can start the assessment again.",
        "destroy": {
          "success": "%{title} has been successfully withdrawn"
        },
        "report_tos": {
          "advisor": "The coach",
          "both": "Both",
          "candidate": "The candidate"
        },
        "reset": {
          "success": "%{title} has been reset successfully"
        }
      },
      "candidate_email_messages": {
        "index": {
          "sent_at": "Sent at",
          "status": "Status",
          "subject": "Subject",
          "title": "Emails sent to %{candidate}"
        }
      },
      "candidate_reports": {
        "add": "Generate report",
        "create": {
          "success": "The report is being generated. You'll receive a notification when the document is ready in the Documents and Media library."
        },
        "download": "Download report",
        "form": {
          "external_report": "Report for %{candidate_assessment}",
          "generated_reports": "Reports generated for %{candidate_assessment}",
          "no_external_reports_generated_yet": "No reports have been generated for %{candidate_assessment}",
          "no_reports_generated_yet": "No reports have been generated for %{candidate_assessment}",
          "report_objective": "Report objective: %{norm_group}"
        },
        "form_list": {
          "document_options": "Report options",
          "language": "Language",
          "media_item": "Document",
          "no_media_item_available": "Document not yet available",
          "norm_group_id": "Reporting objective",
          "shared_with": "Shared with",
          "title": "Generated report"
        },
        "html": "Report as html",
        "index": {
          "assessment": "Assessment",
          "created_at": "Created at",
          "document_options": "Report options",
          "language": "Language",
          "link_title": "Reports",
          "media_item": "Document",
          "no_media_item_available": "Document not yet available",
          "norm_group_id": "Reporting objective",
          "shared_with": "Shared with",
          "title": "Reports for %{candidate}"
        },
        "new": {
          "link_title": "Generate report",
          "title": "Generate a report for %{candidate}"
        },
        "only_shared_with": {
          "one": "%{count} organization manager",
          "other": "%{count} organization managers"
        },
        "shared_with": {
          "one": "and %{count} organisation manager",
          "other": "and %{count} organisation managers"
        },
        "stitch_reports": "Stitch reports together"
      },
      "candidate_results": {
        "index": {
          "title": "Personal results overview"
        }
      },
      "candidate_selection": {
        "active_filter_info": "<span class='font-bold'> / %{count}</span> of the active filter are selected.",
        "deselect_button": "Deselect all",
        "select_button": "Select all",
        "total_info": "<span class='font-bold'> / %{count} candidates</span> are selected."
      },
      "candidate_steps": {
        "create": {
          "action_required": "The process has been assigned. Action is required before the candidate can be invited.",
          "candidate_created": "The candidate has been notified of the newly created account",
          "candidate_invited": "The candidate has been notified of the newly assigned process by email",
          "cannot_enroll": "You cannot assign this process at the moment"
        },
        "filter": {
          "all": "All",
          "assessment_definitions": "Assessments",
          "assessment_programs": "Assessment Programs",
          "programs": "Processes"
        },
        "info_block": "Detailed information",
        "new": {
          "candidate_title": "Add candidate",
          "coadvisor_title": "Assign Contact person",
          "enrollment_title": "Assign to candidate"
        }
      },
      "candidates": {
        "add": "Add candidate",
        "add_label": "Add label",
        "added": "Added candidate <a class='underline' href='%{link}'>%{name}</a>.",
        "assign_bulk_button": "Assign",
        "contact_person": {
          "remove": "Remove"
        },
        "create": {
          "title": "Add candidate"
        },
        "deactivate": "The candidate has been deactivated",
        "delete": "Delete candidate",
        "deleted": "The candidate is deleted",
        "download_report": {
          "not_shared_alt_text": "The result has not yet been shared with you"
        },
        "edit": {
          "contact_persons": "Contact persons",
          "labels": "Labels",
          "title": "Edit candidate"
        },
        "export": {
          "started": "Export of candidates is started. You will receive notification once the export is completed."
        },
        "index": {
          "advisor": "Advisor",
          "api_identifier": "API identifier",
          "coadvisor": "Co-advisor",
          "contact_person": "Contact persons",
          "cost_center": "Cost center",
          "created_at": "Created at",
          "email": "Email",
          "first_name": "First name",
          "labels": "Labels",
          "language": "Language",
          "last_activity": "Last active",
          "last_name": "Surname",
          "name": "Name",
          "organization": "Organisation",
          "passwordless_login_link": "Passwordless login link",
          "privacy_accepted_at": "Privacy accepted",
          "report_objective": "Reporting objective",
          "status": "State",
          "title": "Candidates",
          "track": "Last activity",
          "unconfirmed_email": "Unconfirmed email"
        },
        "label": {
          "remove": "Remove"
        },
        "premium_candidate_tooltip": "This is a premium candidate",
        "reactivated": "The candidate has been reactivated",
        "review_required": "Review needed",
        "update": {
          "title": "Edit candidate"
        },
        "updated": "Candidate updated",
        "upload_candidates": "Upload Candidates"
      },
      "candidates_contact_persons": {
        "show": {
          "account_email": "Email",
          "contact_person": "Contact person",
          "name": "Name",
          "organization": "Organisation",
          "role": "Role"
        }
      },
      "clients": {
        "add": "Add client",
        "contact_person": {
          "edit": "Edit",
          "remove": "Remove"
        },
        "create": {
          "client_created": "The client has been created",
          "help_text": "Create a new organisation client",
          "title": "New client"
        },
        "edit": {
          "labels": "Labels",
          "title": "Edit client"
        },
        "form": {
          "add_contact_person": "Add Contact Person",
          "add_label": "Add label",
          "labels": "Labels"
        },
        "index": {
          "candidates": "Candidates",
          "contact_persons": "Contact persons",
          "help_text": "Contact people are stakeholders in a track of a candidate. They can view the progress a candidate makes without viewing specific details, they can share documents with candidates and their advisors, and they can be requested to approve documents. You can organize your contact people into groups called 'clients'.",
          "name": "Name",
          "new_button": "New client",
          "title": "Clients",
          "updated_at": "Updated at"
        },
        "label": {
          "remove": "Remove"
        },
        "new": {
          "help_text": "Create a new organisation client",
          "title": "New client"
        },
        "row": {
          "number_of_candidates": "%{number} active",
          "number_of_contact_persons": "%{number} active"
        },
        "show": {
          "help_text": "Here are all of your contact persons and candidates from this client",
          "new_button": "New contact person",
          "table_header": [
            "Details",
            "Email address",
            "Status"
          ]
        },
        "update": {
          "client_updated": "The client has been updated",
          "title": "Edit client"
        }
      },
      "clients_contact_persons": {
        "add": "Add contact person",
        "create": {
          "created": "Contact person %{name} was created"
        },
        "deactivate": {
          "deactivated": "The contact person has been deactivated"
        },
        "destroy": {
          "deleted": "The contact person is deleted"
        },
        "edit": {
          "labels": "Labels",
          "title": "Edit contact person"
        },
        "form": {
          "add_label": "Add label",
          "new": "New contact person"
        },
        "index": {
          "account_email": "Email",
          "candidates": "Candidates",
          "client": "Client",
          "created_at": "Created at",
          "labels": "Labels",
          "list_text": "Contact people are stakeholders in a track of a candidate. They can view the progress a candidate makes without viewing specific details, they can share documents with candidates and their advisors, and they can be requested to approve documents. You can organize your contact people into groups called 'clients'.",
          "name": "Name",
          "organization": "Organisation",
          "privacy_accepted_at": "Privacy accepted",
          "status": "Status",
          "title": "Contact persons",
          "two_factor": "2-Factor Authentication",
          "unconfirmed_email": "Unconfirmed email",
          "updated_at": "Updated at"
        },
        "label": {
          "remove": "Remove"
        },
        "new": {
          "title": "New contact person",
          "title_with_client": "New contact person for the %{client}"
        },
        "reactivate": {
          "reactivated": "The contact person has been reactivated"
        },
        "send_welcome": {
          "welcome_mail_sent": "The welcome email has been sent"
        },
        "show": {
          "client": "Client",
          "email": "Email address",
          "email_address": "Email address",
          "status": "Status"
        },
        "update": {
          "updated": "The contact person has been updated"
        }
      },
      "contact_persons_labels": {
        "destroy": {
          "removed": "Label successfully removed from the contact person"
        },
        "form": {
          "new": "New Label"
        }
      },
      "dashboards": {
        "show": {
          "title": "Dashboard"
        }
      },
      "enrollers": {
        "add": "Add Enroller",
        "create": {
          "created": "The enrolment link has been created",
          "created_with_premium_components": {
            "one": "The enrolment link has been created (%{costs} per enrolment due to 1 premium element)",
            "other": "The enrolment link has been created (%{costs} per enrolment due to %{count} premium elements)"
          },
          "title": "New enrolment link"
        },
        "created": "The registration link has been created",
        "created_with_premium_components": {
          "one": "The registration link has been created (%{costs} per registration due to 1 premium element)",
          "other": "The registration link has been created (%{costs} per registration due to %{count} premium elements)"
        },
        "deactivate": {
          "deactivated": "The enrolment link has been deactivated"
        },
        "destroy": {
          "deleted": "The enrolment link has been deleted",
          "destroy_confirm": "Are you sure you want to delete this enrolment link?"
        },
        "edit": {
          "explanation": "Candidates can use the link above to enrol in the selected process. You can share this link by email or via your website, for example. Please note that anyone can enrol if you make the link public.",
          "link": "Link",
          "title": "Edit enrolment link"
        },
        "form": {
          "help_texts": {
            "advisor": "The coach will coach the candidate in the track after the candidate enrolled themselves.",
            "show_default": "By default, a short text is shown which explains how to enrol. Alternatively, you can provide your own text in the process's description.",
            "url": "Enrolment link",
            "url_explanation": "Candidates can use the link above to enrol in the selected process. You can share this link by email or via your website, for example. Please note that anyone can enrol if you make the link public."
          }
        },
        "index": {
          "actions": "Actions",
          "add": "New enrolment link",
          "coach": "Coach",
          "enrolments": "Enrolments",
          "list_of_enrollers": "Here are all of your organisation's enrolment links",
          "process": "Process",
          "title": "Enrolment links",
          "url": "Enrolment link"
        },
        "new": {
          "title": "New enrolment link"
        },
        "reactivate": {
          "reactivated": "The enrolment link has been reactivated"
        },
        "show": {
          "coach": "Coach",
          "created_at": "Created at",
          "enrolments": "Enrolments",
          "process": "Process",
          "status": "Status",
          "url": "Enrolment link"
        },
        "update": {
          "title": "Edit enrolment link",
          "updated": "The enrolment link has been updated"
        }
      },
      "enrollments": {
        "create": {
          "cannot_enroll": "You cannot assign this process at the moment"
        },
        "destroy": {
          "cannot_destroy": "You cannot remove this, because it's started",
          "enrollment_destroyed": "Enrollment removed"
        },
        "form": {
          "add": "Add contact person"
        },
        "invite": {
          "cannot_invite": "You cannot invite, because enrollment has a different status."
        },
        "new": {
          "contact_persons": "Contactpersons"
        }
      },
      "external_reports": {
        "create": {
          "error": "The report cannot be generated",
          "success": "The report is being generated."
        }
      },
      "integrations": {
        "show": {
          "api_status": {
            "incorrect": "Due to technical problems it's not possible to assign assessments to candidates at the moment. Please contact support if this problem persists.",
            "new": "Currently it's not yet possible to assign assessments to candidates within your organisation. Please contact support to enable this functionality."
          }
        }
      },
      "label_multiples": {
        "create": {
          "send": "Labels were attached to %{number} candidates"
        },
        "index": {
          "title": "Attach labels"
        }
      },
      "labels": {
        "create": {
          "label_created": "Label has been created"
        },
        "destroy": {
          "removed": "Label succesfully removed"
        },
        "edit": {
          "title": "Edit label"
        },
        "form": {
          "new": "New label"
        },
        "index": {
          "advisors": "Advisors",
          "candidates": "Candidates",
          "contact_persons": "Contact people",
          "help_text": "Here are all labels can be used in the application",
          "name": "Name",
          "programs": "Process",
          "title": "Labels",
          "updated_at": "Updated at"
        },
        "new": {
          "title": "New label"
        },
        "update": {
          "label_updated": "Label has been updated"
        }
      },
      "mail_multiples": {
        "create": {
          "send": {
            "one": "Mail sent to %{count} candidate",
            "other": "Mail sent to %{count} candidates",
            "zero": "No email sent, inactive candidates cannot be emailed."
          }
        },
        "index": {
          "title": "Send a mail"
        }
      },
      "multiple_bulk_assignments": {
        "create": {
          "candidates_invited": "The candidates have been notified of the newly assigned processes by email",
          "cannot_enroll": "You cannot assign this process at the moment",
          "processes_created": "The processes have been assigned. The candidates haven't been notified of the newly assigned process yet."
        },
        "new": {
          "title": "Assign processes to candidates: %{names}"
        }
      },
      "multiple_talent_solution_assignments": {
        "create": {
          "cannot_enroll": "Assigned process has already been assigned to all candidates."
        }
      },
      "news": {
        "index": {
          "title": "News"
        },
        "show": {
          "title": "News"
        }
      },
      "norm_groups": {
        "advice": "advice",
        "selection": "selection"
      },
      "organization_tasks": {
        "index": {
          "credits": "Credits",
          "default_report": "Default Report",
          "help_text": "You can set the default report per available assessment below",
          "key": "Key",
          "language": "Language",
          "module": "Module",
          "name": "Name",
          "price": "Price",
          "title": "Default reports"
        },
        "task": {
          "de": "German",
          "en": "English",
          "nl": "Dutch"
        }
      },
      "program_templates": {
        "destroy": {
          "destroyed": "The '%{program_title}' process has been deleted"
        },
        "duplicate": {
          "duplicate_prefix": "Copy:",
          "duplicate_suffix": "[copy]",
          "program_duplicated": "The '%{program_title}' process has been copied",
          "template_duplicated": "The '%{program_title}' process has been copied to your organisation's own processes"
        },
        "index": {
          "actions": "Actions",
          "assigned": "Assigned to",
          "date": "Updated at",
          "labels": "Label",
          "length": "Length",
          "list": "List of designed organisation template processes",
          "program_title": "Title",
          "status": "Status",
          "title": "Process templates",
          "type": "Type"
        },
        "new": "Add process",
        "program": {
          "candidates": "Candidates",
          "delete_confirm": "Are you sure you want to delete this process?",
          "steps": "Steps",
          "type": {
            "program": "Own process",
            "program_template": "Template"
          }
        },
        "publish": {
          "all_pages_invisible": "You cannot publish this process because none of its steps are set as visible to the candidate",
          "published": "The '%{program_title}' process has been published"
        },
        "publish_as_template": {
          "published_as_template": "The process '%{program_title}' has been published as a template"
        },
        "update": {
          "update_failed": "The process could not be updated",
          "updated": "The process has been updated"
        },
        "withdraw": {
          "withdrawn": "The '%{program_title}' process has been withdrawn"
        }
      },
      "programs": {
        "destroy": {
          "destroyed": "The '%{program_title}' process has been deleted"
        },
        "duplicate": {
          "duplicate_prefix": "Copy:",
          "duplicate_suffix": "[copy]",
          "program_duplicated": "The '%{program_title}' process has been copied",
          "template_duplicated": "The '%{program_title}' process has been copied to your organisation's own processes"
        },
        "index": {
          "actions": "Actions",
          "assigned": "Assigned to",
          "date": "Updated at",
          "labels": "Label",
          "length": "Length",
          "list": "Design, edit or view your organisation's processes. Processes are available for advisors with the coach role and premium candidates.",
          "program_title": "Title",
          "status": "Status",
          "title": "Process development",
          "type": "Type"
        },
        "new": "Add process",
        "program": {
          "candidates": "Candidates",
          "delete_confirm": "Are you sure you want to delete this process?",
          "steps": "Steps",
          "type": {
            "program": "Own process",
            "program_template": "Template"
          }
        },
        "publish": {
          "all_pages_invisible": "You cannot publish this process because none of its steps are set as visible to the candidate",
          "published": "The '%{program_title}' process has been published"
        },
        "publish_as_template": {
          "published_as_template": "The process '%{program_title}' has been published as a template"
        },
        "update": {
          "update_failed": "The process could not be updated",
          "updated": "The process has been updated"
        },
        "withdraw": {
          "withdrawn": "The '%{program_title}' process has been withdrawn"
        }
      },
      "rooms": {
        "add": "Add video call",
        "create": {
          "created": "The video call has been created",
          "title": "Create a new organisation video call"
        },
        "destroy": {
          "deleted": "The video call has been deleted"
        },
        "edit": {
          "title": "Edit video call"
        },
        "form": {
          "help_texts": {
            "end_to_end_encryption": "This extra layer of protection only works on newer browsers with support for Insertable Streams. This prevents joining from most browsers."
          }
        },
        "index": {
          "actions": "Actions",
          "help_text": "Here are all of your organisation's video calls",
          "participants": "Candidates",
          "subject": "Subject",
          "title": "Video calls",
          "updated_at": "Last activity"
        },
        "new": {
          "title": "Create a new organisation video call"
        },
        "room": {
          "participants_present": "%{number} present"
        },
        "update": {
          "title": "Edit video call",
          "updated": "The video call has been updated"
        }
      },
      "shared": {
        "assessment_program_card": {
          "assessment_program_subtasks": "This Assessment Porgram combines the %{subtasks} Assessments."
        },
        "candidate_form": {
          "add_contact_person": "Add Contact Person",
          "add_label": "Add label",
          "help_texts": {
            "advisor": "The advisor can assign tracks to a candidate and coach the candidate within a track.",
            "coadvisor": "The co-advisor is an additional advisor who can view the candidate's tracks.",
            "contact_person": "A contact person is a stakeholder in a track of a candidate. They can view the progress a candidate makes, share documents with the candidate and their advisors and can be requested to approve documents.",
            "intro": {
              "text": "Choose 'Save', and the candidate will not be invited to work on the platform yet. No email will be sent. Choose 'Next', and you can invite the candidate to create an account and complete an assessment.",
              "title": "Add Candidate"
            },
            "outro": {
              "text": "Choose 'Save' to invite a candidate later; the candidate will not receive an email yet. Choose 'Next' to invite a candidate immediately and to assign an assessment right away.",
              "title": "Assign Assessment"
            },
            "report_objective": "When you are assessing candidates to advice them in their development, choose 'Advice'; when you are assessing candidates to select among them, choose 'selection'. Based on this objective we'll automatically select the most relevant report for this candidate."
          },
          "primary_title": "Primary Information"
        },
        "mail_and_settings_form": {
          "candidate_without_account": "There is no email address known in the system for this candidate. As a result, the candidate can only access the assigned assessments via a one-time passwordless login link. Add an email address if the candidate needs more than one-time access.",
          "delivery_date": "Delivery date",
          "invitation_mail_title": "Set invitation mail",
          "reminder_mail": "Reminder mail",
          "reminder_mail_title": "Set reminder mail",
          "report_settings_explanation": "When a candidate finishes an assessment, a report is automatically generated. Based on your organisation's settings and the reporting objective of your candidate we've already selected a suitable report for you.",
          "report_settings_title": "Report settings"
        },
        "selected_candidates": "Selected candidates"
      },
      "stitched_reports": {
        "create": {
          "success": "The stitched report is being generated. You'll receive a notification when the report is ready in the Documents and Media library."
        },
        "new": {
          "stitch_button": "Stitch",
          "title": "Stitch reports for %{candidate}"
        }
      },
      "whitelabel": {
        "edit": {
          "title": "White label settings"
        },
        "update": {
          "whitelabel_updated": "The Whitelabel has been updated"
        }
      }
    },
    "authentication_actions": {
      "authenticate": "%{actor} has successfully logged in.",
      "impersonate": "%{actor} logged in as %{target}",
      "unknown": "Unknown action: %{action}"
    },
    "boolean": {
      "yes": "Yes"
    },
    "builder": {
      "component": {
        "component_delete_confirm": "Are you sure that you want to delete this element?",
        "settings": "Element settings"
      },
      "elements": {
        "advisor_introduction": "Coach introduction",
        "advisor_introduction_tooltip": "The coach can use this to introduce themselves to the candidate",
        "amc": "AMC",
        "amc_tooltip": "With the AMC, the advisor can map out the limitations of the candidate and orient on career opportunities",
        "appointment": "Meeting scheduler",
        "appointment_tooltip": "The coach and the candidate can use this to arrange a (physical) meeting",
        "blog": "Blog",
        "blog_tooltip": "Blog with all posts that are shared with the candidate",
        "bov": "BOV (Dutch)",
        "bov_tooltip": "The BOV allows the candidate to search through a database of professions",
        "checkboxes": "Checkboxes",
        "checkboxes_tooltip": "Number of options from which to select multiple",
        "consent": "Consent",
        "consent_tooltip": "A yes-no question that the candidate must answer to continue",
        "custom_html": "Custom HTML",
        "custom_html_tooltip": "Insert custom HTML code",
        "date": "Date input",
        "date_tooltip": "An input field for a date",
        "document_approval": "Document approval",
        "document_approval_tooltip": "The coach can use this to upload a file for the candidate or contact person to approve",
        "document_download": "Document download",
        "document_download_tooltip": "The coach can use this to upload a file for the candidate to download",
        "document_upload": "Document upload",
        "document_upload_tooltip": "The candidate can use this to upload a file for the coach to download",
        "docx_template": "CV & letter builder (Word)",
        "docx_template_tooltip": "This can be used to upload a DOCX document template, which the candidate can fill with personal information",
        "fit": "FIT (Dutch)",
        "fit_tooltip": "With the FIT, the candidate can map out his limitations and orient himself on career opportunities",
        "image": "Image",
        "image_tooltip": "An image to view",
        "input_text": "Text input (short)",
        "input_text_tooltip": "An input field of one line, to be filled in by the candidate",
        "ixly_test": "Ixly assessment",
        "ixly_test_tooltip": "The candidate can use this to fill in a test or questionnaire and download a report afterwards",
        "job_market_scan": "Job market Opportunities (Dutch)",
        "job_market_scan_en": "Job market Opportunities",
        "job_market_scan_en_tooltip": "With Job market Opportunities the candidate can calculate their position in the Dutch job market",
        "job_market_scan_tooltip": "With Job market Opportunities the candidate can calculate their position in the Dutch job market",
        "job_suggestions": "Job Suggestions (Dutch)",
        "job_suggestions_plus": "Job Suggestions Plus (Dutch)",
        "job_suggestions_plus_tooltip": "With Job Suggestions Plus, the candidate can discover suitable jobs, plus related training and vacancies",
        "job_suggestions_tooltip": "With Job Suggestions, the candidate can discover suitable jobs",
        "jobfeed": "Jobfeed (Dutch)",
        "jobfeed_tooltip": "With Jobfeed the candidate can search through all vacancies in the Netherlands",
        "logbook": "Logbook",
        "logbook_tooltip": "The candidate can use this to record notes",
        "no_premium_components_available_yet": "(no premium elements are available at the moment)",
        "pdf_template": "CV & letter builder",
        "pdf_template_tooltip": "This can be used to compose templates for PDF documents, which the candidate can fill with personal information",
        "premium": "Premium",
        "premium_costs_are_per_assignment": "(the prices above are per assignment of the process and excluding %{vat}% VAT)",
        "quiz": "Quiz",
        "quiz_tooltip": "The candidate can use this to answer questions (open-ended or multiple choice) and check their answers afterwards",
        "radio_buttons": "Multiple choice",
        "radio_buttons_tooltip": "Number of options from which to select one (radio buttons)",
        "search_job": "Indeed job search",
        "search_job_tooltip": "Integrated job search using the Indeed database",
        "selectbox": "Selection",
        "selectbox_tooltip": "Number of options from which to select one (drop-down)",
        "standard": "Standard",
        "text": "Text",
        "text_tooltip": "Text to be read",
        "textarea": "Text input (long)",
        "textarea_tooltip": "An input field of multiple lines, to be filled in by the candidate",
        "title": "Elements",
        "vacancy": "Vacancy manager",
        "vacancy_tooltip": "The candidate can use this to log details of vacancies they are interested in",
        "youtube_video": "YouTube video",
        "youtube_video_tooltip": "A YouTube video to watch"
      },
      "menu_item_page": {
        "page_delete_confirm": "Are you sure you want to delete this step, including all of its elements?",
        "settings": "Step settings"
      },
      "sidebar": {
        "new_page": "New step",
        "settings": "Process settings"
      },
      "top_steps": {
        "placeholder": "Step group name..."
      }
    },
    "button": {
      "add": "Add",
      "add_migration_organization": "Migrate TTK organisation",
      "back": "Back",
      "cancel": "Cancel",
      "click_to_close_modal": "(click here to close this window)",
      "close": "Close",
      "complete_setup": "Send invitation",
      "copy": "Copy",
      "copy_token_authentication_link": "Copy single use passwordless login link",
      "deactivate": "Deactivate",
      "delete": "Delete",
      "download_csv": "Download as CSV",
      "duplicate": "Duplicate",
      "edit": "Edit",
      "export": "Export",
      "filter": "Apply",
      "inspect": "Inspect",
      "invite": "Invite",
      "join": "Join",
      "mark_all_as_read": "Mark as Read",
      "mark_as_template": "Mark as application template",
      "next": "Next",
      "publish": "Publish",
      "publish_as_template": "Publish as template",
      "reactivate": "Reactivate",
      "refresh": "Refresh",
      "reset": "Reset",
      "reset_password": "Send reset password mail",
      "select": "Select",
      "send": "Send",
      "skip": "Skip",
      "start": "Start",
      "test_results": "Results",
      "unmark_as_template": "Unmark as application template",
      "verify": "Verify",
      "view": "View",
      "withdraw": "Withdraw"
    },
    "candidate": {
      "assessments": {
        "advisor_action": "The advisor must take action regarding this assessment",
        "finished_at": "Finished at",
        "started_at": "Started at"
      },
      "assignments": {
        "active_count": "%{number} started assignments(s)",
        "finished_count": "%{number} finished assignment(s)",
        "not_started_count": "%{number} not started assignment(s)"
      },
      "created": {
        "empty_list": "There are no new tasks assigned"
      },
      "enrollment": {
        "deadline": "This track must be completed no later than %{date}.",
        "download_alt_text": "Download report",
        "enrolment_link_alt_text": "Open %{title}",
        "enrolment_link_disabled_alt_text": "%{title} not available - deadline passed",
        "finished_at": "Finished at",
        "invite_alt_text": "Invite candidate for %{title}",
        "invited_at": "The candidate will be invited on %{date}.",
        "not_invited": "The candidate hasn't been invited yet.",
        "not_started": "Not yet started",
        "reminder_date": "Reminder date: %{date}",
        "started_at": "Started at"
      },
      "enrollments": {
        "finished": "finished",
        "not_started": "not started",
        "started": "started"
      },
      "finished": {
        "empty_list": "There are no finished tasks yet"
      },
      "started": {
        "empty_list": "There are no started tasks"
      },
      "title": "My tracks",
      "unknown_initials": "unk."
    },
    "candidates": {
      "advisors": {
        "show": {
          "coach_role": "Coach",
          "email": "Email",
          "role": "Role"
        }
      },
      "confirmations": {
        "advisors": {
          "show": {
            "coach_role": "Coach",
            "email": "E-mail",
            "role": "Role"
          }
        },
        "contact_people": {
          "show": {
            "client": "Client",
            "contact_person_role": "Contact person",
            "email": "E-mail",
            "role": "Role"
          }
        },
        "show": {
          "title": "Create an account"
        }
      },
      "contact_people": {
        "show": {
          "client": "Client",
          "contact_person_role": "Contact person",
          "email": "Email",
          "role": "Role"
        }
      },
      "external_reports": {
        "create": {
          "error": "The report cannot be generated",
          "success": "The report is being generated."
        }
      },
      "results": {
        "index": {
          "title": "Personal results overview"
        }
      },
      "send_mail": {
        "blank_email": "Email can't be sent, the candidate doesn't have an email address",
        "inactive_candidate": "Email can't be sent, the candidate is inactive",
        "message": "Send template email",
        "resend_welcome": "Resend welcome email",
        "send_welcome": "Send welcome email"
      },
      "token_authentications": {
        "new": {
          "invalid": "The passwordless login link is not valid anymore"
        },
        "update": {
          "copied": "The single use passwordless login link was copied!"
        }
      }
    },
    "categories": {
      "adaptive": "Adaptive test",
      "all": "All categories",
      "e_assessment": "E-Assessment",
      "game": "Psychometric game",
      "intelligence": "Intelligence",
      "personality": "Personality",
      "sample_tests": "Sample Tests",
      "simulation": "Simulation"
    },
    "clients": {
      "create": {
        "client_created": "The client has been created",
        "help_text": "Create a new organisation client",
        "title": "New client"
      },
      "edit": {
        "title": "Edit client"
      },
      "form": {
        "labels": "Labels"
      },
      "index": {
        "help_text": "Here are all of your organisation's clients (companies)",
        "new_button": "New client",
        "table_header": [
          "Name",
          "Contact persons",
          "Candidates"
        ],
        "title": "Clients"
      },
      "new": {
        "help_text": "Create a new organisation client",
        "title": "New client"
      },
      "row": {
        "number_of_candidates": "%{number} active",
        "number_of_contact_persons": "%{number} active"
      },
      "show": {
        "help_text": "Here are all of your contact persons and candidates from this client",
        "new_button": "New contact person",
        "table_header": [
          "Details",
          "Email address",
          "Status"
        ]
      },
      "update": {
        "client_updated": "The client has been updated",
        "title": "Edit client"
      }
    },
    "comments": {
      "index": {
        "title": "Message history"
      }
    },
    "common": {
      "by": "by",
      "copy_to_clipboard": "Copy to clipboard"
    },
    "compare_and_select": {
      "competencies_rnd_service": {
        "aansturenvangroepen": "group_leadership",
        "aansturenvanindividuen": "individual_leadership",
        "ambitie": "ambition",
        "assertiviteit": "assertiveness",
        "besluitvaardigheid": "decisiveness",
        "coachenvanmedewerkers": "employee_coaching",
        "delegeren": "delegation",
        "doorzettingsvermogen": "perseverance",
        "flexibiliteit": "flexibility",
        "initiatief": "initiative",
        "inzet": "commitment",
        "klantorientatie": "customer_orientation",
        "kwaliteitsgerichtheid": "quality_focus",
        "leerbereidheid": "willingness_to_learn",
        "nauwkeurigheid": "accuracy",
        "onderhandelen": "negotiation",
        "ondernemerschap": "entrepreneurship",
        "optreden": "performance",
        "organisatiesensitiviteit": "organizational_sensitivity",
        "overtuigingskracht": "persuasiveness",
        "plannenenorganiseren": "planning_organizing",
        "relatiebeheer": "relationship_management",
        "resultaatgerichtheid": "results_orientation",
        "samenwerking": "collaboration",
        "sensitiviteit": "sensitivity",
        "sociabiliteit": "sociability",
        "stressbestendigheid": "stress_resistance",
        "voortgangscontrole": "progress_monitoring",
        "zelfstandigheid": "independence"
      }
    },
    "competencies": {
      "accuracy": "Accuracy",
      "ambition": "Ambition",
      "assertiveness": "Assertiveness",
      "collaboration": "Collaboration",
      "commitment": "Commitment",
      "customer_orientation": "Customer Orientation",
      "decisiveness": "Decisiveness",
      "delegation": "Delegation",
      "employee_coaching": "Employee Coaching",
      "entrepreneurship": "Entrepreneurship",
      "flexibility": "Flexibility",
      "group_leadership": "Group Leadership",
      "independence": "Independence",
      "individual_leadership": "Individual Leadership",
      "initiative": "Initiative",
      "negotiation": "Negotiation",
      "organizational_sensitivity": "Organizational Sensitivity",
      "performance": "Performance",
      "perseverance": "Perseverance",
      "persuasiveness": "Persuasiveness",
      "planning_organizing": "Planning & Organizing",
      "progress_monitoring": "Progress Monitoring",
      "quality_focus": "Quality Focus",
      "relationship_management": "Relationship Management",
      "results_orientation": "Results Orientation",
      "sensitivity": "Sensitivity",
      "sociability": "Sociability",
      "stress_resistance": "Stress Resistance",
      "willingness_to_learn": "Willingness to Learn"
    },
    "components": {
      "iframe": {
        "back": "Back to process"
      },
      "report_visible_for_explanation": "The report can still be made visible to their partner afterwards.",
      "update": {
        "error": "The element could not be saved because:"
      }
    },
    "consents": {
      "cannot_share": "Unable to share the report.",
      "confirm_retract": "Are you sure that you want to withdraw consent for sharing your results?",
      "consent_retracted": "Consent withdrawn",
      "retract_consent": "Withdraw consent",
      "share_consent": "Share results",
      "shared_successfully": "Report shared successfully."
    },
    "contact_people": {
      "enrollments": {
        "list": {
          "advisor": "Coach",
          "candidate": "Candidate",
          "coadvisor": "Co-coach",
          "contact_person": "Contact person",
          "pages": "Pages",
          "status": "Status"
        }
      }
    },
    "current": "Current",
    "datatables": {
      "filtered_from_after_count": "results",
      "filtered_from_before_count": "filtered from",
      "loading": "Loading...",
      "next": "Next",
      "previous": "Previous",
      "showing_page_after_count": "of",
      "showing_page_before_count": "Page",
      "zero_records": "No results found"
    },
    "date": {
      "abbr_day_names": [
        "Sun",
        "Mon",
        "Tue",
        "Wed",
        "Thu",
        "Fri",
        "Sat"
      ],
      "abbr_month_names": [
        null,
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec"
      ],
      "day_names": [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday"
      ],
      "formats": {
        "date_with_day": "%A, %e %B %Y",
        "date_without_day": "%e %B %Y",
        "default": "%Y-%m-%d",
        "long": "%B %d, %Y",
        "short": "%b %d"
      },
      "month_names": [
        null,
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      ],
      "order": [
        "year",
        "month",
        "day"
      ]
    },
    "datetime": {
      "distance_in_words": {
        "about_x_hours": {
          "one": "about %{count} hour",
          "other": "about %{count} hours"
        },
        "about_x_months": {
          "one": "about %{count} month",
          "other": "about %{count} months"
        },
        "about_x_years": {
          "one": "about %{count} year",
          "other": "about %{count} years"
        },
        "almost_x_years": {
          "one": "almost %{count} year",
          "other": "almost %{count} years"
        },
        "half_a_minute": "half a minute",
        "less_than_x_minutes": {
          "one": "less than a minute",
          "other": "less than %{count} minutes"
        },
        "less_than_x_seconds": {
          "one": "less than %{count} second",
          "other": "less than %{count} seconds"
        },
        "over_x_years": {
          "one": "over %{count} year",
          "other": "over %{count} years"
        },
        "x_days": {
          "one": "%{count} day",
          "other": "%{count} days"
        },
        "x_minutes": {
          "one": "%{count} minute",
          "other": "%{count} minutes"
        },
        "x_months": {
          "one": "%{count} month",
          "other": "%{count} months"
        },
        "x_seconds": {
          "one": "%{count} second",
          "other": "%{count} seconds"
        },
        "x_years": {
          "one": "%{count} year",
          "other": "%{count} years"
        }
      },
      "prompts": {
        "day": "Day",
        "days": "days",
        "hour": "Hour",
        "minute": "Minute",
        "month": "month",
        "months": "months",
        "second": "Second",
        "year": "year",
        "years": "years"
      }
    },
    "delimit_options_by_commas": "Use a comma to separate different options (e.g. apple, pear, banana).",
    "delimit_sections_by_commas": "Use a comma to separate different sections.",
    "devise": {
      "confirmations": {
        "confirmed": "Your email address has been successfully confirmed.",
        "new": {
          "resend_confirmation_instructions": "Resend confirmation instructions"
        },
        "send_instructions": "You will receive an email with instructions for how to confirm your email address in a few minutes.",
        "send_paranoid_instructions": "If your email address exists in our database, you will receive an email with instructions for how to confirm your email address in a few minutes."
      },
      "failure": {
        "already_authenticated": "You are already signed in.",
        "inactive": "Your account is not activated yet.",
        "invalid": "No account was found with the email and password combination you provided",
        "last_attempt": "You have one more attempt before your account is locked.",
        "locked": "Your account is locked.",
        "not_found_in_database": "No account was found with the email and password combination you provided",
        "organization_inactive": "Unable to login, the organisation you belong to is not active",
        "timeout": "Your session expired. Please sign in again to continue.",
        "unauthenticated": "You need to sign in before continuing",
        "unconfirmed": "You have to confirm your email address before continuing.",
        "user_status_created": "Your account has not been activated yet",
        "user_status_inactive": "Your account has not been activated",
        "user_status_invited": "Unable to login, please follow the intructions you received via email"
      },
      "mailer": {
        "confirmation_instructions": {
          "action": "Confirm my account",
          "greeting": "Welcome %{recipient}!",
          "instruction": "You can confirm your account email through the link below:",
          "subject": "Confirmation instructions"
        },
        "email_changed": {
          "greeting": "Hello %{recipient}!",
          "message": "We're contacting you to notify you that your email has been changed to %{email}.",
          "message_unconfirmed": "We're contacting you to notify you that your email is being changed to %{email}.",
          "subject": "Email Changed"
        },
        "password_change": {
          "greeting": "Hello %{recipient}!",
          "message": "We're contacting you to notify you that your password has been changed.",
          "subject": "Password Changed"
        },
        "reset_password_instructions": {
          "action": "Change my password",
          "greeting": "Hello %{recipient}!",
          "instruction": "Someone has requested a link to change your password. You can do this through the link below.",
          "instruction_2": "If you didn't request this, please ignore this email.",
          "instruction_3": "Your password won't change until you access the link above and create a new one.",
          "subject": "Reset password instructions"
        },
        "unlock_instructions": {
          "action": "Unlock my account",
          "greeting": "Hello %{recipient}!",
          "instruction": "Click the link below to unlock your account:",
          "message": "Your account has been locked due to an excessive number of unsuccessful sign in attempts.",
          "subject": "Unlock instructions"
        }
      },
      "omniauth_callbacks": {
        "failure": "Could not authenticate you from %{kind} because \"%{reason}\".",
        "success": "Successfully authenticated from %{kind} account."
      },
      "passwords": {
        "edit": {
          "change_my_password": "Change my password",
          "change_your_password": "Change your password",
          "confirm_new_password": "Confirm new password",
          "new_password": "New password"
        },
        "new": {
          "forgot_your_password": "Forgot your password?",
          "send_me_reset_password_instructions": "Send me reset password instructions"
        },
        "no_token": "You can't access this page without coming from a password reset email. If you do come from a password reset email, please make sure you used the full URL provided.",
        "send_instructions": "You will receive an email with instructions on how to reset your password in a few minutes.",
        "send_paranoid_instructions": "If your email address exists in our database, you will receive a password recovery link at your email address in a few minutes.",
        "updated": "Your password has been changed successfully. You are now signed in.",
        "updated_not_active": "Your password has been changed successfully."
      },
      "registrations": {
        "destroyed": "Bye! Your account has been successfully cancelled. We hope to see you again soon.",
        "edit": {
          "are_you_sure": "Are you sure?",
          "cancel_my_account": "Cancel my account",
          "currently_waiting_confirmation_for_email": "Currently waiting confirmation for: %{email}",
          "leave_blank_if_you_don_t_want_to_change_it": "leave blank if you don't want to change it",
          "title": "Edit %{resource}",
          "unhappy": "Unhappy?",
          "update": "Update",
          "we_need_your_current_password_to_confirm_your_changes": "we need your current password to confirm your changes"
        },
        "new": {
          "sign_up": "Sign up"
        },
        "signed_up": "Welcome! You have signed up successfully.",
        "signed_up_but_inactive": "You have signed up successfully. However, we could not sign you in because your account is not yet activated.",
        "signed_up_but_locked": "You have signed up successfully. However, we could not sign you in because your account is locked.",
        "signed_up_but_unconfirmed": "A message with a confirmation link has been sent to your email address. Please follow the link to activate your account.",
        "update_needs_confirmation": "You updated your account successfully, but we need to verify your new email address. Please check your email and follow the confirmation link to confirm your new email address.",
        "updated": "Your account has been updated successfully.",
        "updated_but_not_signed_in": "Your account has been updated successfully, but since your password was changed, you need to sign in again."
      },
      "sessions": {
        "already_signed_out": "Signed out successfully.",
        "new": {
          "sign_in": "Log in"
        },
        "signed_in": "Signed in successfully.",
        "signed_out": "Signed out successfully."
      },
      "shared": {
        "links": {
          "back": "Back",
          "didn_t_receive_confirmation_instructions": "Didn't receive confirmation instructions?",
          "didn_t_receive_unlock_instructions": "Didn't receive unlock instructions?",
          "forgot_your_password": "Forgot your password?",
          "sign_in": "Log in",
          "sign_in_with_provider": "Sign in with %{provider}",
          "sign_up": "Sign up"
        },
        "minimum_password_length": {
          "one": "(%{count} character minimum)",
          "other": "(%{count} characters minimum)"
        }
      },
      "sign_out": "Sign out",
      "two_factor_authentication": {
        "attempt_failed": "Incorrect code.",
        "code_has_been_sent": "Your two-step verification code has been sent.",
        "contact_administrator": "Contact an organisation administrator.",
        "max_login_attempts_reached": "Access completely denied because you've reached the maximum number of attempts",
        "success": "Two factor authentication passed."
      },
      "unlocks": {
        "new": {
          "resend_unlock_instructions": "Resend unlock instructions"
        },
        "send_instructions": "You will receive an email with instructions for how to unlock your account in a few minutes.",
        "send_paranoid_instructions": "If your account exists, you will receive an email with instructions for how to unlock it in a few minutes.",
        "unlocked": "Your account has been unlocked successfully. Please sign in to continue."
      }
    },
    "discounts": {
      "create": {
        "created": "The discounts have been created"
      },
      "destroy": {
        "already_started": "This discount has already started."
      },
      "index": {
        "new_button": "New discounts",
        "table_header": [
          "On",
          "Percentage",
          "Start date",
          "End date",
          "Actions"
        ],
        "title": "Discounts"
      },
      "new": {
        "premium_components": "Premium components",
        "subscriptions": "Subscriptions",
        "title": "New discounts for %{organization}"
      },
      "row": {
        "discount_delete": "Are you sure you want to delete this discount?"
      }
    },
    "education_levels": {
      "academic": "University (WO/Master)",
      "higher_vocational": "University of Applied Sciences (HBO)",
      "pre_vocational": "Pre-Vocational Education (VMBO)",
      "secondary_vocational": "Vocational Education and Training (MBO)"
    },
    "education_levels_rnd": {
      "academic": "wo",
      "higher_vocational": "hbo",
      "pre_vocational": "vmbo",
      "secondary_vocational": "mbo"
    },
    "education_levels_rnd_keys": {
      "hbo": "higher_vocational",
      "mbo": "secondary_vocational",
      "vmbo": "pre_vocational",
      "wo": "academic"
    },
    "enrollers": {
      "enroll": {
        "cannot_enroll": "You cannot enrol in this process at the moment",
        "enrolled": "Your are now participating in this process"
      },
      "enroll_existing": {
        "cannot_enroll": "You cannot enrol in this process at the moment",
        "enrolled": "Your are now participating in this process",
        "enrolling_user_is_a_contact_person": "As a contact person you are unable to participate in processes yourself",
        "enrolling_user_is_enrollers_advisor": "As the enrolment link's coach you are unable to participate in this process yourself"
      },
      "enroll_test": {
        "cannot_enroll": "You cannot enrol in this process at the moment"
      },
      "form": {
        "accept": "I agree to",
        "and": "and",
        "participate": "Participate",
        "privacy_label": "the <a class='btn-link' href='%{privacy_link}' target='_blank'>privacy statement</a>",
        "terms_label": "the <a class='btn-link' href='%{terms_link}' target='_blank'>terms and conditions</a>"
      },
      "show": {
        "deactivated": "This enrolment link is no longer available",
        "sign_in_or_sign_up": "If you already have a Assessment Platform account you need to <a class='btn-link' href='%{login_link}'>log in</a> to participate in this process. If you do not have an account yet you can create one using the form below."
      },
      "signed_in_form": {
        "already_enrolled": "You are already participating in this process, continue from <a class='alert-link' href='%{link}'>%{text}</a>",
        "participate": "Participate",
        "participate_as": "Using the button below you can participate in this process directly."
      },
      "test_only_form": {
        "accept": "I agree to",
        "and": "and",
        "participate": "Participate",
        "privacy_label": "the <a class='btn-link' href='%{privacy_link}' target='_blank'>privacy statement</a>",
        "terms_label": "the <a class='btn-link' href='%{terms_link}' target='_blank'>terms and conditions</a>"
      },
      "tests_assigned": {
        "email_sent": "Thank you for your participation. You will receive an email from no-reply@test-toolkit.nl with instructions on how to begin."
      }
    },
    "enrollment_pages": {
      "control_panel": {
        "accept_page": "Approve this step",
        "back_to_tracks_as_advisor": "Back to my candidates",
        "back_to_tracks_as_candidate": "Back to my tracks",
        "comments": "Messages",
        "finish_enrollment": "Finish process",
        "finish_enrollment_confirm": "Are you sure you want to finish the process? A finished process can no longer be modified by the candidate or coach.",
        "finish_page": "Finish this step",
        "finish_page_and_next": "Finish and next",
        "next_page": "Next step",
        "next_page_unavailable": "Next step not yet available...",
        "reject_page": "Reject this step",
        "reopen_enrollment": "Reopen process",
        "reopen_enrollment_confirm": "Are you sure you want to reopen the process? The candidate will be able to make changes again.",
        "submit_page": "Submit this step",
        "submit_page_and_next": "Submit and next"
      },
      "insert_optional": {
        "cannot_enroll": "The page could not be inserted as it contains an assessment which is not available in your subscription.",
        "page_inserted": "The '%{page_title}' step has been inserted"
      },
      "show": {
        "insert": "Insert",
        "is_optional": "This step is currently not a part of this process, but it can still be inserted if required",
        "original_page_deleted": "This optional step can no longer be inserted, because the original has been deleted",
        "page_insert_confirm_part_1": "Insertion is irreversible",
        "page_insert_confirm_part_2": "Are you sure you want to insert this step at the designated spot in the process?",
        "with_premium_components": {
          "one": "and costs %{costs} due to 1 premium element",
          "other": "and costs %{costs} due to %{count} premium elements"
        }
      }
    },
    "enrollments": {
      "confirm_withdraw": "Are you sure you want to withdraw this process?",
      "create": {
        "candidate_double_invited": "The candidate has been invited and notified of the newly assigned process by email",
        "candidate_invited": "The candidate has been notified of the newly assigned process by email",
        "cannot_enroll": "You cannot assign this process at the moment",
        "enrollment_created": "The process has been assigned. The candidate hasn't been notified of the newly assigned process yet."
      },
      "destroy": {
        "candidate_enrollment_already_started": "This process cannot be withdrawn as it has already been started",
        "candidate_enrollment_destroyed": "The process has been withdrawn"
      },
      "finish": {
        "enrollment_finished": "The process has been finished",
        "finish_enrollment_failed": "This process cannot be finished as it's not yet started or already finished"
      },
      "navbar_page": {
        "not_available_yet": "Available after finishing preceding steps"
      },
      "new": {
        "help_text": "Select one of your published processes to assign to the candidate",
        "table_header": [
          "Process",
          "Actions"
        ],
        "title": "Process assignment"
      },
      "progress": {
        "done": "finished",
        "rejected": "again",
        "submitted": "submitted"
      },
      "reopen": {
        "enrollment_reopened": "The process has been reopened",
        "reopen_enrollment_failed": "This process cannot be reopened as it's not yet finished"
      },
      "row": {
        "add": "Assign",
        "enrollment_create_confirm": {
          "one": "The selected process contains 1 premium element, which costs %{costs}. Are you sure you want to assign it?",
          "other": "The selected process contains %{count} premium elements, with an aggregate cost of %{costs}. Are you sure you want to assign it?"
        },
        "invite_later": "Invite later",
        "view": "View"
      }
    },
    "errors": {
      "format": "%{attribute} %{message}",
      "messages": {
        "accepted": "must be accepted",
        "advisor_cannot_be_coadvisor": "It is not possible to assign selected advisor as a co-advisor",
        "already_confirmed": "was already confirmed, please try signing in",
        "already_generated": "is already generated",
        "already_passed": "has already passed",
        "aspect_ratio_is_not": "must have an aspect ratio of %{aspect_ratio}",
        "aspect_ratio_not_landscape": "must be a landscape image",
        "aspect_ratio_not_portrait": "must be a portrait image",
        "aspect_ratio_not_square": "must be a square image",
        "aspect_ratio_unknown": "has an unknown aspect ratio",
        "assessment_platform_link_or_passwordless_login_link_missing": "is invalid because must contain either an assessment platform link or a passwordless login link",
        "attachment_size": "Should be less than 50MB",
        "before_start_date": "must be after the start date",
        "blank": "can't be blank",
        "candidate_limit_reached": "Your account cannot be activated because the maximum number of active candidates has been reached",
        "cannot_be_sub_organization_of_itself": "An organisation cannot be its own suborganisation",
        "cannot_downgrade_before_year": "must be at least a year after the start date of the previous subscription",
        "carrierwave_download_error": "could not be downloaded",
        "carrierwave_integrity_error": "is not of an allowed file type",
        "carrierwave_processing_error": "failed to be processed",
        "confirmation": "does not match %{attribute}",
        "confirmation_period_expired": "needs to be confirmed within %{period}, please request a new one",
        "contains_unpermitted_variables": "is invalid because contains unpermitted variables",
        "content_type_allowlist_error": "You are not allowed to upload %{content_type} files, allowed types: %{allowed_types}",
        "content_type_blacklist_error": "SVG images are not supported",
        "content_type_denylist_error": "You are not allowed to upload %{content_type} files",
        "content_type_invalid": "has an invalid content type",
        "content_type_is_invalid": "Only accept csv as content type",
        "content_type_whitelist_error": "The uploaded file is not an image",
        "create_otp_organization_failed": "A problem occurred when creating the organisation in the Test-Toolkit",
        "current_included": "cannot include itself",
        "destroy_otp_organization_failed": "A problem occurred when deleting the organisation in the Test-Toolkit",
        "dimension_height_equal_to": "height must be equal to %{length} pixel",
        "dimension_height_greater_than_or_equal_to": "height must be greater than or equal to %{length} pixel",
        "dimension_height_inclusion": "height is not included between %{min} and %{max} pixel",
        "dimension_height_less_than_or_equal_to": "height must be less than or equal to %{length} pixel",
        "dimension_max_inclusion": "must be less than or equal to %{width} x %{height} pixel",
        "dimension_min_inclusion": "must be greater than or equal to %{width} x %{height} pixel",
        "dimension_width_equal_to": "width must be equal to %{length} pixel",
        "dimension_width_greater_than_or_equal_to": "width must be greater than or equal to %{length} pixel",
        "dimension_width_inclusion": "width is not included between %{min} and %{max} pixel",
        "dimension_width_less_than_or_equal_to": "width must be less than or equal to %{length} pixel",
        "email_invalid": "must match the following format: name@domain.com",
        "empty": "can't be empty",
        "equal_to": "must be equal to %{count}",
        "even": "must be even",
        "exclusion": "is reserved",
        "expired": "has expired, please request a new one",
        "extension_allowlist_error": "You are not allowed to upload %{extension} files, allowed types: %{allowed_types}",
        "extension_denylist_error": "You are not allowed to upload %{extension} files, prohibited types: %{prohibited_types}",
        "file_size_not_between": "file size must be between %{min_size} and %{max_size} (current size is %{file_size})",
        "file_size_not_greater_than": "file size must be greater than %{min_size} (current size is %{file_size})",
        "file_size_not_greater_than_or_equal_to": "file size must be greater than or equal to %{min_size} (current size is %{file_size})",
        "file_size_not_less_than": "file size must be less than %{max_size} (current size is %{file_size})",
        "file_size_not_less_than_or_equal_to": "file size must be less than or equal to %{max_size} (current size is %{file_size})",
        "free_included": "cannot include a free subscription",
        "greater_than": "must be greater than %{count}",
        "greater_than_or_equal_to": "must be greater than or equal to %{count}",
        "image_metadata_missing": "is not a valid image",
        "image_not_processable": "is not a valid image",
        "in": "must be in %{count}",
        "inclusion": "is not included in the list",
        "incomplete_post_rule": "The sharing rule below is incomplete",
        "insufficient_credits": "The report cannot be generated because there are not enough prepaid credits",
        "internet_explorer_error": "The browser you are using (Internet Explorer) is no longer supported. Please use another browser.",
        "invalid": "is invalid",
        "invalid_annual_end_date": "of %{subscription_type} must be a multiple of %{period_in_months} months after its start date (- 1 day)",
        "invalid_annual_start_date": "of %{subscription_type} must be a multiple of %{period_in_months} months before its end date (+ 1 day)",
        "invalid_category": "%{value} is not a valid category",
        "invalid_date_range": "is invalid, should be after invitation date",
        "invalid_otp": "The supplied code incorrect",
        "invalid_subscription_type": "changing to %{subscription_type} is not allowed",
        "invalid_vat": "is not a valid %{country_adjective} VAT number",
        "language_not_available": "is not available in the organisation",
        "less_than": "must be less than %{count}",
        "less_than_or_equal_to": "must be less than or equal to %{count}",
        "limit_out_of_range": "total number is out of range",
        "login_not_allowed": "Your organisation or account is not active",
        "max_size_error": "File size should be less than %{max_size}",
        "maximum_number_of_advisors_reached": "It is not possible to add a new coach because the maximum number of coaches has been reached",
        "maximum_number_of_candidates_reached": "It is not possible to add a new candidate because the maximum number of candidates has been reached",
        "min_size_error": "File size should be greater than %{min_size}",
        "mini_magick_processing_error": "Failed to manipulate with MiniMagick",
        "model_invalid": "Validation failed: %{errors}",
        "mollie_error": "An error occurred at our payment service provider Mollie; please try again later",
        "more_than_two_decimals": "allows not more than two decimals",
        "not_a_number": "is not a number",
        "not_an_integer": "must be an integer",
        "not_found": "not found",
        "not_locked": "was not locked",
        "not_saved": {
          "one": "1 error prohibited this %{resource} from being saved:",
          "other": "%{count} errors prohibited this %{resource} from being saved:"
        },
        "odd": "must be odd",
        "one_pending_subscription": "is not allowed because a subscription is planned already",
        "organization_address_missing": "address must be set to assign a paid subscription",
        "organization_must_be_ixly": "It is not possible to create an Ixly Employee user outside of the Ixly Organisation",
        "organization_vat_number_missing": "VAT number must be set to assign a paid subscription",
        "other_than": "must be other than %{count}",
        "overlaps_existing_dates": "cannot overlap existing dates",
        "password_too_long": "is too long",
        "present": "must be blank",
        "required": "must exist",
        "restrict_dependent_destroy": {
          "has_many": "Cannot delete record because dependent %{record} exist",
          "has_one": "Cannot delete record because a dependent %{record} exists"
        },
        "rmagick_processing_error": "Failed to manipulate with rmagick, maybe it is not an image?",
        "sub_organization_not_allowed": "Sub organisations cannot have their own sub organisations",
        "subscriptions_attached": "cannot be changed because there are subscriptions with this type",
        "taken": "has already been taken",
        "too_long": {
          "one": "is too long (maximum is %{count} character)",
          "other": "is too long (maximum is %{count} characters)"
        },
        "too_short": {
          "one": "is too short (minimum is %{count} character)",
          "other": "is too short (minimum is %{count} characters)"
        },
        "url_invalid": "must match the following format: https://www.example.com/path/to/page",
        "user_limit_reached": "Your account cannot be activated because the maximum number of coaches has been reached",
        "vies_down": "Unable to validate your VAT number: the VIES service is down. Please try again later.",
        "vips_processing_error": "Failed to manipulate with vips, maybe it is not an image? Original Error: %{e}",
        "wrong_length": {
          "one": "is the wrong length (should be %{count} character)",
          "other": "is the wrong length (should be %{count} characters)"
        }
      },
      "template": {
        "body": "There were problems with the following fields:",
        "header": {
          "one": "1 error prohibited this %{model} from being saved",
          "other": "%{count} errors prohibited this %{model} from being saved"
        }
      }
    },
    "filterable": {
      "no_results": "No results",
      "reset": "reset filters",
      "title": "Filter"
    },
    "flash": {
      "actions": {
        "create": {
          "notice": "%{resource_name} was successfully created."
        },
        "destroy": {
          "alert": "%{resource_name} could not be destroyed.",
          "notice": "%{resource_name} was successfully destroyed."
        },
        "update": {
          "notice": "%{resource_name} was successfully updated."
        }
      },
      "try_again": "Please try again"
    },
    "helpers": {
      "select": {
        "prompt": "please select",
        "sr_only": "select"
      },
      "submit": {
        "create": "Create",
        "submit": "Save %{model}",
        "update": "Save"
      }
    },
    "i18n": {
      "plural": {
        "keys": [
          "one",
          "other"
        ]
      }
    },
    "impersonated_as": "You're logged in as %{name}.",
    "impersonation_ended": "You are now logged in as yourself again.",
    "import": {
      "current_template": "Template",
      "download_template": "Download template",
      "errors": {
        "duplicate": "Duplicate"
      },
      "import/candidate_file": {
        "candidate": {
          "account": {
            "email": "Email %{message}"
          },
          "advisor": "Advisor %{message}",
          "language": "Language %{message}"
        },
        "file": {
          "headers": "Incorrect columns %{message}"
        }
      },
      "import/organization_file": {
        "file": {
          "headers": "Incorrect columns %{message}",
          "unique": "Organisation '%{organization_name}' already exists. (api_organization_id: %{api_organization_id}"
        }
      },
      "labels": {
        "description": "Labels",
        "placeholder": "Enter labels"
      },
      "loading": "Loading...",
      "processing": "The file is being processed. You can close this page now.",
      "status": {
        "errors": "Problems",
        "existed": "Unchanged",
        "invalid": "Invalid",
        "new": "New",
        "previous_data": "Was previously",
        "updated": "Changed"
      },
      "store": "Save",
      "title": "CSV import",
      "upload": "Upload CSV"
    },
    "info": {
      "error_occured": "Something went wrong. Please forward this error message to your coach or organisation manager.",
      "flagged_elements": "Flagged pages contain flagged elements that require additional setup before the candidate can begin the process.",
      "no_results_found": "No matches found",
      "review_required": "A review is required. The coach must assess the assessment before reporting options can be made available.",
      "review_started": "Finish the review in the other tab and refresh this page shortly thereafter.",
      "verification_planned": "The candidate must start the verification test.",
      "verification_required": "Verification is required. The coach must check the assessment before reporting options can be made available.",
      "verification_started": "The candidate must finish the verification test before new reports can be downloaded."
    },
    "invoice_kind": {
      "administrative_costs": "Administrative costs",
      "change_internal_limit": "Internal limit adjustment",
      "change_mandate": "Authorisation payment",
      "extra_external_credit": "External credit increase",
      "fixed_price": "Finished assessments",
      "frequent_credits": "Credit use",
      "incidental_credits": "Credit bundle",
      "initial_authorization": "Initial authorisation",
      "intensive_credits": "Credit use",
      "monthly_bill": "Monthly debit",
      "premium_components": "Premium elements"
    },
    "invoice_status": {
      "canceled": "cancelled",
      "charged_back": "charged back",
      "closed": "cancelled",
      "expired": "expired",
      "failed": "failed",
      "open": "open",
      "paid": "paid",
      "processed": "processed",
      "refunded": "refunded",
      "revoked": "cancelled",
      "sent": "sent",
      "settled": "settled",
      "unconfirmed": "unconfirmed"
    },
    "invoices": {
      "no_longer_unconfirmed": "This order has already been confirmed",
      "show": {
        "administrative_costs": "Administrative costs",
        "annual_fee": "Periodic subscription fee",
        "back_to_account": "(click here to return to your billing)",
        "change_internal_limit_explanation": "Internal limit adjustments only impact the current month's costs when the limit is raised above this month's previous highest limit. The difference between the new and the previous highest limit (%{previous_limit}) will then be settled in the next monthly bill, proportional to the number of days left in the current month.",
        "confirm_direct_debit": "Debit from x-%{mandate_string}",
        "confirm_direct_invoice": "Receive this invoice",
        "confirm_direct_payment": "To the payment screen",
        "costs_next_months": "Costs from next month onwards",
        "costs_now": "Immediate costs",
        "credit_charge": "Credits",
        "external_user_spots": "Active candidates (monthly price)",
        "external_user_spots_partial": {
          "general": "Active candidates",
          "one": "Active candidates (1 day)",
          "other": "Active candidates (%{count} days)"
        },
        "fill_out_address": "We're missing some address information needed to send a correct invoice for your purchase. Please check the settings below and fill out or correct the required data.",
        "internal_user_spots": "Active coaches (monthly price)",
        "internal_user_spots_partial": {
          "general": "Active coaches",
          "one": "Active coaches (1 day)",
          "other": "Active coaches (%{count} days)"
        },
        "internal_users": "Internal user slots (monthly price)",
        "monthly_bill": "Monthly bill for %{month} %{year}",
        "premium_components": "Assignment of premium elements: %{kind}",
        "premium_components_invoice": "Assignment of premium elements",
        "status_flash_part_1": "This order was made by",
        "status_flash_part_2": "and was %{status} on %{updated_at}",
        "system_initiated": "the Assessment Platform",
        "table_header": [
          "Date",
          "Product",
          "Cost centre",
          "Unit price",
          "Amount",
          "Cost"
        ],
        "terms_and_services": "by paying you automatically accept",
        "terms_and_services_link": "Ixly's terms and services",
        "title": "Confirm your order",
        "total_with_vat": "Total including VAT",
        "total_without_vat": "Total excluding VAT",
        "vat": "%{vat}% VAT"
      }
    },
    "ixly": {
      "accounts": {
        "add": "Add Account",
        "index": {
          "title": "All Accounts"
        }
      },
      "advisors": {
        "create": {
          "success": "Advisor has been added",
          "title": "Add new advisor"
        },
        "edit": {
          "title": "Edit advisor"
        },
        "new": {
          "title": "Add new advisor"
        },
        "update": {
          "success": "Advisor has been updated",
          "title": "Edit advisor"
        }
      },
      "candidates": {
        "edit": {
          "title": "Edit candidate"
        },
        "index": {
          "title": "All candidates"
        },
        "new": {
          "title": "All candidates"
        }
      },
      "discounts": {
        "create": {
          "created": "Discount created",
          "title": "New discount"
        },
        "destroy": {
          "already_started": "This discount has already started.",
          "destroyed": "Discount removed"
        },
        "edit": {
          "title": "Edit discount"
        },
        "index": {
          "actions": "Actions",
          "add": "Add discount",
          "end_date": "End date",
          "help_text": "An overview of all discounts. Use the filters to see the discounts for one organisation or one price type.",
          "on": "On",
          "organization": "Organisation",
          "percentage": "Percentage",
          "start_date": "Start date",
          "title": "Discounts",
          "true": "On"
        },
        "new": {
          "title": "New discount"
        },
        "update": {
          "title": "Edit discount",
          "updated": "Discount has been adjusted"
        }
      },
      "entries": {
        "create": "Leniency"
      },
      "fixed_prices": {
        "create": {
          "already_exists": "Fixed price for this organisation and task already exists",
          "created": "New fixed price was created"
        },
        "destroy": {
          "destroyed": "The fixed price was destroyed"
        },
        "edit": {
          "title": "Edit fixed price"
        },
        "index": {
          "add": "Add fixed price",
          "description": "Description on invoice",
          "help_text": "An overview of all fixed prices. Use the filters to see the fixed_prices for specific organisation or task.",
          "organization": "Organisation",
          "price": "Fixed price",
          "task": "Task",
          "task_price": "Default task price",
          "title": "Fixed Prices"
        },
        "new": {
          "title": "New fixed price"
        },
        "update": {
          "updated": "The fixed price was updated"
        }
      },
      "import_files": {
        "import_file": {
          "delete": "Delete",
          "delete_confirm": "Are you sure you want to delete this import?",
          "failed": "Failed",
          "original": "Original",
          "processed": "Processed"
        },
        "index": {
          "title": "Imported CSV files"
        },
        "new": {
          "title": "Import CSV file"
        }
      },
      "integrations": {
        "edit": {
          "title": "Test-Toolkit integration"
        },
        "form": {
          "api_anonymous_explanation": "If you select this option, user names will not be shared with the Test-Toolkit",
          "checking_integration": "API status is being checked"
        },
        "link_testtoolkit": "View the linked organisation in the Test-Toolkit",
        "show": {
          "api_status": {
            "correct": "The organisation has a working connection with the Test-Toolkit API",
            "incorrect": "The organisation is missing an access token to connect with the Test-Toolkit API, please contact SD",
            "new": "API isn't configured yet, add the api user, the api key and the organisation uuid from Test-Toolkit to generate an access token"
          }
        }
      },
      "invoices": {
        "index": {
          "title": "Invoices"
        },
        "stats": {
          "last_month_amount": "Last month",
          "last_month_count": "Invoices (last month)",
          "search_results": "Search results",
          "upcoming_candidate_assessment_entry_count": "Assessment (upcoming)",
          "upcoming_candidate_assessment_program_entry_count": "Program (upcoming)",
          "upcoming_candidate_report_entry_count": "Candidate reports (upcoming)",
          "upcoming_leniency_entry_count": "Leniencies (upcoming)"
        }
      },
      "ixly_employees": {
        "create": {
          "created": "Ixly employee successfully created <a class='underline' href='%{link}'>%{name}</a>."
        },
        "edit": {
          "title": "Edit Ixly employee"
        },
        "new": {
          "title": "Add Ixly Employee"
        },
        "update": {
          "success": "Changes to Ixly employee saved"
        }
      },
      "job_profile_templates": {
        "create": {
          "success": "Job Profile Template has been successfully created",
          "title": "New Job Profile Template"
        },
        "destroy": {
          "success": "Job Profile Template removed"
        },
        "edit": {
          "title": "Edit Job Profile Template"
        },
        "index": {
          "title": "Job Profile Templates"
        },
        "new": {
          "title": "New Job Profile Template"
        },
        "update": {
          "success": "Job Profile Template has been successfully edited",
          "title": "Edit Job Profile Template"
        }
      },
      "leniency_entries": {
        "create": {
          "created": "Leniency transaction was successfully created.",
          "title": "New leniency transaction"
        },
        "new": {
          "form": {
            "in_euro": "Amount in Euro"
          },
          "title": "New leniency transaction"
        }
      },
      "mail_templates": {
        "mark_as_template": {
          "marked": "The e-mail template %{title} has been marked as application template"
        },
        "unmark_as_template": {
          "unmarked": "The e-mail template %{title} has been unmarked as application template"
        }
      },
      "migrated_organizations": {
        "index": {
          "title": "Migrated organisations"
        }
      },
      "migration_organizations": {
        "create": {
          "success": "Migration organisation successfully created"
        },
        "index": {
          "title": "Organisations to be migrated"
        },
        "new": {
          "title": "Migrate new TTK organisation"
        },
        "reset": {
          "reset": "Migration organisation restored to 'created'"
        },
        "run_migration": {
          "run_migration": "Migration has started!"
        },
        "show": {
          "title": "Migration organisation details"
        }
      },
      "organization_csv_imports": {
        "import_file": {
          "log": "Log",
          "original": "Original"
        },
        "index": {
          "list": "This lists organisation csv import files",
          "title": "Organisation CSV imports"
        },
        "new": {
          "title": "Organisation CSV import"
        }
      },
      "organization_settings": {
        "edit": {
          "title": "Ixly settings"
        }
      },
      "organization_statistics": {
        "customer_credits": {
          "index": {
            "balance_at": "Credit balance at %{date}:",
            "candidate": "Candidate",
            "candidate_assessment_entry": "Candidate Assessment",
            "candidate_assessment_program_entry": "Candidate Assessment Program",
            "candidate_report_entry": "Candidate Report",
            "cost_center": "Cost Center",
            "credits": "Amount",
            "date": "Date",
            "description": "Description",
            "entry_type": "Transaction type",
            "generated_report_entry": "Generated Report",
            "leniency_entry": "Leniency",
            "no_entries": "No credit transaction found within this period",
            "order": {
              "frequent_credits": "Monthly charge for used credits",
              "incidental_credits": "Credit bundle purchase",
              "intensive_credits": "Monthly charge for used credits"
            },
            "order_entry": "Order",
            "title": "Credit overview"
          }
        },
        "finished_assessments": {
          "index": {
            "number_finished": "Number Finished",
            "task_key": "Task key",
            "task_name": "Task name",
            "title": "Finished Assessments"
          }
        },
        "premium_components": {
          "index": {
            "list": {
              "number_assigned": "Number assigned",
              "premium_component": "Premium Component"
            },
            "title": "Assigned Premium Components"
          }
        }
      },
      "organizations": {
        "create": {
          "organization_created": "The organisation has been created! <a class='alert-link' href='%{link}'>Please start a subscription</a> to enable its users to log in",
          "title": "New organisation"
        },
        "destroy": {
          "organization_deleted": "The organisation has been deleted",
          "organization_deletion_failed": "The organisation could not be deleted"
        },
        "index": {
          "add": "Add organisation",
          "list": {
            "tooltip": {
              "sub_organization": "Top Organization: %{top_organization}",
              "top_organization": "Sub Organizations: %{sub_organizations}"
            }
          },
          "statistics_button": "Usage",
          "subscription_button": "Subscriptions",
          "title": "Organisations"
        },
        "new": {
          "title": "New organisation"
        }
      },
      "prices": {
        "create": {
          "title": "New price"
        },
        "edit": {
          "title": "Edit price"
        },
        "index": {
          "help_text": "Here are list of prices",
          "title": "Prices"
        },
        "new": {
          "title": "New price"
        },
        "show": {
          "amc_price": "AMC price",
          "bov_price": "BOV price",
          "fit_price": "FIT price",
          "help_text": "Price details",
          "job_market_opportunities_price": "Job market opportunities price",
          "job_suggestions_price": "Job suggestions price",
          "jobfeed_price": "Jobfeed price",
          "monthly_fee_per_coach": "Monthly fee per coach",
          "monthly_fee_per_participant": "Monthly fee per candidate",
          "title": "Price"
        },
        "update": {
          "title": "Edit price"
        }
      },
      "products": {
        "create": {
          "created": "The module has been created"
        },
        "deactivate": {
          "deactivated": "The module has been deactivated"
        },
        "destroy": {
          "delete_confirm": "Are you sure you want to delete this module?",
          "deleted": "The module has been deleted"
        },
        "edit": {
          "title": "Edit module"
        },
        "index": {
          "help_text": "Here are the list of all products",
          "name": "Name",
          "status": "Status",
          "status_changed_at": "Status changed at",
          "title": "Products",
          "updated_at": "Updated at"
        },
        "new": {
          "title": "New module"
        },
        "reactivate": {
          "reactivated": "The module has been reactivated"
        },
        "update": {
          "updated": "The module has been updated"
        }
      },
      "subscription_type_products": {
        "create": {
          "created": "The products have been added to the subscription type",
          "products_blank": "No products were selected"
        },
        "form": {
          "add_module": "Add module"
        },
        "new": {
          "title": "Add products to %{subscription_type}"
        }
      },
      "subscriptions": {
        "create": {
          "incidental_subscription_credits_left": "If any credits are left over when the new subscription starts, they will be used first",
          "success": "The subscription has been added."
        },
        "destroy": {
          "success": "The subscription has been deleted"
        },
        "edit": {
          "title": "Edit %{subscription} subscription"
        },
        "index": {
          "add": "Add new subscription",
          "end_date": "End date",
          "help_text": "Here is the overview of subscriptions assigned to the organisation",
          "invoice_settings": "Invoice settings",
          "name": "Name",
          "start_date": "Start date",
          "title": "Subscriptions of %{organization}"
        },
        "new": {
          "title": "New subscription for %{organization}"
        },
        "update": {
          "success": "The subscription has been updated successfully"
        }
      },
      "users": {
        "index": {
          "title": "Users"
        }
      }
    },
    "javascript": {
      "before_unload": "Your latest change has not been saved yet.",
      "components": {
        "add_to_calendar": {
          "add_appointment": "Add to calendar"
        },
        "advisor_introduction": {
          "avatar_placeholder": "After assignment the coach's profile picture will be displayed here",
          "biography_placeholder": "The coach's personal description, which they can set on their profile page, will be displayed here.",
          "default_biography": "%{advisor_first_name} will mentor you while you are following the track. Should you have any questions, they are here to answer them.",
          "default_biography_explanation": "(your candidate currently sees the default text above, because you have not set a personal description on your profile page yet)",
          "title_placeholder": "[coach's name]",
          "your_advisor": "Your coach"
        },
        "appointment": {
          "accept": "Accept",
          "appointment": "Meeting scheduler",
          "appointment_accepted": "Meeting accepted",
          "appointment_cancelled": "Meeting cancelled",
          "appointment_created": "Meeting created",
          "appointment_declined": "Meeting declined",
          "appointment_submitted": "Proposed meeting",
          "at_time": "at",
          "back": "Back",
          "cancel": "Cancel",
          "date": "Date",
          "decline": "Decline",
          "delete": "Delete",
          "description": "Description",
          "description_placeholder": "optional description",
          "end_time": "to",
          "help_text": "No meetings have been proposed yet.",
          "join": "Join call",
          "location": "Location",
          "location_placeholder": "meeting place",
          "on_date": "on",
          "plan_appointment": "Propose a meeting",
          "propose": "Propose",
          "start_time": "from",
          "title": "Title",
          "title_placeholder": "title for this meeting"
        },
        "blog": {
          "help_text": "Displays all blog posts that are shared with the candidate.",
          "manage": "Manage posts",
          "title": "Blog"
        },
        "comments": {
          "placeholder": "message",
          "send": "Send message",
          "step_unavailable": "This step is currently unavailable to the candidate. The candidate will receive a notification about this comment once the step has become available."
        },
        "consent": [
          "No",
          "Yes",
          "Open the element settings and enter the question"
        ],
        "custom_html": {
          "html_placeholder": "Open the element settings and insert HTML code to create a custom element"
        },
        "document_approval": {
          "approve": "Approve",
          "approved": "Approved",
          "candidate": "the candidate",
          "contact_person": "the contact person",
          "no_file_uploaded": "No file uploaded by the coach",
          "no_file_uploaded_again": "No new file uploaded by the coach",
          "reason_placeholder": "reason (optional)",
          "reason_placeholder_table": "Rejected? Please enter a reason",
          "reject": "Reject",
          "rejected": "Rejected",
          "reset": "Reset",
          "status_message_responded": "%{action} on %{date} at %{time}",
          "status_message_submitted": "%{can_approve} has not yet responded to the approval request",
          "status_message_submitted_again": "%{can_approve} has not yet responded to the new approval request",
          "submitted": "Submitted",
          "upload_file": "Upload file",
          "upload_file_again": "Upload new file",
          "with_reason": "because of \"%{reason}\""
        },
        "document_upload": {
          "no_file_uploaded": {
            "advisor": "No file uploaded by the coach",
            "candidate": "No file uploaded by the candidate"
          },
          "upload_file": "Upload file"
        },
        "filter_bar": {
          "labels": {
            "filter": "Filter",
            "records": "Records",
            "search": "Search",
            "sort": "Sort"
          },
          "no_filters": "all",
          "sort": {
            "ascending": "ascending",
            "default": "Default",
            "descending": "descending"
          }
        },
        "filter_notifications": {
          "all": "All",
          "done": "Done",
          "label": "Status",
          "open": "To-do"
        },
        "image": {
          "no_image_uploaded": "No image uploaded",
          "upload_image": "Upload image"
        },
        "interactive_results": {
          "agile": {
            "descriptions": {
              "groups": {
                "early_adopter": [
                  "You belong to the ‘early adopter’ category. You are open to changes and readily embrace them, even if it not yet clear what problems they may bring and what the consequences may be. You can deal well with changes and with a rapidly changing world. This makes you adaptable.\n\nOne of the risks here is that you are unable to properly judge the consequences of the changes. Try to take a step back every now and then, to listen to the ideas of others and to their 'ifs' and 'buts' about the changes.\n\nAgile working will suit you, as it fits well with your flexibility. It is, however, important not to always go along with every change but finish one project before starting on something new.",
                  "You belong to the ‘early adopter’ category. You are open to changes and readily embrace them, even if it not yet clear what problems they may bring and what the consequences may be. You can deal well with changes and with a rapidly changing world. This makes you adaptable.\n\nOne of the risks here is that you are unable to properly judge the consequences of the changes. Try to take a step back every now and then, to listen to the ideas of others and to their 'ifs' and 'buts' about the changes.\n\nAgile working will suit you, as it fits well with your flexibility. It is, however, important not to always go along with every change but finish one project before starting on something new."
                ],
                "early_majority": [
                  "You belong to the ‘early majority’. You are slightly more likely than average to go along with innovation, though you prefer to wait until the bugs have been ironed out. While you have a positive attitude toward change, you want to know how it will affect you. You’re more comfortable with plans, changes and new working methods if they have first been well tested.\n\nYou will function quite well with Agile and will appreciate that it also includes structured working methods. Do not, however, view the method as an objective in itself. Ultimately, the focus lies in the style of working and the realisation of suitable, new products and working methods.",
                  "You belong to the ‘early majority’. More often than not, you tend to go along with innovation, though you prefer to wait until the bugs have been worked out. While you have a positive attitude toward change, you want to know how it will affect you. You’re more comfortable with plans, changes and new working methods if they have been well tested.\n\nYou will function well with Agile working, and you will value its structured working methods. Do not, however, view the method as an objective in itself. Ultimately, the focus lies in the style of working and the realisation of suitable, new products and working methods."
                ],
                "innovator": [
                  "You are an ‘innovator’ and forerunner. You often initiate change, contribute new ideas and are open to new ventures. You encourage others to go along with change processes. Agile working is particularly enjoyable for you as it provides a versatile way of working which accommodates innovation. You enjoy seeing results faster. As you primarily see the big picture, you may miss important details – be sure, therefore, to utilise the unique talents and strengths of others in your team.\n\nThere is a risk that you may, at times, move too quickly. Ensure that you don’t outrun your team and keep your plans realistic. Resistance and misunderstanding can irritate you. Reporting is also not really your strong point. This can lead to inaccuracies and make it difficult for others to follow your example.",
                  "You are an ‘innovator’ and clear forerunner. You initiate change, contribute new ideas and are open to new ventures. You encourage others to go along with change processes. Agile working is particularly enjoyable for you as it provides a versatile way of working which accommodates innovation. You enjoy seeing results faster. As you primarily work in broad lines, you may miss important details – be sure, therefore, to utilise the unique talents and strengths of others in your team.\n\nYou may be prone to proceeding too quickly. Ensure that you don’t outrun your team and keep your plans realistic. You find resistance and misunderstandings irritating. Reporting is also not really your strong point. This can lead to inaccuracies and make it difficult for others to follow your example."
                ],
                "laggards": [
                  "You belong to the group of so-called ‘laggards’, the stragglers. During periods of change, you wait before taking any action and prefer to keep things the way they are. You need to be thoroughly convinced before accepting change and any changes must be implemented in small steps rather than large leaps. You are sceptical of change, sometimes rightly so. After all, not all change leads to improvement. Be wary of not falling too far behind and ending up clinging to what's familiar whatever the cost. The fact is that the world around us is changing rapidly. Occasionally, you will have to push yourself to learn and apply new methods of working.\n\nYou are able to contribute to innovations by providing attention to detail, in doing so ensuring quality of innovation processes. You can also assist in the practicalities of changes, ensuring that everything is developed and recorded to the last detail.\n\nYou are less suited to working in Agile Teams. You prefer to be part of a team with structured, more routine activities.",
                  "You belong to the group of so-called ‘laggards’, the stragglers. During periods of change, you tend to wait before taking any action. You often prefer to keep things the way they are. You need to be thoroughly convinced before accepting change and any changes must be implemented in small steps rather than large leaps. You are sceptical of change, sometimes rightly so. After all, not all change leads to improvement. Be wary of not falling too far behind and ending up clinging to what's familiar whatever the cost. The fact is that the world around us is changing rapidly. Occasionally, you will have to push yourself to learn and apply new methods of working.\n\nYou are able to contribute to innovations by providing attention to detail, in doing so ensuring quality of innovation processes. You can also assist in the practicalities of changes, ensuring that everything is developed and recorded to the last detail.\n\nWorking in Agile Teams may be less suitable for you. You prefer to work within a team with structured activities, potentially also of a more routine nature.",
                  "You belong to the group of so-called ‘laggards’, the stragglers. During periods of change, you tend to wait before taking any action. You often prefer to keep things the way they are. You need to be thoroughly convinced before accepting change and any changes must be implemented in small steps rather than large leaps. You are sceptical of change, sometimes rightly so. After all, not all change leads to improvement. Be wary of not falling too far behind and ending up clinging to what's familiar whatever the cost. The fact is that the world around us is changing rapidly. Occasionally, you will have to push yourself to learn and apply new methods of working.\n\nYou are able to contribute to innovations by providing attention to detail, in doing so ensuring quality of innovation processes. You can also assist in the practicalities of changes, ensuring that everything is developed and recorded to the last detail.\n\nWorking in Agile Teams may be less suitable for you. You prefer to work within a team with structured activities, potentially also of a more routine nature."
                ],
                "late_majority": [
                  "You belong to the ‘late majority’. You will eventually go along with change, but usually not wholeheartedly. It takes considerable energy for you to adapt. This is why you always first want to know what advantages the change will bring, its value and how it will personally benefit you. Ensure that you don’t develop a ‘fixed mindset’ and end up clinging to the familiar. You don’t want to fall too far behind. After all, the world is a dynamic place.\n\nYour contribution to the change process is your ability to effectively identify and communicate the concerns, the points for improvement, at an early stage. In this way, your critical attitude increases the innovation’s likelihood of success.\n\nWhether working in Agile Teams is suited to you is difficult to say. Your role within the team would most likely be that of controller or tester – someone who identifies the risks and raises concerns. A valuable role within teams!",
                  "You belong to the ‘late majority’. You eventually go along with change, but not always wholeheartedly. It requires considerable energy for you to adapt. For this reason, you want to know what advantages are provided by the change, its value and how it personally benefits you. Ensure that you don’t develop a ‘fixed mindset’ and end up clinging to the familiar. You don’t want to fall too far behind. After all, the world is a dynamic place.\n\nYour contribution to the change process is your ability to effectively identify and communicate the concerns, the points for improvement, at an early stage. In this way, your critical attitude increases the innovation’s likelihood of success.\n\nWhether working in Agile Teams is suited to you is difficult to say. Your role within the team would most likely be that of controller or tester – someone who identifies the risks and raises concerns. A valuable role within teams!"
                ]
              },
              "pillars": {
                "change": [
                  "You enjoy knowing exactly where you stand. You prefer things to stay unchanged and you have a certain routine in your activities. Working with a set range of tasks according to tested procedures is favourable to you. You prefer certainty rather than taking risks. You don’t necessarily see the point of innovations or changes, and you may experience the implementation of changes in your tasks as inconvenient. Others may view this as tiresome, as resistance. Try to see the positive aspects of what crosses your path. Take small steps but keep moving. Be sure that you don’t end up as one of the stragglers.",
                  "You like things to remain the same, but you don’t object to small changes. You usually prefer to work on a set range of tasks according to tested procedures. While you enjoy knowing where you stand, you are occasionally interested in changes aimed at improvements. You won’t be a frontrunner of these changes as you prefer to limit risks, but you will conform to the innovations. On the other hand, you aren’t very quick at picking things up either.",
                  "You have a neutral attitude toward change. While you enjoy having a set range of tasks, you also view variation in environment or tasks as positive. When a change takes place within your organisation, you won’t be directly responsible for initiating the change, but you will adapt to the new situation with relative ease. You don’t find it necessary to work according to set frameworks, though you also aren’t bothered by this. Moreover, you welcome new tasks which require further development.",
                  "You enjoy regular changes in the workplace. You have little need for a set range of tasks and prefer a varied job. When completing set tasks, you think of new methods for approaching the work. You then find inflexible instructions and working methods demotivating. You are open to the initiation of changes, though you find that the accompanying risks must be limited.",
                  "You embrace change. You often initiate change as well; new insights and experiments often present themselves to you. You would prefer every day to be different. You thoroughly enjoy challenging and varied tasks that you are free to to define yourself. Task procedures, routine and provisions are demotivating for you. You are often at the head of innovations, which you also often initiate. You are not daunted by risks as you consider innovation to be more important than certainty."
                ],
                "growth": [
                  "You need certainty and routine. Ambition or comparing yourself to others is not stimulating for you but rather deters you. You have embraced and perfected various tasks and you don’t see the necessity for learning new skills. On the whole, you are satisfied with your current performance and you don’t feel any pressure to perform better than your colleagues. You avoid tasks you consider to be beyond your capacity as you prefer to limit errors. As you are so focused on quality, you often struggle personally when errors occur.",
                  "You have a greater need for certainty than for development. You have embraced various tasks in which you excel, and you are satisfied with your current performance. You would, however, like to improve your performance of these tasks even further, though you see little need for mastering new tasks. You avoid tasks which you consider to be beyond your capacity as you prefer to limit errors. You may over-internalise professional feedback as you want to make a good impression on others.",
                  "You have an average need for personal growth. You enjoy having several set tasks, but you are also open to learning new tasks. You also enjoy improving performance in your current tasks. You view your performance as a separate entity and are not inclined to comparing your performance to that of your colleagues. While you don’t like making errors, you understand that this is a component of the learning process of a larger challenging task.",
                  "You have a high requirement for personal growth. Your objective is to learn continuously and improve in the tasks for which you are responsible. You would prefer to conduct your tasks more effectively and you accept more challenging tasks than your colleagues. You don’t mind making errors as you understand that this is part of the learning process. You have a resilient approach and aren’t easily held back. Lastly, you don’t mind having some tasks which do not accommodate further personal growth.",
                  "You find personal growth immensely important. You have a great thirst for knowledge and you enjoy making progress. Routine tasks may irritate you as you prefer challenging work. Your objective is to learn continuously, and you don’t mind making mistakes. You view these mistakes as sources of information as these give you insight into areas for further development. You accept challenging tasks with enthusiasm. Wherever possible, you try to get the best out of yourself and you aim to be better than the rest."
                ],
                "power": [
                  "Based on your quality-oriented approach, you are focused on preserving what is good. You prefer to work on familiar projects or tasks requiring your well-developed skills, as you want to deliver flawless work. Initiating new projects or developing new tasks is less appealing to you as you dread making errors. Your preference, therefore, is for your well-developed projects. You are sceptical of changes; your scepticism can benefit the quality of these innovations. However, as you often identify concerns, you are prone to standing in the way of change. Changes can also demand too much energy from you. It is advisable for you to look into how you can strengthen your power, to ensure sufficient energy to enable you to secure your position in a dynamic world, and to provide a positive contribution.",
                  "You have a penchant for delving into one topic. You prefer to work for a longer period on a project which increases your expertise rather than focusing on expansion. You also enjoy working on projects in which all processes are familiar and streamlined. Your preference, therefore, goes out to your well-developed tasks. You can become annoyed by bugs within new projects. This leads to a sceptical attitude toward innovation; this scepticism can benefit the quality of these changes. Ensure, however, that you do not allow yourself to be excessively limited by concerns as changes can also provide many positive effects. It is advisable for you to look into strengthening your power to ensure sufficient energy to enable you to secure your position in a dynamic world and to provide a positive contribution.",
                  "You have sufficient energy to deal with change without playing an initiating role. On the one hand, you see the advantages of changes and on the other hand, you enjoy holding onto the familiar. You are open to new tasks and activities, though you prefer any changes to come gradually. The advantage of this variation is that it allows you the space required to identify potential bugs.",
                  "You possess a high dose of energy for innovation. You have a high level of energy and dedication which allows you to deal with change well. You generally recognise the positive aspects of change, though you are occasionally troubled by the lack of process optimisation. You don't let this stop you, however, and you actively contribute to the improvement of change processes in the workplace.",
                  "You possess an immense dose of enthusiasm for innovation. This lends you the energy, dedication and attitude to handle change well. While innovations can have many positive outcomes, you will also encounter bugs and processes which haven’t yet been optimised. You understand that this is part of the process and can act accordingly. You’re not deterred by such obstacles and you continue to work with unhampered optimism. You are convinced that with hard work and perseverance, you can handle any challenge."
                ]
              },
              "tips": {
                "change": [
                  "While, rationally speaking, each change has its positive points and opportunities, you may often focus more on the accompanying risks and threats. During change processes, regularly ask yourself what can be concluded with certainty and what you have fabricated yourself. By becoming aware of the line between facts and your own interpretations, you can learn to take a more neutral approach to dealing with change. An alternate method for processing threats is to write down each of the identified risks and disadvantages. You can then force yourself to think of an equal number of advantages and new opportunities.",
                  "While, rationally speaking, each change holds its positive points and opportunities, you may often focus more on the accompanying risks and threats. During change processes, regularly ask yourself what can be concluded with certainty and what you have fabricated yourself. By becoming aware of the line between facts and your own interpretations, you can learn to take a more neutral approach to handling change. An alternate method for processing threats is to write down each of the identified risks and disadvantages. You can then force yourself to think of an equal number of advantages and new opportunities.",
                  "You adapt to changes fairly smoothly. However, you are not always likely to embrace changes with enthusiasm. Rationally speaking, each change holds its positive points and opportunities. To increase your enthusiasm for innovation, prepare a list of the advantages and new opportunities to be gained from the relative change. By making yourself aware of the change’s benefits, you can increase your enthusiasm.",
                  "With your open attitude toward changes, you would be a good compass for Innovators, who may tend to go too far ahead of the group. Prepare an estimate of the risks accompanying the change and how realistic you consider these to be and discuss this with the Innovators.",
                  "Ensure that you remain connected to the rest, don’t get too far ahead of the group. There’s no harm in investing in quality assurance."
                ],
                "growth": [
                  "Look into which competences you would like to develop further, and which skills are beneficial to your employability. As you are focused on certainty and routine, you may place insufficient focus on competences which are interesting and relevant to your employer. Try to be aware of this and grasp opportunities to further develop competences which you consider useful.",
                  "Look into which competences you would like to further develop, and which skills are useful for your employability. As you are focused on certainty and routine, you may place insufficient focus on competences which are interesting and relevant to your employer. Try to be aware of this by looking into which relevant competences you can master and take initiative in developing these skills.",
                  "You possess a good combination of attributes: you don’t mind conducting familiar tasks, but you also enjoy continued learning. Evaluate whether you are satisfied with your current balance. Are there any additional competences which you would like to master? What can you do to develop these skills? Try to take initiative and create opportunities.",
                  "You possess a good combination of qualities: you enjoy developing yourself in various areas, but you are also comfortable carrying out familiar tasks. Evaluate whether you are satisfied with your current balance. Are there any additional competences you would like to master? What can you do to develop these skills? Try to take initiative and create opportunities.",
                  "You have a great thirst for knowledge and personal growth, a good quality in terms of innovation within organisations. Keep in mind, however, that everything can’t be changed simultaneously and that some routine tasks are simply part of your job. Don’t allow familiar tasks to influence your motivation and mood too much; with your openness for development, a more challenging task will inevitably come along."
                ],
                "power": [
                  "To reinforce your power, you can investigate what positive effects other people have experienced resulting from certain innovations. Try to also evaluate which changes have occurred in your own life or working environment and how you handled these. Focus primarily on the aspects which you handled well. Have there been moments in which you initiated change of your own accord? Which positive affects resulted from this? In focusing on these elements, you can increase your self-confidence in terms of handling change.",
                  "To reinforce your power, you can investigate the positive impact on other people resulting from certain innovations. Try to also evaluate which changes have occurred in your own life or working environment and how you handled these. Focus primarily on the aspects which you handled well. Have there been moments in which you initiated change by your own accord? Which positive affects resulted from this? In focusing on these elements, you can increase your self-confidence in terms of handling change.",
                  "Spot your opportunity: You have an important role in the moderation of overly ambitious plans in which people take on too much at the same time. While you are open to change, you stand with both feet firmly on the ground. This attitude can have a sobering effect on the Innovators.",
                  "Spot your opportunity: You have an important role in the moderation of overly ambitious plans in which people take on too much at the same time. While you are highly open to change, you have a good eye for potential obstacles. Try to bring these to the attention of the Innovators and consider how you can resolve or prevent the obstacles.",
                  "Handling change with a high level of energy is a positive attribute. Ensure, however, that you aren’t blinded by your enthusiasm, and listen to the concerns and wishes expressed by your colleagues. Take the potential obstacles seriously which they have brought to your attention and try to find a suitable solution. This will benefit the quality of the respective changes."
                ]
              }
            },
            "header": "Agile mindset",
            "tip": "TIP!",
            "titles": {
              "groups": {
                "early_adopter": "Early adopter",
                "early_majority": "Early majority",
                "innovator": "Innovator",
                "laggards": "Laggards",
                "late_majority": "Late majority"
              },
              "pillars": {
                "change": "Change",
                "growth": "Growth",
                "power": "Power"
              }
            }
          },
          "behavior": {
            "header": "Behavioral characteristics",
            "keys": {
              "aanspreken": "Is able to address and speak up",
              "durft": "Is daring",
              "veranderbereid": "Embraces and initiates change"
            }
          },
          "candidate": {
            "date_of_birth": "Birth date",
            "education": "Education level",
            "email": "Email address",
            "header": "Personal information",
            "most_recent_work_experience": "Most recent work experience",
            "study_subject": "Education",
            "years": {
              "one": "%{years} year",
              "other": "%{years} years"
            },
            "years_of_experience": "Number of years of experience"
          },
          "competences": {
            "descriptions": {
              "aansturenvangroepen": "Is able to provide leadership to a group.",
              "aansturenvanindividuen": "Is able to give direction to an individual.",
              "ambitie": "Is ambitious, wants to achieve more than others.",
              "assertiviteit": "Is able to stand up for own opinions, even when under pressure from others.",
              "besluitvaardigheid": "Is able to make quick and effective decisions.",
              "coachenvanmedewerkers": "In managerial role is able to stimulate employees and guide them in their development.",
              "delegeren": "Is able to effectively delegate duties to others.",
              "doorzettingsvermogen": "Is focused, even when faced with setbacks, on finishing what has been started.",
              "flexibiliteit": "Is able to act purposefully and effectively under different and changing circumstances by changing behavioural style.",
              "initiatief": "Is able to take initiate, able to take efficient action on own accord.",
              "inzet": "Is able and willing to put in great effort over a longer period of time.",
              "klantorientatie": "Is able to empathise with and react to clients’ needs.",
              "kwaliteitsgerichtheid": "Is focused on delivering high quality and on perfection.",
              "leerbereidheid": "Is willing to develop and expand knowledge and skills through learning.",
              "nauwkeurigheid": "Is able to work precisely and has an eye for detail and context.",
              "onderhandelen": "Is able to sound out mutual interests and positions and use them to come to an agreement that is acceptable to all parties.",
              "ondernemerschap": "Is focused on gaining advantage by spotting and using business opportunities and taking calculated risks.",
              "optreden": "Makes a strong and professional impression on others.",
              "organisatiesensitiviteit": "Is able to recognise the influence and consequences of own decisions or activities on other parts of the organisation.",
              "overtuigingskracht": "Is able to persuade others to share point of view, proposal or idea.",
              "plannenenorganiseren": "Is able to plan and organise activities and duties.",
              "relatiebeheer": "Is able to constructively form and maintain relationships.",
              "resultaatgerichtheid": "Is driven to reach concrete goals and results.",
              "samenwerking": "Is able to achieve joint goals by working constructively with others.",
              "sensitiviteit": "Is able to read other people’s signals and adequately respond to them.",
              "sociabiliteit": "Is at ease and enjoys being in groups.",
              "stressbestendigheid": "Is able to function effectively in stressful situations.",
              "voortgangscontrole": "Is able to follow up on undertaken tasks, to check on their progress.",
              "zelfstandigheid": "Is able to work independently, set own goals and give them shape and substance."
            },
            "header": "Your top 5 competences",
            "keys": {
              "aansturenvangroepen": "Manage groups",
              "aansturenvanindividuen": "Manage individuals",
              "ambitie": "Ambition",
              "assertiviteit": "Assertiveness",
              "besluitvaardigheid": "Decisiveness",
              "coachenvanmedewerkers": "Coaching employees",
              "delegeren": "Delegating",
              "doorzettingsvermogen": "Perseverance",
              "flexibiliteit": "Adaptability",
              "initiatief": "Initiative",
              "inzet": "Commitment",
              "klantorientatie": "Service orientation",
              "kwaliteitsgerichtheid": "Quality orientation",
              "leerbereidheid": "Willingness to learn",
              "nauwkeurigheid": "Precision",
              "nauwkeurigheid_comp": "Precision",
              "onderhandelen": "Negotiation",
              "ondernemerschap": "Entrepreneurship",
              "optreden": "Presentation",
              "organisatiesensitiviteit": "Organisational sensitivity",
              "overtuigingskracht": "Influential capacity",
              "plannenenorganiseren": "Planning and organising",
              "relatiebeheer": "Management of business contacts",
              "resultaatgerichtheid": "Result orientation",
              "samenwerking": "Cooperation",
              "samenwerking_comp": "Cooperation",
              "sensitiviteit": "Sensitivity",
              "sociabiliteit": "Sociability",
              "stressbestendigheid": "Stress resilience",
              "voortgangscontrole": "Progress control",
              "zelfstandigheid": "Independence"
            }
          },
          "development": {
            "header": "Development potential",
            "keys": {
              "ambitie": "Ambition",
              "growth": "Personal growth",
              "onafhankelijkheid": "Independence",
              "samenwerking": "Teamwork",
              "sociabiliteit": "Sociability"
            }
          },
          "documents": {
            "date": "Date",
            "header": "Documents (%{documents})",
            "less": "Show less",
            "more": "Show more",
            "refresh": "Your session has expired. Click the button below to refresh the page."
          },
          "explanations": {
            "behavior": {
              "description": "Behavioral characteristics are the principles that form the basis for how we want to work together within NS"
            },
            "intelligence": {
              "description": "In this widget you see two types of scores. On the first page your intelligence score is shown. A score of 100 is average in the Dutch workforce.\n\nOn the following pages your score is compared to reference groups of several educational levels. The numbers on these pages are sten scores, which we use to compare your result to the reference group. Scores like 5 or 6 are average and common. Scores like 1 and 10 are exceptional and uncommon."
            },
            "interests": {
              "description": "In this widget you see two types of scores, your interest in sectors and in tasks. Your interest in sectors shows in which sector of the labor market you would like to work. Your interest in tasks indicates which activities you would like to do. This information can help you discover which work is interesting for you. You will perform this work with more motivation and pleasure than work that is less relevant to your interests.\n\nThe first page shows your top 5 interests in sectors. The scores range from 1 to 10, where 1 represents far below average interest and 10 represents far above average interest. The second page shows your top 5 interests in tasks. The scores range from 1 to 5, where 1 represents far below average interest and 5 represents far above average interest."
            },
            "star_scores": {
              "description": "The stars in this widget show how developable the competences are for you. The more stars are coloured yellow, the larger your aptitude is. This doesn't mean that the competence has already been developed and to what level."
            },
            "sten_scores": {
              "description": "The numbers in this widget are sten scores, which we use to compare your result to the Dutch workforce. Scores like 5 or 6 are average and common. Scores like 1 and 10 are exceptional and uncommon. Scores below or above average entail both qualities and pitfalls.",
              "scores": [
                "Far below average",
                "Well below average",
                "Below average",
                "Just below average",
                "Average",
                "Average",
                "Just above average",
                "Above average",
                "Well above average",
                "Far above average"
              ]
            }
          },
          "filter": {
            "clear": "Clear selection",
            "empty": "Please select up to 10 competences to see your results",
            "header": "Filter competences"
          },
          "hybrid_work": {
            "average": {
              "gemiddeld": "medium",
              "hoog": "high",
              "laag": "low"
            },
            "header": "21st century skills",
            "skills": {
              "autonomie": {
                "descriptions": {
                  "gemiddeld": "When working from home, you experience some freedom to decide independently how you perform your work. You do sometimes need permission to perform certain tasks and you sometimes experience being controlled. Your home environment may sometimes distract you. You probably feel somewhat able to use your skills to contribute to the team and/or organisation where you work. That can lead to some motivation and satisfaction.",
                  "hoog": "When working from home, you experience the freedom to decide how to carry out your work independently. You are not being monitored frequently. You experience a high level of responsibility and are able to plan and organise your work on your own. You experience little distraction from your home environment and seldom experience work-life conflict. You probably feel able to use your skills to contribute to the team and/or organisation where you work. This can lead to high motivation and satisfaction.",
                  "laag": "When working from home, you experience little freedom to independently decide how to perform your work. You are often monitored and need permission to perform certain tasks. That makes you dependent on others to make decisions about your work. You probably are distracted by your home environment during work sometimes and you experience conflicts between private and work. You may feel inhibited as a result and you have little motivation and satisfaction."
                },
                "label": "Autonomy"
              },
              "competentie": {
                "descriptions": {
                  "gemiddeld": "When working from home, you experience an average sense of competence. This means that you usually have the skills and knowledge to do your job effectively, but sometimes you struggle to do certain tasks well. You can sometimes be limited by your technological skills. In general, you have some confidence in your own abilities, but you may feel insecure about your ability to do your job well. This can lead to reduced motivation and satisfaction.",
                  "hoog": "When working from home, you experience a high degree of competence. You have the skills and knowledge to do your job effectively. You experience little or no problems with your technical skills when working from home. You are very confident in your skills and are likely to be motivated and very satisfied with your performance at work.",
                  "laag": "When working from home, you experience a low sense of competence. This means that you probably have limited skills and knowledge to do your job effectively and that you struggle to complete certain tasks. You may experience problems with your technology skills while working from home. This is probably why you have little confidence in your own abilities. You may feel insecure about your ability to do your job well. This can lead to reduced motivation and satisfaction."
                },
                "label": "Competence"
              },
              "verbondenheid": {
                "descriptions": {
                  "gemiddeld": "When working from home, you experience a reasonable degree of connection with your colleagues. You may occasionally find it difficult to get in touch with colleagues. You do not always experience involvement from your colleagues. This can lead to reduced motivation and satisfaction.",
                  "hoog": "When working from home, you experience a strong sense of connection with your colleagues. You easily get in touch with your colleagues and experience support and involvement from them when you need it. That contributes to your interest in your work. You are probably motivated to perform in your work and are satisfied with your work.",
                  "laag": "When working from home, you experience a weak sense of connection with your colleagues. You have trouble getting in touch with your colleagues and experience little support and involvement from them. This can lead to reduced motivation and satisfaction."
                },
                "label": "Connectedness"
              }
            },
            "title": "Hybride werken"
          },
          "information": {
            "skills": {
              "informatievaardigheden": {
                "label": "Information skills: Dealing with information",
                "tips": [
                  "The way you search for and process information can be improved in a number of areas. You can read more about the Big 6 model of information literacy on <a href=\"https://o21.nu/informatieinformatie\" target=\"_blank\">this</a> website. Check with yourself which steps you can still improve!",
                  "The way you search for and process information can be improved in a number of areas. You can read more about the Big 6 model of information literacy on <a href=\"https://o21.nu/informatieinformatie\" target=\"_blank\">this</a> website. Check with yourself which steps you can still improve!",
                  "The way you search for and process information is already good in many respects! But there is still room for improvement. You can read more about the Big 6 model of information literacy on <a href=\"https://o21.nu/informatieinformatie\" target=\"_blank\">this</a> website. Check with yourself which steps you are already doing well and which steps you can improve.",
                  "The way you search for and process information is already at a high level. But who knows, there is still room for improvement. On <a href=\"https://o21.nu/informatieinformatie\" target=\"_blank\">this</a> website you can read more about the Big 6 model of information literacy. Check with yourself which steps you are already doing well and which steps you can improve further!",
                  "The way you search for and process information is already at a high level. But who knows, there is still room for improvement. You can read more about the Big 6 model of information literacy on <a href=\"https://o21.nu/informatieinformatie\" target=\"_blank\">this</a> website. Check with yourself which steps you are already doing well and which steps you can improve even further!"
                ]
              },
              "kritisch": {
                "description": "Critically examining information",
                "label": "Critical",
                "tips": [
                  "You do not look critically at the source of your information and you tend to copy information quite literally. Try to combine information from different sources more often and put it in your own words. This way you are automatically more critical about the information you adopt.",
                  "You are not always very critical of the source of your information. You tend to take information quite literally. Try to combine information from different sources more often and put it in your own words. This way you are automatically more critical about the information you adopt.",
                  "You try not to just accept information and copy it literally. But you could do this even more. Try to combine information from different sources more often and put it in your own words. This way you are automatically more critical about the information you adopt.",
                  "You are thorough during your search and when processing the information. You critically examine various sources. You also try to put information in your own words instead of just copying it down. Do not relax this critical view.",
                  "You are thorough during your search and while processing the information. You critically examine various sources. You also try to put information in your own words instead of simply copying it down. Maintain this critical eye."
                ]
              },
              "organisatie": {
                "description": "Organizing information",
                "label": "Organisation",
                "tips": [
                  "You don't always take the time to prepare or evaluate your search process. As a result, you will not always find the correct information and your search process may take longer than necessary.",
                  "You can sometimes take more time to prepare or evaluate your search process. This allows you to search even more specifically and speed up your search process.",
                  "You are trying to prepare and evaluate your search process, but there is still room for improvement. With good preparation and a critical eye during the search process, you can search even more specifically.",
                  "You take the time to prepare and evaluate your search process. Try to do this more often, because with good preparation and a critical eye during the search process you can search even more specifically.",
                  "You take plenty of time to prepare and evaluate your search process. This way you avoid mistakes and duplication of work."
                ]
              },
              "presenteren": {
                "description": "Presenting information",
                "label": "Present",
                "tips": [
                  "The way you present information is not always clear and orderly. Maybe you have trouble creating structure or don't take your audience into account.",
                  "The way you present information can be clearer and more organized. Maybe you have trouble creating structure or don't take your audience into account.",
                  "You do your best to present information clearly and concisely. You could improve this even further by adding more structure. You can also take the level and prior knowledge of your audience into account even more.",
                  "The way you present information is generally clear and orderly. To improve this further, it is good to think about who your audience is. This way you can better take the level and prior knowledge of your audience into account.",
                  "The way you present information is clear and orderly. You make sure that your story has a common thread. You also take into account the level and prior knowledge of your audience."
                ]
              },
              "strategie": {
                "description": "The strategic search for information",
                "label": "Strategy",
                "tips": [
                  "You are limited to one search method. As a result, you will not always find the correct information. Your search will also take longer than necessary. Look for tips to refine your search. Change your strategy if you notice that you cannot find the right information.",
                  "You tend to limit yourself to one search method. This can make it difficult to find the right information. Your search will also take longer than necessary. Look for tips to improve your search. Change your strategy if you notice that you cannot find the right information.",
                  "You try to adapt your way of searching to the situation. This usually means you can quickly find the right information. Consider whether certain types of information are more difficult for you to find. Could you improve this by, for example, using filters or more specific search terms?",
                  "You usually adapt your search method to the information you are looking for. This usually allows you to quickly find the right information. Use this knowledge to streamline your search process even more.",
                  "During your search, adjust your search method to achieve the best results. This often makes you find the information you are looking for easily and quickly."
                ]
              }
            }
          },
          "intelligence": {
            "closest_group": "Your score best matches reference group:",
            "description": "In this chart you can see your score compared to the Dutch workforce. We can conclude with 80% certainty that your score is located between the dashed lines.",
            "groups": {
              "hbo_ba": "HBO/Bachelor",
              "mbo": "MBO",
              "mbo1": "MBO1",
              "mbo2": "MBO2",
              "mbo3": "MBO3",
              "mbo4": "MBO4",
              "vmbo": "VMBO",
              "wo_ma": "WO/Master"
            },
            "header": "Intelligence",
            "keys": {
              "abstract": "Abstract",
              "numeriek": "Numerical",
              "totaalscore": "Total score",
              "verbaal": "Verbal"
            },
            "labels": {
              "average": "Average",
              "high": "Above average",
              "low": "Below average"
            },
            "subtitles": [
              "Your total score is far below average compared to a %{group} reference group",
              "Your total score is below average compared to a %{group} reference group",
              "Your total score is about average compared to a %{group} reference group",
              "Your total score is above average compared to a %{group} reference group",
              "Your total score is far above average compared to a %{group} reference group"
            ],
            "title": "Reference group %{group}"
          },
          "interests": {
            "sectoren": {
              "header": "Top 5 interest in sectors",
              "keys": {
                "administratie": "Administration",
                "advieseninformatie": "Advice and information",
                "agrarischeondersteuning": "Agricultural support",
                "beheer": "Management",
                "bouw": "Build",
                "commercieledienstverlening": "Commercial service",
                "communicatie": "Communication",
                "cultuur": "Culture",
                "delfstofwinning": "Mining",
                "dieren": "Animals",
                "elektrotechniek": "electrical engineering",
                "gezondheidszorg": "healthcare",
                "glasaardewerkkeramiek": "Glass, pottery and ceramics",
                "grafischetechniek": "Graphic technology",
                "handel": "Trade",
                "horeca": "catering industry",
                "houtmeubileringstechniek": "Wood furnishing technology",
                "huishouding": "Housekeeping",
                "industrielereiniging": "Industrial cleaning",
                "installatietechniek": "Installation technology",
                "magazijnopslagbezorging": "Warehouse, storage and delivery",
                "media": "Media",
                "metaal": "Metal",
                "milieu": "Environment",
                "onderwijs": "Education",
                "ontwerpenontwikkeling": "Design and development",
                "openbaarbestuur": "Public administration",
                "organisatieenadvies": "Organisation and advice",
                "personeelarbeidloopbaan": "Personnel, labor and career",
                "persoonlijkeverzorging": "Personal care",
                "planningproductiebegeleiding": "Planning and production supervision",
                "planten": "Plants",
                "procestechniek": "Process engineering",
                "rechtspraak": "Jurisdiction",
                "recreatie": "Recreation",
                "schoonmaak": "Cleaning",
                "sportontspanning": "Sports and leisure",
                "textielmode": "Textiles and fashion",
                "toerisme": "Tourism",
                "veiligheid": "Safety",
                "vervoer": "Transport",
                "visserij": "Fisheries",
                "welzijn": "Welfare",
                "wetenschap": "Science"
              }
            },
            "taken": {
              "header": "Top 5 interest in tasks",
              "keys": {
                "administratievetaken": "Administrative tasks",
                "analyseren": "Analyze",
                "assisteren": "Assist",
                "conflictenoplossen": "Resolving conflicts",
                "contactonderhouden": "Maintain contact",
                "creatiefzijn": "Being creative",
                "cursussentrainingengeven": "Providing courses and training",
                "elektronischeapparatierepareren": "Repair electronic devices",
                "fysiekinspannen": "Physical exertion",
                "grotemachinesbedienen": "Operate large machines",
                "informatieverzamelen": "Getting information",
                "inroosteren": "Scheduling",
                "klantenhelpen": "Help customers",
                "leidinggeven": "To lead",
                "lesgeven": "Teaching",
                "lezen": "Read",
                "machinesrepareren": "Repairing machines",
                "mensenadviseren": "Advising people",
                "mensenbegeleiden": "To guide people",
                "mensenbeinvloeden": "Influencing people",
                "mensenovertuigen": "Convince people",
                "metcollegasoverleggen": "Consult with colleagues",
                "metcomputerswerken": "Working with computers",
                "metelektronischeapparatenwerken": "Working with electronic devices",
                "methandenwerken": "Working with hands",
                "metmachineswerken": "Working with machines",
                "middelenvoorwerpenbeheren": "Manage resources and items",
                "motiveren": "Motivate",
                "onderhandelen": "Negotiate",
                "organiseren": "To organize",
                "projectenopzetten": "Setting up projects",
                "rekenen": "Calculate",
                "schrijven": "To write",
                "strategischdenken": "Strategic thinking",
                "teplannen": "To plan",
                "voertuigenbesturen": "Driving vehicles",
                "werkzaamhedenplannen": "Plan work"
              }
            }
          },
          "it": {
            "descriptions": {
              "gemiddeld": "Your basic ICT skills are sufficient, but you still have room to learn.",
              "hoog": "Your basic ICT skills are excellent.",
              "laag": "Your basic ICT skills could use an update."
            },
            "header": "21st century skills",
            "title": "Basic ICT skills: Dealing with technology"
          },
          "media": {
            "categories": [
              "You are the best at it",
              "You're very good at it",
              "You're as good as most at it",
              "You can still improve",
              "You still have a lot to learn about it",
              "This is how you deal with social media"
            ],
            "header": "21st century skills",
            "skills": {
              "consumptie": {
                "description": "Being critical about what information you share and consume",
                "label": "Consumption",
                "tips": [
                  "You are not critical about the information you share. Try to be more alert to what can be found about you online and what messages you spread.",
                  "You are not always critical of the information you share. Try to be more alert to what can be found about you online and what messages you spread.",
                  "You usually think first before sharing information and ensure that nothing strange can be found about you online.",
                  "You are usually critical of the information you share and what can be found about you online.",
                  "You are critical about the information you share and what can be found about you online."
                ]
              },
              "maatschappelijk": {
                "description": "The social influence of media",
                "label": "Social",
                "tips": [
                  "You find it difficult to estimate the social influence of media. You don't always realize the extent to which media can influence or mislead people.",
                  "You have a moderate insight into the social influence of media. You do not always fully understand the extent to which media can be used to influence or even mislead people.",
                  "You have an average understanding of the social influence of media. You usually see how people use media and can be misled by it. You cannot yet recognize this influence of media in all situations.",
                  "You have excellent insight into the social influence of media. You usually see how people use media and how people can be misled by it.",
                  "You have a good insight into the social influence of media. You see how people use media and how people can be misled by it."
                ]
              },
              "mediawijsheid": {
                "label": "Media literacy: Dealing with media",
                "tips": [
                  "Social media have become indispensable. Social media not only plays a role in private life, but also in the workplace. How do you deal with social media as an employee? Gain insight into and tools for using social media as a civil servant with the workshop <a href=\"https://www.ubrijk.nl/service/ advocaten-en-adviseurs-laborsrecht/masterclasses-en-trainingen/social-media -and-civil servants\" target=\"_blank\">Social media and civil servants</a>.",
                  "Social media have become indispensable. Social media not only plays a role in private life, but also in the workplace. How do you deal with social media as an employee? Gain insight into and tools for using social media as a civil servant with the workshop <a href=\"https://www.ubrijk.nl/service/ advocaten-en-adviseurs-laborsrecht/masterclasses-en-trainingen/social-media -and-civil servants\" target=\"_blank\">Social media and civil servants</a>.",
                  "You are aware of the strengths and weaknesses of modern media, but there is still room for improvement. Media literacy is not about whether you use media a lot. Media literacy is often a matter of consciously weighing the pros and cons. Give yourself space to (re)consider your choices. Are you perhaps too critical and cautious and therefore make insufficient use of the powers of modern media? Or are you not aware enough of the risks and are you therefore making yourself vulnerable?",
                  "Make use of your active and sharp attitude towards media literacy. Stay informed of the latest technological developments and subscribe to the <a href=\"https://ibestuur.nl/nieuwsbrief/\" target=\"_blank\">iBestuur magazine</a>.",
                  "Make use of your active and sharp attitude towards media literacy. Stay informed of the latest technological developments and subscribe to the <a href=\"https://ibestuur.nl/nieuwsbrief/\" target=\"_blank\">iBestuur magazine</a>."
                ]
              },
              "schuwheid": {
                "descriptions": [
                  "You use social media little compared to others.",
                  "You use social media less than average.",
                  "You use social media about as much as others.",
                  "Compared to others, you use social media more.",
                  "You use social media a lot compared to others."
                ],
                "label": "Shyness",
                "tips": [
                  "You make little or no use of social/digital media. Many people use social media to stay informed or maintain contacts. Could it be that you are missing information or opportunities because you are not active online? Or do you see it as an advantage that you decide for yourself what information you receive?",
                  "You make relatively little use of social/digital media. Many people use social media to stay informed or maintain contacts. Could it be that you are missing information or opportunities because you are not active online? Or do you see it as an advantage that you decide for yourself what information you receive?",
                  "You regularly use social/digital media. This can help you stay informed and maintain contacts. But this type of media can also give a distorted picture of reality.",
                  "You make above-average use of social/digital media. This probably keeps you informed of what is going on (online) and helps you maintain contacts. But be aware that this type of media can give a distorted picture of reality.",
                  "You use social/digital media relatively often. This probably means that you are well informed about what is going on (online). It also helps maintain your contacts. But be aware that this type of media can give a distorted picture of reality."
                ]
              },
              "trend": {
                "description": "Have an eye for trends",
                "label": "Trend",
                "tips": [
                  "You don't have a keen eye for trends. You are not concerned with what is and is not popular.",
                  "You don't really have an eye for trends. You don't worry much about what is and isn't popular.",
                  "You try to stay abreast of trends. You notice things that are very popular or are becoming outdated. But you are also not someone who immediately follows all trends.",
                  "You stay informed of trends. You usually realize what is popular and see it as things becoming outdated.",
                  "You stay well informed of trends. You have insight into what is popular and notice when things become outdated."
                ]
              }
            }
          },
          "no_data": "(no results are available yet)",
          "no_relevant_data": "(no results are available yet for which widgets exist)",
          "personality": {
            "descriptions": {
              "competitie": "Wanting to be better than others.",
              "conformisme": "Being able to adapt to the applicable standards and values. Respecting authority.",
              "contactbehoefte": "Being in need of company.",
              "dominantie": "Taking the lead. Playing a decisive role in the collaboration.",
              "energie": "Having the energy to do a lot.",
              "frustratietolerantie": "Being tolerant towards others.",
              "hartelijkheid": "Being nice, happy and cheerful towards others.",
              "incasseringsvermogen": "Having adequate ability to deal with criticism and setbacks.",
              "nauwkeurigheid": "Working with care, with an eye for detail.",
              "onafhankelijkheid": "Wanting to do business in own way.",
              "ordelijkheid": "Applying structure.",
              "originaliteit": "Coming up with new solutions. Being creative.",
              "positivisme": "Having a positive outlook on life.",
              "regelmaat": "Needing  order and rules.",
              "sociaal_ontspannen": "Feeling relaxed in contact with others.",
              "status": "Wanting to achieve the best possible. Wanting to get higher up.",
              "vernieuwing": "Enjoying change and gaining new experiences.",
              "vertrouwen": "Believing in the good intentions of others.",
              "volharding": "Showing commitment. Taking tasks and agreements seriously and sticking to them.",
              "weloverwogen": "Thinking carefully before taking action.",
              "zelfonthulling": "Wanting to share one's own feelings with others.",
              "zelfontwikkeling": "Driven to make full use of his or her own qualities.",
              "zelfvertoon": "Like being the centre of attention.",
              "zelfvertrouwen": "Being self-confident.",
              "zorgzaamheid": "Feeling involved with others. Wanting to help others."
            },
            "header": "Personality",
            "keys": {
              "competitie": "Competition",
              "conformisme": "Conformity",
              "contactbehoefte": "Need for contact",
              "dominantie": "Dominance",
              "energie": "Energy",
              "frustratietolerantie": "Frustration-tolerance",
              "hartelijkheid": "Friendliness",
              "incasseringsvermogen": "Resilience",
              "nauwkeurigheid": "Precision",
              "onafhankelijkheid": "Independence",
              "ordelijkheid": "Orderliness",
              "originaliteit": "Originality",
              "positivisme": "Optimism",
              "regelmaat": "Regularity",
              "sociaal_ontspannen": "Socially at ease",
              "status": "Status",
              "vernieuwing": "Need for variety",
              "vertrouwen": "Trust",
              "volharding": "Perseverance",
              "weloverwogen": "Deliberation",
              "zelfonthulling": "Self-disclosure",
              "zelfontwikkeling": "Personal growth",
              "zelfvertoon": "Self-presentation",
              "zelfvertrouwen": "Self-confidence",
              "zorgzaamheid": "Attentiveness"
            },
            "titles": [
              "Your most characteristic features",
              "Your characteristic features",
              "Your least characteristic features"
            ]
          },
          "thinking": {
            "average": {
              "benedengemiddeld": "Below average",
              "bovengemiddeld": "Above average",
              "gemiddeld": "Average",
              "hoog": "High",
              "laag": "Low"
            },
            "header": "21st century skills",
            "skills": {
              "communiceren": {
                "descriptions": {
                  "benedengemiddeld": "In the 21st century, communication is increasingly done in writing, for example via email or messenger apps. For good communication, it is important that your message has a good structure and can be followed by others. You also have to take the recipient of your message into account. Compared to other government employees, communicating is naturally less easy for you.",
                  "bovengemiddeld": "In the 21st century, communication is increasingly done in writing, for example via email or messenger apps. For good communication, it is important that your message has a good structure and can be followed by others. You also have to take the recipient of your message into account. Compared to other government employees, communicating naturally comes relatively easily to you.",
                  "gemiddeld": "In the 21st century, communication is increasingly done in writing, for example via email or messenger apps. For good communication, it is important that your message has a good structure and can be followed by others. You also have to take the recipient of your message into account. You have an average aptitude for communicating compared to the reference group of government employees.",
                  "hoog": "In the 21st century, communication is increasingly done in writing, for example via email or messenger apps. For good communication, it is important that your message has a good structure and can be followed by others. You also have to take the recipient of your message into account. You naturally have a strong aptitude for communication.",
                  "laag": "In the 21st century, communication is increasingly done in writing, for example via email or messenger apps. For good communication, it is important that your message has a good structure and can be followed by others. You also have to take the recipient of your message into account. Compared to other government employees, you have less aptitude for communication."
                },
                "label": "To communicate"
              },
              "creatief_denken": {
                "descriptions": {
                  "benedengemiddeld": "Compared to other government employees, you are naturally less likely to come up with new ideas and applications. Creative people have original ideas. They often have less need for structure and security. Creative thinking helps you to see new possibilities and develop further.",
                  "bovengemiddeld": "Compared to other government employees, you naturally find it easier to come up with new ideas and applications. Creative people have original ideas. They often have less need for structure and security. Creative thinking helps you to see new possibilities and develop further. Consciously look for situations where you can apply and maintain this skill.",
                  "gemiddeld": "Compared to other government employees, you have an average aptitude for coming up with new ideas and applications. Creative people have original ideas. They often have less need for structure and security. Creative thinking helps you to see new possibilities and develop further. There is room to develop in this.",
                  "hoog": "Compared to other government employees, you naturally find it easy to come up with new ideas and applications. Creative people have original ideas. They often have less need for structure and security. Creative thinking helps you to see new possibilities and develop further. Consciously look for situations where you can apply and maintain this skill.",
                  "laag": "Compared to other government employees, you naturally have more difficulty coming up with new ideas and applications. Creative people have original ideas. They often have less need for structure and security. Creative thinking helps you to see new possibilities and develop further."
                },
                "label": "Creative thinking"
              },
              "kritisch_denken": {
                "descriptions": {
                  "benedengemiddeld": "Critical thinkers are independent and enjoy getting to the bottom of things. Critical thinking keeps you sharp and ensures that you do not simply rely on what technology tells us. Your natural ability to approach a problem critically and in depth is below average compared to other government employees.",
                  "bovengemiddeld": "Critical thinkers are independent and enjoy getting to the bottom of things. Your natural ability to approach a problem critically and in depth is above average. Critical thinking keeps you sharp and ensures that you do not simply rely on what technology tells us.",
                  "gemiddeld": "Critical thinkers are independent and enjoy getting to the bottom of things. Critical thinking keeps you sharp and ensures that you do not simply rely on what technology tells us. Your natural ability to approach a problem critically and in depth is comparable to that of the average government employee.",
                  "hoog": "Critical thinkers are independent and enjoy getting to the bottom of things. Your natural ability to approach a problem critically and deeply is strong. Critical thinking keeps you sharp and ensures that you do not simply rely on what technology tells us.",
                  "laag": "Critical thinkers are independent and enjoy getting to the bottom of things. Critical thinking keeps you sharp and ensures that you do not simply rely on what technology tells us. Your natural ability to approach a problem critically and in depth is less strong than that of other government employees."
                },
                "label": "Critical thinking"
              },
              "probleem_oplossen": {
                "descriptions": {
                  "benedengemiddeld": "People with strong problem-solving skills can take a beating and do not give up easily. They persevere and easily get others on board with their ideas. As a result, they also know how to solve complex problems or long-term conflicts. Compared to other government employees, your aptitude for solving problems is below average.",
                  "bovengemiddeld": "People with strong problem-solving skills can take a beating and do not give up easily. They persevere and easily get others on board with their ideas. As a result, they also know how to solve complex problems or long-term conflicts. Your aptitude for problem solving is above average.",
                  "gemiddeld": "People with strong problem-solving skills can take a beating and do not give up easily. They persevere and easily get others on board with their ideas. As a result, they also know how to solve complex problems or long-term conflicts. Your aptitude for solving problems is comparable to that of the average government employee.",
                  "hoog": "People with strong problem-solving skills can take a beating and do not give up easily. They persevere and easily get others on board with their ideas. As a result, they also know how to successfully resolve complex problems or long-term conflicts. Compared to the average government employee, you have a strong aptitude for solving problems.",
                  "laag": "People with strong problem-solving skills can take a beating and do not give up easily. They persevere and easily get others on board with their ideas. As a result, they also know how to solve complex problems or long-term conflicts. Compared to the average government employee, you have a less strong aptitude for solving problems."
                },
                "label": "Resolving problems"
              },
              "samenwerken": {
                "descriptions": {
                  "benedengemiddeld": "You naturally have a lesser aptitude for collaboration than the average government employee. To work well together, you must dare to trust others and communicate well with each other. You also have to take responsibility.",
                  "bovengemiddeld": "You naturally have an above-average talent for collaboration. The skill of collaboration consists of taking responsibility, daring to trust others and communicating with each other.",
                  "gemiddeld": "You naturally have an average aptitude for collaboration compared to other government employees. To work well together, you must dare to trust others and communicate well with each other. You also have to take responsibility.",
                  "hoog": "You naturally have a strong aptitude for collaboration. The skill of collaboration consists of taking responsibility, daring to trust others and communicating with each other.",
                  "laag": "You naturally have less aptitude for collaboration than the average government employee. To work well together, you must dare to trust others and communicate well with each other. You also have to take responsibility."
                },
                "label": "To collaborate"
              },
              "sociale_culturele_vaardigheden": {
                "descriptions": {
                  "benedengemiddeld": "Compared to the reference group, you naturally have more difficulty taking someone's background into account. In order to interact well with people from different backgrounds, it is important that you are open to different views and ideas. You also need good social antennas and you must be open to self-improvement.",
                  "bovengemiddeld": "Compared to the reference group, you naturally easily take someone's background into account. In order to interact well with people from different backgrounds, it is important that you are open to different views and ideas. You also need good social antennas and you are open to self-improvement.",
                  "gemiddeld": "Compared to the reference group, you are doing about as well as the average in taking someone's background into account. In order to interact well with people from different backgrounds, it is important that you are open to different views and ideas. You also need good social antennas and you must be open to self-improvement.",
                  "hoog": "Compared to the reference group, you naturally easily take someone's background into account. In order to interact well with people from different backgrounds, it is important that you are open to different views and ideas. You also need good social antennas and you are open to self-improvement.",
                  "laag": "Compared to the reference group, you naturally have difficulty taking someone's background into account. In order to interact well with people from different backgrounds, it is important that you are open to different views and ideas. You also need good social antennas and you must be open to self-improvement."
                },
                "label": "Social and cultural skills"
              },
              "zelfregulering": {
                "descriptions": {
                  "benedengemiddeld": "In work, today and certainly in the future, it is becoming increasingly important that you as an employee do more than simply follow orders. That is why it is important that you take responsibility for planning your own work and completing it as planned. Compared to other government employees, you naturally have a little more difficulty regulating your own work.",
                  "bovengemiddeld": "In work, today and certainly in the future, it is becoming increasingly important that you as an employee do more than simply follow orders. That is why it is important that you take responsibility for planning your own work and completing it as planned. Compared to other government employees, you have an above-average aptitude for regulating your work.",
                  "gemiddeld": "In work, today and certainly in the future, it is becoming increasingly important that you as an employee do more than simply follow orders. That is why it is important that you take responsibility for planning your own work and completing it as planned. You have an average aptitude for this compared to other government employees.",
                  "hoog": "In work, today and certainly in the future, it is becoming increasingly important that you as an employee do more than simply follow orders. That is why it is important that you take responsibility for planning your own work and completing it as planned. You have a natural talent for regulating your work.",
                  "laag": "In work, today and certainly in the future, it is becoming increasingly important that you as an employee do more than simply follow orders. That is why it is important that you take responsibility for planning your own work and completing it as planned. Regulating your work naturally suits you less well."
                },
                "label": "Self-regulation"
              }
            },
            "titles": {
              "thinking_styles": "Thinking styles: How do I collaborate with technology?",
              "work_competences": "Work competencies: How do I perform my work?"
            }
          },
          "title": "Personal results overview",
          "values": {
            "descriptions": {
              "demotivators": {
                "aandacht": "You prefer to be in the background. You do not enjoy being in the limeligt.",
                "analyseren": "You find analysing problems demotivating, and you prefer not to do it.",
                "autonomie": "You are not particularly autonomous and independent. You prefer to consult with others and let them make decisions for you.",
                "beinvloeden": "You do not like having to influence others.",
                "carriere": "You may be averse to status and the need to achieve. Being asked to focus on these factors will probalby have a demotivating effect on you.",
                "concrete_resultaten": "Having to achieve concrete results is more likely to demotivate than stimulate you. You are more process-driven than result-driven.",
                "creatief_denken": "You find the need to be creative demotivating rather than stimulating.",
                "financiele_beloning": "Financial targets can demotivate you. You have no interest whatsoever in financial matters so may feel demotivated if required to deal with them.",
                "fysiek_actief_zijn": "You prefer to avoid physical activity at work. You experience too much physical activity at work negatively.",
                "hectiek": "You prefer a quiet environment where you know what to expect and where you can quietly get on with your work.",
                "hulp_verlenen": "Giving directly help to others demotivates you.",
                "kwaliteit": "You are more focused on the big picture than on the details. You may resent high quality requirements.",
                "ondernemen": "You are quite happy leaving the initiative to others. People should not expect you to take the initiative, you find it demotivating.",
                "ontwikkelen": "You do not feel the need to develop further. You do not particularly enjoy doing so. Maybe you think the skills you already have are good enough.",
                "samenwerking": "You prefer to work alone than with others. Having to work with others, particularly having to coordinate with them, frustrates you rather than giving you any pleasure.",
                "taakuitdaging": "New or difficult challenges demotivate you. You prefer knowing you are up to dealing with your tasks.",
                "waardering_en_erkenning": "You are not sensitive to praise and recognition. They are more likely to put you off.",
                "zekerheid_en_stabiliteit": "Security and stability demotivate you. You prefer not knowing exactly where you stand and you like surprises.",
                "zinvolle_bijdrage": "You are not an idealist. In fact, you would probably find it demotivating to work for a social organisation."
              },
              "neutral_motivators": {
                "aandacht": "You are not on the lookout for opportunities to stand in the limelight. On the contrary, you do not mind at all when not all eyes are focussed on you.",
                "analyseren": "Intellectual challenges or intricate problems do not motivate you much but you are not put off by them.",
                "autonomie": "Independence and autonomy in your work do not motivate you very much. You do not mind having to answer to others.",
                "beinvloeden": "You do not find it very important to be influential: You are happy to be led by others.",
                "carriere": "Status and having a career only stimulate you to a limited extent. You are not particularly interested in reaching a senior position.",
                "concrete_resultaten": "You do not find it necessary to work towards clear or tangible results. You are also happy to be more process-oriented.",
                "creatief_denken": "You do not feel the need to be creative or to be a creative thinker in your work.",
                "financiele_beloning": "You appreciate financial rewards but they are not your main interest.",
                "fysiek_actief_zijn": "You do not mind physical activity in your work.",
                "hectiek": "You are not particularly motivated by dynamic situations. You prefer to have the time to finish your business and to focus on your work.",
                "hulp_verlenen": "Providing others with help does not particularly motivate you. You do not actively look for it.",
                "kwaliteit": "You are not particularly motivated by quality norms. The demand for high standards does not challenge you.",
                "ondernemen": "You do not always need the space to take initiative.",
                "ontwikkelen": "Being able to develop further is not particularly important to you at the moment. You are more likely to want to make use of your present skills and knowledge.",
                "samenwerking": "You do not mind having to work with others. You could be a member of a team, but you could also work very well on your own. You do not have the express need to belong somewhere or to work in a team.",
                "taakuitdaging": "Challenging work is not a particularly strong motivating factor for you. You do not necessarily want to be regularly challenged in your work.",
                "waardering_en_erkenning": "Although praise and recognition are nice, they only motivate you to a limited extent. You are probably more sensitive to your own opinion of your actions than to that of others.",
                "zekerheid_en_stabiliteit": "Security and stability are not that important to you. You do not mind not knowing where you stand.",
                "zinvolle_bijdrage": "Making a useful contribution to society does not particularly motivate you. It is not important to you whether or not your work is meaningful to others."
              },
              "primary_motivators": {
                "aandacht": "You like being in the limelight and in the centre of attention. You like people to look up to you. You would like a job with a certain amount of status. A danger here is that you demand too much of the limelight or do not give others the space they need.",
                "analyseren": "You find analysing and contemplating issues and problems motivating. You enjoy brain teasers and having to think things through. A danger here is that you may over-think things and get stuck into a problem for too long.",
                "autonomie": "You enjoy autonomy and being able to determine for yourself how you do your work. You find freedom and independence very stimulating. Being monitored closely or told what to do can irritate you. A danger here is that others may find you too independent and stubborn. Being well-embedded in an organisation is important to you. Working completely independently may be an option for you.",
                "beinvloeden": "You find it motivating to be able to influence others. You like seeing your views reflected in decisions and in how things are done. Convincing and motivating others appeals to you. The danger is that you are less receptive to the opinions of others or that you are too dominant.",
                "carriere": "It is important to you to be an achiever, to get ahead in your job. Status and prestige play a role in this. You are quite competitive, with yourself and with others. There is a risk that you focus too much on appearance; make sure that your ambition does not get the better of you and that you can also find intrinsic motivation.",
                "concrete_resultaten": "You like to work towards a clear and concrete goal. You find it stimulating to have a clear end result in which your direct contribution is visible. You risk getting lazy if you do not regularly achieve concrete results.",
                "creatief_denken": "You want to use your creativity, generate ideas and think out of the box. To this end you look for new solutions. A danger here may be that you have too many ideas to be able to carry them all out, or that your ideas may not always be practical.",
                "financiele_beloning": "You are motivated by a good financial reward. You are especially stimulated by financial incentives such as targets and variable remuneration. This is just as relevant if you are achieving financial results for your employer or for a contracting agent. A danger here is that you will take on work that you probably should not, for example because it is less satisfying in other areas, merely because of the financial incentive.",
                "fysiek_actief_zijn": "You like to be physically active. You find physical activity and sports stimulating. This means that you enjoy physical work. Because that is not always possible, you will need to satify this need in your private life by, for example, taking up a sport. There is a risk that you may be too restless for an office job.",
                "hectiek": "You like to be surrounded by action and movement: you find a hectic environment stimulating. You like to keep busy. If things are quiet at work you may slacken and lose concentration.",
                "hulp_verlenen": "You find it very satisfying to be of service to others or to offer them concrete help. You are thus very focused on others' well-being. A danger here is that you may go too far and fail to protect your own boundaries.",
                "kwaliteit": "You find it stimulating to provide good quality. Good is not always good enough for you, you want to meet to high standards. The danger is that there is not always enough time for that or that others do not meet you very high quality standards.",
                "ondernemen": "You want to be enterprising. You enjoy taking initiatives and making the most of opportunities. You can do this as a private entrepreneur but also within an organisation which offers space for entrepreneurship. A disadvantage of this motivating factor is that you may be overly optimistic and take too many risks.",
                "ontwikkelen": "You find it important to develop and gain new knowledge and skills. In your book, standing still is the same as taking a step backwards. You will often also enjoy helping others to develop themselves further. There is a danger that you quickly lose interest in things and want to move on to something new.",
                "samenwerking": "You find it important to work with others. You enjoy being in direct contact with others and to work together on a common project or towards a common goal. The danger is that you may slacken when you work independently or alone.",
                "taakuitdaging": "You find challenging tasks which ask a lot of you stimulating. You want to get the best out of yourself. There is a danger that you quickly need to move onto something new. You may also take on too much, thus running the risk that things will not go well.",
                "waardering_en_erkenning": "You are very motivated by praise and recognition. You find compliments and the gratitude of others encouraging and stimulating. There is a danger that you may become too dependent on appreciation. Make sure you continue to get satisfaction from the work itself.",
                "zekerheid_en_stabiliteit": "You like security and stability. You like to know where you stand. At work you like to have a clearly defined set of tasks so that you know what is expected of you. There is a danger that you may not be able to deal well with change and insecurity.",
                "zinvolle_bijdrage": "You like to make a useful contribution to society or to charities. It is important to you that your work is useful to others. This can be achieved via your job or the organisation for which you work. There is a danger that you are too idealistic and therefore less realistic or that you put your own interests to one side."
              },
              "secondary_motivators": {
                "aandacht": "You like being in the limelight and being the centre of attention. You like people to look up to you.",
                "analyseren": "You find analysing and contemplating issues and problems motivating. You enjoy brain teasers and having to think things through.",
                "autonomie": "You enjoy autonomy and being able to determine for yourself how you do your work. You find freedom and independence very stimulating.",
                "beinvloeden": "You find it motivating to be able to influence others. You like seeing your views reflected in decisions and in how things are done. Convincing and motivating others appeals to you.",
                "carriere": "It is important to you to be an achiever, to get ahead in your job. Status and prestige play a role in this. You are quite competitive, with yourself and with others.",
                "concrete_resultaten": "You like to work towards a clear and concrete goal. You find it stimulating to have a clear end result in which your direct contribution is visible.",
                "creatief_denken": "You want to use your creativity, generate ideas and think out of the box. To this end you look for new solutions.",
                "financiele_beloning": "You are motivated by a good financial rewards. You are especially stimulated by financial incentives such as targets and variable remuneration. This is just as relevant if you are achieving financial results for your employer or for a contracting agent.",
                "fysiek_actief_zijn": "You like to be physically active. You find physical activity and sports stimulating. This means that you enjoy physical work. Because that is not always possible, you will need to satify this need in your private life by, for example, taking up a sport.",
                "hectiek": "You like to be surrounded by action and movement: you find a hectic environment stimulating. You like to keep busy.",
                "hulp_verlenen": "You find it very satisfying to be of service to others or to offer them concrete help. You are thus very focused on others' well-being.",
                "kwaliteit": "You are motivated by providing good quality. Good is not always good enough for you, you want to meet to high standards.",
                "ondernemen": "You want to be enterprising. You enjoy taking initiatives and making the most of opportunities. You can do this as a private entrepreneur but also within an organisation which offers space for entrepreneurship.",
                "ontwikkelen": "You find it important to develop and gain new knowledge and skills. In your book, standing still is the same as taking a step backwards. You will often also enjoy helping others to develop themselves further.",
                "samenwerking": "You find it important to work with others. You enjoy being in direct contact with others and to work together on a common project or towards a common goal.",
                "taakuitdaging": "You find challenging tasks which ask a lot of you stimulating. You want to get the best out of yourself.",
                "waardering_en_erkenning": "You are very motivated by praise and recognition. You find compliments and the gratitude of others encouraging and stimulating.",
                "zekerheid_en_stabiliteit": "You like security and stability. You like to know where you stand. At work you like to have a clearly defined set of tasks so that you know what is expected of you.",
                "zinvolle_bijdrage": "You like to make a useful contribution to society or to charities. It is imprtant to you that your work is useful to others. This can be achieved via your job or the organisation for which you work."
              }
            },
            "header": "Career values",
            "keys": {
              "aandacht": "Exposure",
              "analyseren": "Analysing",
              "autonomie": "Autonomy",
              "beinvloeden": "Influencing",
              "carriere": "Career",
              "concrete_resultaten": "Tangible results",
              "creatief_denken": "Creative thinking",
              "financiele_beloning": "Financial reward",
              "fysiek_actief_zijn": "Being physically active",
              "hectiek": "Dynamism",
              "hulp_verlenen": "Helping others",
              "kwaliteit": "Quality",
              "ondernemen": "Enterprising",
              "ontwikkelen": "Developing",
              "samenwerking": "Cooperation",
              "taakuitdaging": "Challenging tasks",
              "waardering_en_erkenning": "Praise and recognition",
              "zekerheid_en_stabiliteit": "Security and stability",
              "zinvolle_bijdrage": "Useful contribution"
            },
            "titles": {
              "demotivators": "What are your demotivating factors?",
              "neutral_motivators": "What are your neutral motivators?",
              "primary_motivators": "What are your primary motivators?",
              "secondary_motivators": "What are your secondary motivators?"
            }
          }
        },
        "ixly_test": {
          "calculating_result": "Result is being calculated...",
          "component": "Element",
          "document_option_for": "Document options for",
          "download_modal_header": "Report download",
          "download_report": "Download report",
          "generating_report": "Report is being generated...",
          "make_visible_confirm": "This action can not be reversed. Are you sure you want to continue?",
          "no_test_selected_desc": "Open the element settings and select an assessment",
          "no_test_selected_title": "No assessment selected",
          "norm_group_for": "Available norm groups",
          "payment_required": "Your organisation has insufficient credits to download this report.",
          "program_subtasks": "%{program} consists of %{subtest_names}",
          "report_name": "Report Name",
          "report_settings": "Report settings",
          "report_visible_for_advisor": "Result visible to coach",
          "report_visible_for_both": "Result visible to both",
          "report_visible_for_candidate": "Result visible to candidate",
          "resume_subtest": "Resume %{test}",
          "resume_test": "Resume the assessment",
          "save_date": "Date Saved",
          "select_document_option_placeholder": "Please select the desired document option for this report:",
          "select_norm_group_placeholder": "Please select the desired norm for this report:",
          "settings_modal_header": "Report settings",
          "skip_verification": "Skip",
          "start_review": "Begin the review",
          "start_subtest": "Start %{test}",
          "start_test": "Start the assessment",
          "start_verification": "Start",
          "start_verification_subtest": "Begin the verification of %{test}",
          "start_verification_test": "Begin the verification",
          "task": "Task",
          "test_completed": "Finished",
          "test_inprogress": "Started",
          "test_pending": "Not yet started",
          "test_reviewrequired": "Review required",
          "test_verificationrequired": "Verification required",
          "verification_completed": "The verification test has been finished.",
          "verification_restart": "Restart",
          "verification_skipped": "Verification has been skipped. You can however restart verification using the button.",
          "verification_skipped_processing": "Verification has been skipped. You can however restart verification using the button, after the report has been generated.",
          "view_results": "View results",
          "waiting_verification_start": "The candidate has not yet started the verification test; verification can still be skipped."
        },
        "job_market_scan": {
          "compare": "Compare jobs",
          "description_created": "Job market Opportunities calculates your current and future position in the Dutch job market. After filling in some information about your current education and job, a score will be given between 1 (very bad) and 99 (very good). At the end a report with detailed results can be downloaded.",
          "description_finished": "Job market Opportunities is finished! All results have been calculated. Click the 'Download' button to download the report (again) as a PDF document.",
          "description_in_progress": "Job market Opportunities has not been fully finished yet. This is required to generate the report. Click the button below to continue.",
          "download": "Download",
          "download_alert": "Job market Opportunities was finished over 6 months ago; no new reports can be generated.",
          "download_description_1": "Download all saved reports below",
          "download_description_2": "to generate a new report with updated vacancies, educations and courses (available for 6 months)",
          "download_link_text": "Or click here",
          "download_title": "Downloads reports",
          "resume": "Resume",
          "start": "Start",
          "terms_and_conditions": "I accept the terms and conditions",
          "title": "Job market Opportunities Dutch version",
          "title_finished": "Results",
          "working": "Working..."
        },
        "job_market_scan_en": {
          "title": "Job market Opportunities",
          "title_finished": "Results"
        },
        "logbook": {
          "back": "Back",
          "content_placeholder": "the content goes here",
          "delete": "Delete",
          "help_text": "You can use your logbook to record pages with notes.",
          "logbook": "Logbook",
          "new_page": "New page",
          "title_placeholder": "logbook page title"
        },
        "quiz": {
          "add_answer": "Add answer",
          "answer_correct": "Correct",
          "answer_incorrect": "Incorrect",
          "answer_placeholder": "Enter an answer here",
          "answer_placeholder_viewer": "The candidate enters an answer here",
          "answer_text_placeholder": "Enter answer %{index} here",
          "back_current": "Previous",
          "back_started": "Back",
          "correct_placeholder": "Enter the correct answer here",
          "delete": "Delete",
          "next_add": "Add question",
          "next_check": "Check",
          "next_current": "Next",
          "question_of": "QUESTION %{index} OF %{length}",
          "question_text_placeholder": "Enter the question here",
          "question_type_choice": "Multiple choice",
          "question_type_open": "Open-ended",
          "selections_number": "Number of selections",
          "selections_to": "to",
          "start_text_checking": "View answers",
          "start_text_resume": "Resume",
          "start_text_set_up": "Set up",
          "start_text_start": "Start",
          "start_text_viewer": "View questions"
        },
        "report_visibility": {
          "advisor": {
            "both": "The report is now available to the candidate. The permission for the candidate to download/view the report can be withdrawn by deactivating the slider on the right.",
            "self": "The report is not yet available to the candidate. By activating the slider on the right, you give the candidate permission to download and view the report."
          },
          "candidate": {
            "both": "The report is now available to the coach. The permission for the coach to download/view the report can be withdrawn by deactivating the slider on the right.",
            "self": "The report is not yet available to the coach. By activating the slider on the right, you give the coach permission to download and view the report."
          }
        },
        "search_job": {
          "delete": "Delete",
          "favorite": "Favourite",
          "favorites": "Favourites",
          "location": "Where?",
          "no_favorites_chosen": "(no favourites chosen)",
          "no_jobs_found": "(no jobs found)",
          "query": "What?",
          "search": "Search",
          "view": "View"
        },
        "templates": {
          "add": "Add",
          "add_template": "Add template",
          "added": "Selectable",
          "builder": {
            "add_column": "Add column",
            "add_element": "Add section",
            "add_row": "Add row",
            "block": "Block",
            "circle": "Circle",
            "date_formats": {
              "D": "day number (1 or 2 digits)",
              "DD": "day number (2 digits)",
              "M": "month nummer (1 or 2 digits)",
              "MM": "month number (2 digits)",
              "MMM": "month name (abbreviated)",
              "MMMM": "month name",
              "YY": "year (2 digits)",
              "YYYY": "year (4 digits)",
              "ddd": "day name (abbreviated)",
              "dddd": "day name"
            },
            "description": "Description",
            "destroy_confirm_column": "Are you sure you want to delete this column?",
            "destroy_confirm_element": "Are you sure you want to delete this section?",
            "destroy_confirm_row": "Are you sure you want to delete this row?",
            "global": "For all organisations?",
            "header": "Header",
            "header_font_size": "Header font size",
            "height": "Height",
            "hide_borders": "Hide borders",
            "hide_preview": "Hide preview",
            "margin_from_top": "Top margin",
            "optional": "Optional",
            "padding": "Padding",
            "shape": "Shape",
            "show_borders": "Show borders",
            "show_header": "Show header",
            "show_icon": "Show icon",
            "show_preview": "Show preview",
            "square": "Square",
            "title": "Title",
            "type": "Section type",
            "variable_add_option": "Add option",
            "variable_date": "Date",
            "variable_date_format": "Format",
            "variable_dropdown": "Selection",
            "variable_help_text": "Help text",
            "variable_options": "Options",
            "variable_text": "Text",
            "variable_type": "Variable type",
            "width": "Width"
          },
          "change_template": "Change template",
          "default": "Selected",
          "delete": "Delete",
          "deselect": "Deselect",
          "destroy_confirm": "Are you sure you want to delete this template?",
          "documentation": "(click here for documentation on the CV & letter builder)",
          "edit": "Edit",
          "edit_replacements": "Edit help texts",
          "filler": {
            "add_item": "Add item",
            "add_optional": "Add this section to the document",
            "change": "Change",
            "crop_and_save": "Crop & Save",
            "download_docx": "Download DOCX",
            "download_pdf": "Download PDF",
            "remove": "Remove",
            "remove_optional": "Remove this section from the document",
            "upload_image": "Select an image"
          },
          "image": "Image",
          "list": "List",
          "no_template_selected": "No template selected",
          "not_ready_yet_text": "Not ready yet",
          "open": "Open",
          "remove": "Remove",
          "remove_template": "Remove template",
          "select": "Select",
          "select_template": "Select a template",
          "switch_template": "Template selection",
          "text": "Text"
        },
        "text": {
          "placeholder": "Click here to enter text (highlight text for extra styling options)"
        },
        "vacancy": {
          "action": "Action",
          "action_deadline": "Action deadline",
          "action_placeholder": "apply, prepare first interview...",
          "back": "Back",
          "before": "before",
          "delete": "Delete",
          "help_text": "You can use the vacancy manager to log details of vacancies you are interested in and to plan follow-up actions.",
          "new_vacancy": "New vacancy",
          "next_action": "Below, the next action and its deadline can be set:",
          "notes": "Notes",
          "notes_placeholder": "additional notes",
          "status": "Status",
          "statuses": {
            "created": "Created",
            "first_interview": "First interview",
            "hired": "Hired",
            "interested": "Interested",
            "letter_sent": "Letter sent",
            "other": "Other",
            "rejected": "Rejected",
            "second_interview": "Second interview"
          },
          "title": "Title",
          "title_placeholder": "Barista at Starbucks",
          "vacancy_link": "Vacancy link",
          "vacancy_link_placeholder": "add vacancy link",
          "vacancy_manager": "Vacancy Manager",
          "view_vacancy": "view vacancy"
        },
        "visibility": {
          "advisor": {
            "both": "The document is now available to the candidate. The permission for the candidate to download/view the document can be withdrawn by deactivating the slider on the right.",
            "self": "The document is not yet available to the candidate. By activating the slider on the right, you give the candidate permission to download and view the document."
          },
          "candidate": {
            "both": "The document is now available to the coach. The permission for the coach to download/view the document can be withdrawn by deactivating the slider on the right.",
            "self": "The document is not yet available to the coach. By activating the slider on the right, you give the coach permission to download and view the document."
          }
        },
        "youtube_video": {
          "url_placeholder": "Open the element settings and enter the full URL of a YouTube video"
        }
      },
      "cost_centers": {
        "number_of_users": "%{number} active"
      },
      "editable_empty": "(none)",
      "program_templates": {
        "number_of_pages": {
          "one": "1 step",
          "other": "%{count} steps"
        },
        "status": {
          "deleted": "Deleted",
          "editable": "Editable",
          "hidden": "Deleted",
          "published": "Published",
          "template": "Template"
        }
      },
      "programs": {
        "number_of_pages": {
          "one": "1 step",
          "other": "%{count} steps"
        },
        "status": {
          "deleted": "Deleted",
          "editable": "Editable",
          "hidden": "Deleted",
          "published": "Published",
          "template": "Template"
        }
      },
      "rooms": {
        "browser_unsupported": "This browser is not supported. Use a modern browser such as Chrome or Firefox.",
        "default_local_display_name": "me",
        "default_remote_display_name": "Anonymous"
      }
    },
    "label": {
      "search": "Search"
    },
    "labels": {
      "index": {
        "title": "Labels"
      }
    },
    "language": {
      "name": "English"
    },
    "languages": {
      "all": "All languages",
      "de": "German",
      "en": "English",
      "fr": "French",
      "it": "Italian",
      "nl": "Dutch"
    },
    "layouts": {
      "assessment": {
        "header": {
          "logout": "Log out",
          "notifications": "Notifications"
        },
        "language_menu": {
          "select_language": "Select language"
        },
        "resource_header": {
          "notifications": "Notifications",
          "profile": "Profile",
          "switch_account": "Switch to another account"
        },
        "users": {
          "dropdown": {
            "notifications": "Notifications"
          }
        }
      },
      "mailer": {
        "personal_info": "Change personal information",
        "privacy_policy": "Privacy Policy",
        "rights": "Ixly 2023 Vleutensevaart 100 3532 AD Utrecht",
        "terms": "Terms and Conditions",
        "unsubscribe": "Unsubscribe"
      }
    },
    "legacy_invoices": {
      "show": {
        "title": "Test-Toolkit invoice"
      }
    },
    "login_mailer": {
      "confirmation_button": {
        "text": "Set up account"
      },
      "welcome_mail": {
        "subject": "Welcome"
      }
    },
    "media_item_share": {
      "empty_shared_with_list": "There is nobody to share a media item with",
      "only_shared_with": {
        "one": "%{count} organisation manager",
        "other": "%{count} organisation managers"
      },
      "send": "Share",
      "share_with": "Share with",
      "shared_with": {
        "one": "and %{count} organisation manager",
        "other": "and %{count} organisation managers"
      }
    },
    "media_item_shares": {
      "deleted": "Media item sharing was successfully deleted.",
      "drawer_content": {
        "download": "Download"
      },
      "failed_create": "The media item could not be shared. Select a user to share it with.",
      "index": {
        "created_at": "Date",
        "filename": "Name",
        "relates_to": "Relates to"
      },
      "success_create": "The media item was successfully shared."
    },
    "media_items": {
      "add": "Add file",
      "allowed_formats": "Allowed file-formats:",
      "cannot_delete": "Deletion failed, a media item that's in use cannot be deleted",
      "created": "Created",
      "deleted": "Media item was successfully deleted.",
      "destroy": "Delete",
      "download": "Download",
      "failed_upload": "File size is too big (maximum is 50 MB)",
      "files": "My files",
      "index": {
        "created_at": "Date",
        "filename": "Name",
        "relates_to": "Relates to",
        "shared_with": "Shared with"
      },
      "relates_to": "Relates to",
      "shared_by": "Shared by",
      "shared_with": "Shared with",
      "shared_with_me": "Shared with me",
      "success_upload": "Media item was successfully created.",
      "title": "Documents & Media library",
      "upload_title": "Choose file"
    },
    "notice": {
      "bulk": {
        "candidates_enrolled": "The candidates has been notified of the newly assigned process by email",
        "send_welcome": "Welcome emails are sent"
      },
      "password_has_been_updated": "Your password has been updated"
    },
    "notification": {
      "details": {
        "assessment_finished_notification": "%{candidate} has finished %{assessment}.",
        "candidate_assessment": {
          "finished": "%{candidate} has finished %{assessment}.",
          "invited": "The assessment '%{assessment}' has been assigned to you."
        },
        "comment_notification": "%{sender} has sent you a message: \"%{message}\"",
        "credit_limit_reached_notification": "You are notified because the prepaid credits for your organisation have dropped below %{credit_limit}. We advise you to purchase additional credits to ensure you can keep generating reports.",
        "document": {
          "approved_notification": "%{sender} has approved the document %{filename}.",
          "rejected_notification": "%{sender} has rejected the document %{filename}. You can upload a new document and request to have it approved.",
          "submitted_notification": "%{sender} requests to have the document %{filename} approved.",
          "uploaded_notification": "%{sender} has uploaded the document %{filename}."
        },
        "enroller_notification": "%{candidate} has registered for '%{title}'.",
        "enrollment": {
          "coach_action_notification": "The process '%{title}' for %{candidate} has assessments which require you to select a report. After selecting reports the candidate can be invited to start the process.",
          "created_notification": "The process '%{title}' has been assigned to you."
        },
        "enrollment_page": {
          "component_action_notification": "The step %{title} in the process %{process} has a segment that requires action.",
          "done_notification": "The step %{title} in the process %{process} has been finished.",
          "rejected_notification": "The step %{title} in the process %{process} has been rejected. You can update the segments in the step and submit the step again.",
          "submitted_notification": "The step %{title} in the process %{process} has been submitted. You can review and approve or reject the step."
        },
        "media_item_notification": "The file %{filename} has been added to the Documents & Media Library.",
        "media_item_share_notification": "%{name} has shared  %{filename} with you in the Document & Media Library.",
        "post_notification": "The blog post %{title} has been shared with you.",
        "postmark": {
          "inactive_recipient": "We're sorry, something went while sending an email to %{candidate}. Please check the email address %{email} for any mistakes and contact our customer support team at info@ixly.nl with details of the issue if the email address is correct.",
          "invalid_address": "We're sorry, the provided email %{email} of %{candidate} is incorrect. Please correct the email address to ensure that %{candidate} can receive emails properly."
        },
        "review_required": "%{candidate_name}'s assessment %{assessment_title} requires review.",
        "suppressed_email": "We tried to send an email to %{name}, but it was unsuccessful due to the %{reason} reason. Make sure the used email address is correct (%{email}).\n%{name} has not received an email.\n",
        "unsuppressed_email": "We are able to send messages through %{email} to %{name} again.",
        "verification_required": "%{candidate_name}'s assessment %{assessment_title} requires verification.\nChoose whether the verification can be started, or whether the verification can be skipped in order to finish the assessment.\n",
        "verification_started": "The assessment %{assessment_title} has a verification test that can be started"
      },
      "type": {
        "AppointmentAcceptedNotification": "Meeting accepted",
        "AppointmentCancelledNotification": "Meeting cancelled",
        "AppointmentDeclinedNotification": "Meeting declined",
        "AppointmentSubmittedNotification": "Meeting proposed",
        "assessment_finished_notification": "Assessment finished",
        "candidate_assessment": {
          "finished": "Assessment finished",
          "invited": "Newly assigned assessment"
        },
        "comment_notification": "New message",
        "credit_limit_reached_notification": "Few credits left",
        "document": {
          "approved_notification": "Document approved",
          "rejected_notification": "Document rejected",
          "submitted_notification": "New approval request",
          "uploaded_notification": "File uploaded"
        },
        "enroller_notification": "New enrolment",
        "enrollment": {
          "coach_action_notification": "Report selection required",
          "created_notification": "Newly assigned process"
        },
        "enrollment_page": {
          "component_action_notification": "Action required",
          "done_notification": "Step finished",
          "rejected_notification": "Step rejected",
          "submitted_notification": "Step submitted"
        },
        "media_item_notification": "A new file was added to Documents & Media Library",
        "media_item_share_notification": "A file has been shared with you",
        "post_notification": "New blog post",
        "postmark_notification": "Unable to deliver email notification",
        "review_required": "Review required for %{assessment_title}",
        "suppressed_email": "We were unable to reach %{member_type} via email",
        "unsuppressed_email": "The %{member_type} is reachable via email again",
        "verification_required": "Verification required for %{assessment_title}",
        "verification_started": "The assessment %{assessment_title} has a verification test that can be started"
      }
    },
    "notifications_overview_mailer": {
      "notification_mail": {
        "subject": "You have received new notifications"
      }
    },
    "number": {
      "currency": {
        "format": {
          "delimiter": ",",
          "format": "%u%n",
          "negative_format": "-%u%n",
          "precision": 2,
          "separator": ".",
          "significant": false,
          "strip_insignificant_zeros": false,
          "unit": "€"
        }
      },
      "format": {
        "delimiter": ",",
        "precision": 3,
        "round_mode": "default",
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false
      },
      "human": {
        "decimal_units": {
          "format": "%n %u",
          "units": {
            "billion": "Billion",
            "million": "Million",
            "quadrillion": "Quadrillion",
            "thousand": "Thousand",
            "trillion": "Trillion",
            "unit": ""
          }
        },
        "format": {
          "delimiter": "",
          "precision": 3,
          "significant": true,
          "strip_insignificant_zeros": true
        },
        "storage_units": {
          "format": "%n %u",
          "units": {
            "byte": {
              "one": "Byte",
              "other": "Bytes"
            },
            "eb": "EB",
            "gb": "GB",
            "kb": "KB",
            "mb": "MB",
            "pb": "PB",
            "tb": "TB",
            "zb": "ZB"
          }
        }
      },
      "nth": {
      },
      "percentage": {
        "format": {
          "delimiter": "",
          "format": "%n%"
        }
      },
      "precision": {
        "format": {
          "delimiter": ""
        }
      }
    },
    "organization": "Organisation",
    "organization_products": {
      "create": {
        "created": "The products have been added to the organisation"
      },
      "destroy": {
        "already_started": "This module has already started."
      },
      "index": {
        "from_subscription": "From subscription",
        "new_button": "Add products",
        "table_header": [
          "Name",
          "Start date",
          "End date",
          "Actions"
        ],
        "title": "Products"
      },
      "new": {
        "title": "Add products to %{organization}"
      },
      "row": {
        "product_delete": "Are you sure you want to remove this module from the organisation?"
      }
    },
    "organization_status": {
      "active": "Active",
      "created": "Created",
      "deleted": "Deleted",
      "inactive": "Inactive"
    },
    "organizations": {
      "billing": {
        "details": {
          "cost_centers": {
            "in_use": "This cost centre still has active users.",
            "new_button": "New cost centre",
            "table_header": [
              "Name",
              "Description",
              "Users",
              "Kandidaten",
              "Actions"
            ],
            "title": "Cost centres"
          },
          "organization": "Organisation",
          "title": "Details"
        },
        "help_text": "Here is an overview of your subscription, usage, invoices and details",
        "info": "Choose a payment method and the credit bundle you want to purchase.",
        "invoices": {
          "legacy_invoices": "Test-Toolkit invoices",
          "no_orders": "(no orders have been made yet)",
          "previous_orders": "Previous orders",
          "title": "Invoices"
        },
        "payment": "Method of payment",
        "payment_title": "Purchase credit bundle",
        "subscription": {
          "credits": {
            "invoice": "Your credit increase has been processed! An invoice will be sent. You now have %{count} credits",
            "open": "Your credit increase has been processed! The amount will be debited automatically. You now have %{count} credits",
            "paid": "Your credit increase has been processed! You now have %{count} credits"
          },
          "mandate": {
            "activate_credit_purchase": "Activate your organisation with a credit bundle purchase so that your candidates can get started!",
            "change_html": "<a href='%{link}' data-method='post'>Click here to authorise a new direct debit</a>.",
            "credit_card": "the credit card with number XXXX XXXX XXXX %{number}.",
            "direct_debit": "the IBAN account with number %{number}.",
            "not_valid_html": "Your users can only log in after an authorisation payment. This authorises Ixly to set up a direct debit for the subscription. <a class='alert-link' href='%{link}' target='_blank'>Our terms and services</a> apply.",
            "open": "The authorisation payment is still being processed; refresh the page for an update",
            "paid": "The authorisation payment was successful! From now on your subscription will be debited from %{number}",
            "valid": "Your costs are debited automatically from"
          },
          "not_active": "Your organisation has no active subscription",
          "order": {
            "canceled": "The payment was cancelled"
          },
          "payment": {
            "automatic_method": {
              "explain": "Authorize by purchasing an initial credit bundle for smooth and effortless credit transactions, now and in the future",
              "title": "Automatic payment"
            },
            "direct_method": {
              "info_text1": "Experience direct payments without extra costs with iDEAL",
              "info_text2": "Safe, fast and easy.",
              "title": "Direct payment"
            },
            "info_text1": "No more worries about payments!",
            "info_text2": "With direct debit you can arrange it quickly and easily.",
            "manual_method": {
              "confirmation": "Are you sure? This means you will pay €9.99 per invoice starting from June.",
              "explain": "As of July 1, we will charge € 9.99 administration costs for each invoice",
              "info_text1": "Do you prefer to pay afterwards?",
              "info_text2": "This is possible with manual payment.",
              "please_note": "Please note",
              "title": "Manual payment"
            },
            "mollie": {
              "conditional1": "Manual payments are a thing of the past",
              "conditional2": "You have full control over your finances",
              "conditional3": "Save on extra costs and hassle with invoices"
            },
            "payment_type": {
              "automatic": "Automatic payment",
              "direct": "Direct payment",
              "invoice": "Manual payment"
            }
          },
          "subscriptions": {
            "active_advisors_title": "Active advisors",
            "active_candidates": "(from %{max_candidates})",
            "active_candidates_title": "Active candidates",
            "active_premium_candidates_title": "Active premium candidates",
            "active_users": "(from %{max_users})",
            "active_users_title": "Active coaches",
            "already_started": "This subscription has already started.",
            "credits": "Credits:",
            "downgrade": "Downgrade",
            "downgrades": "Downgrades",
            "incidental_subscription_credits_left": "If any credits are left over when the new subscription starts, they will be used first",
            "new_button": "New subscription",
            "prices": "Prices",
            "purchase": "Purchase",
            "settings": "Specifications",
            "table_header": [
              "Name",
              "Start date",
              "End date",
              "Actions"
            ],
            "title": "Subscriptions",
            "total_advisors": "(of %{max_users})",
            "total_advisors_title": "All advisors",
            "total_candidates": "(of %{max_candidates})",
            "total_candidates_title": "All candidates",
            "total_coaches_title": "All coaches",
            "total_premium_candidates_title": "All premium candidates",
            "upgrade": "Upgrade",
            "upgrades": "Upgrades",
            "user_counts": "User counts"
          },
          "title": "Subscription"
        },
        "title": "Billing",
        "usage": {
          "active_candidates": {
            "prepaid": "prepaid",
            "table_header": [
              "Candidate",
              "Activated at",
              "Price"
            ],
            "title": "Active candidates"
          },
          "active_coaches": {
            "table_header": [
              "Coach",
              "Activated at",
              "Price"
            ],
            "title": "Active coaches"
          },
          "aycr_periods": {
            "table_header": [
              "Candidate",
              "Valid for",
              "Price"
            ],
            "title": "Candidats with started assessments"
          },
          "generated_reports": {
            "table_header": [
              "Assessment",
              "Report",
              "Candidate",
              "Generated at",
              "Credits",
              "Price"
            ],
            "title": "Generated reports"
          },
          "period": "Period",
          "premium_components": {
            "table_header": [
              "Element",
              "Candidate",
              "Started at",
              "Price"
            ],
            "title": "Started premium components"
          },
          "title": "Usage",
          "total_with_vat": "Total costs including %{vat}% VAT: %{price}"
        }
      },
      "edit": {
        "available_languages_confirm": "You are about to disable languages that are used by your users. Their language will be changed to your organisation's default language. Are you sure?",
        "explanation": "The selected language will be the default setting for all new organisation users.",
        "logo": "Logo",
        "title": "Organisation settings",
        "upload_logo": "Click here to upload a logo"
      },
      "index": {
        "table_header": [
          "Name",
          "Subscription",
          "Users",
          "Processes",
          "Status",
          "Actions"
        ]
      },
      "payment_method_mollie_direct": {
        "success": "Direct payment was successfully created"
      },
      "row": {
        "impersonate": "Log in as",
        "organization_delete": "Are you sure you want to delete this organisation?"
      },
      "texts": {
        "welcome_mail": {
          "example_mail_body": "Hi, %{user_name}. Welcome to Assessment Platform.\n\nA new account has been created for you at Assessment Platform.\n\nSet you password immediately to gain access to your personal environment.\n\n<a href='%{password_set_link}'>Set up account</a>.\n\nIt is important to choose a strong and unique password to protect your account. Therefore, make sure that your new password is at least ten characters long and is a combination of letters, numbers and symbols.\n\nYours sincerely,\n%{organization_name}"
        }
      },
      "update": {
        "organization_updated": "The organisation has been updated"
      }
    },
    "otp_secret": {
      "cancel_otp": "Cancel the authenticator process",
      "input_the_code": "Enter the code provided by the authenticator app",
      "scan_the_code": "Scan the code with an authenticator app such as %{app_1} or %{app_2}."
    },
    "page_status": {
      "created": {
        "seen": "Unread",
        "set": "Open",
        "submit": "Open"
      },
      "done": {
        "seen": "Seen",
        "set": "Finished",
        "submit": "Approved"
      },
      "hidden": "Hidden",
      "optional": "Optional",
      "rejected": {
        "submit": "Again"
      },
      "submitted": {
        "submit": "Submitted"
      }
    },
    "pagy": {
      "combo_nav_js": "<label>Page %{page_input} of %{pages}</label>",
      "info": {
        "multiple_pages": "Displaying %{item_name} <b>%{from}-%{to}</b> of <b>%{count}</b> in total",
        "no_items": "No %{item_name} found",
        "single_page": "Displaying <b>%{count}</b> %{item_name}"
      },
      "item_name": {
        "one": "item",
        "other": "items"
      },
      "items_selector_js": "<label>Show %{items_input} %{item_name} per page</label>",
      "nav": {
        "gap": "&hellip;",
        "next": "Next&nbsp;&rsaquo;",
        "prev": "&lsaquo;&nbsp;Prev"
      }
    },
    "pallet": {
      "image": {
        "label": "Select an image"
      },
      "ixly_test": {
        "description": "Assessment description",
        "label": "Assessment name"
      },
      "label": "Label",
      "quiz": {
        "title": "Quiz"
      },
      "templates": {
        "title": "CV & Letter Builder"
      }
    },
    "password": {
      "forgot_password": {
        "help_text": "Enter your email address to request a new password",
        "request": "Request",
        "title": "Forgot your password?"
      },
      "reset_password_form": {
        "button": "Change",
        "help_text": "Enter your new password and confirm it",
        "invalid_link": "Your reset link has either already been used, has expired or is invalid: please request a new one",
        "title": "Change password"
      },
      "send_reset_password_link": {
        "help_text": "Within a few minutes you will receive an email link to create a new password",
        "title": "Password reset email sent",
        "unknown_email": "Unknown email address"
      },
      "update_password_with_reset_token": {
        "invalid_link": "Your reset link has either already been used, has expired or is invalid: please request a new one",
        "password_has_been_reset": "Your password was changed and you have logged in successfully",
        "password_is_of_insufficient_length": "The new password must be at least 10 characters",
        "passwords_dont_match": "Password and confirmation do not match"
      }
    },
    "placeholder": {
      "search": "Search"
    },
    "posts": {
      "available_locales": "Available languages",
      "destroy": {
        "destroyed": "The post has been deleted"
      },
      "destroy_confirm": "Are you sure you want to delete this post?",
      "edit": "Edit post",
      "edit_rules": "Edit sharing rules",
      "empty": "(no posts have been added yet)",
      "help_text": "Manage these posts and their sharing rules",
      "hide_more": "Hide",
      "index": {
        "title": "Blog posts"
      },
      "new": "New post",
      "open": "Open",
      "post_rules": {
        "add": "Add a sharing rule",
        "private": "(no-one yet)",
        "program_labels": {
          "multiple": ", who are assigned to <strong>processes with labels %{list}</strong>",
          "single": ", who are assigned to <strong>processes with label %{list}</strong>"
        },
        "programs": {
          "multiple": ", who are assigned to <strong>processes %{list}</strong>",
          "single": ", who are assigned to <strong>process %{list}</strong>"
        },
        "public": "(visible to everyone)",
        "roles": {
          "account_managers": "account managers",
          "accountmanagers": "account managers",
          "advisors": "advisors",
          "all_internal_users": "coaches",
          "all_users": "users",
          "coachs": "coaches",
          "contact_persons": "contact persons",
          "ixly_admins": "Ixly administrators",
          "ixly_employees": "Ixly employees",
          "organization_managers": "organisation managers",
          "selected": "All <strong>%{list}</strong>",
          "selected_advisor": "with <strong>coach %{author_name}</strong>",
          "selected_global": "from <strong>all organisations</strong>",
          "users": "candidates"
        },
        "user_labels": {
          "multiple": "with <strong>labels %{list}</strong>",
          "single": "with <strong>label %{list}</strong>"
        },
        "users": {
          "multiple": "Users <strong>%{names}</strong>",
          "single": "User <strong>%{names}</strong>"
        },
        "users_type": {
          "certain": "Share with specific users",
          "filters": "Share with roles, labels and processes",
          "public": "Public"
        }
      },
      "private": "Private",
      "published_by": "Published by",
      "shared": "Shared",
      "shared_with": "This post is shared with:",
      "show_more": "Show more",
      "sidebar": {
        "advisor": "For your own candidates",
        "global": "For all organisations",
        "organization": "For %{organization_name}",
        "shared": "Shared with you"
      },
      "status": "Status",
      "title": "Title",
      "update": {
        "updated": "The sharing rules have been updated"
      }
    },
    "prices": {
      "active": "Active",
      "amc": "AMC price",
      "bov": "BOV price",
      "cannot_modify_expired": "You cannot edit active or expired price lists",
      "created_by": "Created by",
      "does_not_exist": "Price list not found",
      "edit": "Edit price list",
      "effective_from": "Effective from",
      "expired": "Expired",
      "external_user": "Monthly fee per candidate",
      "fit": "FIT price",
      "internal_user": "Monthly fee per coach",
      "job_market_scan": "Job market Opportunities price",
      "job_market_scan_en": "Price Labor Market Opportunities in English",
      "job_suggestions": "Job Suggestions price",
      "jobfeed": "Jobfeed price",
      "new": "New price list",
      "pending": "Pending",
      "price_created": "The price list has been created",
      "price_updated": "The price list has been updated",
      "something_went_wrong": "Something went wrong",
      "status": "Status",
      "updated_at": "Updated at"
    },
    "product_program_templates": {
      "create": {
        "created": "Process templates have been added to the module",
        "product_program_templates_blank": "No process templates were selected"
      },
      "destroy": {
        "product_program_template_destroyed": "Process template has been removed from the module"
      },
      "new": {
        "title": "Add Process Templates to %{product}"
      },
      "row": {
        "product_program_template_delete": "Are you sure you want to delete these process templates from the module?"
      }
    },
    "product_status": {
      "active": "Active",
      "deactivated": "Deactivated"
    },
    "product_tasks": {
      "create": {
        "created": "The assessments have been added to the module",
        "product_tasks_blank": "No assessments were selected"
      },
      "deactivate": {
        "deactivated": "The connection with the assessment has been deactivated"
      },
      "destroy": {
        "task_destroyed": "The assessment has been removed from the module"
      },
      "new": {
        "title": "Add assessments to %{product}"
      },
      "reactivate": {
        "reactivated": "The connection with the assessment has been reactivated"
      },
      "row": {
        "task_delete": "Are you sure you want to remove this assessment from the module?"
      }
    },
    "products": {
      "create": {
        "product_created": "The module has been created",
        "title": "New module"
      },
      "deactivate": {
        "deactivated": "The module has been deactivated"
      },
      "destroy": {
        "deleted": "The module has been deleted"
      },
      "edit": {
        "title": "Edit module"
      },
      "index": {
        "new_button": "New module",
        "table_header": [
          "Name",
          "Status",
          "Status changed at",
          "Actions"
        ],
        "title": "Products"
      },
      "new": {
        "title": "New module"
      },
      "reactivate": {
        "reactivated": "The module has been reactivated"
      },
      "row": {
        "product_delete": "Are you sure you want to delete this module?"
      },
      "show": {
        "new_program_template_button": "New Process templates",
        "new_task_button": "Add assessments",
        "organizations": "Organisations",
        "product_program_templates": "Process Templates",
        "product_program_templates_table_header": [
          "Name",
          "Language",
          "Status",
          "Actions"
        ],
        "product_tasks": "Assessments",
        "product_tasks_table_header": [
          "Name",
          "Key",
          "Language",
          "Credits",
          "Status",
          "Connection",
          "Actions"
        ],
        "status": "Status",
        "subscription_types": "Subscription types"
      },
      "update": {
        "product_updated": "The module has been updated",
        "title": "Edit module"
      }
    },
    "profile": {
      "audits": {
        "show": {
          "action": "Action",
          "browser": "Browser",
          "caption": "Below you'll find all authetication actions for your account/",
          "created_at": "Logged in at",
          "ip": "IP",
          "title": "Audit history"
        }
      },
      "base": {
        "tabs": {
          "audit_history": "Audit history",
          "personal_information": "Personal information",
          "settings": "Settings"
        }
      },
      "candidates": {
        "form": {
          "create_account": "Create account"
        },
        "update": {
          "updated": "Your personal information has been updated"
        }
      },
      "images": {
        "destroy": {
          "deleted": "Profile image deleted",
          "destroy_confirm": "Delete profile image"
        },
        "form": {
          "allowed_formats": "Allowed file formats: JPG, PNG",
          "upload": "Upload Photo"
        },
        "update": {
          "updated": "Profile image updated"
        }
      },
      "modal": {
        "profile_image": {
          "change": "Change",
          "save": "Save",
          "title": "Change profile picture",
          "upload": "Upload"
        }
      },
      "notification_settings": {
        "email_notifications": {
          "help_text": "Choose the desired notification settings, keeping in mind that only unseen notifications will be sent by email.",
          "title": "Notification E-Mail Frequency"
        },
        "update": {
          "updated": "Your personal settings has been updated"
        }
      },
      "notifications": {
        "all": "Show all",
        "bulk_update": {
          "marked_as_read": "The notifications have been marked as read"
        },
        "document_approvals": "Document approvals",
        "documents": "Documents",
        "last_30_days": "Last 30 days",
        "messages": "Messages",
        "news": "News",
        "system": "System",
        "title": "Notifications",
        "tracks": "Tracks"
      },
      "passwords": {
        "update": {
          "sent": "You'll receive an email with instructions on how to reset your password"
        }
      },
      "profiles": {
        "show": {
          "password": {
            "instructions": "If you want to change your password, we'll send you an email with instructions to reset it.",
            "request": "Request",
            "title": "Change password"
          },
          "profile_image": "Profile image",
          "title": "Your info"
        }
      },
      "settings": {
        "activate_2fa": {
          "activate": "Activate",
          "inactive": "2FA has not been activated yet"
        },
        "deactivate_2fa": {
          "active": "2FA is active",
          "deactivate": "Deactivate",
          "disabled": "The use of two-step verification has been required by the organisation"
        },
        "two_factor_authentication": {
          "title": "2-Factor Authentication"
        }
      },
      "title": "My profile",
      "two_factor_authentications": {
        "destroy": {
          "destroyed": "2FA is deactivated",
          "tfa_required": "2FA can't be deactivated"
        },
        "update": {
          "2fa_settings_reset": "Two factor can be reconfigured."
        }
      },
      "users": {
        "update": {
          "updated": "Your personal information has been updated"
        }
      }
    },
    "profile_picture_disclaimer": {
      "accept": "Accept",
      "cancel": "Cancel",
      "title": "Profile picture disclaimer"
    },
    "program_pages": {
      "edit": {
        "drop": "Drag elements here"
      },
      "new": {
        "default_page_title": "New step"
      },
      "show": {
        "not_editable": "This process has been published and can therefore be assigned, but it cannot be edited until it is withdrawn",
        "not_editable_advisor": "As an advisor you are unable to edit this process, yet you can still view it (all settings included)",
        "not_editable_deleted": "This process has been deleted and can therefore no longer be edited or assigned",
        "not_editable_template": "You can view this template or copy it to your organisation's own processes"
      }
    },
    "program_templates": {
      "bulk": {
        "publish": "Multiple processes are published",
        "withdraw": "Multiple processes are withdrawn"
      },
      "destroy": {
        "destroyed": "The '%{program_title}' process has been deleted"
      },
      "duplicate": {
        "duplicate_prefix": "Copy:",
        "program_duplicated": "The '%{program_title}' process has been copied",
        "template_duplicated": "The '%{program_title}' process has been copied to your organisation's own processes"
      },
      "form": {
        "invite_mail": "Invitation email",
        "invite_mail_documentation": "(click here for documentation on invitation emails)",
        "labels": "Labels",
        "options": "Options",
        "title_placeholder": "Choose a title",
        "your_labels": "Your labels"
      },
      "index": {
        "help_text": "Design, edit or view your organisation's processes. Processes are available for advisors with the coach role and premium candidates.",
        "new_button": "New process",
        "own_programs": "Own processes",
        "table_header": [
          "Status",
          "Details",
          "Length",
          "Actions"
        ],
        "templates": "Templates",
        "title": "Process development"
      },
      "new": {
        "default_program_title": "Process"
      },
      "publish": {
        "all_pages_invisible": "You cannot publish this process because none of its steps are set as visible to the candidate",
        "published": "The '%{program_title}' process has been published"
      },
      "publish_as_template": {
        "published_as_template": "The '%{program_title}' process has been published as template"
      },
      "update": {
        "update_failed": "The process could not be updated",
        "updated": "The process has been updated"
      },
      "withdraw": {
        "withdrawn": "The '%{program_title}' process has been withdrawn"
      }
    },
    "programs": {
      "bulk": {
        "publish": "Multiple processes are published",
        "withdraw": "Multiple processes are withdrawn"
      },
      "destroy": {
        "destroyed": "The '%{program_title}' process has been deleted"
      },
      "duplicate": {
        "duplicate_prefix": "Copy:",
        "program_duplicated": "The '%{program_title}' process has been copied",
        "template_duplicated": "The '%{program_title}' process has been copied to your organisation's own processes"
      },
      "form": {
        "labels": "Labels",
        "options": "Options",
        "title_placeholder": "Choose a title",
        "your_labels": "Your labels"
      },
      "index": {
        "help_text": "Design, edit or view your organisation's processes. Processes are available for advisors with the coach role and premium candidates.",
        "new_button": "New process",
        "own_programs": "Own processes",
        "table_header": [
          "Status",
          "Details",
          "Length",
          "Actions"
        ],
        "templates": "Templates",
        "title": "Process development"
      },
      "new": {
        "default_program_title": "Process"
      },
      "publish": {
        "all_pages_invisible": "You cannot publish this process because none of its steps are set as visible to the candidate",
        "published": "The '%{program_title}' process has been published"
      },
      "publish_as_template": {
        "published_as_template": "The '%{program_title}' process has been published as template"
      },
      "update": {
        "update_failed": "The process could not be updated",
        "updated": "The process has been updated"
      },
      "withdraw": {
        "withdrawn": "The '%{program_title}' process has been withdrawn"
      }
    },
    "prompt": {
      "cost_center_delete_confirm": "Are you sure you want to delete this cost centre?",
      "file_remove_confirm": "Are you sure you want to delete this file?",
      "program_delete_confirm": "Are you sure you want to delete this process, including all of its steps and elements?",
      "room_delete_confirm": "Are you sure you want to delete this video call?",
      "subscription_delete_confirm": "Are you sure you want to delete this subscription?",
      "subscription_upgrade_confirm": "Are you sure you want to upgrade your subscription? You cannot downgrade within a year after the new subscription starts."
    },
    "resource": {
      "candidate_email_messages": "Sent emails",
      "close": "Close",
      "edit": "Edit",
      "filter": "Filter",
      "impersonate": "Log in as",
      "interactive_results": "Results",
      "new_enrollment": "Add track",
      "reinvite": "Reinvite"
    },
    "role": {
      "AccountManager": "Account manager",
      "Coach": "Coach",
      "IxlyAdmin": "Ixly administrator",
      "OrganizationManager": "Organisation manager",
      "SelectionManager": "Selection manager"
    },
    "rooms": {
      "show": {
        "cannot_join": "This video call is currently closed"
      }
    },
    "score": {
      "no_score": "No score",
      "with_score": "With score"
    },
    "selection": {
      "compare_candidates": {
        "new": {
          "current_position": "Current position:",
          "no_scores": "You can only compare candidates with scores",
          "position_unknown": "Not specified",
          "title": "Candidate comparison",
          "too_many_candidates": "You can compare up to 5 candidates at a time."
        }
      },
      "job_profile_templates": {
        "duplicate": "The Job Profile '%{job_profile_title}' has been copied to your organisation",
        "index": {
          "list": "List of pre-designed Job Profile templates",
          "title": "Job Profile Templates"
        },
        "new": {
          "tooltip": "Add Template"
        },
        "show": {
          "competencies": "Competencies",
          "education_level": "Education Level",
          "job_profile_title": "Title",
          "title": "%{job_profile_title}"
        }
      },
      "job_profiles": {
        "create": {
          "success": "Job Profile has been created successfully",
          "title": "New Job Profile"
        },
        "destroy": {
          "failure": "could not be deleted because it is associated with one or more matching assignments",
          "success": "Job Profile has been removed successfully"
        },
        "edit": {
          "title": "Edit Job Profile"
        },
        "form": {
          "job_profile_explanation": "Within Compare & Select, we use advanced assessments that objectively measure candidates' competencies and intelligence. Our standardized evaluations provide concrete scores, which form the basis for our data-driven matching. With this scientific approach, we eliminate subjectivity and introduce precision."
        },
        "index": {
          "compare_select_icon_alt": "Compare & Select icon",
          "created_at": "Created on",
          "created_by": "Created by",
          "job_title": "Title",
          "list": "Navigate to the <strong>candidate overview, select the candidates,</strong> and press the <strong>'Compare & Select'</strong> %{icon} button to create a matching assignment.",
          "title": "Job Profiles",
          "updated_at": "Last edited on"
        },
        "new": {
          "title": "New Job Profile",
          "tooltip": "Add Job Profile"
        },
        "update": {
          "success": "Job Profile has been updated successfully",
          "title": "Edit Job Profile"
        }
      },
      "matching_assignments": {
        "create": {
          "candidates": "Candidates",
          "success": "Matching Assignment has been created successfully",
          "title": "Compose Matching Assignment"
        },
        "edit": {
          "title": "Edit Matching Assignment"
        },
        "index": {
          "candidate": "candidates",
          "caption": "Here you will find an overview of your matching assignments",
          "incomplete": "Matching incomplete",
          "list": {
            "candidates": "Candidates",
            "created_at": "Created on",
            "job_title": "Job title",
            "label": "Label",
            "status": "State",
            "title": "Reference",
            "updated_at": "Last edited on"
          },
          "title": "Matching Assignments"
        },
        "list": {
          "assignment_note": "<span class='font-bold'>Note:</span> Not all candidates have been assigned an ACT and a WPV. <a href='%{assignment_link}' class='font-bold underline hover:text-blue-light' data-turbo-frame='_top'>Click here</a> Click here to assign an intelligence test (ACT) and a competency test (WPV) directly.<br> We ensure that this process runs smoothly and prevents duplicate assignments.",
          "name": "Name"
        },
        "new": {
          "title": "Compose Matching Assignment"
        },
        "show": {
          "education_level": "Measured education level",
          "title": "Matching results"
        },
        "tooltip": {
          "start_comparison": "Compare candidates"
        },
        "update": {
          "success": "Matching Assignment has been updated successfully",
          "title": "Edit Matching Assignment"
        }
      },
      "matching_results": {
        "index": {
          "education_level": "Measured level of education",
          "list": {
            "competencies": "Competences",
            "education_level": "Measured education level",
            "favorite": "Favorites",
            "matching_score": "Matching score",
            "name": "Name"
          },
          "title": "Matching results"
        },
        "list": {
          "assignment_note": "<span class='font-bold'>Note:</span> not all candidates have been assigned to assessments. <a href='%{assignment_link}' class='font-bold underline hover:text-blue-light' data-turbo-frame='_top'>Click here</a> to assign assessments directly.",
          "name": "Name"
        }
      },
      "title": "Compare & Select"
    },
    "sessions": {
      "create": {
        "combination_does_not_exist": "Incorrect email address or password"
      },
      "destroy": {
        "logout": "You have logged out successfully"
      },
      "new": {
        "forgot_your_password": "Forgot your password?",
        "help_text": "Welcome to the login page of the Assessment Platform",
        "new_login": "Log in",
        "title": "Login"
      },
      "verify_otp": {
        "invalid": "The provided code was incorrect"
      }
    },
    "shared": {
      "accounts": {
        "suppressed_email": "The email address associated with this account <span class=\"font-medium\">cannot be reached</span>.",
        "suppressed_email_tooltip": "The email address associated with this account cannot be reached.",
        "unconfirmed_email": {
          "unconfirmed_email": "The email address will be changed to:",
          "unconfirmed_email_explanation": "The owner should confirm the email address via a link. After confirmation the changed email address will be used."
        }
      },
      "candidate_result_widgets": {
        "personal_information": {
          "date_of_birth": "Birth date",
          "education": "Education level",
          "email": "Email address",
          "most_recent_work_experience": "Most recent work experience",
          "study_subject": "Education",
          "title": "Personal information",
          "years": {
            "one": "%{years} year",
            "other": "%{years} years"
          },
          "years_of_experience": "Number of years of experience"
        }
      },
      "enrollment_sidebar": {
        "advisor": "Coach",
        "candidate": "Candidate",
        "coadvisor": "Co-coach",
        "comments": "Messages",
        "contact_person": "Contact person",
        "room": "Video call",
        "track": "Track"
      },
      "forms": {
        "password": {
          "hide_password": "hide password",
          "show_password": "Show password"
        }
      },
      "header": {
        "admin": "Administrator",
        "advisors": "Advisors",
        "all_organizations": "All organisations",
        "all_users": "All users",
        "approval_requests": "Approval requests",
        "blog": "Blog",
        "candidates": "Candidates",
        "clients": "Clients",
        "enrollers": "Enrolment links",
        "last_activity": "Last activity",
        "login": "Login",
        "organization": "Organisation",
        "prices": "Prices",
        "products": "Products",
        "programs": "Process development",
        "rooms": "Video calls",
        "settings": "Settings",
        "state": "State",
        "subscription": "Subscription",
        "subscription_types": "Subscription types",
        "tasks": "Tasks",
        "tracks": "Tracks",
        "translations": "Translations",
        "users": "Users"
      },
      "header_profile": {
        "log_in_as": "Log in as:",
        "log_in_at": "Log in at:",
        "logout": "Log out",
        "my_tracks": "My tracks",
        "profile": "Profile"
      },
      "notifications_sidebar": {
        "help_text": "Filter your list of tasks using the options below:",
        "title": "Filters"
      },
      "organization_sidebar": {
        "clients_help_text": "Filter your list of clients using the options below:",
        "labels": "Labels",
        "programs_help_text": "Filter your list of processes using the options below:",
        "title": "Filters",
        "users_help_text": "Filter your list of users using the options below:",
        "your_own_organization": "Your organisation"
      },
      "rebound_email_banner": {
        "action_label": "Update email",
        "description": "An email recently sent to [[email]] has bounced. Here’s why: [[reason]]",
        "fallback_description": "An email recently sent to [[email]] has bounced.",
        "title": "Please update your email"
      },
      "suppressed_email": "The email address associated with this account <span class=\"font-medium\">cannot be reached</span>.",
      "suppressed_email_tooltip": "The email address associated with this account cannot be reached.",
      "user_sidebar": {
        "my_track": "My track",
        "new_track": "Create track",
        "show_deactivated": "Show deactivated",
        "track_of": "Track of %{name}",
        "track_with": "Track with %{name}"
      }
    },
    "sidebar": {
      "close": "Close sidebar"
    },
    "simple_form": {
      "error_notification": {
        "default_message": "Please review the problems listed below:"
      },
      "include_blank": {
        "default_mail_template": {
          "no_default": "No default"
        },
        "default_reminder_mail_template": {
          "no_default": "No default"
        },
        "select_option": "Please select an option"
      },
      "labels": {
        "advisor": {
          "avatar": "Profile picture",
          "biography": "Personal description",
          "can_impersonate": "Can log in as another user",
          "consent_given": "Consent",
          "cost_center_id": "Cost centre",
          "current_password": "Current password",
          "first_name": "First name",
          "invite_immediately": "Send a welcome email immediately",
          "label": "Labels",
          "label_ids": "Labels",
          "language": "Language",
          "last_name": "Surname",
          "mailing_frequency": "Email frequency",
          "new_password": "New password",
          "organization": "Organisation",
          "organization_language": "Organisation language",
          "organization_name": "Organisation name",
          "otp_code": "Code",
          "privacy_accepted": "Terms / Privacy",
          "remove_avatar": "Remove this picture",
          "role": "Role",
          "roles": "Roles",
          "status": "Status",
          "type": "User Type",
          "unconfirmed_email": "Email"
        },
        "assignment": {
          "deadline": "Assessment deadline",
          "invited_at": "Invitation date",
          "mail_template_id": "Invitation mail",
          "reminder_mail_delivery_date": "Reminder email date",
          "reminder_mail_template_id": "Reminder mail"
        },
        "authentication_action": {
          "auth_action": "Type of authentication"
        },
        "candidate": {
          "account_email": "Email address",
          "advisor": "Advisor",
          "advisor_id": "Advisor",
          "client": "Clients",
          "client_ids": "Clients",
          "coadvisor": "Co-Advisor",
          "coadvisor_ids": "Co-Advisors",
          "coadvisors": "Co-Advisor",
          "contact_person": "Contact persons",
          "contact_person_ids": "Contact persons",
          "contact_persons": "Contact persons",
          "cost_center_id": "Cost center",
          "created_at": "Registration Date",
          "deadline": "Assessment deadline",
          "end_date": "End Date",
          "first_name": "First name",
          "invitation_date": "Invitation date",
          "invite_immediately": "Invite immediately",
          "label": "Labels",
          "label_ids": "Labels",
          "language": "Language",
          "last_name": "Surname",
          "mail_template_id": "Invitation mail",
          "name": "Name",
          "organization": "Organisation",
          "program": "Processen",
          "reminder_mail_delivery_date": "Remindermail date",
          "reminder_mail_template_id": "Remindermail",
          "report_objective": "Reporting objective",
          "start_date": "Start Date",
          "status": "Status"
        },
        "candidate_assessment": {
          "document_options": "Report options",
          "end_date": "Completed up to and including",
          "norm_group_uuid": "Reporting objective",
          "report_to": "Results first available for",
          "selected_report_id": "Report",
          "start_date": "Rounded from"
        },
        "candidate_assessment_program": {
          "document_options": "Report options",
          "norm_group_uuid": "Reporting objective",
          "report_to": "Results first available for",
          "selected_report_id": "Report"
        },
        "candidate_matching_assignment": {
          "education_level": "Education Level",
          "no_score": "No Score",
          "score": "Matching score"
        },
        "candidate_report": {
          "candidate_assessment_program_id": "Assessment Program",
          "document_options": "Report options",
          "norm_group_id": "Reporting objective",
          "report_id": "Report",
          "reportable": "Assessment"
        },
        "client": {
          "label_ids": "Labels",
          "name": "Name"
        },
        "component": {
          "alt_text": "Description of the image for screen readers",
          "bov_editable_by": "BOV can be started by",
          "can_approve": "To be approved by",
          "cc_load_policy": "Show (localised) closed captions by default",
          "controls": "Show the video player controls below the video",
          "description_finished": "Extra description after finishing",
          "editable_by": "Can be answered by",
          "html": "HTML code",
          "iframe_title": "Title of the video for screen readers",
          "label": "Label for this field",
          "max_selections": "Maximum number of selections",
          "options": "Options",
          "question": "Question",
          "rel": "Show related videos from other channels as well",
          "report_visible_for": "Result visible to",
          "required": "Must be answered with 'Yes' to continue",
          "sections": "Sections",
          "task_key": "Selected assessment",
          "template_can_be_switched_by": "Template can be switched by",
          "url": "YouTube video URL",
          "visible_for": "Document visible to",
          "visible_to_candidate": "Make the element visible to the candidate as well",
          "visible_to_contact_person": "After approval automatically share with contact people"
        },
        "contact_person": {
          "avatar": "Profile picture",
          "biography": "Personal description",
          "can_impersonate": "Can log in as another user",
          "client": "Client",
          "consent_given": "Consent",
          "cost_center_id": "Cost centre",
          "current_password": "Current password",
          "first_name": "First name",
          "id": "Contact",
          "invite_immediately": "Send a welcome email immediately",
          "label": "Labels",
          "label_ids": "Labels",
          "language": "Language",
          "last_name": "Surname",
          "mailing_frequency": "Email frequency",
          "new_password": "New password",
          "organization": "Organisation",
          "organization_language": "Organisation language",
          "organization_name": "Organisation name",
          "otp_code": "Code",
          "privacy_accepted": "Terms / Privacy",
          "remove_avatar": "Remove this picture",
          "role": "Role",
          "status": "Status",
          "type": "User Type",
          "unconfirmed_email": "Email"
        },
        "cost_center": {
          "identifier": "Name"
        },
        "defaults": {
          "account_email": "Email address",
          "advisor_id": "Advisor",
          "candidate_id": "Candidate",
          "client_id": "Client",
          "coadvisor_id": "Co-Advisor",
          "contact_person_id": "Contact person",
          "description": "Description",
          "email": "Email address",
          "end_date": "End date",
          "explanation": "Explanation",
          "first_name": "First name",
          "language": "Application language",
          "last_name": "Surname",
          "name": "Name",
          "organization_id": "Organisation",
          "password": "Password",
          "product_id": "Products",
          "program_id": "Process",
          "start_date": "Start date",
          "title": "Title",
          "two_factor": "Two-step verification"
        },
        "discount": {
          "end_date": "End date",
          "kind": "On",
          "organization": "Organisation",
          "organization_id": "Organisation",
          "percentage": "Percentage",
          "start_date": "Start date"
        },
        "enroller": {
          "advisor": "Coach",
          "advisor_id": "Coach",
          "allow_repeated": "Allow repeated enrolments",
          "link": "Link",
          "program": "Process",
          "program_id": "Process",
          "show_default": "Show default text"
        },
        "enrollment": {
          "coadvisor_id": "Co-coach",
          "invite_immediately": "Invite immediately",
          "program_id": "Process"
        },
        "entry": {
          "end_date": "Until",
          "identifier": "identifier",
          "start_date": "From",
          "type": "Type"
        },
        "fixed_price": {
          "description": "Description on invoice",
          "organization": "Organisation",
          "organization_id": "Organisation",
          "price": "Price",
          "task": "Task"
        },
        "import/file": {
          "created_at": "Created",
          "imported_rows": "imported rows",
          "name": "Name",
          "organization": "organisation",
          "organization_id": "Organisation",
          "state": "state import",
          "total_rows": "total rows",
          "type": "Type"
        },
        "import_file": {
          "created_at": "Created",
          "download_example": "Download exampe file",
          "imported_rows": "imported rows",
          "name": "Name",
          "organization": "organisation",
          "organization_id": "Organisation",
          "state": "state import",
          "total_rows": "total rows",
          "type": "Type"
        },
        "ixly_employee": {
          "avatar": "Profile picture",
          "biography": "Personal description",
          "can_impersonate": "Can log in as another user",
          "consent_given": "Consent",
          "cost_center_id": "Cost centre",
          "current_password": "Current password",
          "first_name": "First name",
          "invite_immediately": "Send a welcome email immediately",
          "label": "Labels",
          "language": "Language",
          "last_name": "Surname",
          "mailing_frequency": "Email frequency",
          "new_password": "New password",
          "organization": "Organisation",
          "organization_language": "Organisation language",
          "organization_name": "Organisation name",
          "otp_code": "Code",
          "privacy_accepted": "Terms / Privacy",
          "remove_avatar": "Remove this picture",
          "role": "Role",
          "roles": "Roles",
          "status": "Status",
          "type": "User Type",
          "unconfirmed_email": "Email"
        },
        "job_profile": {
          "competencies": "Competencies",
          "education_level": "Education Level",
          "job_profile": "Job profile",
          "label": "Labels",
          "label_ids": "Labels",
          "title": "Job Title",
          "user": "User"
        },
        "join": {
          "display_name": "Display name",
          "start_hidden": "Start hidden",
          "start_muted": "Start muted"
        },
        "leniency": {
          "credits": "Credits",
          "reason": "Reason"
        },
        "mail_template": {
          "body": "Body",
          "language": "Language",
          "subject": "Subject",
          "title": "Title"
        },
        "matching_assignment": {
          "education_level": "Education Level",
          "job_profile": "Job profile",
          "job_profile_competencies": "Competencies",
          "job_profile_education_level": "Education Level",
          "job_profile_title": "Job profile",
          "label": "Labels",
          "label_ids": "Labels",
          "matching_score": "Matching score",
          "no_score": "No Score",
          "title_description": "Reference",
          "user": "User",
          "vacancy_number_placeholder": "Add a reference to this job profile, such as a vacancy number, department name or location."
        },
        "media_item": {
          "advisor": "Advisor",
          "candidate": "Candidate",
          "contact_person": "Contact person"
        },
        "organization": {
          "account_manager_id": "Account manager",
          "address": "Address",
          "advisor_can_create_candidates": "Advisors can create candidates",
          "advisor_can_manage_existing_candidates": "Advisors can view and update all candidates",
          "advisor_can_manage_users": "Advisors can create Advisors and Contact Persons",
          "advisor_can_remove_candidates": "Advisors can remove candidates",
          "advisor_can_view_enrollers": "Advisors can view enrolment links",
          "advisor_can_view_prices": "Advisors can see prices",
          "advisor_deactivation_period": "Advisor deactivation period",
          "advisor_rooms_permission": "Advisors can view video calls",
          "allow_compare_select": "Allow Compare & Select",
          "allow_impersonation": "Allow users log in as",
          "allow_ixly_support": "Allow Ixly support",
          "api_anonymous": "API anonymous",
          "api_key": "API key",
          "api_organization_id": "Test Toolkit organisation uuid",
          "api_user": "API user email",
          "available_languages": "Available languages",
          "brand_logo": "Logo",
          "can_create_suborganizations": "Allow organisation to create suborganisations",
          "candidate_deactivation_period": "Candidate deactivation period",
          "city": "City",
          "clients_enabled": "Clients and contact persons are enabled for the organisation",
          "country": "Country",
          "credit_bundle": "Bundle",
          "default_language": "Default language",
          "default_report_objective": "Default for 'Reporting objective'",
          "default_report_to": "Results first available for",
          "has_dashboard_link": "Advisors have a link to the Dashboard app",
          "interactive_button_color": "Button colour",
          "interactive_header_color": "Secondary colour",
          "interactive_header_text_color": "Page title text colour",
          "interactive_primary_color": "Primary colour",
          "invoice_company_name": "Invoice company name",
          "invoice_company_name_placeholder": "If the same as company name, leave empty",
          "invoice_email": "Billing email address",
          "invoice_remarks": "Invoice remarks",
          "invoice_remarks_placeholder": "Appears below the invoice",
          "invoices_need_manual_confirmation": "Invoices are created as manual invoices, which requires an Office employee to confirm and finalize the invoice in the Invoice App before sending.",
          "name": "Name",
          "organization_manager_can_view_enrollments": "Organisation manager can view processes of all candidates",
          "payment_type": "Payment type",
          "postal_code": "Postal code",
          "privacy_link": "Privacy",
          "profile_picture_disclaimer": "Disclaimer text",
          "remove_logo": "Remove logo",
          "remove_report_cover_logo": "Remove report cover logo",
          "remove_report_header_logo": "Remove report header logo",
          "report_cover_logo": "Report cover logo",
          "report_header_logo": "Report header logo",
          "retention_period": "Personal data retention period",
          "show_interactive_results": "Show Results",
          "show_posts": "Show Blog",
          "status": "Status",
          "subscription_type": "Subscription",
          "terms_link": "Terms",
          "top_organization": "Top organisation",
          "two_factor": "Require two-step verification for these roles",
          "vat_number": "VAT identification number",
          "welcome_mail_subject": "Welcome email subject",
          "welcome_mail_text": "Welcome email text",
          "whitelabeled": "White labeled"
        },
        "organization_default_mail_template": {
          "mail_template_id": "Default mail template"
        },
        "organization_default_reminder_mail_template": {
          "mail_template_id": "Default reminder mail template"
        },
        "post_rule": {
          "label_ids": "Labels",
          "program_ids": "Processes",
          "roles": "User roles"
        },
        "premium_candidate": {
          "coadvisor_ids": "Co-Advisors",
          "contact_person_ids": "Contact persons",
          "label_ids": "Labels"
        },
        "price": {
          "amc": "AMC price, €",
          "bov": "BOV price, €",
          "external_user": "Monthly fee per candidate, €",
          "fit": "FIT price, €",
          "internal_user": "Monthly fee per coach, €",
          "job_market_scan": "Job market opportunities price, €",
          "job_suggestions": "Job suggestions price, €",
          "jobfeed": "Jobfeed price, €",
          "start_date": "Effective from"
        },
        "product": {
          "name": "Name"
        },
        "product_program_templates": {
          "ids": "Process templates"
        },
        "product_tasks": {
          "ids": "Assessments"
        },
        "program": {
          "invite_mail_subject": "Invitation email subject",
          "invite_mail_text": "Invitation email text",
          "label": "Labels",
          "language": "Language",
          "status": "Status",
          "step_order": "Step order",
          "type": "Type"
        },
        "program_page": {
          "approve_by": "Approval by",
          "has_comments": "Enable comments to be displayed below the step",
          "hidden_for_candidate": "Hide the step from the candidate",
          "optional": "Make the step optional (insertable after assignment)"
        },
        "program_template": {
          "invite_mail_subject": "Invitation email subject",
          "invite_mail_text": "Invitation email text",
          "label": "Labels",
          "status": "Status",
          "step_order": "Step order",
          "type": "Type"
        },
        "report_objectives": {
          "advice": "Advice",
          "other": "Other",
          "selection": "Selection"
        },
        "role": {
          "AccountManager": "Account manager",
          "Coach": "Coach",
          "IxlyAdmin": "Ixly administrator",
          "OrganizationManager": "Organisation manager",
          "SelectionManager": "Selection manager"
        },
        "room": {
          "end_to_end_encryption": "End-to-end encryption",
          "everyone_starts_hidden": "Everyone starts hidden",
          "everyone_starts_muted": "Everyone starts muted",
          "link": "Link",
          "only_managers_can_join": "Only organisation managers can join",
          "only_managers_can_kick": "Only organisation managers can kick others",
          "only_managers_can_mute": "Only organisation managers can mute others",
          "subject": "Subject"
        },
        "subscription": {
          "annual_fee": "Annual subscription fee",
          "end_date": "End date",
          "start_date": "Start date",
          "subscription_type": "Subscription type",
          "subscription_type_id": "Subscription type"
        },
        "subscription_type": {
          "can_downgrade_to": "Can downgrade to subscription type",
          "can_upgrade_to": "Can upgrade to subscription type",
          "candidate_price": "Annual fee per candidate for unlimited assessments",
          "credit_price": "Price per credit",
          "credits_notice": "Few credits left notice at",
          "external_user": "Monthly fee per candidate",
          "internal_user": "Monthly fee per coach",
          "invoice_line_type": "Category for accounting",
          "max_candidates": "Maximum number of active candidates",
          "max_coaches": "Maximum number of active coaches",
          "max_users": "Maximum number of active coaches",
          "month_price": "Monthly subscription fee",
          "payment_period": "Payment period",
          "prepay": "Prepaid",
          "prepay_credits": "Available credit bundles",
          "type": "Category"
        },
        "task": {
          "language": "Language"
        },
        "translation": {
          "identifier": "Identifier"
        },
        "user": {
          "avatar": "Profile picture",
          "biography": "Personal description",
          "can_impersonate": "Can log in as another user",
          "consent_given": "Consent",
          "cost_center_id": "Cost centre",
          "current_password": "Current password",
          "first_name": "First name",
          "invite_immediately": "Send a welcome email immediately",
          "label": "Labels",
          "language": "Language",
          "last_name": "Surname",
          "mailing_frequency": "Email frequency",
          "new_password": "New password",
          "organization": "Organisation",
          "organization_language": "Organisation language",
          "organization_name": "Organisation name",
          "otp_code": "Code",
          "privacy_accepted": "Terms / Privacy",
          "remove_avatar": "Remove this picture",
          "role": "Role",
          "status": "Status",
          "type": "User Type",
          "unconfirmed_email": "Email"
        },
        "whitelabel": {
          "domain": "Domain",
          "subdomain": "Subdomain"
        }
      },
      "options": {
        "component": {
          "bov_editable_by": {
            "both": "Both (the coach can start only after the candidate has started)",
            "candidate": "The candidate"
          },
          "can_approve": {
            "candidate": "The candidate",
            "contact_person": "The contact person"
          },
          "editable_by": {
            "advisor": "The coach",
            "both": "Both",
            "candidate": "The candidate"
          },
          "report_visible_for": {
            "advisor": "The coach",
            "both": "Both",
            "candidate": "The candidate"
          },
          "template_can_be_switched_by": {
            "advisor": "The coach",
            "both": "Both",
            "candidate": "The candidate",
            "creator": "No one"
          },
          "visible_for": {
            "advisor": "The coach",
            "both": "Both",
            "candidate": "The candidate"
          }
        },
        "discount": {
          "kind": {
            "amc": "AMC price",
            "bov": "BOV price",
            "candidate_price": "Annual fee per candidate for unlimited assessments",
            "credit_price": "Price per credit",
            "external_user": "Monthly fee per candidate",
            "fit": "FIT price",
            "internal_user": "Monthly fee per coach",
            "job_market_scan": "Job market Opportunities price",
            "job_suggestions": "Job Suggestions price",
            "jobfeed": "Jobfeed price",
            "month_price": "Monthly subscription fee"
          }
        },
        "organization": {
          "advisor_deactivation_period": {
            "never": "Never"
          },
          "payment_type": {
            "automatic": "Automatic",
            "invoice": "Invoice"
          },
          "two_factor": {
            "Advisor": "Advisor",
            "ContactPerson": "Contact person",
            "OrganizationManager": "Organisation manager",
            "User": "Candidate"
          }
        },
        "program": {
          "step_order": {
            "approval": "The candidate can continue after approval of the current step",
            "free": "The candidate may follow the steps in any order",
            "submission": "The candidate can continue after submitting the current step"
          }
        },
        "program_page": {
          "approve_by": {
            "none": "The step does not have to be approved",
            "seen": "The step is approved automatically when the candidate views it",
            "set": "Both the coach and the candidate may choose to approve the step",
            "submit": "The coach approves the step manually after submission by the candidate"
          }
        },
        "subscription_type": {
          "invoice_line_type": {
            "frequent": "Frequent",
            "intensive": "Intensive"
          },
          "payment_period": {
            "month": "Month",
            "quarter": "Quarter",
            "year": "Year"
          },
          "type": {
            "AllYouCanSubscription": "All-you-can-report",
            "AnnualSubscription": "Annual",
            "DemoSubscription": "Demo",
            "IncidentalSubscription": "Incidental",
            "ManualSubscription": "Manual",
            "ResearchSubscription": "Research",
            "StandardSubscription": "Standard",
            "TestSubscription": "Test"
          }
        },
        "user": {
          "mailing_frequency": {
            "every_morning": "Collect notifications and email them every morning.",
            "immediately": "Receive instant notifications.",
            "never": "Never receive notifications by email.",
            "once_an_hour": "Collect notifications and email them every hour."
          },
          "type": {
            "Advisor": "Advisor",
            "ContactPerson": "Contact person",
            "IxlyEmployee": "Ixly employee",
            "User": "Participant"
          }
        }
      },
      "placeholders": {
        "search_by_name": "Search by name",
        "search_by_title": "Search by title",
        "search_for_coadvisor_ids": "Search Co-Coach",
        "search_for_competencies": "Find Competencies",
        "search_for_contact_person_ids": "Search Contact Person",
        "search_for_job_profile_competencies": "Find Competencies",
        "search_for_label_ids": "Search Label",
        "search_for_labels": "Search Label",
        "search_for_options": "Search options",
        "user": {
          "edit": {
            "biography": "text entered here will be publicly displayed on your profile page"
          }
        }
      },
      "required": {
        "mark": "*",
        "text": "This is a required field"
      }
    },
    "skiplink": "Skip to main content",
    "status": {
      "advisor": {
        "active": "Active",
        "created": "Created",
        "inactive": "Inactive",
        "invited": "Invited"
      },
      "candidate": {
        "active": "Active",
        "created": "Created",
        "inactive": "Inactive",
        "invited": "Invited"
      },
      "candidate_email_message": {
        "bounced": "Bounced",
        "delivered": "Delivered",
        "loading": "Loading",
        "sent": "Sent",
        "transient": "Potential problems with delivery",
        "unsubscribed": "Unsubscribed"
      },
      "contact_person": {
        "active": "Active",
        "created": "Created",
        "inactive": "Inactive",
        "invited": "Invited"
      },
      "enroller": {
        "active": "Active",
        "created": "Created",
        "deactivated": "Deactivated",
        "inactive": "Inactive"
      },
      "enrollment": {
        "action_required": "Action required",
        "created": "Created",
        "finished": "Finished",
        "invited": "Invited",
        "started": "Started"
      },
      "enrollment_page": {
        "created": "Created",
        "done": "Done",
        "rejected": "Rejected",
        "submitted": "Submitted"
      },
      "import_file": {
        "created": "Created",
        "failed": "Failed",
        "processed": "Processed",
        "validating": "Validating"
      },
      "invoice": {
        "canceled": "Cancelled",
        "charged_back": "Refunded",
        "closed": "Closed",
        "expired": "Expired",
        "failed": "Failed",
        "open": "Open",
        "paid": "Paid",
        "processed": "Incorporated",
        "refunded": "Refunded",
        "revoked": "Withdrawn",
        "sent": "Sent",
        "settled": "Paid",
        "unconfirmed": "Unconfirmed"
      },
      "mail_template": {
        "draft": "Draft",
        "published": "Published"
      },
      "organization": {
        "active": "Active",
        "created": "Created",
        "deleted": "Deleted",
        "inactive": "Inactive"
      },
      "price": {
        "active": "Active",
        "expired": "Expired",
        "pending": "Pending"
      },
      "product": {
        "active": "Active",
        "deactivated": "Deactivated"
      },
      "program": {
        "deleted": "Deleted",
        "editable": "Editable",
        "published": "Published",
        "template": "Template"
      },
      "program_template": {
        "deleted": "Deleted",
        "editable": "Editable",
        "published": "Published",
        "template": "Template"
      },
      "ttk_migration": {
        "completed": "Migration completed",
        "created": "Created",
        "failed": "Migration fails",
        "ready_to_migrate": "Ready to migrate",
        "syncing_default_reports": "Migrate standard reports",
        "syncing_settings": "Migrate settings",
        "syncing_users_candidates": "Migrate accounts",
        "updating_managed_via_ap": "Complete migration",
        "updating_manager": "Set up organisation manager"
      },
      "user": {
        "active": "Active",
        "created": "Created",
        "inactive": "Inactive",
        "invited": "Invited"
      }
    },
    "sub_org": {
      "sub_organizations": {
        "index": {
          "caption": "This lists all sub organisations of your organisation",
          "title": "Sub organisations"
        }
      }
    },
    "suborganizations": "Sub organisations",
    "subscription_type_product_status": {
      "active": "Active",
      "deactivated": "Deactivated"
    },
    "subscription_type_products": {
      "create": {
        "created": "The products have been added to the subscription type",
        "products_blank": "No products were selected"
      },
      "deactivate": {
        "deactivated": "The connection with the module has been deactivated"
      },
      "destroy": {
        "deleted": "The module has been removed from the subscription type"
      },
      "list": {
        "table_header": [
          "Name",
          "Connection",
          "Actions"
        ]
      },
      "new": {
        "title": "Add products to %{subscription_type}"
      },
      "reactivate": {
        "reactivated": "The connection with the module has been reactivated"
      },
      "row": {
        "product_delete": "Are you sure you want to remove this module from the subscription type?"
      }
    },
    "subscription_type_status": {
      "active": "Active",
      "deactivated": "Deactivated"
    },
    "subscription_types": {
      "create": {
        "subscription_type_created": "The subscription type has been created",
        "title": "New subscription type"
      },
      "deactivate": {
        "deactivated": "The subscription type has been deactivated"
      },
      "destroy": {
        "deleted": "The subscription type has been deleted"
      },
      "edit": {
        "title": "Edit subscription type"
      },
      "index": {
        "new_button": "New subscription type",
        "table_header": [
          "Name",
          "Status",
          "Status changed at",
          "Active subscriptions",
          "Category",
          "Actions"
        ],
        "title": "Subscription types"
      },
      "new": {
        "title": "New subscription type"
      },
      "reactivate": {
        "reactivated": "The subscription type has been reactivated"
      },
      "row": {
        "subscription_type_delete": "Are you sure you want to delete this subscription type?"
      },
      "show": {
        "disabled": "No",
        "enabled": "Yes",
        "product_new_button": "Add products",
        "status": "Status"
      },
      "update": {
        "subscription_type_updated": "The subscription type has been updated",
        "title": "Edit subscription type"
      }
    },
    "support": {
      "array": {
        "last_word_connector": " and ",
        "two_words_connector": " and ",
        "words_connector": ", "
      }
    },
    "templates": {
      "document": "document"
    },
    "time": {
      "am": "am",
      "formats": {
        "date_with_day": "%A, %e %B %Y",
        "date_with_time": "%e %B %Y, %H:%M",
        "date_with_time_short": "%e %b %Y, %H:%M",
        "date_without_day": "%e %B %Y",
        "date_without_day_and_year": "%d %b",
        "date_without_time": "%d-%m-%Y",
        "default": "%a, %d %b %Y %H:%M:%S %z",
        "long": "%B %d, %Y %H:%M",
        "short": "%d %b %H:%M",
        "time_only": "%H:%M",
        "time_with_date": "%H:%M (%e %B %Y)",
        "us": "%m/%d/%Y %I:%M %p"
      },
      "pm": "pm"
    },
    "translations": {
      "actions": "Actions",
      "edit": "Edit translation",
      "new": "New translation",
      "show": "View translation",
      "source": "Source",
      "translation_created": "The translation has been created",
      "translation_destroyed": "The translation has been deleted",
      "translation_updated": "The translation has been updated"
    },
    "two_factor": {
      "activate_help_text": "Scan this QR code with the authenticator app of your choice (e.g. Google Authenticator or Authy) and enter the code to activate two-step verification.",
      "title": "Two-step verification",
      "verify_help_text": "Please enter the code from your authenticator app"
    },
    "type": {
      "Advisor": "Advisor",
      "Candidate": "Candidate",
      "CandidateAssessmentEntry": "CandidateAssessment",
      "CandidateAssessmentProgramEntry": "CandidateAssessmentProgram",
      "CandidateReportEntry": "Candidate report",
      "Cocoach": "Cocoach",
      "ContactPerson": "Contact person",
      "GeneratedReportEntry": "Generated report",
      "IxlyEmployee": "Ixly employee",
      "LeniencyEntry": "Leniency",
      "OrderEntry": "Order",
      "PremiumCandidate": "Candidate",
      "TestOnlyCandidate": "Candidate",
      "User": "Participant",
      "plural": {
        "Advisor": "Advisors",
        "Candidate": "Candidates",
        "ContactPerson": "Contact people",
        "PremiumCandidate": "Candidates",
        "TestOnlyCandidate": "Candidates"
      }
    },
    "unauthorized": {
      "manage": {
        "all": "You do not have the right permissions to do this"
      }
    },
    "user_types": {
      "Advisor": "Advisor",
      "Candidate": "Candidate",
      "Co_coach": "Coadvisor",
      "Coach": "Advisor",
      "ContactPerson": "Contact person"
    },
    "users": {
      "all": {
        "new_button": "New user",
        "table_header": [
          "Name",
          "Organisation",
          "Type",
          "Roles",
          "Status",
          "Cost centre"
        ],
        "title": "All users"
      },
      "confirmations": {
        "form": {
          "privacy_accepted_label": "I accept the <a href='%{terms_url}' target='_blank' class='underline hover:text-blue-light'>terms and conditions</a> and the <a href='%{privacy_statement_url}' target='_blank' class='underline hover:text-blue-light'>privacy statement</a>",
          "submit": "Continue"
        },
        "show": {
          "already_confirmed_account": "<span class='font-medium'>Attention,</span> an account with this email address already exists. For your new account you can use the same password to log in.",
          "already_confirmed_resource": "You've already created an account. Please use your email address and password to log in.",
          "confirmed": "The email address of your account has been confirmed, log into your account here",
          "title": "Create an account"
        },
        "update": {
          "confirmed": "Your account has been created, log into your new account here"
        }
      },
      "create": {
        "created": "The user has been created",
        "created_and_invited": "The user has been created and was sent a welcome email",
        "help_text": "Create a new organisation user",
        "more_information": "More information",
        "title": "New user"
      },
      "deactivate": {
        "user_deactivated": "The user has been deactivated"
      },
      "edit": {
        "title": "Edit user"
      },
      "form": {
        "impersonation_explanation": "If you select this option, you enable this user with impersonation rights.",
        "labels": "Labels",
        "unconfirmed_email": "The email address will be changed to:",
        "unconfirmed_email_explanation": "The owner should confirm the emailaddress via a link. After confirmation the changed emailaddress will be used.",
        "upload_avatar": "Click the image to upload your own picture",
        "user_type_documentation": "(click here for documentation on user roles)",
        "your_labels": "Your labels"
      },
      "index": {
        "help_text": "Here are all of your organisation's users",
        "import": "CSV import",
        "new_button": "New user",
        "title": "Users"
      },
      "new": {
        "help_text": "Create a new organisation user",
        "title": "New user"
      },
      "password": {
        "title": "Change password"
      },
      "reactivate": {
        "user_reactivated": "The user has been reactivated"
      },
      "removed_member": "removed %{type}",
      "reset_password": {
        "sent": "The password reset email has been sent"
      },
      "row": {
        "number_of_assigned_programs": {
          "one": "1 process",
          "other": "%{count} processes"
        }
      },
      "send_welcome": {
        "bulk_button": "Invite",
        "welcome_mail_sent": "The welcome email has been sent"
      },
      "show": {
        "account_manager": "Account manager of",
        "advisor": "Advisor",
        "biography": "Personal description",
        "candidate_overview": "Candidate overview",
        "client": "Client",
        "cost_center": "Cost centre",
        "created_at": "Created on",
        "disabled": "Disabled",
        "email": "Email address",
        "enabled": "Enabled",
        "impersonate": "Log in as",
        "organization": "Organisation",
        "resend_welcome_email": "Resend welcome email",
        "reset_password": "Reset password",
        "send_email": "Send email",
        "send_welcome_email": "Send welcome email",
        "status": "Status",
        "two_factor": "Two-step verification",
        "type": "Role",
        "user_overview": "User overview"
      },
      "update": {
        "title": "Edit user",
        "updated": "The user has been updated"
      }
    },
    "validations": {
      "minlength_12": "at least twelve characters."
    },
    "valvat": {
      "country_adjectives": {
        "at": "Austrian",
        "be": "Belgian",
        "bg": "Bulgarian",
        "cy": "Cypriot",
        "cz": "Czech",
        "de": "German",
        "dk": "Danish",
        "ee": "Estonian",
        "es": "Spanish",
        "eu": "European",
        "fi": "Finnish",
        "fr": "French",
        "gb": "British",
        "gr": "Greek",
        "hu": "Hungarian",
        "ie": "Irish",
        "it": "Italian",
        "lt": "Lithuanian",
        "lu": "Luxembourgian",
        "lv": "Latvian",
        "mt": "Maltese",
        "nl": "Dutch",
        "pl": "Polish",
        "pt": "Portuguese",
        "ro": "Romanian",
        "se": "Swedish",
        "si": "Slovenian",
        "sk": "Slovakian"
      }
    },
    "will_paginate": {
      "container_aria_label": "Pagination",
      "next_aria_label": "Next page",
      "next_label": "Next &#8594;",
      "page_aria_label": "Page %{page}",
      "page_entries_info": {
        "multi_page": "Displaying %{model} %{from} - %{to} of %{count} in total",
        "multi_page_html": "Displaying %{model} <b>%{from}&nbsp;-&nbsp;%{to}</b> of <b>%{count}</b> in total",
        "single_page": {
          "one": "Displaying 1 %{model}",
          "other": "Displaying all %{count} %{model}",
          "zero": "No %{model} found"
        },
        "single_page_html": {
          "one": "Displaying <b>1</b> %{model}",
          "other": "Displaying <b>all&nbsp;%{count}</b> %{model}",
          "zero": "No %{model} found"
        }
      },
      "page_gap": "&hellip;",
      "previous_aria_label": "Previous page",
      "previous_label": "&#8592; Previous"
    }
  }
}